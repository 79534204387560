/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button, Table, Tag } from "antd";
import { useNavigate } from 'react-router-dom';
import ModalApproveRequest from "./modalApproveRequest";

const TableRequest = (props: any) => {
    const { ListData, params, permissionConfirm } = props;
    const navigate = useNavigate();

    const columns = [
        {
            title: 'STT',
            dataIndex: 'index',
            key: 'index',
            render: (record) => (
                <div>
                    {((params.page - 1) * params.pageSize) + record + 1}
                </div>
            ),
        },
        {
            title: 'SĐT cũ',
            dataIndex: 'oldPhone',
            key: 'oldPhone',
        },
        {
            title: 'Tên KH',
            dataIndex: 'customerName',
            key: 'customerName',
        },
        {
            title: 'BillId',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'SĐT mới',
            dataIndex: 'newPhone',
            key: 'newPhone',
        },
        {
            title: 'Người đề nghị',
            dataIndex: 'createdBy',
            key: 'createdBy',
        },
        {
            title: 'Ghi chú của người tạo yêu cầu',
            dataIndex: 'teleSaleNote',
            key: 'teleSaleNote',
        },
        {
            title: 'Trạng thái',
            dataIndex: 'confirm',
            key: 'confirm',
            render: (record) => (
                <div>
                    {record === 1 &&
                        <Tag color="green" className="w-max">
                            Đã duyệt
                        </Tag>
                    }
                    {record === 2 &&
                        <Tag color="red" className="w-max">
                            Không duyệt
                        </Tag>
                    }
                    {record === 0 &&
                        <Tag color="gray" className="w-max">
                            Chưa duyệt
                        </Tag>
                    }
                </div>
            ),
        },
        {
            title: 'Ghi chú của người duyệt',
            dataIndex: 'noteUpdate',
            key: 'noteUpdate',
        },
        ...(permissionConfirm ? [
            {
                title: 'Hành động',
                dataIndex: 'action',
                key: 'action',
                render: (item, record) => (
                    <>
                        <ModalApproveRequest data={record} />
                    </>
                ),
            }
        ] : [])
    ];

    return (
        <div>
            <Table className={'mt-'} columns={columns} dataSource={ListData} pagination={false} bordered />
        </div>
    );
};

export default TableRequest;
