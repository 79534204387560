/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button, Modal, Spin, notification } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { Stage, Layer, Circle, Image as KonvaImage } from 'react-konva'
import axios from 'axios'
import Cookies from 'js-cookie'
import './index.scss'
import { useMediaQuery } from 'react-responsive'
import TextArea from 'antd/es/input/TextArea'
import { PlusOutlined, RollbackOutlined, UndoOutlined } from '@ant-design/icons'
import { postImageError, refreshImageError } from 'pages/quality/api/api'
import { useParams } from 'react-router-dom'

interface Props {
  selectedImage: string
  selectedImageOrder: number
  setListDefaultImage: any
  listDefaultImage: any
  ListImageNote: any
  setListDefaultNote: any
  isCutImage: boolean
  imageCurl: string
  billId: number
  setSelectedImage: any
  setListDefaultImageCurl: any
  setCheckImageError: any
  setCheckDone: any
  checkDone: any
  setCheckedImageError: any
}

function dataURLtoBlob(dataURL) {
  const binary = atob(dataURL.split(',')[1])
  const array = []
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i))
  }
  return new Blob([new Uint8Array(array)], { type: 'image/png' })
}

const CheckError = (props: Props) => {
  const {
    selectedImage,
    selectedImageOrder,
    setListDefaultImage,
    listDefaultImage,
    ListImageNote,
    setListDefaultNote,
    isCutImage,
    imageCurl,
    billId,
    setSelectedImage,
    setListDefaultImageCurl,
    setCheckImageError,
    setCheckDone,
    checkDone,
    setCheckedImageError
  } = props
  const { id } = useParams()
  const [circles, setCircles] = useState([])
  const [activeCircleId, setActiveCircleId] = useState(null)
  const [draggingCircleId, setDraggingCircleId] = useState(null)
  const [image, setImage] = useState(null)
  const [fittedDimensions, setFittedDimensions] = useState({ width: 0, height: 0 })
  const stageRef = useRef(null)
  const modalRef = useRef(null)
  const isMobile = useMediaQuery({
    query: '(max-width: 500px)'
  })
  const [containerWidth, setContainerWidth] = useState(null)
  const [containerHeight, setContainerHeight] = useState(null)
  const [note, setNote] = useState('')
  const [loading, setLoading] = useState<boolean>(false)
  const [isChange, setIsChange] = useState<boolean>(false)
  const [isResetImage, setIsResetImage] = useState<boolean>(false)

  useEffect(() => {
    if (selectedImageOrder && checkDone) {
      setIsChange(false)
    }
  }, [selectedImageOrder, checkDone])

  console.log(selectedImage, 'selectedImage')
  console.log(image, 'imageConverter')
  // lấy thông tin kích thước modal
  useEffect(() => {
    setContainerWidth(modalRef.current.clientWidth)
    setContainerHeight(500)
  }, [modalRef])

  // thêm note ảnh mặc định
  useEffect(() => {
    if (ListImageNote && selectedImageOrder <= 5) {
      setNote(ListImageNote?.find((item: any) => item?.order === selectedImageOrder)?.error || '')
    }
  }, [ListImageNote, selectedImageOrder])
  // set ảnh
  useEffect(() => {
    if (selectedImage && containerWidth && containerHeight) {
      const img = new window.Image()
      img.crossOrigin = 'Anonymous'
      img.src = selectedImage
        .toString()
        .replace('storage.30shine.com', 's3.ap-southeast-1.amazonaws.com/storage.30shine.com')
      img.onload = () => {
        setImage(img)
        const { width, height } = getFittedDimensions(img.width, img.height, containerWidth, containerHeight)
        setFittedDimensions({ width, height })
      }
      img.onerror = () => {
        console.log('Ảnh không hiển thị được')
      }
    }
  }, [selectedImage, containerWidth, containerHeight])

  // thêm khoanh lỗi & điều chỉnh khoanh lỗi
  const handleAddCircle = () => {
    const id = circles.length > 0 ? circles[circles.length - 1].id + 1 : 0
    setCircles([...circles, { id, x: fittedDimensions.width / 2, y: fittedDimensions.height / 2 }])
    setIsChange(true)
  }

  const handleRemoveLastCircle = () => {
    setCircles(circles.slice(0, -1))
  }

  const handleMouseEnter = (id) => {
    setActiveCircleId(id)
  }

  const handleMouseLeave = () => {
    setActiveCircleId(null)
  }

  const handleTouchStart = (e, id) => {
    setActiveCircleId(id)
  }

  const handleTouchMove = (e) => {
    if (draggingCircleId !== null) {
      const touch = e.targetTouches[0]
      const x = touch.clientX - stageRef.current.getBoundingClientRect().left
      const y = touch.clientY - stageRef.current.getBoundingClientRect().top

      setCircles((circles) => circles.map((circle) => (circle.id === draggingCircleId ? { ...circle, x, y } : circle)))
    }
  }

  const handleTouchEnd = () => {
    setDraggingCircleId(null)
  }

  // thay đổi note
  const handleChangeNote = (note) => {
    setNote(note)
    setIsChange(true)
  }

  // Lấy ảnh cũ chưa chấmsam
  const handleRefreshImage = async () => {
    if (loading) return
    setLoading(true)
    try {
      const response: any = await refreshImageError({
        billId,
        order: selectedImageOrder
      })
      if (response.statusCode === 200) {
        setSelectedImage(response.data)
        setCircles([])
        setIsResetImage(true)
        setIsChange(true)
        notification.success({
          message: 'Thông báo!',
          description: <>Lấy ảnh cũ thành công.</>
        })
      } else {
        notification.error({
          message: 'Thông báo!',
          description: <>Lấy ảnh cũ thất bại.</>
        })
      }
      setLoading(false)
    } catch (error) {
      notification.error({
        message: 'Thông báo!',
        description: <>Lấy ảnh cũ thất bại.</>
      })
      setLoading(false)
      console.log(error, 'error')
    }
  }

  // Ấn nút lưu ảnh
  const handleSaveImage = () => {
    if (loading) return
    setLoading(true)
    const canvas = stageRef.current.toCanvas()
    try {
      if (circles.length === 0) {
        uploadErrorImage(selectedImage)
      } else {
        const dataURL = canvas.toDataURL('image/png')
        const blob = dataURLtoBlob(dataURL)
        uploadImage(blob)
      }
    } catch (error) {
      setLoading(false)
      console.error(error, 'Không lưu được ảnh')
      notification.error({
        message: 'Thông báo!',
        description: <>Không lưu được ảnh</>
      })
      throw error
    }

  }

  // gửi ảnh lên s3 lấy link
  const uploadImage = async (blob: Blob) => {
    const AccessToken = Cookies.get('AccessToken') || ''
    try {
      const formData = new FormData()
      formData.append('photo', blob, 'resized.jpg')
      const axiosResponse = await axios.post(`${process.env.REACT_APP_UPLOAD_STAFF}/staff/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${AccessToken}`
        }
      })
      if (axiosResponse?.data?.data?.image_url) {
        uploadErrorImage(axiosResponse.data.data.image_url)
      }
    } catch (error) {
      console.error(error)
      notification.error({
        message: 'Thông báo!',
        description: <>Không lưu được ảnh</>
      })
      setLoading(false)
      throw error
    }
  }

  // call api cập nhật ảnh mới và note
  const uploadErrorImage = async (img) => {
    const AccessToken = Cookies.get('AccessToken') || ''
    let newListImageNote

    if (ListImageNote) {
      newListImageNote = [...ListImageNote]
      const existingImageNoteIndex = newListImageNote.findIndex((item) => item.order === selectedImageOrder)

      if (existingImageNoteIndex !== -1) {
        newListImageNote[existingImageNoteIndex] = {
          order: selectedImageOrder,
          makeGood: null,
          error: note
        }
      } else {
        newListImageNote.push({
          order: selectedImageOrder,
          makeGood: null,
          error: note
        })
      }
    }

    try {
      const param = {
        id: Number(id),
        order: selectedImageOrder,
        newImage: img,
        note: newListImageNote || [
          {
            order: selectedImageOrder,
            makeGood: null,
            error: note
          }
        ]
      }

      const axiosResponse = await axios.post(
        `${process.env.REACT_APP_API_REPORT}/api/scsc-check/save-image-error`,
        param,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${AccessToken}`
          }
        }
      )

      if (axiosResponse.data.statusCode === 200) {
        setCircles([])
        handleSetErrorImage(img, selectedImageOrder)
        notification.success({
          message: 'Thông báo!',
          description: <>Cập nhật thành công.</>
        })
      } else {
        notification.error({
          message: 'Thông báo!',
          description: <>Cập nhật thất bại.</>
        })
      }
    } catch (error) {
      console.error(error)
      notification.error({
        message: 'Thông báo!',
        description: <>Cập nhật thất bại.</>
      })
      setLoading(false)
      throw error
    }
  }

  // thay ảnh đã chỉnh sửa vào list
  const handleSetErrorImage = (image: string, order: number) => {
    const newListImage = [...listDefaultImage]
    try {
      if (isCutImage) {
        newListImage[order - 1] = image
        setListDefaultImage(newListImage)
      } else {
        setListDefaultImageCurl(image)
      }
      if (circles.length === 0 && !isResetImage) {
        setCheckImageError(true)
        setCheckedImageError(true)
      } else {
        setCheckImageError(false)
        setCheckedImageError(false)
      }
      if (ListImageNote) {
        const newListImageNote = [...ListImageNote]
        if (newListImageNote.find((item: any) => item.order === order)) {
          newListImageNote[newListImageNote.findIndex((item: any) => item.order === selectedImageOrder)] = {
            order: selectedImageOrder,
            makeGood: null,
            error: note
          }
        } else {
          newListImageNote[ListImageNote.length] = {
            order: selectedImageOrder,
            makeGood: null,
            error: note
          }
        }
        setListDefaultNote(newListImageNote)
      } else {
        setListDefaultNote([
          {
            order: selectedImageOrder,
            makeGood: null,
            error: note
          }
        ])
      }
      setIsResetImage(false)
      setCheckDone(true)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  // Lấy kích thước stage điều chỉnh kích thước ảnh
  const getFittedDimensions = (imgWidth, imgHeight, containerWidth, containerHeight) => {
    const imgAspectRatio = imgWidth / imgHeight
    const containerAspectRatio = containerWidth / containerHeight

    let width, height

    if (imgAspectRatio > containerAspectRatio) {
      width = containerWidth
      height = containerWidth / imgAspectRatio
    } else {
      height = containerHeight
      width = containerHeight * imgAspectRatio
    }
    return { width, height }
  }

  return (
    <>
      <div style={{ width: '100%' }} ref={modalRef}></div>
      {selectedImage ? (
        <div className='cover-2'>
          <Spin spinning={!image || loading}>
            <div className='box-cover'>
              <div className='box'>
                {image && (
                  <Stage
                    width={fittedDimensions.width}
                    height={fittedDimensions.height}
                    ref={stageRef}
                    onTouchMove={handleTouchMove}
                  >
                    <Layer>
                      <KonvaImage image={image} width={fittedDimensions.width} height={fittedDimensions.height} />
                      {circles.map((circle) => (
                        <Circle
                          key={circle.id}
                          x={circle.x}
                          y={circle.y}
                          radius={isMobile ? 20 : 30}
                          stroke={'red'}
                          strokeWidth={2}
                          draggable={activeCircleId === circle.id}
                          onMouseEnter={() => handleMouseEnter(circle.id)}
                          onMouseLeave={handleMouseLeave}
                          onTouchStart={(e) => handleTouchStart(e, circle.id)}
                          onTouchEnd={handleTouchEnd}
                        />
                      ))}
                    </Layer>
                  </Stage>
                )}
              </div>
            </div>
          </Spin>

          <div style={{ marginTop: '10px', width: '100%' }}>
            <TextArea
              value={note}
              onChange={(e) => handleChangeNote(e.target.value)}
              placeholder='Note chi tiết lỗi'
              autoSize={{ minRows: 3, maxRows: 5 }}
            />
          </div>
          <div className='flex flex-col mt-2 '>
            <div className='flex flex-w button-cover'>
              <Button
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                icon={<PlusOutlined />}
                onClick={handleAddCircle}
              >
                Khoanh lỗi
              </Button>
              <Button
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                onClick={handleRemoveLastCircle}
                icon={<UndoOutlined rotate={90} />}
                disabled={circles.length === 0}
              >
                Quay lại
              </Button>
              <Button
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                onClick={handleRefreshImage}
                disabled={loading}
                icon={<RollbackOutlined />}
              >
                Ảnh gốc
              </Button>
            </div>
            <div className='flex items-center justify-center mt-2'>
              <Button
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '60%' }}
                onClick={handleSaveImage}
                disabled={loading || !isChange}
                type={loading || !isChange ? 'default' : 'primary'}
              >
                Lưu
              </Button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default CheckError
