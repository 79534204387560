import React, { useState, useEffect } from 'react'
import { Button, Form, Pagination, Row, Select, Spin, Table } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { useMediaQuery } from 'react-responsive'
import api from './api/api'
import { useAppSelector } from 'redux/store'
import BillInfo from './listTable/billInfo'
import StaffReady from './listTable/staffReady'
import StaffTeamInfo from './listTable/staffTeamInfo'

export const SupportLean = () => {
  const { userInfo } = useAppSelector((state) => state.user);
  const [loading, setLoading] = useState<boolean>(false)
  const [form] = Form.useForm()
  const [listSalon, setListSalon] = useState([])
  const [selectedSalon, setSelectedSalon] = useState<any>()
  const [salonData, setSalonData] = useState<any>()
  const isMobile = useMediaQuery({
    query: '(max-width: 500px)'
  })
  const onFinish = async () => {
    if (!selectedSalon) return
    setLoading(true)
    try {
      const data = await api.getInfoSalon(selectedSalon)
      if (data) {
        setSalonData(data)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
    }
  }
  const handleChange = (value: number) => {
    console.log(value, "valuevalue")
    setSelectedSalon(value)
  };

  useEffect(() => {
    if (userInfo?.listSalon && userInfo.listSalon?.length) {
      setListSalon(
        userInfo.listSalon.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      );
      if (userInfo.listSalon?.length === 1) {
        setSelectedSalon(userInfo.listSalon[0].id);
      }
    } else {
      setListSalon([]);
    }
  }, [userInfo]);
  
  return (
    <Spin tip='Đang xử lý dữ liệu...' spinning={loading}>
      <div className='w-full px-5 py-5 search-wrapper'>
        <Form className='pb-10' form={form} onFinish={onFinish}>
          <div className='mb-3 text-2xl'>THÔNG TIN BILL</div>
          <div className='flex flex-col'>
            <div className='mb-3 text-mg'>Chọn salon</div>
            <Select
              value={selectedSalon}
              placeholder="Chọn salon"
              style={{ width: 120 }}
              onChange={(value: number) => handleChange(value)}
              options={listSalon}
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
              }
            />

          </div>
          <div className='mt-8 bg-white ' style={{ padding: isMobile ? '20px 0px 50px 0px' : '20px 20px 100px 20px' }}>
            <Row className='flex gap-x-3'>
              <Button
                type='default'
                htmlType='submit'
                className='w-[200px] bg-[#4096ff] text-white hover:bg-white'
                disabled={loading || !selectedSalon}
              >
                <div className='flex items-center justify-center hover_icon'>
                  <SearchOutlined className='mr-1 text-white' />
                  Xem dữ liệu
                </div>

              </Button>
            </Row>
            {salonData ?
              <div>
                <BillInfo
                  billInfoList={salonData.billInfoList}
                />
                <StaffTeamInfo
                  staffInfoList={salonData.staffInfoList}
                />
                <StaffReady
                  skinnerReadyInfoList={salonData.skinnerReadyInfoList}
                  stylistReadyInfoList={salonData.stylistReadyInfoList}
                  supporterInfoList={salonData.supporterInfoList}
                />
              </div>
              :
              null
            }
          </div>
        </Form>
      </div>
    </Spin>
  )
}
