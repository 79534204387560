import { StaffRequestView } from 'utils/ManagementEndpointRequest/TimelineSocket'
import { catalog } from 'api/config'

export const getListDepartments = () => {
  return catalog.get('/v1/departments')
}

export const getSalons = () => {
  return StaffRequestView.get(`/v1/salons`)
}

export const postNewDepartment = (params: any) => {
  return catalog.post('/v1/departments', params)
}

export const putDepartment = (id: any, params: any) => {
  return catalog.put(`/v1/departments/${id}`, params)
}
