import { Button, DatePicker, Select, Spin, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { useAppSelector } from 'redux/store'
import dayjs from 'dayjs'

const columnVenue = [
  {
    title: 'Doanh thu',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Doanh thu',
    key: 'totalRevenue'
  },
  {
    title: 'Doanh thu/khách',
    key: 'revenueCustomer'
  },
  {
    title: 'Tỷ lệ sử dụng',
    key: 'ratio'
  },
  {
    title: 'So sánh',
    key: 'compare'
  }
]
const columnBook = [
  {
    title: 'Khách hàng',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Số lượng',
    key: 'quantity'
  },
  {
    title: 'Tỷ lệ',
    key: 'ratio'
  },
  {
    title: 'So sánh',
    key: 'compare'
  }
]
const columnProductivity = [
  {
    title: 'Năng suất',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '-',
    key: 'quantity'
  },
  {
    title: 'So sánh',
    key: 'compare'
  }
]
const columnShineMember = [
  {
    title: 'Shine Member',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '-',
    key: 'quantity'
  },
  {
    title: 'Tỷ lệ',
    key: 'ratio'
  },
  {
    title: 'So sánh',
    key: 'compare'
  }
]

const columnQuality = [
  {
    title: 'Chất lượng',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '-',
    key: 'quantity'
  },
  {
    title: 'Tỷ lệ',
    key: 'ratio'
  },
  {
    title: 'So sánh',
    key: 'compare'
  }
]

const initDataVenue = [
  {
    id: 1,
    name: 'TỔNG DOANH THU'
  },
  {
    id: 2,
    name: 'Doanh thu dịch vụ'
  }
]

const initDataBooking = [
  { id: 1, name: 'Lượt khách' },
  { id: 2, name: 'Lượt khách mới' },
  { id: 3, name: 'Book trước' },
  { id: 4, name: 'Khách hủy book' },
  { id: 5, name: 'Book kín slot (online + offline)' },
  { id: 6, name: 'Book online kín slot' },
  { id: 7, name: 'Slot đặt được lịch' },
  { id: 8, name: 'Khách không đến' },
  { id: 9, name: 'Khách có bill bảo hành' }
]

const initDataProductivity = [
  { id: 1, name: 'Năng suất stylist/giờ' },
  { id: 2, name: 'Năng suất skinner/giờ' },
  { id: 3, name: 'Giờ công stylist' },
  { id: 4, name: 'Giờ công skinner' },
  { id: 5, name: 'DT/Salon/ngày' },
  { id: 6, name: 'Khách/stylish/giờ' },
  { id: 7, name: 'Khách/salon/ngày' }
]

const initDataShineMember = [
  { id: 1, name: 'DT TB/khách SM' },
  { id: 2, name: 'Năng suất skinner/giờ' },
  { id: 3, name: 'Tổng SM mới' },
  { id: 4, name: 'Khách mới mua' },
  { id: 5, name: 'Khách cũ chưa có SM mua' },
  { id: 6, name: 'Đã có SM còn hạn mua' },
  { id: 7, name: 'Đã có SM hết hạn mua' },
  { id: 8, name: 'Tổng không mua SM' },
  { id: 9, name: 'Khách mới không mua' },
  { id: 10, name: 'Khách cũ chưa có SM không mua' },
  { id: 11, name: 'Đã có SM còn hạn không mua' },
  { id: 12, name: 'Đã có SM hết hạn không mua' }
]

const initDataQuality = [
  { id: 1, name: 'Rating TB' },
  { id: 2, name: 'Chờ lâu' },
  { id: 3, name: 'Chờ gội >= 10p (Book online)' },
  { id: 4, name: 'Chờ gội >= 15p (Book tại salon)' }
]

const ReportVHV3 = () => {
  const { userInfo } = useAppSelector((state) => state.user)
  const [listSalonSelected, setListSalonSelected] = useState([])
  const [dataVenue, setDataVenue] = useState([])
  const [dataBooking, setDataBooking] = useState([])
  const [dataProductivity, setDataProductivity] = useState([])
  const [dataShineMember, setDataShineMember] = useState([])
  const [dataQuality, setDataQuality] = useState([])
  const [loading, setLoading] = useState(false)
  const [params, setParams] = useState<any>({
    PageSize: 10,
    PageIndex: 1
  })
  const onClickGetData = () => {
    setLoading(true)
    setDataVenue(initDataVenue)
    setDataBooking(initDataBooking)
    setDataProductivity(initDataProductivity)
    setDataShineMember(initDataShineMember)
    setDataQuality(initDataQuality)
    setLoading(false)
  }

  const locale = {
    emptyText: (
      <div className='bg-black bg-opacity-[0.02] py-6'>
        <Button type='primary' loading={loading} onClick={() => onClickGetData()} className='w-max-content'>
          Xem thêm
        </Button>
      </div>
    )
  }

  useEffect(() => {
    if (userInfo.listSalon.length) {
      setListSalonSelected(userInfo.listSalon.map((item) => ({ value: item.id, label: item.salonName })))
    }
  }, [userInfo])
  const handleOnChange = () => {
    console.log(1234)
  }
  return (
    <div className='min-h-[100vh] m-auto bg-[#F3F3F3] pb-12'>
      <div className='wrapper mt-4 text-base font-medium'>
        Báo cáo - Vận hành
        <span className='red pl-1 text-red-500 text-sm'>
          (Chú ý: Báo cáo này được refresh định kỳ vào các phút 0, 10, 20, 30, 40, 50 trong ngày.)
        </span>
      </div>
      <Spin spinning={loading}>
        <div className='wrapper mt-4 flex items-start gap-4'>
          <div className='flex justify-between pr-4 mt-2 gap-x-1'>
            <div className='flex flex-col w-[100px] justify-center items-center gap-y-1'>
              <Button onClick={() => handleOnChange()} style={{ padding: '4px 8px' }} type='primary'>
                {'Hôm kia'}
              </Button>
              <span>{dayjs().subtract(2, 'day').format('DD-MM-YYYY')}</span>
            </div>
            <div className='flex flex-col w-[100px] justify-center items-center gap-y-1'>
              <Button onClick={() => handleOnChange()} style={{ padding: '4px 8px' }} type='primary'>
                {'Hôm qua'}
              </Button>
              <span>{dayjs().subtract(1, 'day').format('DD-MM-YYYY')}</span>
            </div>
            <div className='flex flex-col w-[100px] justify-center items-center gap-y-1'>
              <Button onClick={() => handleOnChange()} style={{ padding: '4px 8px' }} className='' type='primary'>
                {'Hôm nay'}
              </Button>
              <span>{dayjs().format('DD-MM-YYYY')}</span>
            </div>
          </div>
          <div className='flex flex-col'>
            <DatePicker.RangePicker placeholder={['Từ ngày', 'Đến ngày']} />
            <DatePicker.RangePicker placeholder={['Từ ngày so sánh', 'Đến ngày so sánh']} className='mt-4' />
          </div>
          <Select
            showSearch
            placeholder='Chọn salon'
            optionFilterProp='children'
            style={{ width: 200 }}
            filterOption={(input: any, option: any) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={listSalonSelected}
          />
          <Select
            showSearch
            placeholder='Chọn vùng asm'
            optionFilterProp='children'
            style={{ width: 200 }}
            filterOption={(input: any, option: any) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={listSalonSelected}
          />
          <Button type='primary'>Xem dữ liệu</Button>
        </div>

        <div className='wrapper mt-4'>
          <div className='grid grid-cols-2 gap-5'>
            <div className='col-span-1'>
              <Table
                columns={columnVenue}
                locale={locale}
                dataSource={dataVenue}
                pagination={false}
                rowClassName={(record, index) =>
                  index === 0 ? 'bg-orange-300 font-bold' : index === 1 ? 'bg-yellow-200 font-bold' : ''
                }
              />
            </div>
            <div className='col-span-1'>
              <Table
                columns={columnBook}
                locale={locale}
                dataSource={dataBooking}
                pagination={false}
                rowClassName={(record, index) => (index === 0 ? 'bg-blue-400 font-bold' : '')}
              />
            </div>

            <div className='col-span-1 space-y-5'>
              <Table
                columns={columnProductivity}
                locale={locale}
                dataSource={dataProductivity}
                pagination={false}
                rowClassName={(record, index) => (index === 0 ? 'bg-orange-300 font-bold' : '')}
              />
              <Table columns={columnQuality} locale={locale} dataSource={dataQuality} pagination={false} />
            </div>
            <div className='col-span-1'>
              <Table
                columns={columnShineMember}
                locale={locale}
                dataSource={dataShineMember}
                pagination={false}
                rowClassName={(record, index) => (index === 1 ? 'bg-green-400 font-bold' : '')}
              />
            </div>
          </div>
        </div>
      </Spin>
    </div>
  )
}

export default ReportVHV3
