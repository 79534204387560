import { useState, useContext, useEffect } from 'react'
import { getListStaffChange } from 'pages/staff/api'
import FormComponent from 'pages/base/Form'
import { ColumnConfig, SearchConfig, TypeSearch } from 'pages/base/util'
import { AppContext } from 'layouts/DashboardLayout'
import { getSalons } from 'api/catalog'

export const ListBill = () => {
  const [reload, setReload] = useState(false)
  const [options, setOptions] = useState({ salon: [] })
  const { notification } = useContext(AppContext)
  const column: ColumnConfig[] = [
    {
      title: 'STT',
      dataIndex: 'index',
      key: 'index'
    },
    {
      title: 'NGÀY TRẢ',
      dataIndex: 'idSql',
      key: 'idSql'
    },
    {
      title: 'TÊN KHÁCH HÀNG',
      dataIndex: 'idSql',
      key: 'staffName'
    },
    {
      title: 'SDT KHÁCH HÀNG',
      dataIndex: 'staffTypeName',
      key: 'staffTypeName'
    },
    {
      title: 'SALON BÁN',
      dataIndex: 'skillLevelName',
      key: 'skillLevelName'
    },
    {
      title: 'ID NV BÁN TRƯỚC',
      dataIndex: 'salonId',
      key: 'salonId'
    },
    {
      title: 'NV BÁN TRƯỚC',
      dataIndex: 'salonChangeName',
      key: 'salonChangeName'
    },

    {
      title: 'SALON NHẬN',
      dataIndex: 'statusName',
      key: 'statusName'
    },
    {
      title: 'NV NHẬN',
      dataIndex: 'totalSeniorityLevelUp',
      key: 'totalSeniorityLevelUp'
    },
    {
      title: 'ID SẢN PHẨM',
      dataIndex: 'totalSeniorityAt30Shine',
      key: 'totalSeniorityAt30Shine'
    },
    {
      title: 'TÊN SẢN PHẨM',
      dataIndex: 'descriptionDate',
      key: 'descriptionDate'
    },
    {
      title: 'BillCode',
      dataIndex: 'descriptionDate',
      key: 'descriptionDate'
    },
    {
      title: 'SỐ LƯỢNG',
      dataIndex: 'descriptionDate',
      key: 'descriptionDate'
    },
    {
      title: 'HÌNH THỨC TRẢ LẠI',
      dataIndex: 'descriptionDate',
      key: 'descriptionDate'
    },
    {
      title: 'GIÁ SẢN PHẨM',
      dataIndex: 'descriptionDate',
      key: 'descriptionDate'
    },
    {
      title: 'LƯƠNG NHÂN VIÊN',
      dataIndex: 'descriptionDate',
      key: 'descriptionDate'
    },
    {
      title: 'Chiết khấu Doanh thu MP phát sinh (VND) từ việc sử dụng TK topup',
      dataIndex: 'descriptionDate',
      key: 'descriptionDate'
    }
  ]
  const columnSearch: SearchConfig[] = [
    {
      label: 'Ngày trả',
      param: 'createdDate',
      type: TypeSearch.date
    },
    {
      label: 'Salon nhận',
      param: 'salonId',
      type: TypeSearch.select,
      mode: 'multiple',
      options: options.salon,
      placeholder: 'Chọn salon',
      fieldNames: { label: 'name', value: 'id' }
    },
    {
      label: 'Sản phẩm',
      param: 'statusName',
      type: TypeSearch.select,
      options: [],
      placeholder: 'Loại sản phẩm',
      fieldNames: { label: 'label', value: 'value' }
    },
    {
      label: ' ',
      param: 'statusName',
      type: TypeSearch.select,
      options: [],
      placeholder: 'Loại sản phẩm',
      fieldNames: { label: 'label', value: 'value' }
    }
  ]
  const handleChangeValue = (changedValues, onReset: (field: string[]) => void) => {
    if (changedValues?.salonId) {
      onReset(['statusName'])
    }
  }
  const handleFetchData = async () => {
    try {
      const salon: any = await getSalons()
      if (salon.statusCode === 200) {
        setOptions({
          salon: [...salon.data]
        })
      }
    } catch (error) {
      notification('error', 'Thất bại', 'Có lỗi xảy ra')
    }
  }
  useEffect(() => {
    handleFetchData()
  }, [])

  return (
    <div className='min-h-[100vh] m-auto bg-[#F3F3F3] pb-12'>
      <FormComponent
        currentUrl='/bill/back'
        title='HÀNG TRẢ ĐỔI TRẢ'
        column={column}
        columnSearch={columnSearch}
        fetchData={getListStaffChange}
        handleChangeValue={handleChangeValue}
        exportCsv={true}
        reload={reload}
        setReload={setReload}
      ></FormComponent>
    </div>
  )
}
