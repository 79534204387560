/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { DownOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Typography, notification } from "antd";
import axios from "axios";
import { createNoti, updateNoti } from "pages/pushNotification/Api/api";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "redux/store";
import Cookies from 'js-cookie'

interface Props {
    onChangeForm: any,
    form: any,
    isEdit: any,
}

const Step4 = (props: Props) => {
    const navigate = useNavigate()
    const { userInfo } = useAppSelector((state) => state.user)
    const { onChangeForm, form, isEdit } = props
    const [showBox, setShowBox] = useState(false);
    const [warningText, setWarningText] = useState("");
    const [warning, setWarning] = useState(false);
    const [isDone, setIsDone] = useState(false);
    const [loading, setLoading] = useState<boolean>(false)

    const onDoneStep4 = async () => {
        if (loading) return
        setLoading(true)
        if (isEdit) {
            onUpdate()
        } else {
            onCreate()
        }
    };

    const onCreate = async () => {
        const AccessToken = Cookies.get('AccessToken') || '';
        if (warning) return
        const pushData = {
            id: Number(form.id) || undefined,
            name: form.name || undefined,
            title: form.title || undefined,
            descriptions: form.descriptions || undefined,
            url: form.url || undefined,
            images: form.images || undefined,
            type: form.type || undefined,
            isPublish: form.isPublish,
            isDelete: form.isDelete,
            typeObject: form.typeObject || undefined,
            sentNow: form.sentNow,
            sentDate: form.sentDate || undefined,
            salonIds: form.salonIds || undefined,
            departmentIds: form.departmentIds || undefined,
            createdBy: userInfo.fullName,
            fileName: form.fileName || undefined,
        }
        try {
            const response: any = await createNoti(pushData);
            if (response.id) {
                onChangeForm("step4", true)
                notification.success({
                    message: 'Thông báo!',
                    description: <>Lên lịch thông báo thành công</>
                })
                // Upload File
                if (form.file && response.id) {
                    setIsDone(false)
                    setLoading(true)
                    const formData = new FormData()
                    formData.append("data", form.file);
                    const res: any = await axios.post(`${process.env.REACT_APP_API_UPLOAD_CSV_DATA}/notification/import?notiId=${response.id}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: `Bearer ${AccessToken}`,
                        },
                    });
                    if (res.status === 200) {
                        notification.success({
                            message: 'Thông báo!',
                            description: "Upload file thành công!",
                        });
                    } else {
                        notification.error({
                            message: 'Thông báo!',
                            description: "Upload file thất bại!",
                        });

                    }
                    setIsDone(true)
                    setLoading(false)
                }
                setLoading(false)
                setIsDone(true)

            } else {
                setLoading(false)
                notification.error({
                    message: 'Thông báo!',
                    description: <>{response.message}</>,
                });
            }
        } catch (error) {
            setLoading(false)
            notification.error({
                message: 'Thông báo!',
                description: <>Lỗi tạo thông báo</>,
            });
            console.error(error);
            throw error;
        }
    };

    const onUpdate = async () => {
        const AccessToken = Cookies.get('AccessToken') || '';
        if (warning) return
        const pushData = {
            id: Number(form.id) || undefined,
            name: form.name || undefined,
            title: form.title || undefined,
            descriptions: form.descriptions || undefined,
            url: form.url || undefined,
            images: form.images || undefined,
            type: Number(form.type) || undefined,
            isPublish: form.isPublish,
            isDelete: form.isDelete,
            typeObject: form.typeObject || undefined,
            sentNow: form.sentNow,
            sentDate: form.sentDate || undefined,
            salonIds: form.salonIds || undefined,
            departmentIds: form.departmentIds || undefined,
            createdBy: userInfo.fullName,
            fileName: form.fileName || undefined,
            createdTime: form.createdTime || undefined,
            status: form.status || undefined,
        }
        try {
            const response: any = await updateNoti(pushData);
            if (response.statusCode === 200) {
                onChangeForm("step4", true)
                notification.success({
                    message: 'Thông báo!',
                    description: <>Cập nhật thông báo thành công</>
                })
                // Upload File
                if (form.file && Number(form.id)) {
                    setIsDone(false)
                    setLoading(true)
                    const formData = new FormData()
                    formData.append("data", form.file);
                    const res: any = await axios.post(`${process.env.REACT_APP_API_UPLOAD_CSV_DATA}/notification/import?notiId=${form.id}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: `Bearer ${AccessToken}`,
                        },
                    });
                    if (res.status === 200) {
                        notification.success({
                            message: 'Thông báo!',
                            description: "Upload file thành công!",
                        });
                    } else {
                        notification.error({
                            message: 'Thông báo!',
                            description: "Upload file thất bại!",
                        });

                    }
                    setIsDone(true)
                    setLoading(false)
                }
                setIsDone(true)
                setLoading(false)
            } else {
                console.log('error');
                notification.error({
                    message: 'Thông báo!',
                    description: <>{response.message}</>,
                });
                setLoading(false)
            }
        } catch (error) {
            console.error(error);
            setLoading(false)
            throw error;
        }
    };

    useEffect(() => {
        if (form) {
            const text1 = form.step1 ? " " : "Tạo nội dung thông báo "
            const text2 = form.step2 ? " " : "Chọn đối tượng gửi thông báo "
            const text3 = form.step3 ? " " : "Lên lịch gửi thông báo "
            const warningText = "Bạn chưa điền đủ thông tin " + text1 + text2 + text3 + "vui lòng kiểm tra lại."
            setWarningText(warningText)
            if (!form.step1 || !form.step2 || !form.step3) {
                setWarning(true)
            } else {
                setWarning(false)
            }
        }
    }, [form])

    useEffect(() => {
        if (isDone) {
            setShowBox(false)
        }
    }, [isDone])

    useEffect(() => {
        if (form.step3 && !form.step4) {
            setShowBox(true)
        }
    }, [form])
    return (
        <div className="p-[10px]" >
            <div className='p-[10px] flex flex-row justify-between drop-shadow-md' style={{ backgroundColor: showBox ? "#DAEEF9" : "white" }} onClick={() => setShowBox(!showBox)} >
                <div className="flex items-center justify-center">
                    <Typography.Title
                        level={5}
                        style={{ margin: 0 }}
                    >
                        Gửi thông báo
                    </Typography.Title>
                </div>
                <div className="flex items-center justify-center text-lg">
                    {showBox ?
                        <DownOutlined />
                        :
                        <RightOutlined />
                    }
                </div>
            </div>
            {showBox ? (
                <div className="bg-white drop-shadow-md">
                    <div className='p-[20px] body flex justify-center items-center flex-col'>
                        {warning ?
                            <div>{warningText}</div>
                            :
                            <div>Bạn đã điền đủ thông tin để tạo campaign gửi noti.</div>
                        }
                        <div className="pt-5">
                            <Button disabled={warning || isDone} type={warning || isDone ? "default" : "primary"} onClick={onDoneStep4}>Tạo campaign gửi noti</Button>
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    )
}
export default Step4
