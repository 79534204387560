/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState } from 'react'
import Step1 from './steps/step1'
import Step2 from './steps/step2'
import Step3 from './steps/step3'
import Step4 from './steps/step4'
import { getPopupNotiEdit } from '../Api/api'
import { useNavigate, useParams } from 'react-router-dom'
import { getSalons } from 'api/catalog'

const DefaultPushNotification = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [form, setForm] = useState({
    id: 0,
    name: '',
    title: '',
    descriptions: '',
    url: '',
    images: '',
    type: null,
    isPublish: true,
    isDelete: false,
    typeObject: null,
    sentNow: false,
    sentDate: '',
    status: null,
    createdTime: '',
    modifiedTime: '',
    salonIds: '',
    departmentIds: '',
    fileName: '',
    file: null,
    typeTarget: 0,
    step1: false,
    step2: false,
    step3: false,
    step4: false
  })
  const [loading, setLoading] = useState<boolean>(false)
  const [listSalon, setListSalon] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  console.log(form, 'formform')

  const onChangeForm = (key: string, value: any) => {
    setForm((form) => ({ ...form, [key]: value }))
  }

  const getListSalon = async () => {
    if (loading) return
    setLoading(true)
    try {
      const response: any = await getSalons()
      const { statusCode, data } = response
      if (statusCode === 200) {
        setListSalon(data)
      }
      setLoading(false)
    } catch (error) {
      console.error('Error:', error.message)
      setLoading(false)
    }
  }

  const getDefaultData = async (id) => {
    if (loading) return
    setLoading(true)
    try {
      const response: any = await getPopupNotiEdit(id)
      if (response) {
        setForm({
          id: response.id,
          name: response.name,
          title: response.title,
          descriptions: response.descriptions,
          url: response.url,
          images: response.images,
          type: response.type,
          isPublish: response.isPublish,
          isDelete: response.isDelete,
          typeObject: response.typeObject,
          sentNow: response.sentNow,
          sentDate: response.sentDate,
          status: response.status,
          createdTime: response.createdTime,
          modifiedTime: response.modifiedTime,
          salonIds: response.salonIds,
          departmentIds: response.departmentIds,
          fileName: response.fileName,
          file: null,
          typeTarget: response.typeTarget,
          step1: true,
          step2: true,
          step3: true,
          step4: false
        })
        setIsEdit(true)
      }
      setLoading(false)
    } catch (error) {
      console.error('Error:', error.message)
      setLoading(false)
    }
  }

  useEffect(() => {
    getListSalon()
  }, [])

  useEffect(() => {
    if (id) {
      getDefaultData(id)
    } else {
      if (isEdit) {
        window.location.reload()
      }
    }
  }, [id])

  return (
    <div className='px-[20px] notification min-h-[100vh] '>
      <div className='py-4 text-xl font-bold'>Push notification</div>
      <div className='justify-center bg-white '>
        <Step1 onChangeForm={onChangeForm} form={form} isEdit={isEdit} />
        <Step2 onChangeForm={onChangeForm} form={form} listSalon={listSalon} isEdit={isEdit} />
        <Step3 onChangeForm={onChangeForm} form={form} isEdit={isEdit} />
        <Step4 onChangeForm={onChangeForm} form={form} isEdit={isEdit} />
      </div>
    </div>
  )
}
export default DefaultPushNotification
