import { StaffRequestView } from 'utils/ManagementEndpointRequest/TimelineSocket'
import getInstanceAxios from 'utils/requestManager/RequestGlobalConfig'
export const customerViewService = getInstanceAxios(process.env.REACT_APP_API_CUSTOMER_VIEW_SERVICE)


export const convertParamsAxios = (params: any) => {
  const queryString = Object.keys(params)
    .map((key) => {
      const value = params[key]
      if (Array.isArray(value)) {
        return value.map((item) => `${key}=${item}`).join('&')
      }
      return value !== undefined ? `${key}=${value}` : undefined
    })
    .filter((i) => i !== undefined)
    .join('&')
  return queryString
}

export const convertQueryStringToObject = (queryString) => {
  const params = {}

  if (queryString) {
    const pairs = queryString.split('&')

    pairs.forEach((pair) => {
      const [key, value] = pair.split('=')
      if (key && value) {
        if (['PageSize', 'PageIndex', 'Keyword', 'groupId'].includes(key)) {
          params[key] = decodeURIComponent(value)
        }
        // eslint-disable-next-line no-prototype-builtins
        else if (params.hasOwnProperty(key)) {
          if (!Array.isArray(params[key])) {
            params[key] = [params[key]]
          }
          params[key].push(value)
        } else {
          params[key] = [value]
        }
      }
    })
  }

  return params
}

export const getListRequest = (params: any) => {
  return customerViewService.get(`/api/shine-member-update?${convertParamsAxios(params)}`)
}

export const getCusPhone = (params: any) => {
  return customerViewService.get(`/api/v1/checkout/info?Phone=${params}`)
}

export const getCusInfo = (params: any) => {
  return customerViewService.get(`/api/v1/web-customer/overview`, { params })
}

export const postCusPhoneUpdate = (params: any) => {
  return customerViewService.post(`/api/shine-member-update`, params)
}

export const ConfirmUpdateMember = (id: any, params: any) => {
  return customerViewService.put(`/api/shine-member-update/${id}`, params)
}