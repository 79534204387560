/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import './index.scss'
import { Button, DatePicker, Form, Input, Pagination, Row, Select, Spin } from 'antd'
import TableListNoti from './component/tableListNoti'
import { useEffect, useState } from 'react'
import { convertParamsAxios, getListNoti } from '../Api/api'
import { SearchOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import dayjs from 'dayjs'
import { getSalons } from 'api/catalog'

const { RangePicker } = DatePicker

// status là trạng thái đã bắn/đã lên lịch
// type loại đẩy salon/file

export default function ListNotification() {
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [listNoti, setListNoti] = useState<any>([])
  const [name, setName] = useState<any>('')
  const [totalItems, setTotalItems] = useState(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [listSalon, setListSalon] = useState([])
  const [status, setStatus] = useState('')

  const [date, setDate] = useState<{
    fromDate: string
    toDate: string
  }>({
    fromDate: null,
    toDate: null
  })
  const [params, setParams] = useState<any>({
    PageSize: 10,
    PageIndex: 1
  })

  const isMobile = useMediaQuery({
    query: '(max-width: 500px)'
  })

  // Thay đổi lọc time
  const onChangeTime = (value) => {
    setDate({
      fromDate: value[0] ? value[0].format('YYYY-MM-DD') : null,
      toDate: value[1] ? value[1].format('YYYY-MM-DD') : null
    })
  }

  // Thay đổi lọc
  // const onValuesChange = (changedValues) => {
  //   const keyName = Object.keys(changedValues)[0]
  //   const value = changedValues[keyName]
  //   if (keyName.toLowerCase().includes('date')) {
  //     setParams({
  //       ...params,
  //       fromDate: value[0] ? value[0].format('YYYY-MM-DD') : undefined,
  //       toDate: value[1] ? value[1].format('YYYY-MM-DD') : undefined
  //     })
  //   } else {
  //     setParams({ ...params, ...changedValues })
  //   }
  // }
  console.log(params, 'params')
  // Lấy dữ liệu
  const onFinish = async () => {
    if (loading) return
    setLoading(true)
    try {
      const response: any = await getListNoti({
        status: status || undefined,
        name: name || undefined,
        fromDate: date.fromDate || undefined,
        toDate: date.toDate || undefined,
        pageSize: params.PageSize,
        PageIndex: params.PageIndex
      })
      const data = response?.data || null

      if (data) {
        navigate(
          `/pushNotification?${convertParamsAxios({
            ...params,
            name: name || undefined,
            fromDate: date.fromDate || undefined,
            toDate: date.toDate || undefined,
            PageSize: params.PageSize,
            PageIndex: params.PageIndex
          })}`
        )
        setListNoti(data)
        setTotalItems(response?.totalData)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
    }
  }
  // lấy danh sách salon
  const getListSalon = async () => {
    if (loading) return
    setLoading(true)
    try {
      const response: any = await getSalons()
      const { statusCode, data } = response
      if (statusCode === 200) {
        setListSalon(data)
      }
      setLoading(false)
    } catch (error) {
      console.error('Error:', error.message)
      setLoading(false)
    }
  }

  useEffect(() => {
    getListSalon()
  }, [])

  useEffect(() => {
    if (params?.PageIndex) {
      onFinish()
    }
  }, [params?.PageIndex])

  const setIsStop = (id) => {
    setListNoti((prevData) => prevData.map((item) => (item.id === id ? { ...item, status: 4 } : item)))
  }
  const handleChangeStatus = (value: string) => {
    setStatus(value)
  }
  return (
    <div>
      <Spin tip='Đang xử lý dữ liệu...' spinning={loading}>
        <div className={isMobile ? 'w-full search-wrapper' : 'w-full px-5 py-5 search-wrapper'}>
          <Form className='pb-10' form={form} onFinish={onFinish}>
            <div className='text-2xl font-semibold max-sm:pl-2'>Quản lý push noti</div>
            <div
              className='mt-3 bg-white '
              style={{ padding: isMobile ? '20px 0px 50px 0px' : '20px 20px 100px 20px' }}
            >
              <Row>
                <div className={isMobile ? 'flex flex-wrap px-4 justify-between	' : 'flex flex-row gap-x-3'}>
                  <Form.Item label='' style={{ width: isMobile ? '45%' : '100%' }}>
                    <Input
                      defaultValue={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder='Nhập tên thông báo'
                    />
                  </Form.Item>
                  <Form.Item label='' style={{ width: isMobile ? '45%' : '100%' }}>
                    <RangePicker
                      placeholder={['Từ ngày', 'Đến ngày']}
                      onCalendarChange={onChangeTime}
                      value={[date.fromDate ? dayjs(date.fromDate) : null, date.toDate ? dayjs(date.toDate) : null]}
                      format={'DD-MM-YYYY'}
                    />
                  </Form.Item>
                  <Form.Item label='' style={{ width: isMobile ? '45%' : '100%' }}>
                    <Select
                      placeholder='Chọn trạng thái'
                      allowClear
                      onChange={handleChangeStatus}
                      options={[
                        {
                          value: 1,
                          label: 'Đã lên lịch'
                        },
                        {
                          value: 2,
                          label: 'Đang xử lý'
                        },
                        {
                          value: 3,
                          label: 'Đã gửi'
                        },
                        {
                          value: 4,
                          label: 'Đã dừng'
                        }
                      ]}
                    />
                  </Form.Item>
                  <Button type='default' htmlType='submit' className='w-[200px] bg-[#4096ff] text-white hover:bg-white'>
                    <div className='flex items-center justify-center hover_icon'>
                      <SearchOutlined className='mr-1 text-white' />
                      Xem dữ liệu
                    </div>
                  </Button>
                </div>
              </Row>
              <TableListNoti listNoti={listNoti} listSalon={listSalon} setIsStop={setIsStop} />
              <div className='grid mt-8 justify-items-end'>
                <Pagination
                  className=''
                  current={params.PageIndex}
                  pageSize={params.PageSize}
                  total={totalItems}
                  onChange={(page: number, pageSize: number) => {
                    setParams({ ...params, PageSize: pageSize, PageIndex: params.PageSize !== pageSize ? 1 : page })
                    navigate(
                      `/pushNotification?${convertParamsAxios({
                        ...params,
                        PageSize: pageSize,
                        PageIndex: params.PageSize !== pageSize ? 1 : page
                      })}`
                    )
                    setTimeout(
                      () =>
                        window.document.getElementById('body__page').scrollTo({
                          top: 0,
                          behavior: 'smooth'
                        }),
                      500
                    )
                  }}
                />
              </div>
            </div>
          </Form>
        </div>
      </Spin>
    </div>
  )
}
