import { author, catalog } from './config'
import axios from 'axios'
import { AnyARecord } from 'dns'
import Cookies from 'js-cookie'
interface paramsProps {
  Page: number
  PageSize: number
}

export const GetListServices = () => {
  return author.get(`/api/v1/list-select/services-list`)
}
export const updateStatus = (productId, body) => {
  return catalog.put(`/v1/product/publish/${productId}`, body)
}
export const GetListProducts = () => {
  return catalog.get(`/v1/product?pageSize=0&pageIndex=0`)
}
export const GetListProductsInventory = () => {
  return catalog.get(`/v1/product?pageSize=0&pageIndex=0&inventoryType=1`)
}
export const GetListProduct = () => {
  return author.get(`/api/v1/list-select/products-list`)
}
export const updateMemberServices = (body) => {
  return catalog.post(`/v1/member-service`, body)
}
export const updateMemberProduct = (body) => {
  return catalog.post(`/v1/member-product`, body)
}
export const getListServiceSalon = (params: paramsProps) => {
  return author.get(`/api/salon-service/list`, {
    params: params
  })
}
export const getListProductMemberSalon = (salonId, params) => {
  return catalog.get(`/v1/member-product/${salonId}`, {
    params: params
  })
}
export const getListProductMemberProduct = (serviceId, params) => {
  return catalog.get(`/v1/member-product/product/${serviceId}`, {
    params: params
  })
}
export const getListServicesFlowSalon = (params, salonId: number) => {
  return catalog.get(`/v1/salon-product/${salonId}`, {
    params: params
  })
}
export const getListSalonFlowService = (params, serviceId: number) => {
  return catalog.get(`/v1/salon-service/service/${serviceId}`, {
    params: params
  })
}
export const getListServices = (params: paramsProps) => {
  return author.get(`/api/v1/service-manage/services-manage-list`, {
    params: params
  })
}
export const updateSalonProduct = (body) => {
  return catalog.post(`/v1/salon-product`, body)
}
// list config
export const getListTblConfig = (key: string) => {
  return author.get(`/api/v1/list-select/processing-list?key=${key}`)
}
export const getListSalonProduct = (salonId, params) => {
  return catalog.get(`/v1/salon-product/${salonId}`, { params: params })
}
export const getListProductSalon = (productId, params) => {
  return catalog.get(`/v1/salon-product/product/${productId}`, { params: params })
}

export const listProductDatatable = (params: paramsProps) => {
  return author.get(`/api/v1/products/salon-products`, {
    params: params
  })
}
export const createSalonProduct = (body) => {
  return catalog.post(`/v1/salon-product`, body)
}

export const updateSalonProductConfig = (salonProductId: string, body: any) => {
  return author.patch(`/api/v1/products/salon-products/update/${salonProductId}`, body)
}

const getAccessTokenFromCookie = () => {
  return Cookies.get('accessToken') // Thay đổi key này nếu cần
}

export const downloadExcel = async (params: string) => {
  // Thêm kiểu trả về là Blob
  const accessToken = getAccessTokenFromCookie()
  const url = process.env.REACT_APP_API_WEBFORM_ERP + `/api/v1/products/products-list/export?${params}`

  axios({
    url: url,
    method: 'GET',
    responseType: 'blob', // Để nhận dữ liệu dưới dạng blob

    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${accessToken}`
    }
  })
    .then((response) => {
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      })
      const url = URL.createObjectURL(blob)

      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.download = `export_data.xlsx`

      document.body.appendChild(a)
      a.click()

      URL.revokeObjectURL(url)
      document.body.removeChild(a)
    })
    .catch((error) => {
      console.error('Error downloading Excel:', error)
    })
}

export const downloadExcelSalonProducts = async (params: string) => {
  // Thêm kiểu trả về là Blob
  const accessToken = getAccessTokenFromCookie()
  const url = process.env.REACT_APP_API_WEBFORM_ERP + `/api/v1/products/salon-products/export?${params}`

  axios({
    url: url,
    method: 'GET',
    responseType: 'blob', // Để nhận dữ liệu dưới dạng blob

    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${accessToken}`
    }
  })
    .then((response) => {
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      })
      const url = URL.createObjectURL(blob)

      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.download = `export_data.xlsx`

      document.body.appendChild(a)
      a.click()

      URL.revokeObjectURL(url)
      document.body.removeChild(a)
    })
    .catch((error) => {
      console.error('Error downloading Excel:', error)
    })
}

export const listProducts = (params) => {
  return catalog.get(`/v1/product`, {
    params: params
  })
}
export const listCategoryProducts = () => {
  return catalog.get(`/v1/category`)
}

export const updateProduct = (productId: string, body: any) => {
  return catalog.put(`/v1/product/${productId}`, body)
}

export const createProduct = (body: any) => {
  return catalog.post(`/v1/product`, body)
}
export const GenerateCodeProduct = () => {
  return catalog.post(`/v1/product/generate-code`)
}
export const processingList = (key: string) => {
  return author.get(`/api/v1/list-select/processing-list?key=${key}`)
}

export const getListUnit = () => {
  return catalog.get(`/v1/product-config/product-unit-type`)
}

export const getDetailProduct = (productId: string) => {
  return catalog.get(`/v1/product/${productId}`)
}

export const getListProductGroup = () => {
  return catalog.get(`/v1/list-select/product-group-list`)
}

// danh sách thương hiệu
export const getListProductsBrand = () => {
  return author.get(`/api/v1/list-select/product-brands-list`)
}
// list sản phẩm cho thẻ selector
export const getListProductsSelector = () => {
  return author.get(`/api/v1/list-select/products-list`)
}

export const getListProductInventory = () => {
  return catalog.get(`/v1/product-config/product-inventory-type`)
}

export const getListProducMaterialType = () => {
  return catalog.get(`/v1/product-config/product-material-type`)
}

export const getListGroupProduct = () => {
  return catalog.get(`/v1/product-config/product-group-list`)
}

export const getListCategoryProducts = () => {
  return catalog.get(`/v1/category`)
}

export const getListBranchProducts = () => {
  return catalog.get(`/v1/product-config/product-brands-list`)
}
