import React, { Children, useContext, useEffect, useState } from 'react'
import './styles.scss'
import { ClockCircleOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, Checkbox, DatePicker, Form, Pagination, Row, Select, Spin, TreeSelect } from 'antd'
import { useMediaQuery } from 'react-responsive'
import dayjs from 'dayjs'
import { getSalons } from 'api/catalog'
import TableListCongNo from './components/TableListCongNo'
import { ParamsSearchDefault } from 'pages/base/util'
import { AppContext } from 'layouts/DashboardLayout'
import { useAppSelector } from 'redux/store'
import { DATA_CN } from './components/common'
import * as XLSX from 'xlsx'
import { convertParamsAxios, exportToExecl, getListReport } from './api/api'
import { useNavigate } from 'react-router-dom'

function mergeRowsInSheet(worksheet) {
  const mergeRanges = []

  // Gộp các ô từ A1-A2 đến J1-J2
  for (let col = 0; col <= 10; col++) {
    // Cột từ A đến J (index từ 0 đến 9)
    mergeRanges.push({
      s: { r: 0, c: col }, // Ô bắt đầu (row 0, column col)
      e: { r: 1, c: col } // Ô kết thúc (row 1, column col)
    })
  }

  // // Gộp nhóm ô K1-L1-M1
  mergeRanges.push({
    s: { r: 0, c: 11 }, // Ô bắt đầu K1
    e: { r: 0, c: 13 } // Ô kết thúc M1
  })

  //Gộp nhóm ô N1-O1-P1
  mergeRanges.push({
    s: { r: 0, c: 14 }, // Ô bắt đầu N1
    e: { r: 0, c: 16 } // Ô kết thúc P1
  })

  // Thêm các vùng gộp vào worksheet
  if (!worksheet['!merges']) worksheet['!merges'] = []
  mergeRanges.forEach((range) => {
    worksheet['!merges'].push(range)
  })
}

const { RangePicker } = DatePicker
const BcCongNoPage = () => {
  const navigate = useNavigate()
  const { notification } = useContext(AppContext)
  const { userInfo } = useAppSelector((state) => state.user)
  const [totalItems, setTotalItems] = useState(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [listSalon, setListSalon] = useState<any[]>([])
  const [listDataCN, setListDataCN] = useState<any[]>([])
  const [selectedSalon, setSelectedSalon] = useState<number[] | null>([])
  const [form] = Form.useForm()
  const [dataStatic, setDataStatic] = useState<any>()
  const [searchValue, setSearchValue] = useState('')
  const isMobile = useMediaQuery({
    query: '(max-width: 500px)'
  })
  const { SHOW_PARENT } = TreeSelect
  const [params, setParams] = useState<ParamsSearchDefault & any>({
    PageSize: 10,
    PageIndex: 0
  })

  const [date, setDate] = useState<{
    fromDate: string
    toDate: string
  }>({
    fromDate: null,
    toDate: null
  })
  const currentMonth = dayjs().format('M/YYYY')
  const lastMonth = dayjs().subtract(1, 'month').format('M/YYYY')

  const onChangeTime = (value) => {
    setDate({
      fromDate: value[0] ? value[0].format('YYYY-MM-DD') : null,
      toDate: value[1] ? value[1].format('YYYY-MM-DD') : null
    })
  }

  const onChangeTimeThisMonth = () => {
    setDate({
      fromDate: dayjs().startOf('month').format('YYYY-MM-DD'),
      toDate: dayjs().endOf('month').format('YYYY-MM-DD')
    })
  }

  const onChangeTimeLastMonth = () => {
    setDate({
      fromDate: dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
      toDate: dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
    })
  }

  const handleListSalon = async () => {
    try {
      // const response: any = await getSalons()
      // const { statusCode, data } = response
      if (userInfo?.listSalon?.length) {
        const listSalonWithUser = [...userInfo?.listSalon]
        const newData = [
          {
            title: 'Chọn tất cả Salon',
            value: 0,
            key: 0,
            children: [
              ...listSalonWithUser?.map((i) => {
                return {
                  title: i?.name,
                  value: i?.id,
                  key: `${i?.id}`
                }
              })
            ]
          }
        ]
        setListSalon(newData)
      }
    } catch (error) {
      console.error('Error:', error.message)
    }
  }

  const dataValidation = () => {
    let isValid = true
    if (!date?.toDate || !date?.fromDate) {
      notification('error', 'Vui lòng lựa chọn ngày tháng')
      isValid = false
    } else if (!selectedSalon || selectedSalon?.length === 0) {
      notification('error', 'Vui lòng lựa chọn salon')
      isValid = false
    } else {
      isValid = true
    }

    return isValid
  }

  const handleGetData = async () => {
    if (loading) return
    try {
      const isValid = dataValidation()
      if (isValid) {
        const salonListParams = selectedSalon?.includes(0)
          ? [...userInfo?.listSalon?.map((item) => item?.id)]
          : [...selectedSalon]
        setLoading(true)
        const response: any = await getListReport({
          salonId: salonListParams,
          fromDate: dayjs(date.fromDate)?.format('DD/MM/YYYY') || undefined,
          toDate: dayjs(date.toDate)?.format('DD/MM/YYYY') || undefined,
          pageSize: params.PageSize,
          PageIndex: params.PageIndex === 0 ? params.PageIndex + 1 : params?.PageIndex
        })
        console.log('response', response)

        const data = response?.data || null
        if (data) {
          // setParams({ ...params, PageSize: params.pageSize, PageIndex: params.PageIndex + 1 })
          // navigate(
          //   `/BcCongNoPage?${convertParamsAxios({
          //     ...params,
          //     fromDate: date.fromDate || undefined,
          //     toDate: date.toDate || undefined,
          //     PageSize: params.PageSize,
          //     PageIndex: params.PageIndex
          //   })}`
          // )
          const newDataMapIndex = data?.map((item, index) => {
            return {
              ...item,
              index: index
            }
          })
          setListDataCN(newDataMapIndex)
          setTotalItems(response?.totalData)
          setLoading(false)
        }
      } else {
        return
      }
    } catch (error) {
      setLoading(false)
    }
  }

  const handleSearch = (value) => {
    setSearchValue(value)
  }

  useEffect(() => {
    handleListSalon()
  }, [])

  useEffect(() => {
    if (params?.PageIndex) {
      handleGetData()
    }
  }, [params?.PageIndex])

  const onChange = (value) => {
    setSelectedSalon(value)
  }

  const handleExportExcel = async () => {
    try {
      const salonListParams = selectedSalon?.includes(0)
        ? [...userInfo?.listSalon?.map((item) => item?.id)]
        : [...selectedSalon]
      setLoading(true)
      const response: any = await exportToExecl({
        salonId: salonListParams,
        fromDate: dayjs(date.fromDate)?.format('DD/MM/YYYY') || undefined,
        toDate: dayjs(date.toDate)?.format('DD/MM/YYYY') || undefined
      })
      const data = response?.data || null

      if (data) {
        setLoading(false)
        const ws = XLSX.utils.json_to_sheet(
          data['debtTotals']?.map((c, index) => {
            return {
              STT: index + 1,
              Tháng: dayjs(date.fromDate).month() + 1,
              Năm: dayjs(date.fromDate).year(),
              Salon: c.salonName,
              'Tổng tiền KH mua TOPUP': c.totalMoneyBuyTopup,
              'Tổng tiền KH tiêu bằng TOPUP': c.totalMoneySpendTopup,
              'Công nợ phải trả 30Shine': c.totalMoneyPayable,
              'Công nợ 30Shine cần trả': c.totalMoneyReceive
            }
          })
        )
        const ws2 = XLSX.utils.json_to_sheet(
          data['detailBuyTopups']?.map((c, index) => {
            return {
              STT: index + 1,
              BillId: c.billId,
              'Ngày mua': dayjs(c.billDate)?.format('DD/MM/YYYY'),
              'Tên KH': c.customerName,
              SĐT: c.customerPhone,
              Salon: c.salonName,
              'Tên SP': c.productName,
              'Số lượng': c.quantity,
              'Đơn giá mua': c.price,
              'Thành tiền': c.totalMoney,
              'Quy đổi topup': c.totalTopupValue
            }
          })
        )

        const detailSpendTopupsData = [{}, ...data['detailSpendTopups']]

        const ws3 = XLSX.utils.json_to_sheet(
          detailSpendTopupsData?.map((c, index) => {
            return {
              STT: index,
              BillId: c.billId,
              Ngày: dayjs(c.billDate)?.format('DD/MM/YYYY'),
              Salon: c.salonName,
              'Mã DV/ SP': c.productOrServiceCode,
              'Tên DV/ SP': c.productOrServiceName,
              'Số lượng': c.quantity,
              'Đơn giá': c.price,
              'Thành tiền': c.totalMoneyBeforeDiscount,
              '% chiết khấu': c.accountDiscountPercent,
              'Doanh thu': c.totalMoneyAfterDiscount,
              'Số điện thoại thanh toán lần 1': c?.customers?.length > 0 ? c?.customers[0]?.customerPhone : '',
              'Số điện thoại 2_1 ': c?.customers?.length > 0 ? c?.customers[0]?.totalMoneyAfterDiscount : '',
              'Chiết khấu 2_2': c?.customers?.length > 0 ? c?.customers[0]?.accountDiscountPercent : '',
              'Số điện thoại thanh toán lần 2': c?.customers?.length > 0 ? c?.customers[1]?.customerPhone : '',
              'Số điện thoại 3_1': c?.customers?.length > 0 ? c?.customers[1]?.totalMoneyAfterDiscount : '',
              'Chiết khấu 3_2': c?.customers?.length > 0 ? c?.customers[1]?.accountDiscountPercent : ''
            }
          })
        )

        // ws3['K1'] = { v: 'Số điện thoại thanh toán 1', s: { font: { bold: true } } }
        // ws3['N1'] = { v: 'Số điện thoại thanh toán 2', s: { font: { bold: true } } }
        ws3['L2'] = {
          v: 'Số điện thoại',
          s: { font: { bold: true }, alignment: { horizontal: 'center', vertical: 'center' } }
        }
        ws3['M2'] = {
          v: 'Tiền thanh toán',
          s: { font: { bold: true }, alignment: { horizontal: 'center', vertical: 'center' } }
        }
        ws3['N2'] = {
          v: 'Chiết khấu',
          s: { font: { bold: true }, alignment: { horizontal: 'center', vertical: 'center' } }
        }

        ws3['O2'] = {
          v: 'Số điện thoại',
          s: { font: { bold: true }, alignment: { horizontal: 'center', vertical: 'center' } }
        }
        ws3['P2'] = {
          v: 'Tiền thanh toán',
          s: { font: { bold: true }, alignment: { horizontal: 'center', vertical: 'center' } }
        }
        ws3['Q2'] = {
          v: 'Chiết khấu',
          s: { font: { bold: true }, alignment: { horizontal: 'center', vertical: 'center' } }
        }

        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, ws, 'BC Công nợ thẻ member')
        XLSX.utils.book_append_sheet(workbook, ws2, 'Chi tiết bill mua TOPUP')
        XLSX.utils.book_append_sheet(workbook, ws3, 'Chi tiết bill tiêu TOPUP')

        mergeRowsInSheet(workbook.Sheets['Chi tiết bill tiêu TOPUP'])

        const buffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })

        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `bc_cn_the_member.xlsx`
        document.body.appendChild(a)
        a.click()
      }
    } catch (error) {
      setLoading(false)
      notification('error', 'Lỗi xuất excel')
    }
  }

  return (
    <div>
      <Spin tip='Đang xử lý dữ liệu...' spinning={loading}>
        <div className={isMobile ? 'w-full search-wrapper' : 'w-full px-5 py-5 search-wrapper'}>
          <Form className='pb-10' form={form} onFinish={() => null}>
            <div className='text-2xl font-semibold max-sm:pl-2'>Báo cáo công nợ thẻ member</div>
            <div
              className='mt-3 bg-white '
              style={{ padding: isMobile ? '20px 0px 50px 0px' : '20px 20px 100px 20px' }}
            >
              <Row>
                <div className={isMobile ? 'flex flex-wrap px-4 justify-between	' : 'flex flex-row gap-x-3'}>
                  <Form.Item label='' style={{ width: isMobile ? '45%' : '100%' }}>
                    <RangePicker
                      placeholder={['Từ ngày', 'Đến ngày']}
                      onCalendarChange={onChangeTime}
                      value={[date.fromDate ? dayjs(date.fromDate) : null, date.toDate ? dayjs(date.toDate) : null]}
                      format={'DD-MM-YYYY'}
                    />
                  </Form.Item>
                  <Form.Item label='' style={{ width: isMobile ? '45%' : '100%' }}>
                    <TreeSelect
                      showSearch
                      treeNodeFilterProp='title'
                      allowClear
                      style={{ width: 300 }}
                      onChange={(item) => onChange(item)}
                      value={selectedSalon ?? undefined}
                      placeholder='Lựa chọn Salon'
                      treeData={listSalon}
                      treeCheckable
                      treeDefaultExpandAll
                      showCheckedStrategy={SHOW_PARENT}
                      searchValue={searchValue}
                      onSearch={handleSearch}
                      onBlur={() => setSearchValue('')}
                    />
                  </Form.Item>
                  <Button
                    type='default'
                    htmlType='submit'
                    className='w-[200px] bg-[#4096ff] text-white hover:bg-white mb-1'
                    onClick={handleGetData}
                  >
                    <div className='flex items-center justify-center hover_icon'>
                      <SearchOutlined className='mr-1 text-white' />
                      Xem dữ liệu
                    </div>
                  </Button>
                  <Button
                    type='default'
                    htmlType='submit'
                    className='w-[200px] bg-[#4096ff] text-white hover:bg-white'
                    onClick={handleExportExcel}
                  >
                    <div className='flex items-center justify-center hover_icon'>Xuất Excel</div>
                  </Button>
                </div>
              </Row>
              <Row>
                <div className={isMobile ? 'flex flex-wrap px-4 justify-between	' : 'flex flex-row gap-x-3 '}>
                  <div>
                    <Button type='primary' onClick={onChangeTimeLastMonth}>
                      Tháng trước
                    </Button>
                    <p className='text-center'>{lastMonth}</p>
                  </div>
                  <div>
                    <Button type='primary' onClick={onChangeTimeThisMonth}>
                      Tháng này
                    </Button>
                    <p className='text-center'>{currentMonth}</p>
                  </div>
                </div>
              </Row>
              <TableListCongNo listDataCN={listDataCN} setIsStop={true} params={params} />
              <div className='grid mt-8 justify-items-end'>
                <Pagination
                  className=''
                  current={params.PageIndex}
                  pageSize={params.PageSize}
                  total={totalItems}
                  onChange={(page: number, pageSize: number) => {
                    setParams({ ...params, PageSize: pageSize, PageIndex: page })
                    // navigate(
                    //   `/pushNotification?${convertParamsAxios({
                    //     ...params,
                    //     PageSize: pageSize,
                    //     PageIndex: params.PageSize !== pageSize ? 1 : page
                    //   })}`
                    // )
                    setTimeout(
                      () =>
                        window.document.getElementById('body__page').scrollTo({
                          top: 0,
                          behavior: 'smooth'
                        }),
                      500
                    )
                  }}
                />
              </div>
            </div>
          </Form>
        </div>
      </Spin>
    </div>
  )
}

export default BcCongNoPage
