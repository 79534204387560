import getInstanceAxios from 'utils/requestManager/RequestGlobalConfig'

export const staffServiceApi = getInstanceAxios(process.env.REACT_APP_GET_STAFF_SERVICE)
export const productcatalogViewServiceApi = getInstanceAxios(process.env.REACT_APP_API_PRODUCTCATALOG_VIEW_SERVICE)

export const getListSkillLevel = () => {
    return productcatalogViewServiceApi.get(`/api/v1/skill`)
}

export const getStaffSkillList = (param: any) => {
    return staffServiceApi.get(`/v1/web-staff/staff-skill/${param}`)
}

export const getStaffInfo = (param: any) => {
    return staffServiceApi.get(`/v1/staff-skill-info/${param}`)
}

export const addStaffSkill = (param: any, payload: any) => {
    return staffServiceApi.post(`/v1/web-staff/staff-skill/${param}`, payload)
}

export const updateStaffSkill = (staffId: any, staffSkillPointId: any, payload: any) => {
    return staffServiceApi.put(`/v1/web-staff/staff-skill/${staffId}/${staffSkillPointId}`, payload)
}