/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Form } from "antd"
import TransferComponent from "./transfer"

const PositionTest = [
    {
        id: 1,
        name: 'Stylist'
    },
    {
        id: 2,
        name: 'Skinner'
    },
    {
        id: 5,
        name: 'Chuyên viên tư vấn'
    },
    {
        id: 6,
        name: 'Checkout'
    },
    {
        id: 9,
        name: 'Trưởng salon (SM)'
    },
]

interface Props {
    listSalon: any,
    setSelectedSalonList: any,
    setSelectedPositionList: any,
    form: any,
}
const ChoseSalon = (props: Props) => {
    const { listSalon, setSelectedSalonList, setSelectedPositionList, form } = props
    return (
        <div>
            <Form
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
            >
                <Form.Item
                    label='Danh sách salon'
                    rules={[{ required: true, message: 'Chọn Salon nhận thông báo' }]}
                >
                    <TransferComponent
                        listDataP={listSalon}
                        listDataSelected={form.salonIds}
                        setSelectedList={setSelectedSalonList}
                    />
                </Form.Item>

                <Form.Item
                    label='Danh sách bộ phận'
                    rules={[{ required: true, message: 'Chọn bộ phận nhận thông báo' }]}
                >
                    <TransferComponent listDataP={PositionTest}
                        setSelectedList={setSelectedPositionList}
                        listDataSelected={form.departmentIds}
                    />
                </Form.Item>
            </Form>

        </div>

    )
}
export default ChoseSalon
