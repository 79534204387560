/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { Button, Checkbox, Form, Input, notification, Select } from "antd"
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import { addNewStaffLevel, getStaffType } from "../api";
import convert from "utils/helpers/convert";
import { useNavigate } from "react-router-dom";

const AddStaffSkillLevel = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [listStaffType, setListStaffType] = useState([])
    const [formData, setformData] = useState({
        name: "",
        description: "",
        publish: false,
        departmentIds: []
    })
    const [warning, setWarning] = useState(false)

    const CheckValid = (formData: any) => {
        if (formData.name.length === 0) {
            notification.error({
                message: 'Thông báo!',
                description: <>Chưa nhập tên</>
            })
            setWarning(true)
            return true
        }  else {
            setWarning(false)
            return false
        }
    }

    const onFinish = async () => {
        if (CheckValid(formData)) return
        if (loading) return
        setLoading(true)
        try {
            const response: any = await addNewStaffLevel(formData)
            if (response.statusCode === 200) {
                notification.success({
                    description: 'Tạo bậc thành công!',
                    message: 'Thành công'
                })
                setLoading(false)
                navigate(`/staff-skill-salary/skill-level-list`)
            } else {
                notification.error({
                    message: 'Lỗi',
                    description: response?.message || 'Có lỗi xảy ra, vui lòng thử lại!'
                })
                setLoading(false)
            }
            setLoading(false)
        } catch (error) {
            notification.error({
                message: 'Lỗi',
                description: `${error.response.data}`
            })
            setLoading(false)
        }
    };

    // lấy danh sách vị trí
    const getStaffTypeData = async () => {
        if (loading) return
        setLoading(true)
        try {
            const response: any = await getStaffType(1)
            if (response.data) {
                const newListStaffType = response.data
                setListStaffType(newListStaffType)
                setLoading(false)
            }
        } catch (error) {
            console.error('Error:', error.message)
            notification.error({
                message: 'Thông báo!',
                description: <>Lỗi không lấy được danh sách vị trí!</>
            })
            setLoading(false)
        }
    }

    useEffect(() => {
        getStaffTypeData()
    }, [])

    const onChangeSelected = (key: string, value: any) => {
        setformData((state) => ({ ...state, [key]: value }));
    };
    return (
        <div className='min-h-[100vh] m-auto pb-12'>
            <div className='w-full px-5 py-5 search-wrapper' >
                <div className="p-5 bg-white">
                    <h2 className='my-5 text-3xl font-bold'>Thêm mới và gán bậc lương nhân sự</h2>
                </div>
                <div className="mt-10">
                    <Form
                        name="basic"
                        labelCol={{ span: 5 }}
                        wrapperCol={{ span: 19 }}
                        onFinish={onFinish}
                    >
                        <Form.Item
                            label={
                                <div style={{ display: "flex" }}>
                                    <div style={{ color: "red" }}>*</div>
                                    <div>Bậc lương</div>
                                </div>
                            }
                        >
                            <Input
                                value={formData.name}
                                onChange={(e) => onChangeSelected("name", e.target.value)}
                                placeholder="Nhập bậc lương"
                            />
                            {warning && formData.name.length === 0 ?
                                <div style={{ color: "red" }}>Nhập tên bậc lương</div>
                                :
                                null
                            }
                        </Form.Item>

                        <Form.Item
                            label="Mô tả"
                        >
                            <TextArea
                                value={formData.description}
                                onChange={(e) => onChangeSelected("description", e.target.value)}
                                autoSize={{ minRows: 5, maxRows: 10 }}
                                maxLength={260}
                                placeholder="Nhập mô tả"
                            />
                        </Form.Item>
                        <Form.Item
                            label="Publish"
                        >
                            <Checkbox
                                value={formData.publish}
                                onChange={(e) => onChangeSelected("publish", e.target.checked)}>
                            </Checkbox>
                        </Form.Item>

                        <Form.Item
                            label={
                                <div style={{ display: "flex" }}>
                                    <div style={{ color: "red" }}>*</div>
                                    <div>Gán bậc cho vị trí</div>
                                </div>
                            }
                        >
                            <Select
                                mode="multiple"
                                allowClear
                                value={formData?.departmentIds}
                                style={{ width: '100%' }}
                                placeholder="Chọn vị trí"
                                onChange={(value) => onChangeSelected("departmentIds", value)}
                                options={listStaffType}
                                fieldNames={{ label: 'name', value: 'id' }}
                                showSearch
                                optionFilterProp='children'
                                filterOption={(input, option) =>
                                    convert
                                        .removeVietnameseAccent(option.name)
                                        .toLowerCase()
                                        .includes(convert.removeVietnameseAccent(input))
                                }
                            />
                        </Form.Item>
                        <Form.Item wrapperCol={{ offset: 12, span: 12 }}>
                            <Button type="primary" htmlType="submit">
                                Hoàn tất
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>

    )
}

export default AddStaffSkillLevel