/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable jsx-a11y/anchor-has-content */
import '../index.scss'
import '../../../index.css'
import { FileExcelOutlined, LoadingOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { Form, Button, Row, Select, Table, Pagination, Input, Modal, Spin } from 'antd'
import { useEffect, useMemo, useRef, useState, useContext } from 'react'
import {
  convertParamsAxios,
  convertQueryStringToObject,
  exportCSVListStaff,
  getASMS,
  getCity,
  getContractType,
  getListStaff,
  getManagers,
  getPermission,
  getProfileType,
  getRecruitmentSource,
  getStaffSkillLevel,
  getStaffType,
  getWorkingStaff,
  switchSalonStaff
} from '../api'
import { resetStaff, setSearchParams, setStaff } from '../staffSlice'
import { removeVietnamese } from 'utils/selector/removeVietnamese'
import { ItemStaffView, ParamsSearchType, SelectType } from '../interface'
import FormSearch from './FormSearch'
import { SpinIndicator } from 'antd/es/spin'
import { AppContext } from 'layouts/DashboardLayout'
import { Link, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'redux/store'
import { fetchDataByApiKey } from '../hook/fetchViewStaff'
import { uniq } from 'lodash'
import * as XLSX from 'xlsx'
import { useMediaQuery } from 'react-responsive'
import { getSalons } from 'api/catalog'

export const antIcon: SpinIndicator = <LoadingOutlined style={{ fontSize: 24 }} spin />
export default function StaffComponent() {
  const { notification } = useContext(AppContext)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const dispatch = useAppDispatch()
  const { resource, searchParams }: any = useAppSelector((state) => state.staff)
  const { role, userInfo }: any = useAppSelector((state) => state.user)

  const [form] = Form.useForm()
  const [staffSelect, setStaffSelect] = useState({
    id: '',
    idName: '',
    userName: '',
    salonId: '',
    salonSwitch: ''
  })
  const [mount, setMount] = useState(false)
  const [data, setData] = useState([])
  const [total, setTotal] = useState(0)
  const [preParams, setPreParams] = useState<ParamsSearchType>({ ...searchParams })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const isMobile = useMediaQuery({
    query: '(max-width: 500px)'
  })
  const columns = useMemo(() => {
    return [
      {
        title: 'STT',
        dataIndex: 'index',
        key: 'index',
        align: 'center',
        width: 100,
        fixed: 'left'
      },
      {
        title: 'ID',
        dataIndex: 'idSql',
        key: 'idSql',
        render: (item: string) => <div>{item}</div>,
        align: 'center'
      },
      {
        title: <div className='m-auto w-fit'>{'Họ và tên'}</div>,
        key: 'idName',
        render: (item: ItemStaffView) => (
          <Link
            className='text-[#1677ff] hover:text-black'
            onClick={() => dispatch(resetStaff())}
            to={`/staff/detail/${item.id}`}
          >
            {item.idName}
          </Link>
        )
      },
      {
        title: <div className='m-auto w-fit'>{'Salon'}</div>,
        dataIndex: 'salonName',
        key: 'salonName'
      },
      {
        title: <div className='m-auto w-fit'>{'Bộ phận'}</div>,
        dataIndex: 'staffTypeName',
        key: 'staffTypeName'
      },
      {
        title: <div className='m-auto w-fit'>{'Bậc'}</div>,
        dataIndex: 'skillLevelName',
        key: 'skillLevelName'
      },
      {
        title: 'Số điện thoại',
        dataIndex: 'phone',
        key: 'phone',
        align: 'center'
      },
      {
        title: <div className='m-auto w-fit'>{'Tài khoản'}</div>,
        dataIndex: 'userName',
        key: 'userName',
        render: (item: string) => <div className='2xl:max-w-[200px] xl:max-w-[100px]'>{item}</div>
      },
      {
        title: <div className='m-auto w-fit'>{'Tên hợp đồng'}</div>,
        dataIndex: 'contractName',
        key: 'contractName'
      },
      {
        title: (
          <div>
            Ngày (Nâng bậc/ <br />
            Thâm niên)
          </div>
        ),
        dataIndex: 'numSeniorityDay',
        key: 'numSeniorityDay',
        align: 'center'
      },
      {
        title: <div className='m-auto w-fit'>{'Chức năng'}</div>,
        render: (item: ItemStaffView) => {
          return (
            <div className='flex flex-col gap-y-2 max-w-[250px] items-center'>
              <div className='flex items-center justify-center gap-x-3'>
                <Button
                  onClick={async () => {
                    const res: any = await getWorkingStaff(item.id)
                    if (res.statusCode === 0) {
                      setStaffSelect({
                        id: item.id,
                        idName: res.data.idName,
                        userName: res.data.userName,
                        salonId: res.data.salonId,
                        salonSwitch: ''
                      })
                    } else {
                      notification(
                        res.statusCode === 0 ? 'success' : 'error',
                        res.statusCode === 0 ? 'Thành công' : 'Thất bại',
                        res.statusCode === 0 ? 'Chỉnh sửa nhân sự thành công' : res.message
                      )
                    }
                    showModal()
                  }}
                  className={`w-fit ${item.groupId !== 1 ? 'hidden' : ''}`}
                >
                  Chuyển Salon
                </Button>
              </div>
              <div className='flex gap-x-2'>
                <Button
                  className='w-fit '
                  onClick={() => {
                    dispatch(resetStaff())
                    navigate(`/staff/detail/${item.id}`)
                  }}
                >
                  Sửa TT làm việc
                </Button>
              </div>
              <div className='flex gap-x-2'>
                <Button
                  className={`w-fit ${item?.salonName ? '' : 'hidden'}`}
                  onClick={() => {
                    navigate(`/staff/change-staff-skill-level/${item.idSql}`)
                  }}
                >
                  Kỹ năng
                </Button>
              </div>
            </div>
          )
        }
      }
    ]
  }, [])
  function intersection(arr1, arr2) {
    return arr1.filter((value) => arr2.includes(value))
  }
  const handleFetchListStaff = async (paramsSearch: ParamsSearchType) => {
    if (isNaN(Number(paramsSearch.PageIndex))) {
      paramsSearch.PageIndex = 1
    }
    if (isNaN(Number(paramsSearch.PageSize))) {
      paramsSearch.PageSize = 10
    }
    const payload: ParamsSearchType = { ...paramsSearch }
    const result = []
    const currentListSalonId = payload?.SalonId || []
    if (payload?.asms && payload.asms.length > 0 && resource.asms.length > 0 && payload.SalonId?.length > 0) {
      // resource.asms
      //   .filter((i) => payload.asms.includes(i.id))
      //   .forEach((element) => {
      //     element.salonIds.forEach((e) => {
      //       result.push(e)
      //     })
      //   })
      const listSalonAsm = resource.asms.filter((i) => payload.asms.includes(i.id))
      const salonIdsArray = listSalonAsm.map((item) => item.salonIds.filter((id) => typeof id === 'number'))

      // Gộp các mảng con thành một mảng duy nhất
      const flattenedSalonIdsArray = [].concat(...salonIdsArray)
      const resultIntersection = intersection(flattenedSalonIdsArray, payload?.SalonId)
      payload.SalonId = resultIntersection
    }
    if (payload?.asms && payload.asms.length > 0 && resource.asms.length > 0 && !payload.SalonId) {
      console.log('chay vao day')
      resource.asms
        .filter((i) => payload.asms.includes(i.id))
        .forEach((element) => {
          element.salonIds.forEach((e) => {
            result.push(e)
          })
        })
    }
    if (paramsSearch?.birthday) {
      payload.FromDateString = paramsSearch?.birthday[0].slice(5, 10)
      payload.ToDateString = paramsSearch?.birthday[1].slice(5, 10)
    }
    result.length > 0 ? (payload.SalonId = uniq([...result, ...currentListSalonId])) : ''
    delete payload.birthday
    delete payload.asms

    if (role === 'SM' && userInfo.listSalon.length === 1) payload.SalonId = userInfo.listSalon[0].id
    try {
      setLoading(true)
      const res: any = await getListStaff(payload)
      setLoading(false)
      if (res.statusCode === 0) {
        setTotal(res.data.totalRecords)
        setData(res.data.listData)
        setPreParams(payload)
      }
    } catch (error) {
      notification('error', 'Thất bại', 'Có lỗi! Vui lòng kiểm tra lại')
    }
    setLoading(false)
  }
  const prefetchDataStaffList = () => {
    const paramsSearchResult: any = convertQueryStringToObject(window.location.search.replace('?', ''))
    if (Object.keys(paramsSearchResult).length > 0) {
      dispatch(
        setSearchParams({
          ...paramsSearchResult,
          PageIndex: Number(paramsSearchResult.PageIndex),
          PageSize: Number(paramsSearchResult.PageSize)
        })
      )
      let payload: any = {}
      if (userInfo.staffType.id === 17 && !paramsSearchResult.SalonId) {
        const listSalon = userInfo.listSalon
        const paramSalonArray = listSalon.map((salon) => {
          return salon.id
        })
        const newSalonId = {
          SalonId: paramSalonArray
        }
        payload = { ...paramsSearchResult, ...newSalonId }
      } else {
        payload = { ...paramsSearchResult }
      }

      handleFetchListStaff(payload)
    } else {
      setData([])
    }
  }

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = async () => {
    if (!staffSelect.salonSwitch) {
      notification('error', 'Thất bại', 'Chưa chọn salon')
      return
    }
    try {
      setLoading(true)
      const res: any = await switchSalonStaff(staffSelect.id, staffSelect.salonSwitch, {
        id: staffSelect.id,
        idSalonNew: staffSelect.salonSwitch,
        newSalonName: resource.salonId.find((i: SelectType) => i.id === staffSelect.salonSwitch)?.name
      })

      notification(
        res.statusCode === 0 ? 'success' : 'error',
        res.statusCode === 0 ? 'Thành công' : 'Thất bại',
        res.statusCode === 0 ? 'Chỉnh sửa nhân sự thành công' : res.message
      )
      if (res.statusCode === 0) {
        prefetchDataStaffList()
      }
    } catch (error) {
      if (error.response.status === 403) notification('error', 'Thất bại', 'Bạn không có quyền thực thi')
      else notification('error', 'Thất bại', 'Có lỗi xảy ra')
      setLoading(false)
    }
    setLoading(false)
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const onFinish = () => {
    const newUrlParams = convertParamsAxios({
      ...searchParams,
      Keyword: searchParams.Keyword ? encodeURIComponent(searchParams.Keyword) : '',
      PageIndex: 1
    })
    const currentUrlParams = window.location.search.substring(1)
    if (newUrlParams === currentUrlParams) {
      prefetchDataStaffList()
    } else {
      navigate(`/staff/list?${newUrlParams}`)
    }
  }

  const handleFetchData = async () => {
    try {
      await Promise.all([
        fetchDataByApiKey('staffTypeId', getStaffType, dispatch, notification, 1),
        fetchDataByApiKey('staffTypeId2', getStaffType, dispatch, notification, 2),
        fetchDataByApiKey('allSkillLevel', getStaffSkillLevel, dispatch, notification),
        fetchDataByApiKey('salonId', getSalons, dispatch, notification),
        fetchDataByApiKey('provinceId', getCity, dispatch, notification),
        fetchDataByApiKey('recruitmentSource', getRecruitmentSource, dispatch, notification),
        fetchDataByApiKey('permission', getPermission, dispatch, notification),
        fetchDataByApiKey('profileTypes', getProfileType, dispatch, notification),
        fetchDataByApiKey('contractType', getContractType, dispatch, notification),
        fetchDataByApiKey('asms', getASMS, dispatch, notification),
        fetchDataByApiKey('managers', getManagers, dispatch, notification)
      ])
    } catch (error) {
      notification('error', 'Thất bại', 'Có lỗi xảy ra!')
    }
  }
  useEffect(() => {
    handleFetchData()
  }, [])
  useEffect(() => {
    prefetchDataStaffList()
  }, [window.location.search])
  useEffect(() => {
    const layout__header = window.document.getElementById('layout__header')
    if (layout__header) {
      if (isModalOpen) layout__header.style.zIndex = '999'
      else layout__header.style.zIndex = '9999'
    }
  }, [isModalOpen])
  const exportToExcel = async () => {
    try {
      let payload: any = {}
      if ((userInfo.staffType.id === 17 || userInfo.staffType.id === 9) && !preParams.SalonId) {
        const listSalon = userInfo.listSalon
        const paramSalonArray = listSalon.map((salon) => {
          return salon.id
        })
        const newSalonId = {
          SalonId: paramSalonArray
        }
        payload = { ...preParams, ...newSalonId }
      } else {
        payload = { ...preParams }
      }
      delete payload.PageIndex
      delete payload.PageSize
      const dataExcel = await exportCSVListStaff(payload)

      const ws = XLSX.utils.json_to_sheet(dataExcel.data)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
      const buffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })

      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = 'danh_sach_nhan_su.xlsx'
      document.body.appendChild(a)
      a.click()
    } catch (error) {
      console.log(error)
      notification('error', 'Thất bại', 'Có lỗi xảy ra!')
    }
  }
  return (
    <div style={{ width: '100%', padding: 20 }} className='staff_list search-wrapper '>
      <Form className='pb-10' form={form} onFinish={onFinish}>
        <div className='text-sm flex gap-x-3 text-[#b7b3b3]'>
          <Link className='hover:text-black' to='#'>
            Quản lý nhân viên
          </Link>
          <span>{`>`}</span>
          <span className='text-black'>Danh sách nhân viên</span>
        </div>
        <h2 className='text-2xl mb-7'>Danh sách nhân viên</h2>
        <FormSearch />
        <div className='w-full mt-8 bg-white' style={{ padding: '20px 20px 100px 20px' }}>
          <Row className='flex gap-x-3'>
            {isMobile ? (
              <>
                <Button
                  type='default'
                  htmlType='submit'
                  className='px-6 bg-[#4096ff] text-white hover:bg-white flex items-center justify-center w-[80x] hover_icon'
                  disabled={loading}
                  onClick={() => {
                    if (!mount) setMount(true)
                  }}
                >
                  {!loading ? <SearchOutlined className='text-white' /> : <Spin indicator={antIcon} />}
                </Button>
                <Button
                  onClick={exportToExcel}
                  type='default'
                  className='px-6 bg-[#28A745] text-white hover:bg-white hover_icon flex items-center justify-center'
                >
                  <FileExcelOutlined className='text-white ' />
                </Button>
                <Button
                  type='default'
                  onClick={() => navigate(`/staff/add`)}
                  className='px-6 bg-[#28A745] text-white hover:bg-white hover_icon flex items-center justify-center'
                >
                  <PlusOutlined className='text-white ' />
                </Button>
              </>
            ) : (
              <>
                <Button
                  type='default'
                  htmlType='submit'
                  className='w-[200px] bg-[#4096ff] text-white hover:bg-white'
                  disabled={loading}
                  onClick={() => {
                    if (!mount) setMount(true)
                  }}
                >
                  {!loading ? (
                    <div className='flex items-center justify-center hover_icon'>
                      <SearchOutlined className='mr-1 text-white' />
                      Xem dữ liệu
                    </div>
                  ) : (
                    <Spin indicator={antIcon} />
                  )}
                </Button>
                <Button
                  onClick={exportToExcel}
                  type='default'
                  className='w-[200px] bg-[#28A745] text-white hover:bg-white hover_icon'
                >
                  <div className='flex items-center justify-center'>
                    <FileExcelOutlined className='mr-1 text-white' />
                    Xuất excel
                  </div>
                </Button>
                <Button
                  type='default'
                  onClick={() => navigate(`/staff/add`)}
                  className='w-[200px] bg-[#28A745] text-white hover:bg-white hover_icon'
                >
                  <div className='flex items-center justify-center'>
                    <PlusOutlined className='mr-1 text-white' />
                    Thêm NV mới
                  </div>
                </Button>
              </>
            )}
          </Row>
          {isMobile ? (
            <Row style={{ display: 'contents' }} gutter={18}>
              <div className=''>
                <h3 className='mt-3'>
                  {'Tổng số nhân viên : '}
                  <span className='text-[red] font-semibold'>{total}</span>
                </h3>
                <Pagination
                  size={'small'}
                  className='mt-2 mb-5'
                  current={searchParams.PageIndex}
                  pageSize={searchParams.PageSize}
                  total={total}
                  onChange={(page: number, pageSize: number) => {
                    dispatch(
                      setSearchParams({
                        ...searchParams,
                        PageSize: pageSize,
                        PageIndex: searchParams.PageSize !== pageSize ? 1 : page
                      })
                    )
                    navigate(
                      `/staff/list?${convertParamsAxios({
                        ...searchParams,
                        PageSize: pageSize,
                        PageIndex: searchParams.PageSize !== pageSize ? 1 : page
                      })}`
                    )
                  }}
                />
              </div>
              <Table
                className='mt-2 overflow-auto'
                dataSource={data}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                columns={columns}
                pagination={false}
                bordered
              />
              <Pagination
                size={'small'}
                className='mt-10'
                current={searchParams.PageIndex}
                pageSize={searchParams.PageSize}
                total={total}
                onChange={(page: number, pageSize: number) => {
                  dispatch(
                    setSearchParams({
                      ...searchParams,
                      PageSize: pageSize,
                      PageIndex: searchParams.PageSize !== pageSize ? 1 : page
                    })
                  )
                  navigate(
                    `/staff/list?${convertParamsAxios({
                      ...searchParams,
                      PageSize: pageSize,
                      PageIndex: searchParams.PageSize !== pageSize ? 1 : page
                    })}`
                  )
                }}
              />
            </Row>
          ) : (
            <Row style={{ display: 'contents' }} gutter={18}>
              <div className='flex items-center justify-between'>
                <h3>
                  {'Tổng số nhân viên : '}
                  <span className='text-[red] font-semibold'>{total}</span>
                </h3>
                <Pagination
                  className='pb-8 mt-8'
                  current={searchParams.PageIndex}
                  pageSize={searchParams.PageSize}
                  total={total}
                  onChange={(page: number, pageSize: number) => {
                    dispatch(
                      setSearchParams({
                        ...searchParams,
                        PageSize: pageSize,
                        PageIndex: searchParams.PageSize !== pageSize ? 1 : page
                      })
                    )
                    navigate(
                      `/staff/list?${convertParamsAxios({
                        ...searchParams,
                        PageSize: pageSize,
                        PageIndex: searchParams.PageSize !== pageSize ? 1 : page
                      })}`
                    )
                  }}
                  showSizeChanger
                />
              </div>
              <Table
                className='mt-2 overflow-auto'
                dataSource={data}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                columns={columns}
                pagination={false}
                bordered
              />
              <Pagination
                className='float-right pb-8 mt-8'
                current={searchParams.PageIndex}
                pageSize={searchParams.PageSize}
                total={total}
                onChange={(page: number, pageSize: number) => {
                  dispatch(
                    setSearchParams({
                      ...searchParams,
                      PageSize: pageSize,
                      PageIndex: searchParams.PageSize !== pageSize ? 1 : page
                    })
                  )
                  navigate(
                    `/staff/list?${convertParamsAxios({
                      ...searchParams,
                      PageSize: pageSize,
                      PageIndex: searchParams.PageSize !== pageSize ? 1 : page
                    })}`
                  )
                  setTimeout(
                    () =>
                      window.document.getElementById('body__page').scrollTo({
                        top: 0,
                        behavior: 'smooth'
                      }),
                    500
                  )
                }}
                showSizeChanger
              />
            </Row>
          )}
        </div>
        <Modal
          centered
          title='Chuyển Salon'
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button key='back' onClick={handleCancel}>
              Hủy
            </Button>,
            <Button className='bg-[#4096ff]' key='submit' type='primary' loading={loading} onClick={handleOk}>
              Xác nhận chuyển Salon
            </Button>
          ]}
        >
          <div className='flex items-center justify-end mt-3 gap-x-4'>
            <p>Tên đăng nhập:</p>
            <Input className='w-[70%]' disabled value={staffSelect.userName} />
          </div>
          <div className='flex items-center justify-end mt-3 gap-x-4'>
            <p>Họ và tên:</p>
            <Input className='w-[70%]' disabled value={staffSelect.idName} />
          </div>
          <div className='flex items-center justify-end mt-3 gap-x-4'>
            <p>Salon hiện tại:</p>
            <Select
              className='w-[70%]'
              disabled
              value={staffSelect.salonId}
              placeholder='Chọn Salon'
              options={role === 'ADMIN' ? resource.salonId : userInfo.listSalon}
              fieldNames={{ label: 'name', value: 'id' }}
              showSearch
              filterOption={(input: any, option: any) =>
                removeVietnamese(option?.label ?? '').includes(removeVietnamese(input))
              }
            />
          </div>
          <div className='flex items-center justify-end mt-3 gap-x-4'>
            <p>Chuyển tới Salon:</p>
            <Select
              onChange={(e) => setStaffSelect({ ...staffSelect, salonSwitch: e })}
              value={staffSelect.salonSwitch}
              className='w-[70%]'
              placeholder='Chọn Salon'
              options={resource.salonId.filter((i) => i.id !== staffSelect.salonId)}
              fieldNames={{ label: 'name', value: 'id' }}
              showSearch
              optionFilterProp='children'
              filterOption={(input: any, option: any) =>
                removeVietnamese(option?.name ?? '').includes(removeVietnamese(input))
              }
            />
          </div>
        </Modal>
      </Form>
    </div>
  )
}
