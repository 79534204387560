import '../index.scss'
import { CloseCircleOutlined, FilterOutlined, LoadingOutlined } from '@ant-design/icons'
import { Button, Row, Col, Popover, Tag, Select, DatePicker, Input } from 'antd'
import { useEffect, useState } from 'react'
import { columnSearch_instance, listSearch, listSearchAdmin } from '../constant'
import { useMediaQuery } from 'react-responsive'
import dayjs from 'dayjs'
import { removeVietnamese } from 'utils/selector/removeVietnamese'
import FormSearchExpend from './FormSearchExpend'
import { setSearchParams } from '../staffSlice'
import { COLUMN_SEARCH_TYPE, ColumnSearch_instance_type, Column_Item, LabelTagSearch, SelectType } from '../interface'
import { useAppDispatch, useAppSelector } from 'redux/store'

export const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />
const { RangePicker } = DatePicker
export default function FormSearch() {
  const dispatch = useAppDispatch()
  const { resource, searchParams }: any = useAppSelector((state) => state.staff)
  const { role, userInfo }: any = useAppSelector((state) => state.user)

  const [filterVisible, setFilterVisible] = useState(false)
  const [columnSearchExpend, setColumnSearchExpend] = useState(columnSearch_instance)
  const [SalonIds, setSalonIds] = useState(searchParams.SalonId)
  const handleVisibleChange = (visible: boolean) => {
    setFilterVisible(visible)
  }
  const isMobile = useMediaQuery({
    query: '(max-width: 500px)'
  })

  const [listSalons, setListSalons] = useState<any>(userInfo.listSalon)
  const handleChangeAsm = (e) => {
    // console.log(e, 'd9a6y la2 eeee')
    setSalonIds([])
    // dispatch(setSearchParams({ ...searchParams, SalonId: [] }))
    if (e && e.length > 0) {
      const filteredSalonIds = resource.asms.filter((asms) => e.includes(asms.id)).flatMap((asms) => asms.salonIds)
      const uniqueSalonIds = [...new Set(filteredSalonIds)]
      // console.log(uniqueSalonIds, 'uniqueSalonIds')
      dispatch(setSearchParams({ ...searchParams, asms: e, SalonId: [] }))
      const filteredSalons = userInfo.listSalon.filter((salon) => uniqueSalonIds.includes(salon.id))
      setListSalons(filteredSalons)
    } else {
      dispatch(setSearchParams({ ...searchParams, asms: e, SalonId: [] }))
      setListSalons(userInfo.listSalon)
    }
  }
  console.log(searchParams, 'searchParams')
  const COLUMN_SEARCH: COLUMN_SEARCH_TYPE = {
    birthday: (
      <RangePicker
        value={searchParams?.birthday ? [dayjs(searchParams.birthday[0]), dayjs(searchParams.birthday[1])] : undefined}
        onChange={(value) => {
          dispatch(
            setSearchParams({
              ...searchParams,
              birthday: value
                ? [
                    value[0] ? dayjs(value[0]).format('YYYY-MM-DD') : '',
                    value[1] ? dayjs(value[1]).format('YYYY-MM-DD') : ''
                  ]
                : undefined
            })
          )
        }}
        className='w-full'
        placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
        format={'DD-MM'}
        disabledDate={(current) => {
          const currentDate = dayjs()
          const startDate = currentDate.startOf('year')
          const endDate = currentDate.endOf('year')
          return current < startDate || current > endDate
        }}
        onClick={(e) => {
          e.preventDefault()
          const arr = window.document.querySelectorAll(
            '.ant-picker-header-super-prev-btn, .ant-picker-header-super-next-btn, .ant-picker-year-btn'
          )
          if (arr.length > 0) {
            arr.forEach((e: any) => (e.style.display = 'none'))
          }
        }}
      />
    ),
    asms: (
      <Select
        mode='multiple'
        value={searchParams.asms}
        allowClear
        className='w-full'
        onChange={(e) => {
          handleChangeAsm(e)
        }}
        placeholder='Chọn vùng ASM'
        options={role === 'ADMIN' ? resource.asms : []}
        fieldNames={{ label: 'name', value: 'id' }}
        showSearch
        optionFilterProp='children'
        filterOption={(input: any, option: any) =>
          removeVietnamese(option?.name ?? '').includes(removeVietnamese(input))
        }
      />
    ),
    SalonId: (
      <Select
        mode='multiple'
        allowClear
        className='w-full'
        value={SalonIds}
        onChange={(e) => {
          dispatch(setSearchParams({ ...searchParams, SalonId: e }))
          setSalonIds(e)
        }}
        placeholder='Chọn Salon'
        options={listSalons}
        fieldNames={{ label: 'name', value: 'id' }}
        showSearch
        optionFilterProp='children'
        filterOption={(input: any, option: any) =>
          removeVietnamese(option?.name ?? '').includes(removeVietnamese(input))
        }
      />
    ),
    StaffType: (
      <Select
        mode='multiple'
        value={searchParams.StaffType}
        allowClear
        className='w-full'
        onChange={(e) => {
          dispatch(setSearchParams({ ...searchParams, StaffType: e }))
        }}
        placeholder='Chọn bộ phận'
        options={[...resource.staffTypeId, ...resource.staffTypeId2]}
        fieldNames={{ label: 'name', value: 'id' }}
        showSearch
        optionFilterProp='children'
        filterOption={(input: any, option: any) =>
          removeVietnamese(option?.name ?? '').includes(removeVietnamese(input))
        }
      />
    ),
    SkillLevelId: (
      <Select
        mode='multiple'
        value={searchParams.SkillLevelId}
        allowClear
        className='w-full'
        onChange={(e) => {
          dispatch(setSearchParams({ ...searchParams, SkillLevelId: e }))
        }}
        placeholder='Chọn bậc kĩ năng'
        options={resource.allSkillLevel}
        fieldNames={{ label: 'name', value: 'id' }}
        showSearch
        optionFilterProp='children'
        filterOption={(input: any, option: any) =>
          removeVietnamese(option?.name ?? '').includes(removeVietnamese(input))
        }
      />
    ),
    PermissionIds: (
      <Select
        mode='multiple'
        value={searchParams.PermissionIds}
        allowClear
        className='w-full'
        onChange={(e) => {
          dispatch(setSearchParams({ ...searchParams, PermissionIds: e }))
        }}
        placeholder='Chọn nhóm quyền'
        options={resource.permission}
        fieldNames={{ label: 'name', value: 'id' }}
        showSearch
        optionFilterProp='children'
        filterOption={(input: any, option: any) =>
          removeVietnamese(option?.name ?? '').includes(removeVietnamese(input))
        }
      />
    ),
    ProfileTypes: (
      <Select
        mode='multiple'
        value={searchParams.ProfileTypes}
        allowClear
        className='w-full'
        onChange={(e) => {
          dispatch(setSearchParams({ ...searchParams, ProfileTypes: e }))
        }}
        placeholder='Chọn loại hồ sơ'
        options={resource.profileTypes}
        fieldNames={{ label: 'name', value: 'id' }}
        showSearch
        optionFilterProp='children'
        filterOption={(input: any, option: any) =>
          removeVietnamese(option?.name ?? '').includes(removeVietnamese(input))
        }
      />
    ),
    ContractTypes: (
      <Select
        mode='multiple'
        value={searchParams.ContractTypes}
        allowClear
        className='w-full'
        onChange={(e) => {
          dispatch(setSearchParams({ ...searchParams, ContractTypes: e }))
        }}
        placeholder='Chọn loại hợp đồng'
        options={resource.contractType}
        fieldNames={{ label: 'name', value: 'id' }}
        showSearch
        optionFilterProp='children'
        filterOption={(input: any, option: any) =>
          removeVietnamese(option?.name ?? '').includes(removeVietnamese(input))
        }
      />
    )
  }

  const handleSetValueLocalStorage = async () => {
    const columStorage = await localStorage.getItem('SEARCH_COLUMN')
    if (!columStorage) {
      localStorage.setItem('SEARCH_COLUMN', JSON.stringify(columnSearch_instance))
    } else {
      if (userInfo.staffType?.id === 17 || userInfo.staffType?.id === 9) {
        setColumnSearchExpend(JSON.parse(columStorage).filter((d) => d.column !== 'asms'))
      } else {
        setColumnSearchExpend(JSON.parse(columStorage))
      }
    }
  }

  useEffect(() => {
    handleSetValueLocalStorage()
  }, [])

  const clearTag = (e: React.MouseEvent<HTMLElement>, key: string) => {
    dispatch(setSearchParams({ ...searchParams, [key]: undefined }))
  }
  const handleRemoveColumn = async (column: Column_Item) => {
    // console.log(column, 'column')
    if (column === 'asms') {
      // thu hien reset listSalon
      setListSalons(userInfo.listSalon)
    }
    const columStorage = JSON.parse((await localStorage.getItem('SEARCH_COLUMN')) as string)
    const newColumn = columStorage.map((i: ColumnSearch_instance_type) => {
      if (i.column === column) return { ...i, show: false }
      return i
    })
    await localStorage.setItem('SEARCH_COLUMN', JSON.stringify(newColumn))
    setColumnSearchExpend([...newColumn])
    dispatch(setSearchParams({ ...searchParams, [column]: undefined }))
  }
  useEffect(() => {
    if (filterVisible) window.document.getElementById('body__page').style.overflow = 'hidden'
    else window.document.getElementById('body__page').style.overflow = 'auto'
  }, [filterVisible])
  // console.log(searchParams, 'searchParams')
  return (
    <>
      {isMobile ? (
        <>
          <div className='items-start'>
            <Col className='mb-2'>
              <Popover
                placement='bottom'
                title={
                  <div className='flex justify-between items-center pb-1 pl-[10px] pr-[10px] w-[108%] ml-[-10px] text-left border-b border-b-[#f0f0f0]'>
                    <p className=''>Thêm điều kiện lọc</p>
                  </div>
                }
                content={
                  <FormSearchExpend
                    setFilterVisible={setFilterVisible}
                    columnSearchExpend={columnSearchExpend}
                    setColumnSearchExpend={setColumnSearchExpend}
                    listSalons={listSalons}
                    setListSalons={setListSalons}
                  />
                }
                trigger='click'
                open={filterVisible}
                onOpenChange={handleVisibleChange}
              >
                <Button
                  className='bg-[#4096ff] text-[white] flex w-full items-center justify-center hover:bg-[white]'
                  icon={<FilterOutlined />}
                >
                  Thêm điều kiện lọc
                </Button>
              </Popover>
            </Col>
            <Col className='mb-2'>
              <Input
                className='w-full'
                value={searchParams.Keyword}
                onChange={(e) => {
                  dispatch(setSearchParams({ ...searchParams, Keyword: e.target.value }))
                }}
                placeholder='Họ và tên/ID/Tên đăng nhập'
              />
            </Col>
            <Col className='mb-2'>
              <Select
                showSearch
                mode='multiple'
                allowClear
                value={searchParams.ActiveStatus}
                onChange={(e) => {
                  dispatch(
                    setSearchParams({
                      ...searchParams,
                      ActiveStatus: e
                    })
                  )
                }}
                className='w-full'
                options={resource.active}
                placeholder='Trạng thái làm việc'
                filterOption={(input: any, option: any) =>
                  removeVietnamese(option?.label ?? '').includes(removeVietnamese(input))
                }
              />
            </Col>
            <Col className='mb-2'>
              <Select
                showSearch
                mode='multiple'
                allowClear
                className='w-full'
                value={searchParams.AddedType}
                onChange={(e) => {
                  dispatch(
                    setSearchParams({
                      ...searchParams,
                      AddedType: e
                    })
                  )
                }}
                options={resource.addedType}
                placeholder='Loại mã NV'
                filterOption={(input: any, option: any) =>
                  removeVietnamese(option?.label ?? '').includes(removeVietnamese(input))
                }
              />
            </Col>
            {columnSearchExpend.map((column: ColumnSearch_instance_type) => {
              return column.show ? (
                <Col className='relative w-full mb-3' key={column.column}>
                  {COLUMN_SEARCH[column.column]}
                  <CloseCircleOutlined
                    onClick={() => handleRemoveColumn(column.column)}
                    className='text-[#898989] rounded-3xl bg-[white] absolute top-[-5px] right-[-5px] cursor-pointer hover:text-[red]'
                  />
                </Col>
              ) : (
                ''
              )
            })}
          </div>
          <div className='w-full'>
            {Object.keys(searchParams).map((item) => {
              if (
                searchParams[item] &&
                !['Keyword', 'ActiveStatus', 'AddedType', 'PageSize', 'PageIndex']
                  .concat(columnSearchExpend.filter((i) => i.show).map((i) => i.column))
                  .includes(item)
              ) {
                const label: LabelTagSearch = {
                  birthday: `${searchParams[item][0]} - ${searchParams[item][1]}`,
                  asms: resource.asms
                    .filter((i: SelectType) => searchParams[item].includes(i.id))
                    .map((j: SelectType) => j.name)
                    .toString(),
                  SalonId: resource.salonId
                    .filter((i: SelectType) => searchParams[item].includes(i.id))
                    .map((j: SelectType) => j.name)
                    .toString(),
                  StaffType: [...resource.staffTypeId, ...resource.staffTypeId2]
                    .filter((i: SelectType) => searchParams[item].includes(i.id))
                    .map((j: SelectType) => j.name)
                    .toString(),
                  SkillLevelId: resource.allSkillLevel
                    .filter((i: SelectType) => searchParams[item].includes(i.id))
                    .map((j: SelectType) => j.name)
                    .toString(),
                  PermissionIds: resource.permission
                    .filter((i: SelectType) => searchParams[item].includes(i.id))
                    .map((j: SelectType) => j.name)
                    .toString(),
                  ProfileTypes: resource.profileTypes
                    .filter((i: SelectType) => searchParams[item].includes(i.id))
                    .map((j: SelectType) => j.name)
                    .toString(),
                  ContractTypes: resource.contractType
                    .filter((i: SelectType) => searchParams[item].includes(i.id))
                    .map((j: SelectType) => j.name)
                    .toString()
                }
                return (
                  <Tag className='w-full mt-5' key={item} closable onClose={(e) => clearTag(e, item)}>
                    {userInfo.staffType.id !== 17 && role !== 'SM' ? (
                      <>{`${listSearchAdmin.find((i) => i.value === item)?.label || ''} : ${
                        label[item as Column_Item]
                      }`}</>
                    ) : (
                      <>{`${listSearch.find((i) => i.value === item)?.label || ''} : ${label[item as Column_Item]}`}</>
                    )}
                  </Tag>
                )
              }
            })}
          </div>
        </>
      ) : (
        <>
          <Row className='items-start gap-y-4'>
            <Col span={4}>
              <Popover
                placement='bottom'
                title={
                  <div className='flex justify-between items-center pb-1 pl-[10px] pr-[10px] w-[108%] ml-[-10px] text-left border-b border-b-[#f0f0f0]'>
                    <p className=''>Thêm điều kiện lọc</p>
                  </div>
                }
                content={
                  <FormSearchExpend
                    setFilterVisible={setFilterVisible}
                    columnSearchExpend={columnSearchExpend}
                    setColumnSearchExpend={setColumnSearchExpend}
                    listSalons={listSalons}
                    setListSalons={setListSalons}
                  />
                }
                trigger='click'
                open={filterVisible}
                onOpenChange={handleVisibleChange}
              >
                <Button
                  className='bg-[#4096ff] text-[white] flex w-full items-center justify-center hover:bg-[white]'
                  icon={<FilterOutlined />}
                >
                  Thêm điều kiện lọc
                </Button>
              </Popover>
            </Col>
            <Col span={4}>
              <Input
                className='w-full'
                value={searchParams.Keyword}
                onChange={(e) => {
                  dispatch(setSearchParams({ ...searchParams, Keyword: e.target.value }))
                }}
                placeholder='Họ và tên/ID/Tên đăng nhập'
              />
            </Col>
            <Col span={4}>
              <Select
                showSearch
                mode='multiple'
                allowClear
                value={searchParams.ActiveStatus}
                onChange={(e) => {
                  dispatch(
                    setSearchParams({
                      ...searchParams,
                      ActiveStatus: e
                    })
                  )
                }}
                className='w-full'
                options={resource.active}
                placeholder='Trạng thái làm việc'
                filterOption={(input: any, option: any) =>
                  removeVietnamese(option?.label ?? '').includes(removeVietnamese(input))
                }
              />
            </Col>
            <Col span={4}>
              <Select
                showSearch
                mode='multiple'
                allowClear
                className='w-full'
                value={searchParams.AddedType}
                onChange={(e) => {
                  dispatch(
                    setSearchParams({
                      ...searchParams,
                      AddedType: e
                    })
                  )
                }}
                options={resource.addedType}
                placeholder='Loại mã NV'
                filterOption={(input: any, option: any) =>
                  removeVietnamese(option?.label ?? '').includes(removeVietnamese(input))
                }
              />
            </Col>
            {columnSearchExpend.map((column: ColumnSearch_instance_type) => {
              return column.show ? (
                <Col className='relative' key={column.column} span={column.column === 'birthday' ? 5 : 4}>
                  {COLUMN_SEARCH[column.column]}
                  <CloseCircleOutlined
                    onClick={() => handleRemoveColumn(column.column)}
                    className='text-[#898989] rounded-3xl bg-[white] absolute top-[-5px] right-[-5px] cursor-pointer hover:text-[red]'
                  />
                </Col>
              ) : (
                ''
              )
            })}
          </Row>
          <Row className='flex gap-x-3'>
            {Object.keys(searchParams).map((item) => {
              if (
                searchParams[item] &&
                !['Keyword', 'ActiveStatus', 'AddedType', 'PageSize', 'PageIndex']
                  .concat(columnSearchExpend.filter((i) => i.show).map((i) => i.column))
                  .includes(item)
              ) {
                const label: LabelTagSearch = {
                  birthday: `${searchParams[item][0]} - ${searchParams[item][1]}`,
                  asms: resource.asms
                    .filter((i: SelectType) => searchParams[item].includes(i.id))
                    .map((j: SelectType) => j.name)
                    .toString(),
                  SalonId: resource.salonId
                    .filter((i: SelectType) => searchParams[item].includes(i.id))
                    .map((j: SelectType) => j.name)
                    .toString(),
                  StaffType: [...resource.staffTypeId, ...resource.staffTypeId2]
                    .filter((i: SelectType) => searchParams[item].includes(i.id))
                    .map((j: SelectType) => j.name)
                    .toString(),
                  SkillLevelId: resource.allSkillLevel
                    .filter((i: SelectType) => searchParams[item].includes(i.id))
                    .map((j: SelectType) => j.name)
                    .toString(),
                  PermissionIds: resource.permission
                    .filter((i: SelectType) => searchParams[item].includes(i.id))
                    .map((j: SelectType) => j.name)
                    .toString(),
                  ProfileTypes: resource.profileTypes
                    .filter((i: SelectType) => searchParams[item].includes(i.id))
                    .map((j: SelectType) => j.name)
                    .toString(),
                  ContractTypes: resource.contractType
                    .filter((i: SelectType) => searchParams[item].includes(i.id))
                    .map((j: SelectType) => j.name)
                    .toString()
                }
                return (
                  <Tag className='mt-5' key={item} closable onClose={(e) => clearTag(e, item)}>
                    {userInfo.staffType.id !== 17 && role !== 'SM' ? (
                      <>{`${listSearchAdmin.find((i) => i.value === item)?.label || ''} : ${
                        label[item as Column_Item]
                      }`}</>
                    ) : (
                      <>{`${listSearch.find((i) => i.value === item)?.label || ''} : ${label[item as Column_Item]}`}</>
                    )}
                  </Tag>
                )
              }
            })}
          </Row>
        </>
      )}
    </>
  )
}
