/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button, Tag } from 'antd'
import Table, { ColumnsType, TableProps } from 'antd/es/table'
import dayjs from 'dayjs'
import ViewNotification from './viewNotification'
import { EditFilled, EditTwoTone } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import DeleteNotification from './deleteNotification'
import { useState } from 'react'


interface Props {
    listNoti: any,
    listSalon: any,
    setIsStop: any
}

const TableListNoti = (props: Props) => {
    const { listNoti, listSalon, setIsStop } = props
    const navigate = useNavigate()
    const checkTime = (time) => {
        const checkTime = dayjs(time).diff(dayjs().add(20, 'minutes')) > 0
        return !checkTime
    };
    const columns: ColumnsType<any> = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            render: (id) => {
                return <span> {id} </span>
            }
        },
        {
            title: 'Tên thông báo',
            dataIndex: 'name',
            width: 200,
            key: 'name',
        },
        {
            title: 'Ngày bắn/ Ngày đã lên lịch bắn',
            dataIndex: 'sentDate',
            key: 'sentDate',
            sorter: {
                compare: (a, b) => {
                    const dateA = new Date(a.sentDate).getTime()
                    const dateB = new Date(b.sentDate).getTime()
                    return dateA - dateB
                },
                multiple: 1
            },
            render: (value) => {
                return (
                    <span>
                        {value ?
                            dayjs(value).format('YYYY-MM-DD HH:mm:ss')
                            :
                            null
                        }
                    </span>
                )
            }
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            sorter: {
                compare: (a, b) => a?.status - b?.status,
                multiple: 1
            },
            render: (status) => {
                let statusData = { text: "", color: "", textColor: "" };
                if (status !== undefined) {
                    switch (status) {
                        case 0:
                            statusData = { text: "", color: "", textColor: "" };
                            break;
                        case 1:
                            statusData = { text: "Đã lên lịch", color: "#F0F0F0", textColor: "#595959" };
                            break;
                        case 2:
                            statusData = { text: "Đang xử lý", color: "#F6FFED", textColor: "#52C41A" };
                            break;
                        case 3:
                            statusData = { text: "Đã gửi", color: "#E6F7FF", textColor: "#1890FF" };
                            break;
                        case 4:
                            statusData = { text: "Đã dừng", color: "#FFF1F0", textColor: "#F5222D" };
                            break;
                        default:
                            break;
                    }
                }
                return (
                    <span>
                        <Tag
                            color={statusData.color}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                borderRadius: '10px'
                            }}
                        >
                            <span style={{ color: statusData.textColor, padding: 2 }}>{statusData?.text}</span>
                        </Tag>
                    </span>
                )
            }
        },
        {
            title: 'Người tạo',
            dataIndex: 'createdBy',
            key: 'createdBy',
            render: (createdBy) => {
                return <span> {createdBy} </span>
            }
        },
        {
            title: 'Hành động',
            dataIndex: '',
            key: '',
            render: (value, record) => (
                <div style={{ display: 'flex' }}>
                    <ViewNotification id={record.id} listSalon={listSalon} />
                    <div style={{ display: 'flex' }}>
                        <Button
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginRight: 10,
                                color: record.status === 3 || record.status === 4 || checkTime(record.sentDate) ? "#BABABA" : '#1890FF',
                                borderColor: record.status === 3 || record.status === 4 || checkTime(record.sentDate) ? "#BABABA" : '#1890FF'
                            }}
                            shape='round'
                            disabled={record.status === 3 || record.status === 4 || checkTime(record.sentDate)}
                            onClick={() => {
                                navigate(`/pushNotification/DefaultPushNotification/${record.id}`)
                            }}
                        >
                            <EditFilled />
                            <span >Sửa</span>
                        </Button>

                        <DeleteNotification data={record} setIsStop={setIsStop} />
                    </div>
                </div>
            )
        }
    ]
    const onChange: TableProps<any>['onChange'] = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra)
    }

    return (
        <div  >
            <Table columns={columns} dataSource={listNoti} onChange={onChange} pagination={false} bordered />
        </div>
    )
}
export default TableListNoti
