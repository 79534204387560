import { UploadOutlined } from '@ant-design/icons'
import { Button, Form, TreeSelect, Upload, UploadFile, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import * as XLSX from 'xlsx'
import convert from 'utils/helpers/convert'
import { getListDepartments, getStaffSkillLevel, getStaffType, importSalaryConfigList } from './api/api'
import TabelPreViewFile from './components/TabelPreViewFile'

const ConfigBasicSalary = () => {
    const [form] = Form.useForm()
    const [listSalon, setListSalon] = useState<any>([])
    const [defaultListSalon, setDefaultListSalon] = useState<any>([])
    const [selectedListSalon, setSelectedListSalon] = useState([])
    const [dataUpload, setDataUpload] = useState([])
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [loading, setLoading] = useState(false)
    const [listStaffType, setListStaffType] = useState([])
    const [levelList, setLevelList] = useState([])
    const [warning, setWarning] = useState(false)
    
    // lấy danh sách vị trí
    const getStaffTypeData = async () => {
        if (loading) return
        setLoading(true)
        try {
            const response: any = await getStaffType(1)
            if (response.data) {
                const newListStaffType = response.data.filter((item: any) => item.skillLevelIds.length > 0)
                setListStaffType(newListStaffType)
                setLoading(false)

            }
        } catch (error) {
            console.error('Error:', error.message)
            notification.error({
                message: 'Thông báo!',
                description: <>Lỗi không lấy được danh sách vị trí!</>
            })
            setLoading(false)
        }
    }
    // Lấy danh sách level
    const getStaffSkillLevelData = async () => {
        if (loading) return
        setLoading(true)
        try {
            const response: any = await getStaffSkillLevel()
            if (response.data) {
                setLevelList(response.data)
                setLoading(false)

            }
        } catch (error) {
            notification.error({
                message: 'Thông báo!',
                description: <>Lỗi không lấy được danh sách level!</>
            })
            console.error('Error:', error.message)
            setLoading(false)
        }
    }
    // Lấy danh sách salon đã chọn
    const onValuesChange = (changedValues) => {
        const salonIds = defaultListSalon.filter(item => changedValues.includes(item.id)).map(item => item.salonId);
        setSelectedListSalon(changedValues[0] === 0 ? [0] : salonIds)
    }

    const onFinish = async (values: any) => {
        if (selectedListSalon?.length <= 0) {
            notification.warning({
                message: 'Cảnh báo',
                description: 'Chưa chọn salon!'
            })
            return
        }
        if (dataUpload?.length <= 0) {
            notification.warning({
                message: 'Cảnh báo',
                description: 'File không có dữ liệu'
            })
            return
        }
        if (warning) {
            notification.warning({
                message: 'Cảnh báo',
                description: 'File chứa dữ liệu không hợp lệ'
            })
            return
        }
        if (loading) return
        setLoading(true)

        try {
            // let listImport = []
            const listData = dataUpload.map((item: any, index: number) => {
                return {
                    departmentId: Number(item.departmentId),
                    levelId: Number(item.levelId),
                    fixedSalary: Number(item.fixedSalary),
                };
            })
            const payload = {
                data: listData,
                salonId: selectedListSalon
            }
            const response: any = await importSalaryConfigList(payload)
            if (response.statusCode === 200) {
                notification.success({
                    description: 'Import file thành công!',
                    message: 'Thành công'
                })
                setDataUpload([])
                setFileList([])
                setLoading(false)
            } else {
                notification.error({
                    message: 'Lỗi',
                    description: response?.message || 'Có lỗi xảy ra, vui lòng thử lại!'
                })
                setLoading(false)

            }
            setLoading(false)
        } catch (error) {
            notification.error({
                message: 'Lỗi',
                description: 'Có lỗi xảy ra, vui lòng thử lại!'
            })
            setLoading(false)
        }
    }
    // Kiểm tra dữ liệu trong file
    const CheckValid = (item: any, index: number) => {
        if (!listStaffType.some((x: any) => x.id === Number(item.departmentId))) {
            notification.error({
                message: 'Thông báo!',
                description: <>STT {index + 1} vị trí nhân sự không tồn tại!</>
            })
            setWarning(true)
            return
        }
        if (!levelList.some((x: any) => x.id === Number(item.levelId))) {
            notification.error({
                message: 'Thông báo!',
                description: <>STT {index + 1} level không tồn tại!</>
            })
            setWarning(true)
            return
        }
        if (item.fixedSalary < 0) {
            notification.error({
                message: 'Thông báo!',
                description: <>STT {index + 1} lương âm!</>
            })
            setWarning(true)
            return
        }
    }
    // Kiểm tra trung lặp dữ liệu trong file
    function checkDuplicates(listdata: any) {
        const seen = new Set();
        for (let i = 0; i < listdata.length; i++) {
            const pair = `${listdata[i].departmentId}-${listdata[i].levelId}`;
            if (seen.has(pair)) {
                return true; // Có cặp trùng lặp
            }
            seen.add(pair);
        }
        return false; // Không có cặp trùng lặp
    }
    // Xử lý file import
    const props = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setWarning(false)
            const newFileList = [file];
            // setFileList([...fileList, file]);
            setFileList(newFileList);
            // var name = file.name;
            const reader = new FileReader();
            reader.onload = (evt) => {
                const data = evt.target.result;
                const workbook = XLSX.read(data, {
                    type: "binary",
                    cellText: false,
                    cellDates: true,
                });
                const first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
                const dataParse = XLSX.utils.sheet_to_json(first_worksheet, {
                    header: 0,
                    raw: false,
                    dateNF: "yyyy-mm-dd",
                });
                /* Update state */
                if (checkDuplicates(dataParse)) {
                    notification.error({
                        message: 'Thông báo!',
                        description: <>Xuất hiện (Vị trí - Level) trùng nhau</>
                    })
                    return
                }
                const newData = dataParse.filter((item: any) => item.departmentId && item.levelId && item.fixedSalary)
                    .map((item: any, index: number) => {
                        CheckValid(item, index)
                        return {
                            ...item,
                            departmentId: Number(item.departmentId),
                            levelId: Number(item.levelId),
                            fixedSalary: Number(item.fixedSalary),
                            index: index + 1,
                            key: index + 1,
                        };
                    });
                setDataUpload(newData);
            };
            reader.readAsBinaryString(file);
            return false;
        },
        fileList,
    };
    // Tạo data select
    function createDepartmentTree(departments, group, parentId) {
        return departments
            .filter((item) => item.group === group && item.parentId === parentId)
            .map((item) => ({
                ...item,
                title: item.name,
                value: item.id,
                children: createDepartmentTree(departments, group, item.id)
            }));
    }

    const getSalonList = async () => {
        try {
            const response: any = await getListDepartments()
            if (response) {
                const SalonDepartmentList = createDepartmentTree(response.data, 1, 2)
                setDefaultListSalon(response.data.filter((item) => item.group === 1))
                const newdata = [{
                    salonId: 0,
                    parentId: 4,
                    group: 1,
                    id: 0,
                    name: "Salon mặc định",
                    title: "Salon mặc định",
                    value: 0,
                    children: null
                }]
                setListSalon(newdata.concat(SalonDepartmentList))

            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getSalonList()
        getStaffSkillLevelData()
        getStaffTypeData()
    }, [])

    return (
        <div className='min-h-[100vh] m-auto bg-[#F3F3F3] pb-12'>
            <div className='w-full px-5 py-5 search-wrapper'>
                <Form className='pb-10' form={form} onFinish={onFinish} >
                    <h2 className='text-2xl font-bold mb-7'>Cấu hình lương cơ bản</h2>
                    <div className=''>
                        <Form.Item
                            name='salonId'
                            label='Chọn Salon cấu hình lương'
                            rules={[{ required: true, message: 'Bạn phải chọn Salon' }]}
                        >
                            <TreeSelect
                                key={JSON.stringify(listSalon)}
                                treeDefaultExpandedKeys={[0, 4]}
                                allowClear
                                className='w-full'
                                placeholder={'Chọn Salon'}
                                treeData={listSalon}
                                showSearch
                                onChange={onValuesChange}
                                treeCheckable={true}
                                virtual={false}
                                filterTreeNode={(input, option) =>
                                    convert.removeVietnameseAccent(option?.title).indexOf(convert.removeVietnameseAccent(input)) >= 0
                                }
                            />
                        </Form.Item>
                    </div>

                    <div className='flex items-center gap-2 mt-2 italic'>
                        Import file{' '}
                        <a
                            href="https://storage.30shine.com/web-staff/cau-hinh-luong/file_example_import_basic_salary.csv"
                            type="media_type"
                            className="py-2 text-[#1890FF] underline-offset-4 underline "
                        >
                            Download file mẫu
                        </a>
                    </div>
                    <div className='mt-2'>
                        <Upload
                            {...props}
                            fileList={fileList}
                            style={{ width: 100, marginLeft: 4 }}
                            accept=".csv"
                        >
                            <Button icon={<UploadOutlined />}>Import file</Button>
                        </Upload>
                    </div>
                    <div>
                        <TabelPreViewFile data={dataUpload} levelList={levelList} listStaffType={listStaffType} />
                    </div>
                    <Button type='primary' className='mt-5' htmlType='submit' disabled={loading || warning}>
                        Cập nhật cấu hình
                    </Button>
                </Form>
            </div>
        </div>
    )
}

export default ConfigBasicSalary
