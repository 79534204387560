/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Button, Image, DatePicker, Form, Input, Radio, Select, Spin } from 'antd'
import { CameraOutlined, DeleteFilled, EditFilled } from '@ant-design/icons'
import { useContext, useEffect, useState, useRef } from 'react'
import WrapForm from './WrapForm'
import dayjs from 'dayjs'
import { antIcon } from '../list/page'
import { Contract_type, PayloadCreate, PayloadCreateSubmit, SelectASMSType, SelectType } from '../interface'
import { Link, useNavigate } from 'react-router-dom'
import { setResource } from '../staffSlice'
import { AppContext } from 'layouts/DashboardLayout'
import { useAppDispatch, useAppSelector } from 'redux/store'
import axios from 'axios'
import Cookies from 'js-cookie'
import { useMediaQuery } from 'react-responsive'

import {
  convertParamsAxios,
  createStaff,
  getASMS,
  getCity,
  getContractType,
  getDistrict,
  getManagers,
  getPermission,
  getProfileType,
  getRecruitmentSource,
  getStaffSkillLevel,
  getStaffType,
  getWard,
  getListDepartments
} from '../api'
import { fetchDataByApiKey } from '../hook/fetchViewStaff'
import { resizeImage } from './resizeImage'
import SlectDepartments from './selectDepartment'
import { getSalons } from 'api/catalog'

export const AddStaff: React.FC = () => {
  const avatarInputRef = useRef(null)
  const idFrontInputRef = useRef(null)
  const idBackInputRef = useRef(null)
  const { notification } = useContext(AppContext)
  const navigate = useNavigate()
  const isMobile = useMediaQuery({
    query: '(max-width: 500px)'
  })
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()
  const { resource }: any = useAppSelector((state) => state.staff)
  const { role, userInfo }: any = useAppSelector((state) => state.user)

  const searchParams = useAppSelector((state) => state.staff.searchParams)

  const [isAddedType, setIsAddedType] = useState(0)
  const [isGroup, setIsGroup] = useState()
  const [loading, setLoading] = useState(false)
  const [jobPosition, setJobPosition] = useState([])
  const [levelList, setLevelList] = useState([])

  const onFinish = async (values: PayloadCreateSubmit) => {
    let newListJobPosition = []
    if (
      jobPosition[jobPosition?.length - 1]?.departmentId === 0 ||
      jobPosition[jobPosition?.length - 1]?.staffTypeId === 0
    ) {
      newListJobPosition = jobPosition?.filter((item, index) => index !== jobPosition?.length - 1)
      setJobPosition(newListJobPosition)
    }
    const payload: PayloadCreate = {
      detail: {
        idName: values.idName,
        idNo: values.idNo,
        idIssuedDate: dayjs(values.idIssuedDate).format('YYYY-MM-DD'),
        idIssuedLocation: values.idIssuedLocation,
        birthday: dayjs(values.birthday).format('YYYY-MM-DD'),
        gender: values.gender,
        genderName: 'string',
        contracts: resource.contractType
          .map((item: Contract_type) => {
            // @ts-ignore: Unreachable code error
            if (values[`contracts_${item.id}`]) {
              return {
                id: item.id,
                name: item.name,
                // @ts-ignore: Unreachable code error
                signedDate: dayjs(values[`contracts_${item.id}`]).format('YYYY-MM-DD')
              }
            }
          })
          .filter((i: Contract_type) => i !== undefined),
        provinceId: values.provinceId,
        districtId: values.districtId,
        wardId: values.wardId,
        address: values.address,
        phone: values.phone,
        email: values.email,
        insuranceNumber: values.insuranceNumber,
        taxCode: values.taxCode,
        bankName: values.bankName,
        bankAccount: values.bankAccount,
        bankAccountName: values.bankAccountName,
        bankBranch: values.bankBranch,
        profileTypes: values.profileTypes,
        jobDesc: values.jobDesc,
        images: [
          {
            type: 'PORTRAIT',
            url: portrait
          },
          {
            type: 'ID_FRONT',
            url: idFront
          },
          {
            type: 'ID_BACK',
            url: idBack
          }
        ]
      },
      working: {
        jobPosition: newListJobPosition?.length > 0 ? newListJobPosition : jobPosition,
        groupId: values.groupId,
        salonId: values.salonId,
        salonName: resource.salonId.find((i: SelectType) => i.id === values.salonId)?.name,
        staffTypeId: values.staffTypeId,
        managerId: values.managerId,
        skillLevel: values.skillLevel,
        workingDate: dayjs(values.workingDate).format('YYYY-MM-DD'),
        larkRegistrationEmail: values.larkRegistrationEmail,
        seniorityDate: dayjs(values.seniorityDate).format('YYYY-MM-DD'),
        addedType: values.addedType,
        parentId: Number(values.parentId),
        requireEnroll: values.requireEnroll,
        appearence: values.appearence,
        recruitmentSourceId: values.recruitmentSourceId,
        skillLevelName: resource.skillLevel.find((i: SelectType) => i.id === values.skillLevel)?.name,
        recruitmentSourceName: resource.recruitmentSource.find((i: SelectType) => i.id === values.recruitmentSourceId)
          ?.name,
        staffTypeName: [...resource.staffTypeId, ...resource.staffTypeId2].find(
          (i: SelectType) => i.id === values.staffTypeId
        )?.name
      },
      permission: {
        userName: values.userName.toLowerCase(),
        isAccountLogin: values.isAccountLogin,
        permission: values.permission
      }
    }
    try {
      setLoading(true)
      const res: any = await createStaff(payload)
      if (res.statusCode === 0) {
        setTimeout(() => {
          navigate(`/staff/detail/${res.data.id}?tab=3`)
          setLoading(false)
          notification(
            res.statusCode === 0 ? 'success' : 'error',
            res.statusCode === 0 ? 'Thành công' : 'Thất bại',
            res.statusCode === 0 ? 'Thêm mới nhân sự thành công.' : res.message
          )
        }, 3000)
      } else {
        notification(
          res.statusCode === 0 ? 'success' : 'error',
          res.statusCode === 0 ? 'Thành công' : 'Thất bại',
          res.statusCode === 0 ? 'Thêm mới nhân sự thành công.' : res.message
        )
        setLoading(false)
      }
    } catch (error) {
      if (error.response.status === 400 && error.response.data.Phone) {
        notification('error', 'Thất bại', error.response.data.Phone)
        setLoading(false)
        return
      }
      if (error.response.status === 403) notification('error', 'Thất bại', 'Bạn không có quyền thực thi')
      else notification('error', 'Thất bại', 'Có lỗi xảy ra')
      setLoading(false)
    }
  }

  const onValuesChange = async (changedValues: any) => {
    if (changedValues?.provinceId !== undefined) {
      const res: any = await getDistrict(changedValues.provinceId)
      if (res.statusCode === 0) {
        dispatch(setResource({ ...resource, districtId: res.data }))
      }
      form.resetFields(['districtId', 'wardId'])
    }
    if (changedValues?.districtId !== undefined) {
      const res: any = await getWard(changedValues.districtId)
      if (res.statusCode === 0) {
        dispatch(setResource({ ...resource, wardId: res.data }))
      }
      form.resetFields(['wardId'])
    }
    if (changedValues?.salonId !== undefined) {
      const result = resource.asms.filter((i: SelectASMSType) => i.salonIds.includes(changedValues?.salonId))
      form.setFieldValue('managerId', result.length > 0 ? result[0].id : null)
    }
    if (changedValues?.addedType !== undefined) {
      setIsAddedType(changedValues?.addedType)
    }
    if (changedValues?.staffTypeId !== undefined) {
      const result = [...resource.staffTypeId, ...resource.staffTypeId2].find(
        (i) => i.id === changedValues?.staffTypeId
      )
      dispatch(setResource({ ...resource, skillLevel: result ? result.skillLevelIds : [] }))
    }
  }
  const handleFetchData = async () => {
    try {
      await Promise.all([
        fetchDataByApiKey('staffTypeId', getStaffType, dispatch, notification, 1),
        fetchDataByApiKey('staffTypeId2', getStaffType, dispatch, notification, 2),
        fetchDataByApiKey('allSkillLevel', getStaffSkillLevel, dispatch, notification),
        fetchDataByApiKey('salonId', getSalons, dispatch, notification),
        fetchDataByApiKey('provinceId', getCity, dispatch, notification),
        fetchDataByApiKey('recruitmentSource', getRecruitmentSource, dispatch, notification),
        fetchDataByApiKey('permission', getPermission, dispatch, notification),
        fetchDataByApiKey('profileTypes', getProfileType, dispatch, notification),
        fetchDataByApiKey('contractType', getContractType, dispatch, notification),
        fetchDataByApiKey('asms', getASMS, dispatch, notification),
        fetchDataByApiKey('managers', getManagers, dispatch, notification),
        fetchDataByApiKey('listDepartments', getListDepartments, dispatch, notification)
      ])
    } catch (error) {
      notification('error', 'Thất bại', 'Có lỗi xảy ra!')
    }
  }
  useEffect(() => {
    handleFetchData()
  }, [])
  const [portrait, setPortrait] = useState('')
  const [idFront, setIDFront] = useState('')
  const [idBack, setIDBack] = useState('')
  const onFileChange = (url: string, type: string) => {
    switch (type) {
      case 'PORTRAIT':
        setPortrait(url)
        break
      case 'ID_FRONT':
        setIDFront(url)
        break
      case 'ID_BACK':
        setIDBack(url)
        break
    }
  }
  const uploadImage = async (resizedImage: Blob, type: string) => {
    const AccessToken = Cookies.get('AccessToken') || ''
    if (loading) return
    try {
      setLoading(true)
      const formData = new FormData()
      formData.append('image', resizedImage, 'resized.jpg')
      const response = await axios.post(`${process.env.REACT_APP_UPLOAD_STAFF}/common`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${AccessToken}`
        }
      })
      console.log(response, 'response')
      if (response.data) onFileChange(response.data[0], type)
    } catch (error) {
      notification.error({
        description: 'Có lỗi xảy ra, vui lòng thử lại sau',
        message: 'Thông báo'
      })
      throw error
    }
    setLoading(false)
  }
  const handleFileInputChange = async (event: React.ChangeEvent<HTMLInputElement>, type: string) => {
    const file = event.target.files[0]
    if (file) {
      try {
        setLoading(true)
        const resizedImage = await resizeImage(file, 1024, 1024)
        await uploadImage(resizedImage, type)
      } catch (error) {
        console.error('Error resizing image:', error)
        setLoading(false)
      }
    } else {
      console.log('ko có ảnh')
      notification.error({
        description: 'Vui lòng chọn ảnh',
        message: 'Thông báo'
      })
    }
  }
  const handleRemoveImage = (type: string) => {
    switch (type) {
      case 'PORTRAIT':
        setPortrait('')
        break
      case 'ID_FRONT':
        setIDFront('')
        break
      case 'ID_BACK':
        setIDBack('')
        break
    }
  }
  return (
    <>
      {isMobile ? (
        <div className='px-5 py-5 min-h-[calc(100vh)] w-[full] overflow-hidden bg-[#F3F3F3] '>
          <div className='w-[full] text-sm  overflow-x-scroll overflow-y-hidden'>
            <div className='w-[600px] pb-2'>
              <Link className='hover:text-black' to='#'>
                Quản lý nhân viên
              </Link>
              <span className='ml-2 mr-2'>{`>`}</span>
              <Link className='hover:text-black' to={`/staff/list?${convertParamsAxios(searchParams)}`}>
                Danh sách nhân viên
              </Link>
              <span className='ml-2 mr-2'>{`>`}</span>
              <span className='text-black'>Thêm mới nhân viên</span>
            </div>
          </div>
          <Form
            className='m-auto'
            onValuesChange={onValuesChange}
            form={form}
            name='basic'
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete='off'
            style={{ width: 'calc(100vw - 40px)' }}
          >
            <h3 className='text-left font-bold text-[22px] mt-3 w-[120%]'>THÊM MỚI NHÂN SỰ</h3>
            <div className='mt-2 personal_information'>
              <div className=''>
                <div className='w-[200px] text-[#000] text-left font-bold text-xl'>Thông tin cá nhân</div>
                <div className='w-[700px] text-xs text-[#898989] mb-5'>
                  <span className='text-[red]'>(*)</span> : Bắt buộc điền thông tin!
                </div>
              </div>
              <div>
                <Form.Item
                  label='Tên đăng nhập:'
                  name='userName'
                  rules={[
                    {
                      required: true,
                      message: 'Không được để trống'
                    },
                    { max: 50, message: 'Không quá 50 ký tự' },
                    {
                      pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                      message: 'Tên đăng nhập không hợp lệ'
                    }
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label='Họ và tên nhân viên:'
                  name='idName'
                  rules={[
                    {
                      required: true,
                      message: 'Không được để trống'
                    },
                    { max: 300, message: 'Không quá 300 ký tự' }
                  ]}
                >
                  <Input placeholder='Họ và tên theo CCCD' />
                </Form.Item>
                <Form.Item
                  label='Số CCCD:'
                  name='idNo'
                  rules={[
                    { required: true, message: 'Số CCCD không được để trống' },
                    {
                      pattern: /^(\d{12}|\d{9})$/,
                      message: 'Số CCCD/CMND gồm 9-12 số'
                    }
                  ]}
                >
                  <Input maxLength={12} placeholder='Nhập CCCD 12 chữ số' />
                </Form.Item>
                <Form.Item
                  label={
                    <div>
                      <span className='text-[red] text-[16px]'>{`* `}</span>
                      Ngày cấp / Nơi cấp
                    </div>
                  }
                >
                  <div className='flex gap-x-3'>
                    <Form.Item
                      name='idIssuedDate'
                      className='w-[205px]'
                      rules={[{ required: true, message: 'Không được để trống' }]}
                    >
                      <DatePicker placeholder='dd-mm-yyyy' format='DD-MM-YYYY' />
                    </Form.Item>
                    <Form.Item
                      className='w-[-webkit-fill-available]'
                      name='idIssuedLocation'
                      rules={[{ required: true, message: 'Không được để trống' }]}
                    >
                      <Input className='' placeholder='Nhập nơi cấp' />
                    </Form.Item>
                  </div>
                </Form.Item>
                <Form.Item
                  label={
                    <div>
                      <span className='text-[red] text-[16px]'>{`* `}</span>
                      Ngày sinh / Giới tính
                    </div>
                  }
                >
                  <div className='flex jus gap-x-3'>
                    <Form.Item
                      name='birthday'
                      className='w-[205px]'
                      rules={[{ required: true, message: 'Không được để trống' }]}
                    >
                      <DatePicker placeholder='dd-mm-yyyy' format='DD-MM-YYYY' />
                    </Form.Item>
                    <Form.Item
                      className='w-[-webkit-fill-available]'
                      name='gender'
                      rules={[{ required: true, message: 'Không được để trống' }]}
                    >
                      <Select options={resource.gender} placeholder='Chọn Giới tính' />
                    </Form.Item>
                  </div>
                </Form.Item>
                <Form.Item
                  label={
                    <div>
                      <span className='text-[red] text-[16px]'>{`* `}</span>
                      Địa chỉ
                    </div>
                  }
                >
                  <div className='flex gap-x-3'>
                    <Form.Item
                      name='provinceId'
                      className='w-[100%]'
                      rules={[{ required: true, message: 'Không được để trống' }]}
                    >
                      <Select
                        options={resource.provinceId}
                        placeholder='Tỉnh / thành phố'
                        fieldNames={{ label: 'name', value: 'id' }}
                        showSearch
                        optionFilterProp='children'
                        filterOption={(input, option) =>
                          (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      className='w-[-webkit-fill-available]'
                      name='districtId'
                      rules={[{ required: true, message: 'Không được để trống' }]}
                    >
                      <Select
                        options={resource.districtId}
                        placeholder='Quận / huyện'
                        fieldNames={{ label: 'name', value: 'id' }}
                        showSearch
                        optionFilterProp='children'
                        filterOption={(input, option) =>
                          (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                      />
                    </Form.Item>
                  </div>
                </Form.Item>
                <Form.Item
                  label={
                    <div>
                      <span className='text-[red] text-[16px]'>{`* `}</span>
                      Địa chỉ cụ thể
                    </div>
                  }
                >
                  <div className='flex gap-x-3'>
                    <Form.Item
                      name='wardId'
                      className='w-[50%]'
                      rules={[{ required: true, message: 'Không được để trống' }]}
                    >
                      <Select
                        options={resource.wardId}
                        placeholder='Phường / xã / thị trấn'
                        fieldNames={{ label: 'name', value: 'id' }}
                        showSearch
                        optionFilterProp='children'
                        filterOption={(input, option) =>
                          (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      className='w-[50%]'
                      name='address'
                      rules={[{ required: true, message: 'Không được để trống' }]}
                    >
                      <Input placeholder='Số nhà, tên đường, phường / xã / thị trấn ...' />
                    </Form.Item>
                  </div>
                </Form.Item>

                <Form.Item
                  label={
                    <div>
                      <span className='text-[red] text-[16px]'>{`* `}</span>
                      SĐT / Mail
                    </div>
                  }
                >
                  <div className='flex gap-x-3'>
                    <Form.Item
                      name='phone'
                      rules={[
                        { required: true, message: 'Không được để trống' },
                        {
                          pattern: /^(\+84|\+840|84|084|840|0)([9235678]{1}[0-9]{8})$/,
                          message: 'Số điện thoại nhập sai định dạng'
                        }
                      ]}
                    >
                      <Input maxLength={10} className='w-[205px]' placeholder='Số điện thoại' />
                    </Form.Item>
                    <Form.Item
                      name='email'
                      className='w-[-webkit-fill-available]'
                      rules={[
                        {
                          pattern: /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$/,
                          message: 'Sai định dạng mail'
                        }
                      ]}
                    >
                      <Input placeholder='Mail' />
                    </Form.Item>
                  </div>
                </Form.Item>
                <Form.Item label='Số BHXH/Thuế:'>
                  <div className='flex gap-x-3'>
                    <Form.Item name='insuranceNumber'>
                      <Input className='w-[100%]' placeholder='MS BHXH' />
                    </Form.Item>
                    <Form.Item name='taxCode'>
                      <Input className='w-[100%]' placeholder='MS thuế' />
                    </Form.Item>
                  </div>
                </Form.Item>
              </div>
            </div>
            <WrapForm title='Thông tin ngân hàng'>
              <div>
                <Form.Item label='Tên ngân hàng:' name='bankName'>
                  <Input />
                </Form.Item>
                <Form.Item label='Số tài khoản:' name='bankAccount'>
                  <Input />
                </Form.Item>
                <Form.Item label='Tên tài khoản:' name='bankAccountName'>
                  <Input />
                </Form.Item>
                <Form.Item label='Chi nhánh ngân hàng:' name='bankBranch'>
                  <Input />
                </Form.Item>
              </div>
            </WrapForm>

            <WrapForm title={<div className='flex items-center ml-10 gap-x-1'>Thông tin hồ sơ</div>}>
              <div>
                <Form.Item label='Hồ sơ lý lịch:' name='profileTypes'>
                  <Select
                    allowClear
                    mode='multiple'
                    options={resource.profileTypes}
                    fieldNames={{ label: 'name', value: 'id' }}
                    placeholder='Chọn loại hồ sơ'
                  />
                </Form.Item>
                <Form.Item label='Mô tả công việc:' name='jobDesc'>
                  <Input />
                </Form.Item>
              </div>
            </WrapForm>
            <WrapForm styleLabel='ml-10' title={<div className='flex items-center justify-left'>Ảnh</div>}>
              <div>
                <Form.Item label='Ảnh chân dung:' name='Avatar'>
                  {portrait?.length ? (
                    <div className='relative h-[160px] w-[160px]'>
                      <Button
                        className='bg-[#ffffff59] w-[40px] h-[40px] rounded-[50%] top-[35%] left-[65%] translate-x-[-50%] z-20 cursor-pointer absolute'
                        onClick={() => handleRemoveImage('PORTRAIT')}
                      >
                        <DeleteFilled className='relative text-xl top-[-5%] left-[50%] translate-x-[-50%]' />
                      </Button>
                      <div className='w-full cursor-pointer'>
                        <Image
                          src={portrait}
                          alt='Image 30shine'
                          className='image h-[160px] w-[160px] bg-cover bg-center bg-no-repeat rounded-md'
                          height={160}
                          width={160}
                          style={{ objectFit: 'cover' }}
                        />
                        <Button
                          onClick={() => avatarInputRef.current.click()}
                          className='bg-[#ffffff59] w-[40px] h-[40px] rounded-[50%] top-[36%] left-[33%] translate-x-[-50%] z-20 cursor-pointer absolute'
                        >
                          <EditFilled className='relative text-xl top-[-5%] left-[50%] translate-x-[-50%]' />
                        </Button>

                        <input
                          ref={avatarInputRef}
                          className='w-0 h-0 custom-file-input'
                          type='file'
                          accept='image/*'
                          onChange={(e) => handleFileInputChange(e, 'PORTRAIT')}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className='w-[160px] item-null'>
                      <label>
                        <img
                          className='cursor-pointer icon h-[160px] border-2 p-12 rounded-md'
                          src='/icon/upload_avatar.png'
                          alt='upload'
                        />

                        <input
                          className='w-0 h-0 custom-file-input'
                          type='file'
                          accept='image/*'
                          onChange={(e) => handleFileInputChange(e, 'PORTRAIT')}
                        />
                      </label>
                    </div>
                  )}
                </Form.Item>
                <Form.Item label='Ảnh CCCD:' name='IDCard'>
                  <div className='flex'>
                    {idFront?.length ? (
                      <div className='relative h-[160px] w-[240px]'>
                        <Button
                          className='bg-[#ffffff59] w-[40px] h-[40px] rounded-[50%] top-[35%] left-[65%] translate-x-[-50%] z-20 cursor-pointer absolute'
                          onClick={() => handleRemoveImage('ID_FRONT')}
                        >
                          <DeleteFilled className='relative text-xl top-[-5%] left-[50%] translate-x-[-50%]' />
                        </Button>
                        <div className='w-full cursor-pointer'>
                          <Image
                            src={idFront}
                            alt='Image 30shine'
                            className='image h-[160px] w-[240px] bg-cover bg-center bg-no-repeat rounded-md'
                            height={160}
                            width={240}
                            style={{ objectFit: 'cover' }}
                          />
                          <Button
                            onClick={() => idFrontInputRef.current.click()}
                            className='bg-[#ffffff59] w-[40px] h-[40px] rounded-[50%] top-[36%] left-[33%] translate-x-[-50%] z-20 cursor-pointer absolute'
                          >
                            <EditFilled className='relative text-xl top-[-5%] left-[50%] translate-x-[-50%]' />
                          </Button>

                          <input
                            ref={idFrontInputRef}
                            className='w-0 h-0 custom-file-input'
                            type='file'
                            accept='image/*'
                            onChange={(e) => handleFileInputChange(e, 'ID_FRONT')}
                          />
                        </div>
                      </div>
                    ) : (
                      <label className='h-[160px] w-[240px] item-null border-2 rounded-md relative cursor-pointer'>
                        <div className='absolute top-[30%] left-[50%] translate-x-[-50%] text-center text-[#9A9A9A]'>
                          <CameraOutlined className='text-4xl' />
                          <p>Mặt trước CCCD</p>
                        </div>
                        <input
                          className='w-0 h-0 custom-file-input'
                          type='file'
                          accept='image/*'
                          onChange={(e) => handleFileInputChange(e, 'ID_FRONT')}
                        />
                      </label>
                    )}
                  </div>
                  <div className='flex'>
                    {idBack?.length ? (
                      <div className='relative h-[160px] w-[240px] mt-3'>
                        <Button
                          className='bg-[#ffffff59] w-[40px] h-[40px] rounded-[50%] top-[35%] left-[65%] translate-x-[-50%] z-20 cursor-pointer absolute'
                          onClick={() => handleRemoveImage('ID_BACK')}
                        >
                          <DeleteFilled className='relative text-xl top-[-5%] left-[50%] translate-x-[-50%]' />
                        </Button>
                        <div className='w-full cursor-pointer'>
                          <Image
                            src={idBack}
                            alt='Image 30shine'
                            className='image h-[160px] w-[240px] bg-cover bg-center bg-no-repeat rounded-md'
                            height={160}
                            width={240}
                            style={{ objectFit: 'cover' }}
                          />
                          <Button
                            onClick={() => idBackInputRef.current.click()}
                            className='bg-[#ffffff59] w-[40px] h-[40px] rounded-[50%] top-[36%] left-[33%] translate-x-[-50%] z-20 cursor-pointer absolute'
                          >
                            <EditFilled className='relative text-xl top-[-5%] left-[50%] translate-x-[-50%]' />
                          </Button>

                          <input
                            ref={idBackInputRef}
                            className='w-0 h-0 custom-file-input'
                            type='file'
                            accept='image/*'
                            onChange={(e) => handleFileInputChange(e, 'ID_BACK')}
                          />
                        </div>
                      </div>
                    ) : (
                      <label className='h-[160px] w-[240px] item-null border-2 rounded-md relative cursor-pointer mt-3'>
                        <div className='absolute top-[30%] left-[50%] translate-x-[-50%] text-center text-[#9A9A9A]'>
                          <CameraOutlined className='text-4xl' />
                          <p>Mặt sau CCCD</p>
                        </div>
                        <input
                          className='w-0 h-0 custom-file-input'
                          type='file'
                          accept='image/*'
                          onChange={(e) => handleFileInputChange(e, 'ID_BACK')}
                        />
                      </label>
                    )}
                  </div>
                </Form.Item>
              </div>
            </WrapForm>
            <WrapForm title={<div className='flex items-center ml-10 gap-x-1'>Hợp đồng</div>}>
              <div>
                {resource.contractType.map((item: SelectType, index: number) => (
                  <Form.Item key={index} label={item.name} name={`contracts_${item.id}`} className='w-full'>
                    <DatePicker placeholder='dd-mm-yyyy' format='DD-MM-YYYY' className='w-full' />
                  </Form.Item>
                ))}
              </div>
            </WrapForm>

            <div className='working_information'>
              <div className='flex items-baseline gap-6 mb-5'>
                <div className='w-[180px] text-lg text-[#000] text-right font-bold'>Thông tin làm việc</div>
                <div className='w-[700px] text-xs text-[#898989]'>
                  <span className='text-[red]'>(*)</span> : Bắt buộc điền thông tin!
                </div>
              </div>

              <div>
                <Form.Item
                  label='Loại mã nhân viên:'
                  name='addedType'
                  rules={[{ required: true, message: 'Không được để trống' }]}
                >
                  <Radio.Group options={resource.addedType} />
                </Form.Item>
                {isAddedType === 2 && (
                  <Form.Item
                    label='Nhập ID mã chính:'
                    name='parentId'
                    rules={[{ required: true, message: 'Không được để trống' }]}
                  >
                    <Input type='number' />
                  </Form.Item>
                )}

                {/* Mới */}
                <Form.Item label='Khối:' name='groupId' rules={[{ required: true, message: 'Không được để trống' }]}>
                  <Select
                    placeholder='Chọn khối nhân sự'
                    options={resource.groupId}
                    fieldNames={{ label: 'name', value: 'id' }}
                    onChange={(e) => {
                      setIsGroup(e)
                    }}
                  />
                </Form.Item>

                <SlectDepartments
                  listDepartments={resource?.listDepartments}
                  group={isGroup}
                  listGroupSalon={resource.staffTypeId}
                  listGroupOffice={resource.staffTypeId2}
                  jobPosition={jobPosition}
                  setJobPosition={setJobPosition}
                  setLevelList={setLevelList}
                  isAddedType={isAddedType}
                  setLoading={setLoading}
                />

                {isGroup === 1 && levelList.length > 0 && (
                  <Form.Item
                    label='Bậc kỹ năng:'
                    name='skillLevel'
                    rules={[{ required: true, message: 'Không được để trống' }]}
                  >
                    <Select
                      placeholder='Chọn bậc kỹ năng'
                      options={levelList}
                      fieldNames={{ label: 'name', value: 'id' }}
                    />
                  </Form.Item>
                )}

                <Form.Item
                  name='larkRegistrationEmail'
                  className='w-[-webkit-fill-available]'
                  rules={[
                    {
                      pattern: /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$/,
                      message: 'Sai định dạng mail'
                    }
                  ]}
                  label={<div>Email Lark</div>}
                >
                  <Input placeholder='Email Lark' />
                </Form.Item>

                <Form.Item
                  label='Ngày vào làm việc:'
                  name='workingDate'
                  rules={[{ required: true, message: 'Không được để trống' }]}
                >
                  <DatePicker placeholder='dd-mm-yyyy' format='DD-MM-YYYY' className='w-[135px]' />
                </Form.Item>

                <Form.Item
                  label='Ngày tính thâm niên:'
                  name='seniorityDate'
                  rules={[{ required: true, message: 'Không được để trống' }]}
                >
                  <DatePicker placeholder='dd-mm-yyyy' format='DD-MM-YYYY' className='w-[135px]' />
                </Form.Item>

                <Form.Item
                  label='Yêu cầu điểm danh:'
                  name='requireEnroll'
                  rules={[{ required: true, message: 'Không được để trống' }]}
                >
                  <Radio.Group options={resource.requireEnroll} />
                </Form.Item>
                <Form.Item
                  label='Đánh giá ngoại hình:'
                  name='appearence'
                  rules={[{ required: true, message: 'Không được để trống' }]}
                >
                  <Radio.Group options={resource.appearence} />
                </Form.Item>
                <Form.Item label='Nguồn lao động:' name='recruitmentSourceId'>
                  <Select allowClear options={resource.recruitmentSource} fieldNames={{ label: 'name', value: 'id' }} />
                </Form.Item>
              </div>
            </div>

            <div className='erp_role'>
              <div className=''>
                <div className='w-[200px] text-[#000] text-left font-bold text-xl'>Phân quyền ERP</div>
                <div className='w-[700px] text-xs text-[#898989] mb-5'>
                  <span className='text-[red]'>(*)</span> : Bắt buộc điền thông tin!
                </div>
              </div>
              <div>
                <Form.Item
                  label='Loại tài khoản:'
                  name='isAccountLogin'
                  rules={[{ required: true, message: 'Không được để trống' }]}
                >
                  <Radio.Group options={resource.isAccountLogin} />
                </Form.Item>
                <Form.Item label='Phân quyền:' name='permission'>
                  <Select
                    allowClear
                    mode='multiple'
                    placeholder='Chọn phân quyền'
                    options={resource.permission}
                    fieldNames={{ label: 'name', value: 'id' }}
                    showSearch
                    optionFilterProp='children'
                    filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
                  />
                </Form.Item>
              </div>
            </div>
            <Button
              className='float-right w-[90px] mt-5 mb-5 bg-[#4096ff] text-white'
              type='primary'
              htmlType='submit'
              disabled={loading}
            >
              {!loading ? 'Hoàn tất' : <Spin className='text-white' indicator={antIcon} />}
            </Button>
          </Form>
        </div>
      ) : (
        <div className='px-5 py-5 min-h-[calc(100vh)] m-auto pb-28 bg-[#F3F3F3] '>
          <div className='w-full text-sm flex gap-x-3 text-[#b7b3b3]'>
            <Link className='hover:text-black' to='#'>
              Quản lý nhân viên
            </Link>{' '}
            <span>{`>`}</span>
            <Link className='hover:text-black' to={`/staff/list?${convertParamsAxios(searchParams)}`}>
              Danh sách nhân viên
            </Link>
            <span>{`>`}</span>
            <span className='text-black'>Thêm mới nhân viên</span>
          </div>
          <Form
            className='m-auto'
            onValuesChange={onValuesChange}
            form={form}
            name='basic'
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 17 }}
            style={{ maxWidth: 900 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete='off'
          >
            <h3 className='text-center font-bold text-[22px] mt-3 w-[120%]'>THÊM MỚI NHÂN SỰ</h3>
            <div className='mt-12 personal_information'>
              <div className='flex items-baseline gap-6 '>
                <div className='w-[180px] text-lg text-[#000] text-right font-bold mb-3'>Thông tin cá nhân</div>
                <div className='w-[700px] text-xs text-[#898989]'>
                  <span className='text-[red]'>(*)</span> : Bắt buộc điền thông tin!
                </div>
              </div>
              <div>
                <Form.Item
                  label='Tên đăng nhập:'
                  name='userName'
                  rules={[
                    {
                      required: true,
                      message: 'Không được để trống'
                    },
                    { max: 50, message: 'Không quá 50 ký tự' },
                    {
                      pattern: /^(?!.*@)[a-zA-Z.@0-9]+$/,
                      message: 'Tên đăng nhập không hợp lệ'
                    }
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label='Họ và tên nhân viên:'
                  name='idName'
                  rules={[
                    {
                      required: true,
                      message: 'Không được để trống'
                    },
                    { max: 300, message: 'Không quá 300 ký tự' }
                  ]}
                >
                  <Input placeholder='Họ và tên theo CCCD' />
                </Form.Item>
                <Form.Item
                  label='Số CCCD:'
                  name='idNo'
                  rules={[
                    { required: true, message: 'Số CCCD không được để trống' },
                    {
                      pattern: /^(\d{12}|\d{9})$/,
                      message: 'Số CCCD/CMND gồm 9-12 số'
                    }
                  ]}
                >
                  <Input maxLength={12} placeholder='Nhập CCCD 12 chữ số' />
                </Form.Item>
                <Form.Item
                  label={
                    <div>
                      <span className='text-[red] text-[16px]'>{`* `}</span>
                      Ngày cấp / Nơi cấp
                    </div>
                  }
                >
                  <div className='flex gap-x-3'>
                    <Form.Item
                      name='idIssuedDate'
                      className='w-[205px]'
                      rules={[{ required: true, message: 'Không được để trống' }]}
                    >
                      <DatePicker placeholder='dd-mm-yyyy' format='DD-MM-YYYY' />
                    </Form.Item>
                    <Form.Item
                      className='w-[-webkit-fill-available]'
                      name='idIssuedLocation'
                      rules={[{ required: true, message: 'Không được để trống' }]}
                    >
                      <Input className='' placeholder='Nhập nơi cấp' />
                    </Form.Item>
                  </div>
                </Form.Item>
                <Form.Item
                  label={
                    <div>
                      <span className='text-[red] text-[16px]'>{`* `}</span>
                      Ngày sinh / Giới tính
                    </div>
                  }
                >
                  <div className='flex jus gap-x-3'>
                    <Form.Item
                      name='birthday'
                      className='w-[205px]'
                      rules={[{ required: true, message: 'Không được để trống' }]}
                    >
                      <DatePicker placeholder='dd-mm-yyyy' format='DD-MM-YYYY' />
                    </Form.Item>
                    <Form.Item
                      className='w-[-webkit-fill-available]'
                      name='gender'
                      rules={[{ required: true, message: 'Không được để trống' }]}
                    >
                      <Select options={resource.gender} placeholder='Chọn Giới tính' />
                    </Form.Item>
                  </div>
                </Form.Item>
                <Form.Item
                  label={
                    <div>
                      <span className='text-[red] text-[16px]'>{`* `}</span>
                      Địa chỉ
                    </div>
                  }
                >
                  <div className='flex gap-x-3'>
                    <Form.Item
                      name='provinceId'
                      className='w-[100%]'
                      rules={[{ required: true, message: 'Không được để trống' }]}
                    >
                      <Select
                        options={resource.provinceId}
                        placeholder='Tỉnh / thành phố'
                        fieldNames={{ label: 'name', value: 'id' }}
                        showSearch
                        optionFilterProp='children'
                        filterOption={(input, option) =>
                          (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      className='w-[-webkit-fill-available]'
                      name='districtId'
                      rules={[{ required: true, message: 'Không được để trống' }]}
                    >
                      <Select
                        options={resource.districtId}
                        placeholder='Quận / huyện'
                        fieldNames={{ label: 'name', value: 'id' }}
                        showSearch
                        optionFilterProp='children'
                        filterOption={(input, option) =>
                          (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                      />
                    </Form.Item>
                  </div>
                </Form.Item>
                <Form.Item
                  label={
                    <div>
                      <span className='text-[red] text-[16px]'>{`* `}</span>
                      Địa chỉ cụ thể
                    </div>
                  }
                >
                  <div className='flex gap-x-3'>
                    <Form.Item
                      name='wardId'
                      className='w-[50%]'
                      rules={[{ required: true, message: 'Không được để trống' }]}
                    >
                      <Select
                        options={resource.wardId}
                        placeholder='Phường / xã / thị trấn'
                        fieldNames={{ label: 'name', value: 'id' }}
                        showSearch
                        optionFilterProp='children'
                        filterOption={(input, option) =>
                          (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      className='w-[50%]'
                      name='address'
                      rules={[{ required: true, message: 'Không được để trống' }]}
                    >
                      <Input placeholder='Số nhà, tên đường, phường / xã / thị trấn ...' />
                    </Form.Item>
                  </div>
                </Form.Item>

                <Form.Item
                  label={
                    <div>
                      <span className='text-[red] text-[16px]'>{`* `}</span>
                      SĐT / Mail
                    </div>
                  }
                >
                  <div className='flex gap-x-3'>
                    <Form.Item
                      name='phone'
                      rules={[
                        { required: true, message: 'Không được để trống' },
                        {
                          pattern: /^(\+84|\+840|84|084|840|0)([9235678]{1}[0-9]{8})$/,
                          message: 'Số điện thoại nhập sai định dạng'
                        }
                      ]}
                    >
                      <Input maxLength={10} className='w-[205px]' placeholder='Số điện thoại' />
                    </Form.Item>
                    <Form.Item
                      name='email'
                      className='w-[-webkit-fill-available]'
                      rules={[
                        {
                          pattern: /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$/,
                          message: 'Sai định dạng mail'
                        }
                      ]}
                    >
                      <Input placeholder='Mail' />
                    </Form.Item>
                  </div>
                </Form.Item>

                <Form.Item label='Số BHXH/Thuế:'>
                  <div className='flex gap-x-3'>
                    <Form.Item name='insuranceNumber'>
                      <Input className='w-[100%]' placeholder='MS BHXH' />
                    </Form.Item>
                    <Form.Item name='taxCode'>
                      <Input className='w-[100%]' placeholder='MS thuế' />
                    </Form.Item>
                  </div>
                </Form.Item>
              </div>
            </div>
            <WrapForm title='Thông tin ngân hàng'>
              <div>
                <Form.Item label='Tên ngân hàng:' name='bankName'>
                  <Input />
                </Form.Item>
                <Form.Item label='Số tài khoản:' name='bankAccount'>
                  <Input />
                </Form.Item>
                <Form.Item label='Tên tài khoản:' name='bankAccountName'>
                  <Input />
                </Form.Item>
                <Form.Item label='Chi nhánh ngân hàng:' name='bankBranch'>
                  <Input />
                </Form.Item>
              </div>
            </WrapForm>

            <WrapForm title='Thông tin hồ sơ'>
              <div>
                <Form.Item label='Hồ sơ lý lịch:' name='profileTypes'>
                  <Select
                    allowClear
                    mode='multiple'
                    options={resource.profileTypes}
                    fieldNames={{ label: 'name', value: 'id' }}
                    placeholder='Chọn loại hồ sơ'
                  />
                </Form.Item>
                <Form.Item label='Mô tả công việc:' name='jobDesc'>
                  <Input />
                </Form.Item>
              </div>
            </WrapForm>
            <WrapForm title={'Ảnh'}>
              <div>
                <Form.Item label='Ảnh chân dung:' name='Avatar'>
                  {portrait?.length ? (
                    <div className='relative h-[160px] w-[160px]'>
                      <Button
                        className='bg-[#ffffff59] w-[40px] h-[40px] rounded-[50%] top-[35%] left-[65%] translate-x-[-50%] z-20 cursor-pointer absolute'
                        onClick={() => handleRemoveImage('PORTRAIT')}
                      >
                        <DeleteFilled className='relative text-xl top-[-5%] left-[50%] translate-x-[-50%]' />
                      </Button>
                      <div className='w-full cursor-pointer'>
                        <Image
                          src={portrait}
                          alt='Image 30shine'
                          className='image h-[160px] w-[160px] bg-cover bg-center bg-no-repeat rounded-md'
                          height={160}
                          width={160}
                          style={{ objectFit: 'cover' }}
                        />
                        <Button
                          onClick={() => avatarInputRef.current.click()}
                          className='bg-[#ffffff59] w-[40px] h-[40px] rounded-[50%] top-[36%] left-[33%] translate-x-[-50%] z-20 cursor-pointer absolute'
                        >
                          <EditFilled className='relative text-xl top-[-5%] left-[50%] translate-x-[-50%]' />
                        </Button>

                        <input
                          ref={avatarInputRef}
                          className='w-0 h-0 custom-file-input'
                          type='file'
                          accept='image/*'
                          onChange={(e) => handleFileInputChange(e, 'PORTRAIT')}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className='w-[160px] item-null'>
                      <label>
                        <img
                          className='cursor-pointer icon h-[160px] border-2 p-12 rounded-md'
                          src='/icon/upload_avatar.png'
                          alt='upload'
                        />

                        <input
                          className='w-0 h-0 custom-file-input'
                          type='file'
                          accept='image/*'
                          onChange={(e) => handleFileInputChange(e, 'PORTRAIT')}
                        />
                      </label>
                    </div>
                  )}
                </Form.Item>
                <Form.Item label='Ảnh CCCD:' name='IDCard'>
                  <div className='flex'>
                    {idFront?.length ? (
                      <div className='relative h-[160px] w-[240px]'>
                        <Button
                          className='bg-[#ffffff59] w-[40px] h-[40px] rounded-[50%] top-[35%] left-[65%] translate-x-[-50%] z-20 cursor-pointer absolute'
                          onClick={() => handleRemoveImage('ID_FRONT')}
                        >
                          <DeleteFilled className='relative text-xl top-[-5%] left-[50%] translate-x-[-50%]' />
                        </Button>
                        <div className='w-full cursor-pointer'>
                          <Image
                            src={idFront}
                            alt='Image 30shine'
                            className='image h-[160px] w-[240px] bg-cover bg-center bg-no-repeat rounded-md'
                            height={160}
                            width={240}
                            style={{ objectFit: 'cover' }}
                          />
                          <Button
                            onClick={() => idFrontInputRef.current.click()}
                            className='bg-[#ffffff59] w-[40px] h-[40px] rounded-[50%] top-[36%] left-[33%] translate-x-[-50%] z-20 cursor-pointer absolute'
                          >
                            <EditFilled className='relative text-xl top-[-5%] left-[50%] translate-x-[-50%]' />
                          </Button>

                          <input
                            ref={idFrontInputRef}
                            className='w-0 h-0 custom-file-input'
                            type='file'
                            accept='image/*'
                            onChange={(e) => handleFileInputChange(e, 'ID_FRONT')}
                          />
                        </div>
                      </div>
                    ) : (
                      <label className='h-[160px] w-[240px] item-null border-2 rounded-md relative cursor-pointer'>
                        <div className='absolute top-[30%] left-[50%] translate-x-[-50%] text-center text-[#9A9A9A]'>
                          <CameraOutlined className='text-4xl' />
                          <p>Mặt trước CCCD</p>
                        </div>
                        <input
                          className='w-0 h-0 custom-file-input'
                          type='file'
                          accept='image/*'
                          onChange={(e) => handleFileInputChange(e, 'ID_FRONT')}
                        />
                      </label>
                    )}
                    {idBack?.length ? (
                      <div className='relative h-[160px] w-[240px] ml-5'>
                        <Button
                          className='bg-[#ffffff59] w-[40px] h-[40px] rounded-[50%] top-[35%] left-[65%] translate-x-[-50%] z-20 cursor-pointer absolute'
                          onClick={() => handleRemoveImage('ID_BACK')}
                        >
                          <DeleteFilled className='relative text-xl top-[-5%] left-[50%] translate-x-[-50%]' />
                        </Button>
                        <div className='w-full cursor-pointer'>
                          <Image
                            src={idBack}
                            alt='Image 30shine'
                            className='image h-[160px] w-[240px] bg-cover bg-center bg-no-repeat rounded-md'
                            height={160}
                            width={240}
                            style={{ objectFit: 'cover' }}
                          />
                          <Button
                            onClick={() => idBackInputRef.current.click()}
                            className='bg-[#ffffff59] w-[40px] h-[40px] rounded-[50%] top-[36%] left-[33%] translate-x-[-50%] z-20 cursor-pointer absolute'
                          >
                            <EditFilled className='relative text-xl top-[-5%] left-[50%] translate-x-[-50%]' />
                          </Button>

                          <input
                            ref={idBackInputRef}
                            className='w-0 h-0 custom-file-input'
                            type='file'
                            accept='image/*'
                            onChange={(e) => handleFileInputChange(e, 'ID_BACK')}
                          />
                        </div>
                      </div>
                    ) : (
                      <label className='h-[160px] w-[240px] item-null border-2 rounded-md relative cursor-pointer ml-5'>
                        <div className='absolute top-[30%] left-[50%] translate-x-[-50%] text-center text-[#9A9A9A]'>
                          <CameraOutlined className='text-4xl' />
                          <p>Mặt sau CCCD</p>
                        </div>
                        <input
                          className='w-0 h-0 custom-file-input'
                          type='file'
                          accept='image/*'
                          onChange={(e) => handleFileInputChange(e, 'ID_BACK')}
                        />
                      </label>
                    )}
                  </div>
                </Form.Item>
              </div>
            </WrapForm>
            <WrapForm title='Hợp đồng'>
              <div>
                {resource.contractType.map((item: SelectType, index: number) => (
                  <Form.Item key={index} label={item.name} name={`contracts_${item.id}`}>
                    <DatePicker placeholder='dd-mm-yyyy' format='DD-MM-YYYY' />
                  </Form.Item>
                ))}
              </div>
            </WrapForm>

            <div className='working_information'>
              <div className='flex items-baseline gap-6 mb-5'>
                <div className='w-[180px] text-lg text-[#000] text-right font-bold'>Thông tin làm việc</div>
                <div className='w-[700px] text-xs text-[#898989]'>
                  <span className='text-[red]'>(*)</span> : Bắt buộc điền thông tin!
                </div>
              </div>

              <div>
                <Form.Item
                  label='Loại mã nhân viên:'
                  name='addedType'
                  rules={[{ required: true, message: 'Không được để trống' }]}
                >
                  <Radio.Group options={resource.addedType} />
                </Form.Item>
                {isAddedType === 2 && (
                  <Form.Item
                    label='Nhập ID mã chính:'
                    name='parentId'
                    rules={[{ required: true, message: 'Không được để trống' }]}
                  >
                    <Input type='number' />
                  </Form.Item>
                )}

                {/* Mới */}
                <Form.Item label='Khối:' name='groupId' rules={[{ required: true, message: 'Không được để trống' }]}>
                  <Select
                    placeholder='Chọn khối nhân sự'
                    options={resource.groupId}
                    fieldNames={{ label: 'name', value: 'id' }}
                    onChange={(e) => {
                      setIsGroup(e)
                    }}
                  />
                </Form.Item>

                <SlectDepartments
                  listDepartments={resource?.listDepartments}
                  group={isGroup}
                  listGroupSalon={resource.staffTypeId}
                  listGroupOffice={resource.staffTypeId2}
                  jobPosition={jobPosition}
                  setJobPosition={setJobPosition}
                  setLevelList={setLevelList}
                  isAddedType={isAddedType}
                  setLoading={setLoading}
                />
                {isGroup === 1 && levelList.length > 0 && (
                  <Form.Item
                    label='Bậc kỹ năng:'
                    name='skillLevel'
                    rules={[{ required: true, message: 'Không được để trống' }]}
                  >
                    <Select
                      placeholder='Chọn bậc kỹ năng'
                      options={levelList}
                      fieldNames={{ label: 'name', value: 'id' }}
                    />
                  </Form.Item>
                )}
                <Form.Item
                  name='larkRegistrationEmail'
                  className='w-[-webkit-fill-available]'
                  rules={[
                    {
                      pattern: /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$/,
                      message: 'Sai định dạng mail'
                    }
                  ]}
                  label={<div>Email Lark</div>}
                >
                  <Input placeholder='Email Lark' />
                </Form.Item>
                <Form.Item
                  label='Ngày vào làm việc:'
                  name='workingDate'
                  rules={[{ required: true, message: 'Không được để trống' }]}
                >
                  <DatePicker placeholder='dd-mm-yyyy' format='DD-MM-YYYY' className='w-[135px]' />
                </Form.Item>
                <Form.Item
                  label='Ngày tính thâm niên:'
                  name='seniorityDate'
                  rules={[{ required: true, message: 'Không được để trống' }]}
                >
                  <DatePicker placeholder='dd-mm-yyyy' format='DD-MM-YYYY' className='w-[135px]' />
                </Form.Item>

                <Form.Item
                  label='Yêu cầu điểm danh:'
                  name='requireEnroll'
                  rules={[{ required: true, message: 'Không được để trống' }]}
                >
                  <Radio.Group options={resource.requireEnroll} />
                </Form.Item>
                <Form.Item
                  label='Đánh giá ngoại hình:'
                  name='appearence'
                  rules={[{ required: true, message: 'Không được để trống' }]}
                >
                  <Radio.Group options={resource.appearence} />
                </Form.Item>
                <Form.Item label='Nguồn lao động:' name='recruitmentSourceId'>
                  <Select allowClear options={resource.recruitmentSource} fieldNames={{ label: 'name', value: 'id' }} />
                </Form.Item>
              </div>
            </div>

            <div className='erp_role'>
              <div className='flex items-baseline gap-6 '>
                <div className='w-[182px] text-lg text-[#000] text-right font-bold'>Phân quyền ERP</div>
                <div className='w-[700px] text-xs text-[#898989]'>
                  <span className='text-[red]'>(*)</span> : Bắt buộc điền thông tin!
                </div>
              </div>
              <div>
                <Form.Item
                  label='Loại tài khoản:'
                  name='isAccountLogin'
                  rules={[{ required: true, message: 'Không được để trống' }]}
                >
                  <Radio.Group options={resource.isAccountLogin} />
                </Form.Item>
                <Form.Item label='Phân quyền:' name='permission'>
                  <Select
                    allowClear
                    mode='multiple'
                    placeholder='Chọn phân quyền'
                    options={resource.permission}
                    fieldNames={{ label: 'name', value: 'id' }}
                    showSearch
                    optionFilterProp='children'
                    filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
                  />
                </Form.Item>
              </div>
            </div>
            <Button
              className='float-right w-[90px] mt-16 mb-12 bg-[#4096ff] text-white'
              type='primary'
              htmlType='submit'
              disabled={loading}
            >
              {!loading ? 'Hoàn tất' : <Spin className='text-white' indicator={antIcon} />}
            </Button>
          </Form>
        </div>
      )}
    </>
  )
}
