import { useState, useContext } from 'react'
import { ColumnConfig } from '../../base/util'
import { InputNumber, Form, Row, Table, Button, Upload } from 'antd'
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons'
import { AppContext } from 'layouts/DashboardLayout'
import * as XLSX from 'xlsx'
import { createSalonProduct } from '../api'
export const ProductImportPriceSalon = () => {
  const [form] = Form.useForm()
  const [data, setData] = useState([])
  const { notification } = useContext(AppContext)
  const handleOnChange = (item: any, field: string, value?: any) => {
    const newData = [...data]
    const targetIndex = newData.findIndex((dataItem) => dataItem.id === item.id)

    if (targetIndex !== -1) {
      switch (field) {
        case 'IsCheckKCS':
          newData[targetIndex].IsCheckKCS = !newData[targetIndex].IsCheckKCS
          setData(newData)
          break
        case 'price':
          newData[targetIndex].Price = value
          setData(newData)
          break
        case 'delete':
          newData.splice(targetIndex, 1)
          setData(newData)
          break
        default:
          break
      }
    }
  }
  const column: ColumnConfig[] = [
    {
      title: 'Id Salon',
      dataIndex: 'salonId',
      key: 'salonId'
    },
    {
      title: 'Salon',
      dataIndex: 'salonName',
      key: 'salonName'
    },
    {
      title: 'ID Sản phẩm',
      dataIndex: 'productId',
      key: 'productId'
    },
    {
      title: 'Sản phẩm',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Mã sản phẩm',
      dataIndex: 'code',
      key: 'code'
    },
    {
      title: 'Giá chính',
      dataIndex: 'priceDefault',
      key: 'priceDefault'
    },
    {
      title: 'Giá Salon',
      key: 'price',
      render: (record) => (
        <InputNumber value={record.price} onChange={(value: number) => handleOnChange(record, 'price', value)} />
      )
    },
    {
      title: '',
      key: 'delete',
      render: (record) => <DeleteOutlined onClick={(value) => handleOnChange(record, 'delete', value)}></DeleteOutlined>
    }
  ]

  const handleChangeValue = (changedValues, onReset: (field: string[]) => void) => {
    if (changedValues?.salonId) {
      onReset(['statusName'])
    }
  }
  const onValuesChange = (changedValues) => {
    const keyName = Object.keys(changedValues)[0]
    const value = changedValues[keyName]

    if (keyName.toLowerCase().includes('date')) {
      setParams({
        ...params
      })
    } else {
      setParams({ ...params, ...changedValues })
    }
    handleChangeValue(changedValues, onReset)
  }
  const onReset = (field: string[]) => {
    form.resetFields([...field])
  }

  const [params, setParams] = useState<any>({
    PageSize: 10,
    PageIndex: 1
  })

  const beforeUpload = (file: any) => {
    try {
      const reader = new FileReader()

      reader.onload = (e) => {
        const binaryString = e.target.result
        const workbook = XLSX.read(binaryString, { type: 'binary' })

        // Lấy dữ liệu từ sheet đầu tiên của workbook
        if (!workbook.SheetNames[0]) throw new Error('Sheet names do not ewxits Sheet[0]')
        const firstSheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[firstSheetName]

        // Chuyển đổi dữ liệu từ worksheet thành mảng đối tượng
        const excelData = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
        if (excelData.length < 2) throw new Error('Excel cần chứa dữ liệu !!')
        // Lấy dữ liệu từ phần tử thứ 2 trở đi
        const newData = excelData.slice(1).map((row) => {
          return {
            salonId: row[0],
            productId: row[1],
            price: row[2]
          }
        })

        // Lưu giá trị vào state
        setData(newData)
      }

      reader.readAsBinaryString(file)
      return false
    } catch (e) {
      console.log(e)
    }
  }

  const handleUpdateConfig = async () => {
    console.log(data, 'data')
    try {
      const response: any = await createSalonProduct(data)
      if (response?.statusCode === 200) {
        notification('success', 'Thành công')
      } else {
        notification('error', 'Thất bại', response?.message)
      }
    } catch (error) {
      console.error('Error:', error.message)
      notification('error', 'Thất bại', 'Có lỗi xảy ra')
    }
  }
  return (
    <div className='min-h-[100vh] m-auto bg-[#F3F3F3] pb-12'>
      <div className='w-full px-5 py-5 search-wrapper'>
        <div className='pb-10'>
          <h2 className='text-2xl font-bold mb-7'>Import giá sản phẩm Salon</h2>
          <Row className=''>
            <h3>Import file</h3>{' '}
            <a href='/excel/demo-import-product-salon/excel.xlsx' className='ml-3 italic underline'>
              Download file mẫu
            </a>
          </Row>
          <Row className='mt-2'>
            <Upload beforeUpload={beforeUpload} showUploadList={false}>
              <Button icon={<UploadOutlined style={{ position: 'relative', top: '-4px' }} />}>Upload File</Button>
            </Upload>
          </Row>
          <div
            className='mt-5 bg-white'
            style={{ boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px', borderRadius: 10 }}
          >
            <Row style={{ display: 'contents' }} gutter={18}>
              <Table
                scroll={{ x: 2000, y: 500 }}
                className='min-w-[1000px] overflow-auto'
                dataSource={data}
                columns={column}
                pagination={false}
                bordered
              />
            </Row>
          </div>
        </div>
        <Button type='primary' onClick={handleUpdateConfig}>
          Cập nhật cấu hình
        </Button>
      </div>
    </div>
  )
}
