import { createSlice } from '@reduxjs/toolkit'

const innitState = {
  listStaffSCSC: undefined,
  defaultSCSCForm: undefined,
  listSalonDefault: undefined,
  searchParamsSCSC: {
    Date: undefined,
    userName: '',
    Status: undefined,
    PageSize: 10,
    PageIndex: 1
  },
  searchParamsSCSCAdmin: {
    Date: undefined,
    userName: '',
    Status: undefined,
    BillId: undefined,
    PageSize: 10,
    PageIndex: 1
  }
}

const SCSCSlice = createSlice({
  name: 'SCSCSlice',
  initialState: innitState,
  reducers: {
    setListStaff: (state, action) => {
      state.listStaffSCSC = action.payload
    },
    setDefaultSCSCForm: (state, action) => {
      state.defaultSCSCForm = action.payload
    },
    setListSalonDefault: (state, action) => {
      state.listSalonDefault = action.payload
    },
    setSearchParamsSCSC: (state, action) => {
      state.searchParamsSCSC = action.payload
    },
    setSearchParamsSCSCAdmin: (state, action) => {
      state.searchParamsSCSCAdmin = action.payload
    }
  }
})

const { reducer: SCSCSReducer, actions } = SCSCSlice
export const { setListStaff, setDefaultSCSCForm, setSearchParamsSCSC, setSearchParamsSCSCAdmin, setListSalonDefault } =
  actions
export default SCSCSReducer
