export const clearLocalStorageExceptSearchColumns = () => {
  const searchColumnKeysToKeep = ['SEARCH_COLUMN', 'SEARCH_COLUMN_STAFF_CHANGE']

  // Lấy danh sách tất cả các keys trong localStorage
  const allKeys = Object.keys(localStorage)

  // Lọc ra các keys mà không nằm trong danh sách giữ lại
  const keysToDelete = allKeys.filter((key) => !searchColumnKeysToKeep.includes(key))

  // Xóa các mục không cần thiết từ localStorage
  keysToDelete.forEach((key) => {
    localStorage.removeItem(key)
  })
}
