import { ColumnSearch_instance_type } from './interface'

export const listSearchAdmin = [
  {
    label: 'Ngày / tháng sinh',
    value: 'birthday',
    index: 0
  },
  {
    label: 'Vùng ASM',
    value: 'asms',
    index: 1
  },
  {
    label: 'Salon',
    value: 'SalonId',
    index: 2
  },
  {
    label: 'Bộ phận',
    value: 'StaffType',
    index: 3
  },
  {
    label: 'Bậc kĩ năng',
    value: 'SkillLevelId',
    index: 4
  },
  {
    label: 'Nhóm quyền',
    value: 'PermissionIds',
    index: 5
  },
  {
    label: 'Loại hồ sơ',
    value: 'ProfileTypes',
    index: 6
  },
  {
    label: 'Loại hợp đồng',
    value: 'ContractTypes',
    index: 7
  }
]
export const listSearch = [
  {
    label: 'Ngày / tháng sinh',
    value: 'birthday',
    index: 0
  },
  {
    label: 'Salon',
    value: 'SalonId',
    index: 2
  },
  {
    label: 'Bộ phận',
    value: 'StaffType',
    index: 3
  },
  {
    label: 'Bậc kĩ năng',
    value: 'SkillLevelId',
    index: 4
  },
  {
    label: 'Nhóm quyền',
    value: 'PermissionIds',
    index: 5
  },
  {
    label: 'Loại hồ sơ',
    value: 'ProfileTypes',
    index: 6
  },
  {
    label: 'Loại hợp đồng',
    value: 'ContractTypes',
    index: 7
  }
]
export const columnSearch_instance: ColumnSearch_instance_type[] = [
  {
    name: 'Ngày / tháng sinh',
    column: 'birthday',
    show: false
  },
  {
    name: 'Vùng ASM',
    column: 'asms',
    show: false
  },
  {
    name: 'Salon',
    column: 'SalonId',
    show: false
  },
  {
    name: 'Bộ phận',
    column: 'StaffType',
    show: false
  },
  {
    name: 'Bậc kĩ năng',
    column: 'SkillLevelId',
    show: false
  },
  {
    name: 'Nhóm quyền',
    column: 'PermissionIds',
    show: false
  },
  {
    name: 'Loại hồ sơ',
    column: 'ProfileTypes',
    show: false
  },
  {
    name: 'Loại hợp đồng',
    column: 'ContractTypes',
    show: false
  }
]
export const optionsListColumnStaffChange = [
  { label: 'Bộ phận', value: 'staffTypeName' },
  { label: 'Bậc', value: 'levelName' },
  { label: 'Salon hiện tại', value: 'salonCurrentName' },
  { label: 'Salon thay đổi', value: 'salonChangeName' },
  { label: 'Trạng thái', value: 'statusName' },
  { label: 'Ngày biến động', value: 'createdDate' },
  { label: 'Ngày phát sinh công cuối cùng', value: 'lastTimekeepingDate' },
  { label: 'Tổng thâm niên xét lên bậc', value: 'totalSeniorityLevelUp' },
  { label: 'Tổng thâm niên nghề', value: 'totalSeniorityAt30Shine' },
  { label: 'Ghi chú', value: 'description' },
  { label: 'Nguồn lao động', value: 'recruitmentSourceName' }
]
export const valueDefaultListColumnStaffChange = [
  'staffTypeName',
  'levelName',
  'salonCurrentName',
  'salonChangeName',
  'statusName',
  'createdDate',
  'lastTimekeepingDate',
  'totalSeniorityLevelUp',
  'totalSeniorityAt30Shine',
  'description',
  'recruitmentSourceName'
]
