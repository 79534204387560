import { MenuOutlined } from '@ant-design/icons'

export const MENU_BI_REPORT = [
  {
    isParent: true,
    key: 'bi-report',
    path: 'bi-report',
    title: 'BI Report',
    icon: <img src='/icon/bi.png' alt='30shine' />,
    nested: [
      {
        isParent: false,
        key: '/bi-report/doanh-thu-dich-vu',
        path: '/bi-report/doanh-thu-dich-vu',
        title: 'Dịch vụ',
        icon: <MenuOutlined />
      },
      {
        isParent: false,
        key: '/bi-report/doanh-thu-my-pham',
        path: '/bi-report/doanh-thu-my-pham',
        title: 'Mỹ phẩm',
        icon: <MenuOutlined />
      },
      {
        isParent: false,
        key: '/bi-report/doanh-thu-shine-member',
        path: '/bi-report/doanh-thu-shine-member',
        title: 'ShineMember',
        icon: <MenuOutlined />
      },
      {
        isParent: false,
        key: '/bi-report/doanh-thu-topup',
        path: '/bi-report/doanh-thu-topup',
        title: 'Topup',
        icon: <MenuOutlined />
      },
      {
        isParent: false,
        key: '/bi-report/hang-doi-tra',
        path: '/bi-report/hang-doi-tra',
        title: 'Danh sách hàng trả lại',
        icon: <MenuOutlined />
      },
      {
        isParent: false,
        key: '/bi-report/nang-suat',
        path: '/bi-report/nang-suat',
        title: 'Năng suất V2',
        icon: <MenuOutlined />
      },
      {
        isParent: false,
        key: '/bi-report/nop-doanh-thu',
        path: '/bi-report/nop-doanh-thu',
        title: 'Báo cáo HD nộp doanh thu',
        icon: <MenuOutlined />
      },
      {
        isParent: false,
        key: '/bi-report/ket-qua-kinh-doanh',
        path: '/bi-report/ket-qua-kinh-doanh',
        title: 'Tóm tắt Báo cáo KQKD V3',
        icon: <MenuOutlined />
      }
    ]
  }
]
