import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { ColumnConfig, DateFormat, convertParamsAxiosBase } from '../../base/util'
import { SpinIndicator } from 'antd/es/spin'
import { Button, notification, Form, Row, Table, Spin, Select, Popover, Popconfirm } from 'antd'
import { SearchOutlined, LoadingOutlined, DeleteFilled } from '@ant-design/icons'
import { deleteProductConfig, getAllProductList, getProductConfigList } from '../api'
import convert from 'utils/helpers/convert'
import { getSalons } from 'api/catalog'

const ProductSalaryConfigList = () => {
  const navigate = useNavigate()
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [total, setTotal] = useState(0)
  const [reload, setReload] = useState(false)
  const [loading, setLoading] = useState(false)
  const antIcon: SpinIndicator = <LoadingOutlined style={{ fontSize: 24 }} spin />
  const [form] = Form.useForm()
  const [listSalon, setListSalon] = useState([])
  const [listProduct, setListProduct] = useState([])
  const [mount, setMount] = useState(false)
  const [data, setData] = useState([])
  const [params, setParams] = useState<any>({
    pageSize: 10,
    pageIndex: 1
  })

  const getListSalon = async () => {
    try {
      const response: any = await getSalons()
      const { statusCode, data } = response
      if (statusCode === 200) {
        setListSalon(data)
      }
    } catch (error) {
      console.error('Error:', error.message)
    }
  }

  const onClickDeleted = async (id) => {
    if (loadingDelete) return
    try {
      setLoadingDelete(true)
      const response: any = await deleteProductConfig([id])
      if (response.statusCode === 200) {
        notification.success({
          description: response?.message || 'Xoá thành công!',
          message: 'Thành công'
        })

        const newData = data.filter((item: any) => item.id !== id)
        setData(newData)
      } else {
        notification.error({
          message: 'Lỗi',
          description: response?.message || 'Có lỗi xảy ra, vui lòng thử lại!'
        })
      }
      setLoadingDelete(false)
    } catch (error) {
      setLoadingDelete(false)
      console.log(error)
    }
  }

  const column: ColumnConfig[] = [
    {
      title: 'ID sản phẩm',
      dataIndex: 'productId',
      key: 'productId'
    },
    {
      title: 'Sản phẩm',
      dataIndex: 'productName',
      key: 'productName'
    },
    {
      title: 'SKU',
      dataIndex: 'skuCode',
      key: 'skuCode'
    },
    {
      title: 'Salon',
      dataIndex: 'salonId',
      key: 'salonId',
      render: (_) => {
        const salon = listSalon.find((item) => item.id === _)
        return <div>{salon ? salon?.name : _}</div>
      }
    },
    {
      title: 'Hệ số tăng ca',
      dataIndex: 'coefficientOverTime',
      key: 'coefficientOverTime'
    },
    {
      title: 'Tip (VND)',
      dataIndex: 'tip',
      key: 'tip'
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <Popconfirm
          title='Xác nhận xoá'
          description='(Hành động này sẽ không được làm lại)'
          onConfirm={() => onClickDeleted(record.id)}
          okText='Đồng ý'
          cancelText='Huỷ'
        >
          <DeleteFilled />
        </Popconfirm>
      )
    }
  ]

  const onValuesChange = (changedValues) => {
    const keyName = Object.keys(changedValues)[0]

    if (keyName.toLowerCase().includes('date')) {
      setParams({
        ...params
      })
    } else {
      setParams({ ...params, ...changedValues })
    }
  }

  const getProductList = async () => {
    try {
      const response: any = await getAllProductList()
      if (response?.data?.data?.length) {
        setListProduct(response.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleFetchData = async (paramsSearch: any) => {
    try {
      setLoading(true)
      const res: any = await getProductConfigList(paramsSearch)
      setLoading(false)
      if (res) {
        setTotal(res.total)
        setData(res.data)
      }
    } catch (error) {
      notification.error({
        description: 'Không thể thao tác, vui lòng thử lại sau',
        message: 'Lỗi'
      })
    }
    setLoading(false)
    setReload(false)
  }

  const prefetchData = () => {
    const paramsSearchResult = window.location.search.replace('?', '')
    if (Object.keys(paramsSearchResult).length > 0) {
      const params = new URLSearchParams(paramsSearchResult)
      const paramsObject = {}
      params.forEach(function (value, key) {
        paramsObject[key] = value
      })
      handleFetchData(paramsObject)
    } else {
      setData([])
    }
    if (!mount) setMount(true)
  }

  const onFinish = (values: any) => {
    console.log(values, 'values')
    const result: any = {
      pageSize: 0,
      pageIndex: 0
    }
    const value = form.getFieldsValue()
    result.pageSize = params.pageSize
    result.pageIndex = params.pageIndex
    Object.keys(value).map((i) => {
      if (
        typeof value[i] === 'object' &&
        value[i]?.length === 2 &&
        value[i].every((item) => typeof item === 'object')
      ) {
        result[`${i}_from`] = dayjs(value[i][0]).format(DateFormat)
        result[`${i}_to`] = dayjs(value[i][1]).format(DateFormat)
      } else if (typeof value[i] === 'object') {
        result[i] = dayjs(value[i]).format(DateFormat)
      } else result[i] = value[i]
    })
    for (const key in result) {
      if (result[key] === null || result[key] === undefined) {
        delete result[key]
      }
    }
    navigate(
      `/salary/product-salary-list?${convertParamsAxiosBase({
        ...result,
        pageIndex: 1
      })}`
    )
  }

  const onChangePage = (page: number, pageSize: number) => {
    const paramsSearchResult = window.location.search.replace('?', '')
    if (Object.keys(paramsSearchResult).length > 0) {
      const params = new URLSearchParams(paramsSearchResult)
      const paramsObject = {}
      params.forEach(function (value, key) {
        paramsObject[key] = value
      })
      navigate(
        `/salary/product-salary-list?${convertParamsAxiosBase({
          ...paramsObject,
          pageIndex: page,
          pageSize
        })}`
      )
    }
  }

  useEffect(() => {
    getListSalon()
    getProductList()
  }, [])

  useEffect(() => {
    prefetchData()
  }, [window.location.search])

  return (
    <div className='min-h-[100vh] m-auto bg-[#F3F3F3] pb-12'>
      <div className='w-full px-5 py-5 search-wrapper'>
        <Form className='pb-10' form={form} onFinish={onFinish} onValuesChange={onValuesChange}>
          <h2 className='text-2xl font-bold mb-7'>Xem tip lương sản phẩm</h2>
          <Row className=''>
            <Form.Item name='salonId' label='Chọn Salon' className='w-[300px]'>
              <Select
                allowClear
                className='w-full'
                placeholder={'Chọn Salon'}
                fieldNames={{ label: 'name', value: 'id' }}
                options={listSalon}
                showSearch
                optionFilterProp='children'
                onChange={(e) => {
                  form.setFieldValue('SalonId', e)
                  setParams({ ...params, ['SalonId']: e })
                }}
                filterOption={(input, option) =>
                  convert
                    .removeVietnameseAccent(option.name)
                    .toLowerCase()
                    .includes(convert.removeVietnameseAccent(input))
                }
              />
            </Form.Item>
            <Form.Item name='productId' label='Sản phẩm bán' className='w-[400px] ml-10'>
              <Select
                allowClear
                className='w-full'
                placeholder={'Chọn sản phẩm'}
                fieldNames={{ label: 'name', value: 'productId' }}
                options={listProduct}
                showSearch
                optionFilterProp='children'
                filterOption={(input, option) =>
                  convert
                    .removeVietnameseAccent(option.name)
                    .toLowerCase()
                    .includes(convert.removeVietnameseAccent(input))
                }
              />
            </Form.Item>
            <Button
              type='default'
              htmlType='submit'
              className='w-[160px] bg-[#4096ff] text-white hover:bg-white'
              disabled={loading}
            >
              {!loading ? (
                <div className='flex items-center justify-center hover_icon'>
                  <SearchOutlined className='mr-1 text-white' />
                  Xem dữ liệu
                </div>
              ) : (
                <Spin indicator={antIcon} />
              )}
            </Button>
            <Button
              onClick={() => form.resetFields()}
              type='default'
              className='w-[160px] bg-[#4096ff] text-white hover:bg-white'
            >
              Reset Filter
            </Button>
          </Row>
          <div
            className='mt-5 bg-white'
            style={{ boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px', borderRadius: 10 }}
          >
            <Row style={{ display: 'contents' }} gutter={18}>
              <Table
                className='min-w-[1000px] overflow-auto'
                dataSource={data}
                columns={column}
                bordered
                pagination={{
                  current: params.PageIndex,
                  pageSize: params.PageSize,
                  total,
                  onChange: (page, pageSize) => onChangePage(page, pageSize),
                  showSizeChanger: true
                }}
              />
            </Row>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default ProductSalaryConfigList
