import { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import {
  ColumnConfig,
  SearchConfig,
  convertQueryStringToObjectBase,
  DateFormat,
  convertParamsAxiosBase,
  transformObject
} from '../../base/util'
import { SpinIndicator } from 'antd/es/spin'
import type { UploadProps } from 'antd'
import { Spin, notification, Select, InputNumber, TreeSelect, Form, Row, Table, Button, message, Upload } from 'antd'
import { UploadOutlined, SearchOutlined, LoadingOutlined, DeleteOutlined } from '@ant-design/icons'
import {
  getListServices,
  getListSalonFlowService,
  getListServicesFlowSalon,
  updateMemberProduct,
  GetListProductsInventory,
  getListProductMemberProduct,
  getListProductMemberSalon,
  updateMemberServices
} from '../api'
import * as XLSX from 'xlsx'
import { getSalons } from 'api/catalog'

export const ProductConfigPriceMember = () => {
  const navigate = useNavigate()
  const [total, setTotal] = useState(0)
  const [reload, setReload] = useState(false)
  const [loadingTable, setLoadingTable] = useState(false)
  const [loading, setLoading] = useState(false)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const antIcon: SpinIndicator = <LoadingOutlined style={{ fontSize: 24 }} spin />
  const PageIndex = searchParams.get('PageIndex')
  const PageSize = searchParams.get('PageSize')
  const [form] = Form.useForm()
  const currentUrl = '/service/config-member'
  const [listSalon, setListSalon] = useState([])
  const [listServices, setListServices] = useState([])
  const [mount, setMount] = useState(false)
  const [data, setData] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalData, setModalData] = useState<any>()
  const [dataChange, setDataChange] = useState([])
  const handleListSalon = async () => {
    try {
      const response: any = await getSalons()
      const { statusCode, data } = response
      if (statusCode === 200) {
        setListSalon(data)
      }
    } catch (error) {
      console.error('Error:', error.message)
    }
  }
  const handleListServices = async () => {
    try {
      const response: any = await GetListProductsInventory()
      if (response?.statusCode === 200) {
        setListServices(response?.data?.data?.filter((c) => c.publish === true))
      }
    } catch (error) {
      console.error('Error:', error.message)
    }
  }
  useEffect(() => {
    handleListSalon()
    handleListServices()
  }, [])
  function updateOrAddProductById(products, updatedProduct) {
    // Sử dụng map để tạo một mảng mới với phần tử được cập nhật
    const updatedProducts = products.map((product) => {
      // Kiểm tra nếu productId và salonId của phần tử trùng với productId và salonId của sản phẩm cần cập nhật
      if (product.productId === updatedProduct.productId && product.salonId === updatedProduct.salonId) {
        // Thực hiện cập nhật
        return { ...product, ...updatedProduct }
      }
      // Nếu không phải sản phẩm cần cập nhật, giữ nguyên phần tử
      return product
    })

    // Kiểm tra xem sản phẩm có tồn tại trong mảng ban đầu không
    const isProductExist = updatedProducts.some(
      (product) => product.productId === updatedProduct.productId && product.salonId === updatedProduct.salonId
    )

    // Nếu sản phẩm không tồn tại, thêm nó vào cuối mảng
    if (!isProductExist) {
      updatedProducts.push(updatedProduct)
    }

    return updatedProducts
  }
  const handleOnChange = (item: any, field: string, value?: any) => {
    console.log(item, 'item')
    const newData = [...data]
    const targetItem = newData.find(
      (dataItem) => dataItem.productId === item.productId && dataItem.salonId === item.salonId
    )
    console.log(targetItem, 'targetItem')
    if (targetItem) {
      switch (field) {
        case 'IsCheckKCS':
          targetItem.IsCheckKCS = !targetItem.IsCheckKCS
          setData(newData)
          break
        case 'silverPrice':
          targetItem.silverPrice = value
          setData(newData)
          break
        case 'goldPrice':
          targetItem.goldPrice = value
          setData(newData)
          break
        case 'diamondPrice':
          targetItem.diamondPrice = value
          setData(newData)
          break
        default:
          break
      }
      const newDataArrayUpdate = updateOrAddProductById(dataChange, targetItem)
      setDataChange(newDataArrayUpdate)
    }
  }
  console.log(dataChange, 'dataChange')
  const column: ColumnConfig[] = [
    {
      title: 'Id Salon',
      dataIndex: 'salonId',
      key: 'salonId'
    },
    {
      title: 'Salon',
      dataIndex: 'salonName',
      key: 'salonName'
    },
    {
      title: 'ID sản phẩm',
      dataIndex: 'productId',
      key: 'productId'
    },
    {
      title: 'Sản phẩm',
      dataIndex: 'productName',
      key: 'productName'
    },
    {
      title: 'Giá Salon',
      dataIndex: 'salonPrice',
      key: 'salonPrice'
    },
    {
      title: 'Giá Sliver',
      key: 'silverPrice',
      render: (record) => (
        <InputNumber
          value={record.silverPrice}
          onChange={(value: number) => handleOnChange(record, 'silverPrice', value)}
        />
      )
    },
    {
      title: 'Giá Gold',
      key: 'goldPrice',
      render: (record) => (
        <InputNumber
          value={record.goldPrice}
          onChange={(value: number) => handleOnChange(record, 'goldPrice', value)}
        />
      )
    },
    {
      title: 'Giá Diamond',
      key: 'diamondPrice',
      render: (record) => (
        <InputNumber
          value={record.diamondPrice}
          onChange={(value: number) => handleOnChange(record, 'diamondPrice', value)}
        />
      )
    }
  ]

  const handleChangeValue = (changedValues, onReset: (field: string[]) => void) => {
    if (changedValues?.salonId) {
      onReset(['statusName'])
    }
  }
  const onValuesChange = (changedValues) => {
    const keyName = Object.keys(changedValues)[0]
    const value = changedValues[keyName]

    if (keyName.toLowerCase().includes('date')) {
      setParams({
        ...params
      })
    } else {
      setParams({ ...params, ...changedValues })
    }
    handleChangeValue(changedValues, onReset)
  }
  const onReset = (field: string[]) => {
    form.resetFields([...field])
  }
  const handleFetchListStaff = async (paramsSearch: any) => {
    try {
      console.log(paramsSearch)
      setLoadingTable(true)
      setLoading(true)
      const res: any = await getListServices({ ...paramsSearch, inventoryType: 1 })
      setLoading(false)
      if (res) {
        setTotal(res.totalRecords)
        setData(res.data.listData)
      }
    } catch (error) {
      notification.error({
        description: 'Không thể thao tác, vui lòng thử lại sau',
        message: 'Lỗi'
      })
    }
    setLoading(false)
    setReload(false)
    setLoadingTable(false)
  }
  const [params, setParams] = useState<any>({
    PageSize: 10,
    PageIndex: 1
  })
  const onFinish = async () => {
    // console.log('runing')
    const result = {
      PageSize: 0,
      PageIndex: 0
    }
    const value = form.getFieldsValue()
    result.PageSize = params.PageSize
    result.PageIndex = params.PageIndex

    if (isChooseService) {
      const body = {
        salonIds: value.salonId ? value.salonId.toString() : undefined
      }
      setLoadingTable(true)
      const response: any = await getListProductMemberProduct(value.serviceId, body)
      setData(response?.data)
    } else {
      const body = {
        productIds: value.serviceId ? value.serviceId.toString() : undefined
      }
      setLoadingTable(true)
      const response: any = await getListProductMemberSalon(value.salonId, body)
      setData(response?.data)
    }
    setLoadingTable(false)
  }

  const prefetchDataStaffList = () => {
    const paramsSearchResult = window.location.search.replace('?', '')
    console.log(paramsSearchResult, 'paramsSearchResult')
    if (Object.keys(paramsSearchResult).length > 0) {
      const params = new URLSearchParams(paramsSearchResult)
      const paramsObject = {}
      params.forEach(function (value, key) {
        paramsObject[key] = value
      })
      handleFetchListStaff(paramsObject)
    } else {
      setData([])
    }
    if (!mount) setMount(true)
  }

  useEffect(() => {
    prefetchDataStaffList()
  }, [window.location.search])

  const beforeUpload = (file: any) => {
    try {
      const reader = new FileReader()

      reader.onload = (e) => {
        const binaryString = e.target.result
        const workbook = XLSX.read(binaryString, { type: 'binary' })

        // Lấy dữ liệu từ sheet đầu tiên của workbook
        if (!workbook.SheetNames[0]) throw new Error('Sheet names do not ewxits Sheet[0]')
        const firstSheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[firstSheetName]

        // Chuyển đổi dữ liệu từ worksheet thành mảng đối tượng
        const excelData = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
        if (excelData.length < 2) throw new Error('Excel cần chứa dữ liệu !!')
        // Lấy dữ liệu từ phần tử thứ 2 trở đi
        const newData = excelData.slice(1).map((row) => {
          return {
            salonId: row[0],
            salonName: listSalon.find((c) => c.id === row[0])?.name,
            productId: row[1],
            silverPrice: row[2],
            goldPrice: row[3],
            diamondPrice: row[4]
          }
        })

        // Lưu giá trị vào state
        setData(newData)
        setDataChange(newData)
      }

      reader.readAsBinaryString(file)
      return false
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    prefetchDataStaffList()
    // cập nhật các value cho thẻ selector
    if (searchParams.get('salonId')) {
      setSalonId(Number(searchParams.get('salonId')))
    }
    if (searchParams.get('salonIds')) {
      const arrayValue = searchParams.get('salonIds')?.split(',')?.map(Number)
      setSalonId(arrayValue)
      setIsChooseService(true)
    }
    if (searchParams.get('serviceId')) {
      setServiceId(Number(searchParams.get('serviceId')))
    }
    if (searchParams.get('serviceIds')) {
      const arrayValue = searchParams.get('serviceIds')?.split(',')?.map(Number)
      setServiceId(arrayValue)
      setIsChooseSalon(true)
    }
  }, [window.location.search])
  // logic khi chọn salon - service
  const [salonId, setSalonId] = useState<any>(null)
  const [serviceId, setServiceId] = useState<any>(null)
  const [loadingSelectSalon, setLoadingSelectSalon] = useState<boolean>(false)
  const [loadingSelectService, setLoadingSelectService] = useState<boolean>(false)
  const [isChooseSalon, setIsChooseSalon] = useState<boolean>(false)
  const [isChooseService, setIsChooseService] = useState<boolean>(false)
  const handleOnchangeServices = async (e: any) => {
    if (!isChooseSalon) {
      try {
        // console.log(body, 'body')
        setIsChooseService(true)
        setLoadingSelectSalon(true)
        const response = await getListSalonFlowService({}, e)
        // console.log(response, 'response')
        if (response.data) {
          const listSalon = response.data?.map((data) => {
            return {
              id: data.salonId,
              name: data.salonName
            }
          })
          console.log(listSalon, 'listSalon')
          setListSalon(listSalon)
        }
        setLoadingSelectSalon(false)
      } catch (error) {
        setLoadingSelectSalon(false)
        console.error('Error:', error)
        notification.error({
          description: error.message,
          message: 'Lỗi'
        })
      }
    }
    form.setFieldValue('serviceId', e)
    setParams({ ...params, ['serviceId']: e })
  }
  const handleOnchangeSalon = async (e: any) => {
    if (!isChooseService) {
      try {
        // console.log(body, 'body')
        setIsChooseSalon(true)
        setLoadingSelectService(true)
        const response = await getListServicesFlowSalon({}, e)
        // console.log(response, 'response')
        if (response.data) {
          const listServices = response.data?.map((data) => {
            return {
              value: data.productId,
              label: '(' + data.productId + ') ' + data.productName
            }
          })
          console.log(listServices, 'listServices')
          setListServices(listServices)
        }
        setLoadingSelectService(false)
      } catch (error) {
        setLoadingSelectService(false)
        console.error('Error:', error)
        notification.error({
          description: error.message,
          message: 'Lỗi'
        })
      }
    }
    form.setFieldValue('salonId', e)
    setParams({ ...params, ['salonId']: e })
  }
  const handleUpdateConfig = async () => {
    try {
      const body = dataChange.map((c) => {
        return {
          salonId: c.salonId,
          productId: c.productId,
          silverPrice: c.silverPrice,
          goldPrice: c.goldPrice,
          diamondPrice: c.diamondPrice
        }
      })
      const response: any = await updateMemberProduct(body)
      if (response.statusCode === 200) {
        notification.success({
          message: response.message
        })
        setDataChange([])
      } else {
        notification.error({
          description: response.message,
          message: 'Error'
        })
      }
    } catch (e) {
      notification.error({
        description: e,
        message: 'Error'
      })
    }
  }
  const handleResetFilter = () => {
    form.resetFields()
    setSalonId(null)
    setServiceId(null)
    setLoadingSelectSalon(false)
    setLoadingSelectService(false)
    setIsChooseSalon(false)
    setIsChooseService(false)
    handleListSalon()
    handleListServices()
  }
  return (
    <div className='min-h-[100vh] m-auto bg-[#F3F3F3] pb-12'>
      <div className='w-full px-5 py-5 search-wrapper'>
        <Form className='pb-10' form={form} onFinish={onFinish} onValuesChange={onValuesChange}>
          <h2 className='text-2xl font-bold mb-7'>Giá member sản phẩm</h2>

          <Row className=''>
            <Form.Item
              name='salonId'
              label='Chọn Salon'
              className='w-[300px]'
              // key={salonId}
            >
              {isChooseService ? (
                <Select
                  allowClear
                  className='w-full'
                  defaultValue={salonId ? salonId : undefined}
                  placeholder={'Chọn Salon'}
                  fieldNames={{ label: 'name', value: 'id' }}
                  options={listSalon}
                  showSearch
                  optionFilterProp='children'
                  onChange={(e) => handleOnchangeSalon(e)}
                  filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
                  loading={loadingSelectSalon}
                  // disabled={loadingSelectSalon}
                  mode='multiple'
                />
              ) : (
                <Select
                  allowClear
                  className='w-full'
                  defaultValue={salonId ? salonId : undefined}
                  placeholder={'Chọn Salon'}
                  fieldNames={{ label: 'name', value: 'id' }}
                  options={listSalon}
                  showSearch
                  optionFilterProp='children'
                  onChange={(e) => handleOnchangeSalon(e)}
                  filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
                  loading={loadingSelectSalon}
                  // disabled={loadingSelectSalon}
                />
              )}
            </Form.Item>
            <Form.Item
              name='serviceId'
              label='Chọn sản phẩm'
              className='w-[400px] ml-10'
              key={JSON.stringify(serviceId)}
            >
              {isChooseSalon ? (
                <Select
                  allowClear
                  className='w-full'
                  defaultValue={serviceId ? serviceId : undefined}
                  placeholder={'Chọn sản phẩm'}
                  options={listServices}
                  showSearch
                  optionFilterProp='children'
                  onChange={(e) => handleOnchangeServices(e)}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase()) ||
                    option?.value?.toString().includes(input)
                  }
                  loading={loadingSelectService}
                  // disabled={loadingSelectService}
                  mode='multiple'
                />
              ) : (
                <Select
                  allowClear
                  className='w-full'
                  fieldNames={{ label: 'name', value: 'productId' }}
                  defaultValue={serviceId ? serviceId : undefined}
                  placeholder={'Chọn sản phẩm'}
                  options={listServices}
                  showSearch
                  optionFilterProp='children'
                  onChange={(e) => handleOnchangeServices(e)}
                  filterOption={(input, option) =>
                    (option?.name ?? '').toLowerCase().includes(input.toLowerCase()) ||
                    option?.productId?.toString().includes(input)
                  }
                  loading={loadingSelectService}
                  // disabled={loadingSelectService}
                />
              )}
            </Form.Item>
            <Button
              type='default'
              htmlType='submit'
              className='w-[160px] bg-[#4096ff] text-white hover:bg-white'
              disabled={loading}
            >
              {!loading ? (
                <div className='flex items-center justify-center hover_icon'>
                  <SearchOutlined className='mr-1 text-white' />
                  Xem dữ liệu
                </div>
              ) : (
                <Spin indicator={antIcon} />
              )}
            </Button>
            <Button
              onClick={() => {
                handleResetFilter()
              }}
              type='default'
              className='w-[160px] bg-[#4096ff] text-white hover:bg-white'
            >
              Reset Filter
            </Button>
          </Row>
          <Row className=''>
            <h3>Import file</h3>{' '}
            <a href='/excel/demo-import-member/product.xlsx' className='ml-3 italic underline'>
              Download file mẫu
            </a>
          </Row>
          <Row className='mt-2'>
            <Upload beforeUpload={beforeUpload} showUploadList={false}>
              <Button icon={<UploadOutlined style={{ position: 'relative', top: '-4px' }} />}>Upload File</Button>
            </Upload>
          </Row>
          <div
            className='mt-5 bg-white'
            style={{ boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px', borderRadius: 10 }}
          >
            <Row style={{ display: 'contents' }} gutter={18}>
              <Table
                scroll={{ x: 1500, y: 550 }}
                className='min-w-[1000px] overflow-auto'
                dataSource={data}
                columns={column}
                pagination={false}
                bordered
                loading={loadingTable}
              />
            </Row>
          </div>
        </Form>
        <Button type='primary' onClick={handleUpdateConfig}>
          Cập nhật cấu hình
        </Button>
      </div>
    </div>
  )
}
