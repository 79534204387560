type ResultOption = 'success' | 'fail' | 'none'

export const checkValueAI = (value: string | null | undefined, slug: any): ResultOption => {
  try {
    if (value !== null && value !== undefined) {
      const parseValue = JSON.parse(value)
      if (!parseValue) throw new Error('Invalid value')
      const valueCheck = parseValue[slug]
      return Number(valueCheck) >= 0.7 ? 'success' : 'fail'
    } else {
      return 'none'
    }
  } catch (error) {
    console.log(error)
    return 'none'
  }
}
