import { useState, useEffect } from 'react'
import { useAppSelector } from 'redux/store'
import FormComponentV3 from 'pages/base/FormV3'
import { ColumnConfig, SearchConfig, TypeSearch } from 'pages/base/util'
import { Checkbox, Button, Modal, notification } from 'antd'
import { listProducts, listCategoryProducts, updateProduct, downloadExcel, updateStatus } from './api'
import { useLocation, useNavigate } from 'react-router-dom'
import { EditOutlined } from '@ant-design/icons'

export const ListProduct = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const PageIndex = searchParams.get('PageIndex')
  const PageSize = searchParams.get('PageSize')
  const { resource } = useAppSelector((state) => state.staff)
  const [reload, setReload] = useState(false)
  const [listCategoryProduct, setListCategoryProduct] = useState([])
  const [modalData, setModalData] = useState<any>()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const getListCategoryProducts = async () => {
    try {
      const response: any = await listCategoryProducts()
      if (response.statusCode === 200) {
        // setListCategoryProduct(response.data)

        const dataCate = response.data.map((item) => {
          if (item.children && item.children.length > 0) {
            return {
              title: item.title,
              value: 'parent-' + item.value,
              children: genCateChildren(item.children, 'child-')
            }
          } else
            return {
              title: item.title,
              value: 'parent-' + item.value
            }
        })

        setListCategoryProduct(dataCate)
      }
    } catch (error) {
      console.error('Error:', error.message)
    }
  }

  const genCateChildren = (itemChild: any[], prefix: string) => {
    return itemChild.map((item) => {
      if (item.children && item.children.length > 0) {
        return {
          title: item.title,
          key: prefix + item.value,
          value: genCateChildren(item.children, 'child')
        }
      } else
        return {
          title: item.title,
          value: prefix + item.value
        }
    })
  }

  useEffect(() => {
    getListCategoryProducts()
  }, [])
  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleChangeStatus = (record: any, state: string) => {
    let text = ''
    let body = {}
    switch (state) {
      case 'publish':
        if (record.publish) {
          text = `Thay đổi Publish Sản phẩm ${record.name} sang ẩn ?`
        } else {
          text = `Thay đổi Publish Sản phẩm ${record.name} sang hiện ?`
        }
        body = {
          publish: !record.publish
        }
        break
      case 'isCheckVatTu':
        if (record.isCheckVatTu) {
          text = `Bỏ xác nhận Sản phẩm ${record.name} là có Theo dõi tồn kho ?`
        } else {
          text = `Lựa chọn Sản phẩm ${record.name} là có Theo dõi tồn kho ?`
        }
        body = {
          isCheckVatTu: !record.isCheckVatTu
        }
        break
    }
    const dataModal = {
      text: text,
      SalonId: record.id,
      body: body
    }
    setModalData(dataModal)
    showModal()
  }

  const handleOk = async () => {
    try {
      const body = modalData.body
      // console.log(body, 'body')
      const response = await updateStatus(modalData.SalonId, body)
      if (response) {
        notification.success({
          description: 'Bạn đã thay đổi dữ liệu Product',
          message: 'Thành công'
        })
        setReload(true)
      } else {
        notification.warning({
          description: 'Không thể thay đổi dữ liệu Product',
          message: 'Lỗi'
        })
      }
      setIsModalOpen(false)
    } catch (error) {
      console.error('Error:', error)
      notification.error({
        description: error.message,
        message: 'Lỗi'
      })
    }
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }
  const column: ColumnConfig[] = [
    {
      title: 'STT',
      key: 'index',
      render: (item, index, i) => {
        return <span>{PageIndex ? i + (Number(PageIndex) - 1) * Number(PageSize) + 1 : i + 1}</span>
      }
    },
    {
      title: 'Tên sản phẩm',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Mã sản phẩm',
      dataIndex: 'code',
      key: 'code'
    },
    {
      title: 'Mã barcode',
      dataIndex: 'barCode',
      key: 'barCode'
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: 'Giá nhập',
      dataIndex: 'cost',
      key: 'cost'
    },

    {
      title: 'Giá bán',
      dataIndex: 'price',
      key: 'price'
    },
    {
      title: 'Chiết khấu lương (%)',
      dataIndex: 'forSalary',
      key: 'forSalary'
    },
    {
      title: 'Theo dõi tồn kho',
      key: 'isCheckVatTu',
      render: (record) => (
        <div className='flex justify-center'>
          <Checkbox checked={record.isCheckVatTu} onClick={() => handleChangeStatus(record, 'isCheckVatTu')} />
        </div>
      )
    },
    {
      title: 'publish',
      key: 'publish',
      render: (record) => (
        <div className='flex justify-center'>
          <Checkbox checked={record.publish} onClick={() => handleChangeStatus(record, 'publish')} />
        </div>
      )
    },
    {
      title: 'Chỉnh sửa',
      key: 'edit',
      render: (record) => (
        <Button onClick={() => navigate(`/product/detail/${record.id}`)}>
          <EditOutlined />
        </Button>
      )
    }
  ]
  const columnSearch: SearchConfig[] = [
    {
      label: 'Danh mục sản phẩm',
      param: 'CategoryId',
      type: TypeSearch.treeSelect,
      options: listCategoryProduct,
      placeholder: 'Chọn danh mục'
      // fieldNames: { label: 'name', value: 'id' }
    },
    {
      label: 'Nhập tên/ID Sản phẩm',
      param: 'name',
      type: TypeSearch.input,
      options: resource.salonId,
      placeholder: 'Tên/ID sản phẩm'
    },
    // {
    //   label: 'Nhập mã Sản phẩm',
    //   param: 'code',
    //   type: TypeSearch.input,
    //   options: resource.salonId,
    //   placeholder: 'Mã sản phẩm'
    // },
    {
      label: 'Theo dõi tồn kho',
      param: 'isCheckVatTu',
      type: TypeSearch.radio,
      options: [
        { value: true, label: 'Có' },
        { value: false, label: 'Không' }
      ]
    },
    {
      label: 'Publish',
      param: 'publish',
      type: TypeSearch.radio,
      options: [
        { value: true, label: 'Có' },
        { value: false, label: 'Không' }
      ]
    }
  ]
  const handleChangeValue = (changedValues, onReset: (field: string[]) => void) => {
    if (changedValues?.salonId) {
      onReset(['statusName'])
    }
  }
  const exportData = async () => {
    let param = ''
    const Name = searchParams.get('Name')
    const Code = searchParams.get('Code')
    const minPrice = searchParams.get('minPrice')
    const Publish = searchParams.get('Publish')
    const Status = searchParams.get('Status')
    const IsCheckVatTu = searchParams.get('IsCheckVatTu')
    const maxPrice = searchParams.get('maxPrice')
    if (Name) {
      param += `Name=${Name}`
    }
    if (Code) {
      param += `&Code=${Code}`
    }
    if (minPrice) {
      param += `&minPrice=${minPrice}`
    }
    if (maxPrice) {
      param += `&maxPrice=${maxPrice}`
    }
    if (Publish) {
      param += `&Publish=${Publish}`
    }
    if (Status) {
      param += `&Status=${Status}`
    }
    if (IsCheckVatTu) {
      param += `&IsCheckVatTu=${IsCheckVatTu}`
    }
    await downloadExcel(param)
  }
  return (
    <div className='min-h-[100vh] m-auto bg-[#F3F3F3] pb-12'>
      <FormComponentV3
        currentUrl='/product/list'
        title='Danh sách Sản phẩm'
        column={column}
        columnSearch={columnSearch}
        fetchData={listProducts}
        handleChangeValue={handleChangeValue}
        linkADD='/product/add'
        reload={reload}
        exportCsv={false}
        setReload={setReload}
        exportData={exportData}
      ></FormComponentV3>
      {modalData && (
        <Modal title='Xác nhận cập nhật' open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
          <p>{modalData.text}</p>
        </Modal>
      )}
    </div>
  )
}
