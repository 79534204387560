import getInstanceAxios from '../requestManager/RequestGlobalConfig'
import { axiosUploadToS3 } from './AxiosInterceptor'
export const author_webformERP = getInstanceAxios(process.env.REACT_APP_API_WEBFORM_ERP)

interface IbodyKeyS3 {
  key: string
}

const getKeyS3EndPoint = (bodyKeyS3: IbodyKeyS3): Promise<any> => {
  return author_webformERP.post('/api/v1/uploads', bodyKeyS3)
}

const uploadImageS3 = (urlEndPoint: string, file: any): Promise<any> => {
  return axiosUploadToS3.put(urlEndPoint, file)
}
export const MAX_FILE_SIZE = 2 * 1024 * 1024 // 3MB in bytes

export const ALLOWED_TYPE_PHOTO = ['jpg', 'jpeg', 'png', 'webp']
export default { getKeyS3EndPoint, uploadImageS3 }
