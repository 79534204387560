import React, { useState, Key, Dispatch, SetStateAction, useRef, useEffect } from 'react'
import { Button, Modal, Input, Form, notification, Select, InputNumber } from 'antd'
import { validateHairStyleName } from '../utils/validate'
// import { ExclamationCircleOutlined } from '@ant-design/icons'
import VariantData from './VariantData'
import ModalVariantData from './ModalVariantData'
import ApiEndpoint from '../api'
import './style.scss'

type FieldType = {
  title: string
  type: any
  standardTime: any
}
interface TitleBoxProps {
  setLoading: Dispatch<SetStateAction<boolean>>
  data: any
  handleGetData: any
}

const HairStyles: React.FC<TitleBoxProps> = ({ setLoading, data, handleGetData }) => {
  const formRef = useRef(null)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [isModalVariantOpen, setIsModalVariantOpen] = useState<boolean>(false)
  const [name, setName] = useState('')
  const [initialValues, setInitialValues] = useState({
    title: '',
    type: '',
    standardTime: ''
  })
  //   const [modal, contextHolder] = Modal.useModal()
  //   console.log(contextHolder, 'contextHolder')
  const [standardTime, setStandardTime] = useState<number>(null)
  const [type, setType] = useState(null)
  const [isChange, setIsChange] = useState(false)

  useEffect(() => {
    setInitialValues({
      title: data.name,
      type: data.type,
      standardTime: data.standardTime
    })
    setStandardTime(data.standardTime)
    setType(data.type)
  }, [data])
  const handleOk = () => {
    setIsModalOpen(false)
    setLoading(true)
    setIsModalOpen(true)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }
  const onFinish = async (values: FieldType) => {
    // Xử lý update tiêu đề ảnh
    console.log('Received values:', values)
    const body = {
      id: data.id,
      name: values.title,
      type: type,
      standardTime: standardTime ? standardTime.toString() : null,
      createdBy: 'Admin',
      hairStyleDetails: []
    }
    setLoading(true)
    try {
      const response: any = await ApiEndpoint.createUpdateHairStyles(body)
      const { statusCode, data } = response
      if (statusCode === 200) {
        // lấy lại dữ liệu kiểu tóc
        notification.success({
          message: 'Thành công',
          description: 'Bạn đã thành công cập nhật dữ liệu cho kiểu tóc'
        })
        // formRef.current.resetFields()
        handleGetData()
        setIsModalOpen(false)
      }
    } catch (error) {
      console.error('Error checking Name:', error)
      setLoading(false)
    }
  }

  const onFinishFailed = (errorInfo: {
    values: FieldType
    errorFields: { name: Key[]; errors: string[] }[]
    outOfDate: boolean
  }) => {
    console.log('Failed:', errorInfo)
  }

  const preventEnterSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setIsChange(true)
    setName(value)
  }

  const onChange = (value: number) => {
    console.log('changed', value)
    setStandardTime(value)
  }

  const handleChange = (value: string) => {
    console.log(`selected ${value}`)
    setType(value)
    if (!value) {
      setStandardTime(data.standardTime)
    } else {
      setStandardTime(null)
    }
  }
  const handleDeleteVariant = () => {
    console.log('oke')

    Modal.confirm({
      title: 'Xác nhận',
      content: `Bạn có chắc chắn muốn xóa kiểu tóc ${data.name}?`,
      onOk() {
        // Xử lý khi người dùng nhấn nút "OK" (hoặc nút khác tùy theo cấu hình)
        console.log('OK button clicked')
        handleDeleteHairStyle()
      },
      onCancel() {
        // Xử lý khi người dùng nhấn nút "Cancel" (hoặc nút khác tùy theo cấu hình)
        console.log('Cancel button clicked')
      }
    })
  }

  const handleDeleteHairStyle = async () => {
    setLoading(true)
    try {
      const body = {
        id: data.id,
        name: data.name,
        isDelete: true,
        hairStyleDetails: []
      }
      const response: any = await ApiEndpoint.createUpdateHairStyles(body)
      const { statusCode, message } = response
      if (statusCode === 200) {
        // lấy lại dữ liệu kiểu tóc
        notification.success({
          message: 'Thành công',
          description: 'Bạn đã thành công xoá kiểu tóc'
        })
        handleGetData()
        setLoading(false)
      }
    } catch (error) {
      console.error('Error checking Name:', error)
      setLoading(false)
    }
  }
  return (
    <div className='hair-wrap'>
      <div className='navbar'>
        <div
          className='navbar-title'
          onClick={() => setIsModalOpen(true)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setIsModalOpen(true)
            }
          }}
          role='button'
          tabIndex={0}
        >
          <div className='navbar-title-text'>{data.name}</div>
          <div style={{ marginLeft: '10px', display: data.standardTime ? '' : 'none' }}>
            <div>Thời gian tiêu chuẩn: {data.standardTime} phút</div>
          </div>
          <div style={{ marginLeft: '10px' }}>
            Thuộc nhóm: {data.type ? (data.type === 1 ? 'Kiểu uốn' : 'Kiểu nhuộm') : 'Kiểu cắt'}
          </div>
          <img src='/pen.svg' alt='edit' className='navbar-title-img' />
        </div>
        <div className='navbar-btn'>
          <Button className='navbar-btn-item' onClick={handleDeleteVariant}>
            Xóa
          </Button>
          <Button className='navbar-btn-item' type='primary' onClick={() => setIsModalVariantOpen(true)}>
            Thêm biến thể
          </Button>
        </div>
      </div>
      {data && data.hairStyleDetails?.length > 0 && (
        <div className='body'>
          <div className='body-title'>Biến thể</div>
          <div className='body-wrap'>
            {data.hairStyleDetails?.map((item) => (
              <>
                <VariantData
                  setLoading={setLoading}
                  handleGetData={handleGetData}
                  dataHairStyle={data}
                  createdBy={item.createdBy}
                  createdDate={item.createdDate}
                  id={item.id}
                  isDelete={item.isDelete}
                  name={item.name}
                  urlImage={item.urlImage}
                />
              </>
            ))}
          </div>
        </div>
      )}

      <Modal title='Sửa kiểu tóc' open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null}>
        <Form
          name='basic'
          initialValues={initialValues}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete='off'
          ref={formRef}
        >
          <div>Tên kiểu tóc</div>
          <Form.Item
            label=''
            name='title'
            rules={isChange ? [{ validator: (_, value) => validateHairStyleName(value) }] : null}
          >
            <Input placeholder='Nhập tên kiểu tóc' onChange={handleInputChange} />
          </Form.Item>

          <div>Thuộc nhóm</div>
          <Form.Item label='' name=''>
            <Select
              style={{ width: '100%' }}
              defaultValue={type}
              onChange={handleChange}
              options={[
                { value: null, label: 'Kiểu cắt' },
                { value: 1, label: 'Kiểu uốn' },
                { value: 2, label: 'Kiểu nhuộm' }
              ]}
            />
          </Form.Item>
          <div>Thời gian tiêu chuẩn (Phút)</div>
          <Form.Item
            label=''
            name='standardTime'
            rules={[{ required: !standardTime && !type, message: 'Vui lòng nhập số thời gian tiêu chuẩn' }]}
          >
            <InputNumber
              placeholder='Nhập thời gian tiêu chuẩn'
              style={{ width: '100%' }}
              min={1}
              value={standardTime}
              onChange={onChange}
            />
          </Form.Item>
          <Button type='primary' className={'button-submit'} htmlType='submit'>
            Lưu
          </Button>
        </Form>
      </Modal>
      <ModalVariantData
        nameHairStye={data.name}
        idHairStye={data.id}
        setIsModalVariantOpen={setIsModalVariantOpen}
        isModalVariantOpen={isModalVariantOpen}
        setLoading={setLoading}
        handleGetData={handleGetData}
        type={data.type}
      />
    </div>
  )
}

export default HairStyles
