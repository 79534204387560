import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { ColumnConfig, DateFormat, convertParamsAxiosBase } from '../../base/util'
import { notification, TreeSelect, Form, Row, Table, Button, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { getListServices, getListSalonSelectV2, GetListServices, updateServicesSalary } from '../api'
import * as XLSX from 'xlsx'

export const SalaryConfigService = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [form] = Form.useForm()
  const currentUrl = '/service/config-member'
  const [listSalon, setListSalon] = useState([])
  const [listSalonId, setListSalonId] = useState([])
  const [listServices, setListServices] = useState([])
  const [mount, setMount] = useState(false)
  const [data, setData] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalData, setModalData] = useState<any>()
  const handleListSalon = async () => {
    try {
      const response: any = await getListSalonSelectV2()
      setListSalon(response)
    } catch (error) {
      console.error('Error:', error.message)
    }
  }
  const handleListServices = async () => {
    try {
      const response: any = await GetListServices()
      const { statusCode, data } = response
      if (statusCode === 0) {
        setListServices(data)
      }
    } catch (error) {
      console.error('Error:', error.message)
    }
  }
  useEffect(() => {
    handleListSalon()
    handleListServices()
  }, [])
  const handleOnChange = (item: any, field: string, value?: any) => {
    const newData = [...data]
    const targetIndex = newData.findIndex((dataItem) => dataItem.id === item.id)

    if (targetIndex !== -1) {
      switch (field) {
        case 'IsCheckKCS':
          newData[targetIndex].IsCheckKCS = !newData[targetIndex].IsCheckKCS
          setData(newData)
          break
        case 'PriceSliver':
          newData[targetIndex].PriceSliver = value
          setData(newData)
          break
        case 'PriceGold':
          newData[targetIndex].PriceGold = value
          setData(newData)
          break
        case 'PriceDiamond':
          newData[targetIndex].PriceDiamond = value
          setData(newData)
          break
        case 'Delete':
          newData.splice(targetIndex, 1)
          setData(newData)
          break
        default:
          break
      }
    }
  }
  const column: ColumnConfig[] = [
    {
      title: 'Id dịch vụ',
      dataIndex: 'ServiceId',
      key: 'ServiceId'
    },
    // {
    //   title: 'Dịch vụ',
    //   dataIndex: 'Salon',
    //   key: 'Salon'
    // },
    {
      title: 'Vị trí',
      dataIndex: 'DepartmentId',
      key: 'DepartmentId'
    },
    {
      title: 'Level',
      dataIndex: 'LevelId',
      key: 'LevelId'
    },
    {
      title: 'Thưởng dịch vụ',
      dataIndex: 'ServiceSalary',
      key: 'ServiceSalary'
    },
    {
      title: 'Thưởng bonus',
      key: 'ServiceBonus',
      dataIndex: 'ServiceBonus'
    }
    // {
    //   title: 'Giá Gold',
    //   key: 'PriceGold',
    //   render: (record) => (
    //     <InputNumber
    //       value={record.PriceGold}
    //       onChange={(value: number) => handleOnChange(record, 'PriceGold', value)}
    //     />
    //   )
    // },
    // {
    //   title: 'Giá Diamond',
    //   key: 'PriceDiamond',
    //   render: (record) => (
    //     <InputNumber
    //       value={record.PriceDiamond}
    //       onChange={(value: number) => handleOnChange(record, 'PriceDiamond', value)}
    //     />
    //   )
    // }
  ]

  const handleChangeValue = (changedValues, onReset: (field: string[]) => void) => {
    if (changedValues?.salonId) {
      onReset(['statusName'])
    }
  }
  const onValuesChange = (changedValues) => {
    const keyName = Object.keys(changedValues)[0]
    const value = changedValues[keyName]

    if (keyName.toLowerCase().includes('date')) {
      setParams({
        ...params
      })
    } else {
      setParams({ ...params, ...changedValues })
    }
    handleChangeValue(changedValues, onReset)
  }
  const onReset = (field: string[]) => {
    form.resetFields([...field])
  }
  const handleFetchListStaff = async (paramsSearch: any) => {
    try {
      const res: any = await getListServices(paramsSearch)
      if (res) {
        setData(res.data.listData)
      }
    } catch (error) {
      notification.error({
        description: 'Không thể thao tác, vui lòng thử lại sau',
        message: 'Lỗi'
      })
    }
  }
  const [params, setParams] = useState<any>({
    PageSize: 10,
    PageIndex: 1
  })
  const onFinish = () => {
    const result = {
      PageSize: 0,
      PageIndex: 0
    }
    const value = form.getFieldsValue()
    result.PageSize = params.PageSize
    result.PageIndex = params.PageIndex
    Object.keys(value).map((i) => {
      if (
        typeof value[i] === 'object' &&
        value[i]?.length === 2 &&
        value[i].every((item) => typeof item === 'object')
      ) {
        result[`${i}_from`] = dayjs(value[i][0]).format(DateFormat)
        result[`${i}_to`] = dayjs(value[i][1]).format(DateFormat)
      } else if (typeof value[i] === 'object') {
        result[i] = dayjs(value[i]).format(DateFormat)
      } else result[i] = value[i]
    })
    for (const key in result) {
      if (result[key] === null || result[key] === undefined) {
        delete result[key]
      }
    }
    navigate(
      `${currentUrl}?${convertParamsAxiosBase({
        ...result,
        PageIndex: 1
      })}`
    )
  }

  const prefetchDataStaffList = () => {
    const paramsSearchResult = window.location.search.replace('?', '')
    if (Object.keys(paramsSearchResult).length > 0) {
      const params = new URLSearchParams(paramsSearchResult)
      const paramsObject = {}
      params.forEach(function (value, key) {
        paramsObject[key] = value
      })
      handleFetchListStaff(paramsObject)
    } else {
      setData([])
    }
    if (!mount) setMount(true)
  }

  useEffect(() => {
    prefetchDataStaffList()
  }, [window.location.search])

  const beforeUpload = (file: any) => {
    try {
      const reader = new FileReader()

      reader.onload = (e) => {
        const binaryString = e.target.result
        const workbook = XLSX.read(binaryString, { type: 'binary' })

        // Lấy dữ liệu từ sheet đầu tiên của workbook
        if (!workbook.SheetNames[0]) throw new Error('Sheet names do not ewxits Sheet[0]')
        const firstSheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[firstSheetName]

        // Chuyển đổi dữ liệu từ worksheet thành mảng đối tượng
        const excelData = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
        if (excelData.length < 2) throw new Error('Excel cần chứa dữ liệu !!')
        // Lấy dữ liệu từ phần tử thứ 2 trở đi
        const newData = excelData.slice(1).map((row) => {
          return {
            DepartmentId: row[0],
            ServiceId: row[1],
            LevelId: row[2],
            ServiceSalary: row[3],
            ServiceBonus: row[4]
          }
        })

        // Lưu giá trị vào state
        setData(newData)
      }

      reader.readAsBinaryString(file)
      return false
    } catch (e) {
      console.log(e)
    }
  }

  const onChange = (newValue) => {
    // eslint-disable-next-line prefer-const
    let salonIds = []
    newValue.map((item) => {
      // eslint-disable-next-line prefer-const
      let salonCheck = listSalon.find((c) => c.key === item)
      if (salonCheck) {
        salonCheck.children.map((item) => {
          salonIds.push(item.value)
        })
      } else {
        salonIds.push(item)
      }
    })
    setListSalonId(salonIds)
  }

  const { SHOW_PARENT } = TreeSelect
  const tProps = {
    treeData: listSalon,
    onChange,
    treeCheckable: false,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Please select',
    style: {
      width: '100%'
    }
  }
  const handleUpdateConfig = async () => {
    try {
      if (data?.length === 0 && listSalonId?.length === 0) {
        notification.error({
          description: 'Data không hợp lệ.',
          message: 'Lỗi'
        })
      } else {
        const response: any = await updateServicesSalary({ salary: data, salonIds: listSalonId })
        if (response.statusCode === 200) {
          notification.success({ message: 'Thành công' })
        } else {
          notification.error({
            description: response.message,
            message: 'Lỗi'
          })
        }
      }
    } catch (error) {
      notification.error({
        description: 'Không thể thao tác, vui lòng thử lại sau',
        message: 'Lỗi'
      })
    }
  }
  return (
    <div className='min-h-[100vh] m-auto bg-[#F3F3F3] pb-12'>
      <div className='w-full px-5 py-5 search-wrapper'>
        <Form className='pb-10' form={form} onFinish={onFinish} onValuesChange={onValuesChange}>
          <h2 className='text-2xl font-bold mb-7'>Cấu hình lương dịch vụ</h2>

          <Row className=''>
            <Form.Item
              name='SalonId'
              label='Chọn Salon cấu hình lương'
              rules={[{ required: true, message: 'Bạn phải chọn Salon' }]}
              className='w-full'
            >
              {/* <Select
                allowClear
                className='w-full'
                value={''}
                placeholder={'Chọn Salon'}
                fieldNames={{ label: 'name', value: 'id' }}
                options={listSalon}
                showSearch
                optionFilterProp='children'
                onChange={(e) => {
                  form.setFieldValue('SalonId', e)
                  setParams({ ...params, ['SalonId']: e })
                }}
                filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
              /> */}
              <TreeSelect showSearch multiple allowClear {...tProps} />
            </Form.Item>
            {/* <Button
              type='default'
              htmlType='submit'
              className='w-[160px] bg-[#4096ff] text-white hover:bg-white'
              disabled={loading}
            >
              {!loading ? (
                <div className='flex items-center justify-center hover_icon'>
                  <SearchOutlined className='mr-1 text-white' />
                  Xem dữ liệu
                </div>
              ) : (
                <Spin indicator={antIcon} />
              )}
            </Button>
            <Button
              onClick={() => form.resetFields()}
              type='default'
              className='w-[160px] bg-[#4096ff] text-white hover:bg-white'
            >
              Reset Filter
            </Button> */}
          </Row>
          <Row className='mt-5'>
            <h3>Import file</h3>{' '}
            <a href='/excel/demo-import-salary-service/excel.xlsx' className='ml-3 italic underline'>
              Download file mẫu
            </a>
          </Row>
          <Row className='mt-2'>
            <Upload beforeUpload={beforeUpload} showUploadList={false}>
              <Button icon={<UploadOutlined style={{ position: 'relative', top: '-4px' }} />}>Upload File</Button>
            </Upload>
          </Row>
          <div
            className='mt-5 bg-white'
            style={{ boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px', borderRadius: 10 }}
          >
            <Row style={{ display: 'contents' }} gutter={18}>
              <Table
                className='min-w-[1000px] overflow-auto'
                dataSource={data}
                columns={column}
                pagination={false}
                bordered
              />
            </Row>
          </div>
        </Form>
        <Button type='primary' onClick={handleUpdateConfig}>
          Cập nhật cấu hình
        </Button>
      </div>
    </div>
  )
}
