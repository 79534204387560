import { cataLogApi } from './config'
import { StaffRequestView } from 'utils/ManagementEndpointRequest/TimelineSocket'

interface paramsProps {
  page: number
  pageSize: number
}

export const getAllSalonProductConfigList = () => {
  return cataLogApi.get(`/Salon`)
}

export const getAllProductList = () => {
  return cataLogApi.get(`/v1/product?inventoryType=1`)
}

export const getProductConfigList = (params: paramsProps) => {
  return cataLogApi.get(`/v1/tip-salary-product-salon/list`, {
    params: params
  })
}

export const importProductConfigList = (body: any) => {
  return cataLogApi.post(`/v1/tip-salary-product-salon/import`, body)
}

export const deleteProductConfig = (body: any) => {
  return cataLogApi.post(`/v1/tip-salary-product-salon/delete`, body)
}
