/* eslint-disable @typescript-eslint/ban-ts-comment */
'use client'
import '../index.scss'
import {
  Button,
  Table,
  Form,
  Modal,
  Select,
  Spin,
  message,
  Checkbox,
  Pagination,
  InputNumber,
  notification
} from 'antd'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import type { ColumnsType } from 'antd/es/table'
import { GetListServices, ListServicesSalonConfig, updateServicesSalonConfig } from '../api'
import { getSalons } from 'api/catalog'

interface DataType {
  key: React.Key
  isChoice: boolean
  title: string
  price: string
  score_factor: number
}
export const ServicesSalonConfig: React.FC = () => {
  const searchParams = new URLSearchParams(window.location.search)
  const PageIndex = searchParams.get('PageIndex') || 1
  const PageSize = searchParams.get('PageSize') || 10
  const SalonId = searchParams.get('SalonId') || ''
  const ServiceId = searchParams.get('ServiceId') || ''
  const [currentPage, setCurrentPage] = useState(1)
  const [totalItems, setTotalItems] = useState(20)
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [selectedServices, setSelectedServices] = useState('')
  const [selectedSalon, setSelectedSalon] = useState(null)
  const [dataSelectSalon, setDataListSalon] = useState<any[]>([])
  const [dataSelectServices, setDataListServices] = useState<any[]>([])
  const [dataConfig, setDataConfig] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [isNewData, setIsNewData] = useState<boolean>(false)

  const [selectAllChecked, setSelectAllChecked] = useState(false)
  const getServicesSalonConfig = async () => {
    try {
      const response: any = await ListServicesSalonConfig(PageIndex, PageSize, SalonId, ServiceId)
      const { statusCode, data } = response
      if (statusCode === 0) {
        setDataConfig(data.listData)
        setTotalItems(data.totalRecords)
        setIsNewData(data.isNewData)
      }
    } catch (error) {
      console.error('Error:', error.message)
    }
  }
  const handleListSalon = async () => {
    try {
      const response: any = await getSalons()
      const { statusCode, data } = response
      if (statusCode === 200) {
        setDataListSalon(data)
      }
    } catch (error) {
      console.error('Error:', error.message)
    }
  }
  const handleListServices = async () => {
    try {
      const response: any = await GetListServices()
      const { statusCode, data } = response
      if (statusCode === 0) {
        setDataListServices(data)
      }
    } catch (error) {
      console.error('Error:', error.message)
    }
  }
  useEffect(() => {
    handleListSalon()
    handleListServices()
  }, [])
  useEffect(() => {
    if (SalonId || ServiceId) {
      getServicesSalonConfig()
    }
  }, [SalonId, ServiceId, PageIndex])

  const onValuesChange = async (changedValues: any) => {
    console.log('')
  }

  const handleChangeSalon = (value: string) => {
    searchParams.set('SalonId', value)
    setSelectedSalon(value)
    navigate(`?${searchParams.toString()}`)
  }
  const handleChangeServices = (value: string) => {
    setSelectedServices(value)
    searchParams.set('ServiceId', value)
    navigate(`?${searchParams.toString()}`)
  }
  const handleValueChangeAll = (value, id, field) => {
    if (field === 'selectAll') {
      // Nếu bạn nhấp vào checkbox chung, hãy cập nhật trạng thái của tất cả các checkbox cá nhân.
      const updatedData = dataConfig.map((record) => ({
        ...record,
        IsCheck: value
      }))
      setDataConfig(updatedData) // Cập nhật dữ liệu cho bảng
      setSelectAllChecked(value) // Cập nhật trạng thái của checkbox chung
    } else {
      // Xử lý việc cập nhật dữ liệu cho checkbox cá nhân ở đây
    }
  }
  const columns: ColumnsType<DataType> = [
    {
      title: (
        <Checkbox
          checked={selectAllChecked}
          onChange={(e) => handleValueChangeAll(e.target.checked, null, 'selectAll')}
        />
      ),
      render: (record) => (
        <Checkbox
          defaultChecked={record.IsCheck}
          onChange={(e) => handleValueChange(e.target.checked, record.Id, 'IsCheck')}
        ></Checkbox>
      )
    },
    {
      title: 'Salon',
      dataIndex: 'SalonName',
      render: (text: string) => <>{text}</>
    },
    {
      title: 'Dịch vụ',
      dataIndex: 'ServiceName',
      render: (text: string) => <>{text}</>
    },
    {
      title: 'Giá',
      render: (record) => (
        <InputNumber
          defaultValue={record.Price}
          style={{ width: 120 }}
          onChange={(value) => handleValueChange(value, record.Id, 'Price')}
        ></InputNumber>
      )
    }
    // {
    //   title: '	Hệ số điểm HL',
    //   render: (record) => (
    //     <InputNumber
    //       defaultValue={record.HeSoHL}
    //       style={{ width: 80 }}
    //       onChange={(value) => handleValueChange(value, record.Id, 'HeSoHL')}
    //     ></InputNumber>
    //   )
    // }
  ]
  const [values, setValues] = useState<any[]>([])
  const handleValueChange = (value, Id, fieldName) => {
    const updatedValues = [...values]
    const index = updatedValues.findIndex((item) => item.Id === Id)

    if (index === -1) {
      const newValue = { Id, [fieldName]: value }
      updatedValues.push(newValue)
    } else {
      updatedValues[index][fieldName] = value
    }
    setValues(updatedValues)
  }

  const handleResetFilter = () => {
    navigate(`?`)
    setDataConfig([])
    setSelectedServices(undefined)
    setSelectedSalon(undefined)
  }
  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page)
    const params = new URLSearchParams(window.location.search)
    params.set('PageIndex', page)
    params.set('PageSize', pageSize)
    const newUrl = `${window.location.pathname}?${params.toString()}`
    navigate(newUrl)
  }
  const customTotal = () => {
    return <span className='pagination-table-title'>Tổng {totalItems}</span>
  }
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalCreateOpen, setIsModalCreateOpen] = useState(false)

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = async () => {
    if (values.length === 0) {
      return notification.warning({
        description: 'Không có gì để cập nhật',
        message: 'Nothing'
      })
    }
    try {
      const body = {
        data: values
      }
      updateServicesSalonConfig(body)
        .then((response) => {
          if (response) {
            notification.success({
              description: 'Bạn đã thành công cập nhật dữ liệu cấu hình Dịch vụ Salon',
              message: 'Thành công'
            })
            getServicesSalonConfig()
            setIsModalOpen(false)
          } else {
            notification.warning({
              description: 'Không thể cập nhật dữ liệu cấu hình Dịch vụ Salon',
              message: 'Lỗi'
            })
            setIsModalOpen(false)
          }
        })
        .catch((error) => {
          console.error('Error:', error)
          notification.error({
            description: error.message,
            message: 'Lỗi'
          })
        })
    } catch (error) {
      console.log(error.message)
    }
  }

  const handleCreateOk = async () => {
    if (dataConfig.length === 0) {
      return notification.warning({
        description: 'Chưa cấu hình dịch vụ này cho Salon, xin vui lòng cấu hình bằng tay',
        message: 'Nothing'
      })
    }
    // try {
    //   // console.log(dataConfig, "dataConfig")
    //   const body = dataConfig.map(item=> {
    //     return {
    //       IsCheck: item.IsCheck,
    //       Price: item.Price,

    //     }
    //   })
    //   updateServicesSalonConfig(body)
    //     .then((response) => {
    //       if (response) {
    //         notification.success({
    //           description: 'Bạn đã thành công cập nhật dữ liệu cấu hình Dịch vụ Salon',
    //           message: 'Thành công'
    //         })
    //         getServicesSalonConfig()
    //         setIsModalOpen(false)
    //       } else {
    //         notification.warning({
    //           description: 'Không thể cập nhật dữ liệu cấu hình Dịch vụ Salon',
    //           message: 'Lỗi'
    //         })
    //         setIsModalOpen(false)
    //       }
    //     })
    //     .catch((error) => {
    //       console.error('Error:', error)
    //       notification.error({
    //         description: error.message,
    //         message: 'Lỗi'
    //       })
    //     })
    // } catch (error) {
    //   console.log(error.message)
    // }
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <div style={{ minHeight: 'calc(100vh)' }} className='m-auto pb-28 bg-[#F3F3F3] '>
      <Form
        key={JSON.stringify(dataConfig)}
        className='xl:ml-[5%] 2xl:ml-[5%] custom-form-details'
        onValuesChange={onValuesChange}
        form={form}
        name='basic'
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        autoComplete='off'
      >
        <h3 className='font-bold text-[22px] mt-3 ml-10'>CẤU HÌNH THÔNG TIN DỊCH VỤ SALON</h3>
        <div className='mt-12  custom-form-details-form'>
          <div className='flex justify-between mt-12 mb-5  custom-form-details-form-header'>
            <div className='mt-4 mb-3  custom-form-details-form-header-item-once'>
              <span className='mr-3'>Salon </span>
              <Select
                showSearch
                optionFilterProp='children'
                filterOption={(input, option) => (option?.name ?? '').includes(input)}
                // filterSort={(optionA, optionB) =>
                //   (optionA?.name ?? '').toLowerCase().localeCompare((optionB?.name ?? '').toLowerCase())
                // }
                style={{ width: 300 }}
                onChange={handleChangeSalon}
                placeholder='Lựa chọn Salon'
                options={[{ name: 'Chọn tất cả Salon', id: 0 }, ...dataSelectSalon]}
                value={selectedSalon}
                fieldNames={{ label: 'name', value: 'id' }}
              />
            </div>
            <div className='mt-4 mb-3  custom-form-details-form-header-item'>
              <span className='mr-3'>Dịch vụ </span>
              <Select
                showSearch
                optionFilterProp='children'
                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                // filterSort={(optionA, optionB) =>
                //   (optionA?.name ?? '').toLowerCase().localeCompare((optionB?.name ?? '').toLowerCase())
                // }
                style={{ width: 300 }}
                onChange={handleChangeServices}
                placeholder='Lựa chọn Dịch vụ'
                options={[{ label: 'Chọn tất cả dịch vụ', value: 0 }, ...dataSelectServices]}
                value={selectedServices}
                // fieldNames={{ label: 'name', value: 'id' }}
              />
              <Button type='primary' className='ml-10' onClick={handleResetFilter}>
                Reset Filter
              </Button>
            </div>
          </div>
          <div>
            <Pagination
              current={currentPage}
              onChange={handlePageChange}
              total={totalItems}
              showTotal={() => customTotal()}
              pageSize={Number(PageSize) || 10}
              className='mt-2 mb-5 pagination-table'
              pageSizeOptions={[10, 50, 100, 1000]}
            />
            <Table columns={columns} dataSource={dataConfig} pagination={false} />
            <Pagination
              current={currentPage}
              onChange={handlePageChange}
              total={totalItems}
              showTotal={() => customTotal()}
              pageSize={Number(PageSize) || 10}
              className='mt-10 pagination-table'
              pageSizeOptions={[10, 50, 100, 1000]}
            />
          </div>
        </div>
        {isNewData ? (
          <Form.Item label='' name='idNo' className='pl-4 ml-10'>
            <Button
              className='mt-16 mb-12 bg-[#4096ff] text-white button-submit-table'
              type={values.length > 0 ? 'primary' : 'default'}
              onClick={showModal}
              disabled={values.length > 0 ? false : true}
            >
              {!loading ? 'Tạo cấu hình mới' : <Spin />}
            </Button>
          </Form.Item>
        ) : (
          <Form.Item label='' name='idNo' className='pl-4 ml-10'>
            <Button
              className='mt-16 mb-12 bg-[#4096ff] text-white button-submit-table'
              type={values.length > 0 ? 'primary' : 'default'}
              onClick={showModal}
              disabled={values.length > 0 ? false : true}
            >
              {!loading ? 'Cập nhật cấu hình' : <Spin />}
            </Button>
          </Form.Item>
        )}
      </Form>
      {values && values.length > 0 && (
        <Modal title='Xác nhận cập nhật' open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
          <p>Xác nhận cập nhật {values.length} cấu hình dịch vụ salon</p>
        </Modal>
      )}
      <Modal
        title='Xác nhận tạo mới cấu hình dịch vụ'
        open={isModalCreateOpen}
        onOk={handleCreateOk}
        onCancel={() => setIsModalCreateOpen(false)}
      >
        <p>Xác nhận tạo mới cấu hình dịch vụ cho {dataConfig.length} Salon ?</p>
      </Modal>
    </div>
  )
}
