import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ColumnConfig, convertParamsAxiosBase } from '../../base/util'
import { SpinIndicator } from 'antd/es/spin'
import { Checkbox, Button, notification, InputNumber, Form, Row, Table, Spin, Select } from 'antd'
import { SearchOutlined, LoadingOutlined } from '@ant-design/icons'
import { getListServicesFlowSalon, getListSalonFlowService, GetListServicesV2, GetSalaryServices } from '../api'
import { getSalons } from 'api/catalog'
export const ListSalaryService = () => {
  const navigate = useNavigate()
  const [reload, setReload] = useState(false)
  const [loading, setLoading] = useState(false)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const antIcon: SpinIndicator = <LoadingOutlined style={{ fontSize: 24 }} spin />
  const [form] = Form.useForm()
  const currentUrl = '/salary/salary-service'
  const [listSalon, setListSalon] = useState([])
  const [listServices, setListServices] = useState([])
  const [mount, setMount] = useState(false)
  const [data, setData] = useState([])
  const handleListSalon = async () => {
    try {
      const response: any = await getSalons()
      const { statusCode, data } = response
      if (statusCode === 200) {
        setListSalon(data)
      }
    } catch (error) {
      console.error('Error:', error.message)
    }
  }
  const handleListServices = async () => {
    try {
      const response: any = await GetListServicesV2()
      if (response?.statusCode === 200) {
        setListServices(response?.data?.data)
      }
    } catch (error) {
      console.error('Error:', error.message)
    }
  }
  useEffect(() => {
    handleListSalon()
    handleListServices()
  }, [])
  const column = [
    {
      title: 'ID Dịch vụ',
      dataIndex: 'serviceId',
      key: 'serviceId'
    },
    {
      title: 'Dịch vụ',
      dataIndex: 'serviceName',
      key: 'serviceName'
    },
    {
      title: 'Salon',
      dataIndex: 'salonName',
      key: 'salonName'
    },
    {
      title: 'Vị trí',
      dataIndex: 'departmentName',
      key: 'departmentName'
    },
    {
      title: 'Level',
      dataIndex: 'levelName',
      key: 'levelName'
    },
    {
      title: 'Thưởng dịch vụ',
      dataIndex: 'serviceSalary',
      key: 'serviceSalary'
    },
    {
      title: 'Thưởng bonus',
      dataIndex: 'serviceBonus',
      key: 'serviceBonus'
    }
  ]

  const handleChangeValue = (changedValues, onReset: (field: string[]) => void) => {
    if (changedValues?.salonId) {
      onReset(['statusName'])
    }
  }

  const onValuesChange = (changedValues) => {
    const keyName = Object.keys(changedValues)[0]
    const value = changedValues[keyName]

    if (keyName.toLowerCase().includes('date')) {
      setParams({
        ...params
      })
    } else {
      setParams({ ...params, ...changedValues })
    }
    handleChangeValue(changedValues, onReset)
  }
  const onReset = (field: string[]) => {
    form.resetFields([...field])
  }
  const [params, setParams] = useState<any>({})
  const onFinish = async () => {
    const value = form.getFieldsValue()
    console.log(value, 'value')

    const response: any = await GetSalaryServices(value)
    console.log(response)
    if (response.statusCode === 200) {
      setData(response.data)
    } else {
      notification.error({
        description: response.message,
        message: 'Lỗi'
      })
    }
    // const result: any = {}
    // if (value.salonId?.length > 0) {
    //   const commaSeparatedString = value.salonId?.join(',')
    //   result.salonIds = commaSeparatedString
    //   result.serviceId = value.serviceId
    // } else if (value.serviceId?.length > 0) {
    //   const commaSeparatedString = value.serviceId?.join(',')
    //   result.serviceIds = commaSeparatedString
    //   result.salonId = value.salonId
    // } else {
    //   result.serviceId = value.serviceId
    //   result.salonId = value.salonId
    // }
    // navigate(
    //   `${currentUrl}?${convertParamsAxiosBase({
    //     ...result
    //   })}`
    // )
  }
  useEffect(() => {
    // cập nhật các value cho thẻ selector
    if (searchParams.get('salonId')) {
      setSalonId(Number(searchParams.get('salonId')))
    }
    if (searchParams.get('salonIds')) {
      const arrayValue = searchParams.get('salonIds')?.split(',')?.map(Number)
      setSalonId(arrayValue)
      setIsChooseService(true)
    }
    if (searchParams.get('serviceId')) {
      setServiceId(Number(searchParams.get('serviceId')))
    }
    if (searchParams.get('serviceIds')) {
      const arrayValue = searchParams.get('serviceIds')?.split(',')?.map(Number)
      setServiceId(arrayValue)
      setIsChooseSalon(true)
    }
  }, [window.location.search])
  // logic khi chọn salon - service
  const [salonId, setSalonId] = useState<any>(null)
  const [serviceId, setServiceId] = useState<any>(null)
  const [loadingSelectSalon, setLoadingSelectSalon] = useState<boolean>(false)
  const [loadingSelectService, setLoadingSelectService] = useState<boolean>(false)
  const [isChooseSalon, setIsChooseSalon] = useState<boolean>(false)
  const [isChooseService, setIsChooseService] = useState<boolean>(false)
  const handleOnchangeServices = async (e: any) => {
    if (!isChooseSalon) {
      try {
        // console.log(body, 'body')
        setIsChooseService(true)
        setLoadingSelectSalon(true)
        const response = await getListSalonFlowService({}, e)
        // console.log(response, 'response')
        if (response.data) {
          const listSalon = response.data?.map((data) => {
            return {
              id: data.salonId,
              name: data.salonName
            }
          })
          setListSalon(listSalon)
        }
        setLoadingSelectSalon(false)
      } catch (error) {
        setLoadingSelectSalon(false)
        console.error('Error:', error)
        notification.error({
          description: error.message,
          message: 'Lỗi'
        })
      }
    }
    form.setFieldValue('serviceId', e)
    setParams({ ...params, ['serviceId']: e })
  }
  const handleOnchangeSalon = async (e: any) => {
    if (!isChooseService) {
      try {
        // console.log(body, 'body')
        setIsChooseSalon(true)
        setLoadingSelectService(true)
        const response = await getListServicesFlowSalon({}, e)
        // console.log(response, 'response')
        if (response.data) {
          const listServices = response.data?.map((data) => {
            return {
              value: data.serviceId,
              label: data.serviceName
            }
          })
          console.log(listServices, 'listServices')
          setListServices(listServices)
        }
        setLoadingSelectService(false)
      } catch (error) {
        setLoadingSelectService(false)
        console.error('Error:', error)
        notification.error({
          description: error.message,
          message: 'Lỗi'
        })
      }
    }
    form.setFieldValue('salonId', e)
    setParams({ ...params, ['salonId']: e })
  }

  // const handleResetFilter = () => {
  //   form.resetFields()
  //   setSalonId(null)
  //   setServiceId(null)
  //   setLoadingSelectSalon(false)
  //   setLoadingSelectService(false)
  //   setIsChooseSalon(false)
  //   setIsChooseService(false)
  // }
  return (
    <div className='min-h-[100vh] m-auto bg-[#F3F3F3] pb-12'>
      <div className='w-full px-5 py-5 search-wrapper'>
        <Form className='pb-10' form={form} onFinish={onFinish} onValuesChange={onValuesChange}>
          <h2 className='text-2xl font-bold mb-7'>Xem lương dịch vụ</h2>
          <Row className=''>
            <Form.Item
              name='salonId'
              label='Chọn Salon'
              rules={[{ required: false, message: 'Bạn phải chọn Salon' }]}
              className='w-[300px]'
              // key={salonId}
            >
              {isChooseService ? (
                <Select
                  allowClear
                  className='w-full'
                  defaultValue={salonId ? salonId : undefined}
                  placeholder={'Chọn Salon'}
                  fieldNames={{ label: 'name', value: 'id' }}
                  options={listSalon}
                  showSearch
                  optionFilterProp='children'
                  onChange={(e) => handleOnchangeSalon(e)}
                  filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
                  loading={loadingSelectSalon}
                  disabled={loadingSelectSalon}
                />
              ) : (
                <Select
                  allowClear
                  className='w-full'
                  defaultValue={salonId ? salonId : undefined}
                  placeholder={'Chọn Salon'}
                  fieldNames={{ label: 'name', value: 'id' }}
                  options={listSalon}
                  showSearch
                  optionFilterProp='children'
                  onChange={(e) => handleOnchangeSalon(e)}
                  filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
                  loading={loadingSelectSalon}
                  disabled={loadingSelectSalon}
                />
              )}
            </Form.Item>
            <Form.Item
              name='serviceId'
              label='Chọn Dịch vụ'
              rules={[{ required: false, message: 'Bạn phải chọn Dịch vụ' }]}
              className='w-[400px] ml-10'
              key={JSON.stringify(serviceId)}
            >
              {isChooseSalon ? (
                <Select
                  allowClear
                  className='w-full'
                  defaultValue={serviceId ? serviceId : undefined}
                  placeholder={'Chọn dịch vụ'}
                  options={listServices}
                  showSearch
                  optionFilterProp='children'
                  onChange={(e) => handleOnchangeServices(e)}
                  filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
                  loading={loadingSelectService}
                  disabled={loadingSelectService}
                />
              ) : (
                <Select
                  allowClear
                  className='w-full'
                  fieldNames={{ label: 'name', value: 'serviceId' }}
                  defaultValue={serviceId ? serviceId : undefined}
                  placeholder={'Chọn dịch vụ'}
                  options={listServices}
                  showSearch
                  optionFilterProp='children'
                  onChange={(e) => handleOnchangeServices(e)}
                  filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
                  loading={loadingSelectService}
                  disabled={loadingSelectService}
                />
              )}
            </Form.Item>
            <Button
              type='default'
              htmlType='submit'
              className='w-[160px] bg-[#4096ff] text-white hover:bg-white'
              disabled={loading}
            >
              {!loading ? (
                <div className='flex items-center justify-center hover_icon'>
                  <SearchOutlined className='mr-1 text-white' />
                  Xem dữ liệu
                </div>
              ) : (
                <Spin indicator={antIcon} />
              )}
            </Button>
            {/* <Button
              onClick={handleResetFilter}
              type='default'
              className='w-[160px] bg-[#4096ff] text-white hover:bg-white'
            >
              Reset Filter
            </Button> */}
          </Row>
          <div
            className='mt-5 bg-white'
            style={{ boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px', borderRadius: 10 }}
          >
            <Row style={{ display: 'contents' }} gutter={18}>
              <Table
                className='min-w-[1000px] overflow-auto'
                dataSource={data}
                columns={column}
                pagination={false}
                bordered
              />
            </Row>
          </div>
        </Form>
      </div>
    </div>
  )
}
