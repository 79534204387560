import { getAiRecordById } from '../api'
import React, { useMemo, useState, useCallback, useRef, useEffect } from 'react'
import { useWavesurfer } from '@wavesurfer/react'
import Timeline from 'wavesurfer.js/dist/plugins/timeline.esm.js'
import { PlayCircleOutlined, PauseCircleOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import orderBy from 'lodash/orderBy'

const AIRecordDetail = ({ aiRecordId, recordIntent }: { aiRecordId: number; recordIntent: any }) => {
  const [paragraphs, setParagraphs] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [audioSrc, setAudioSrc] = useState('')
  const [aiScriptResult, setAiScriptResult] = useState([])
  const [isAiScriptDone, setIsAiScriptDone] = useState(false)
  const [isWavesurferReady, setIsWavesurferReady] = useState(false)
  const [highlightWordIndex, setHighlightWordIndex] = useState('')

  // wavesurfer player
  const formatTime = (seconds) => [seconds / 60, seconds % 60].map((v) => `0${Math.floor(v)}`.slice(-2)).join(':')
  const containerRef = useRef(null)

  const { wavesurfer, isPlaying, currentTime } = useWavesurfer({
    container: containerRef,
    height: 100,
    waveColor: 'rgb(200, 0, 200)',
    progressColor: 'rgb(100, 0, 100)',
    url: audioSrc,
    plugins: useMemo(() => [Timeline.create()], [])
  })

  const onPlayPause = useCallback(() => {
    wavesurfer && wavesurfer.playPause()
  }, [wavesurfer])

  useEffect(() => {
    if (wavesurfer) {
      wavesurfer.on('ready', () => {
        setIsWavesurferReady(true)
      })
    }
  }, [wavesurfer])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result: any = await getAiRecordById(aiRecordId)
        if (result.AudioUrls) {
          const audios = JSON.parse(result.AudioUrls)
          setAudioSrc(audios[0])
        }
        setParagraphs(JSON.parse(result.MemoResult))

        // kich ban data
        const aiScriptResultConverted = []
        const aiScriptResult = orderBy(JSON.parse(result.AIScriptResult), ['score', 'desc'])
        setIsAiScriptDone(!!aiScriptResult)
        if (aiScriptResult) {
          console.log('aiScriptResult', aiScriptResult)
          recordIntent.forEach((element) => {
            aiScriptResultConverted.push({
              intent_display: element.Name,
              intent: element.Slug,
              sentences: []
            })
          })

          for (let index = 0; index < aiScriptResult.length; index++) {
            const element = aiScriptResult[index]
            if (element.score > 0.7) {
              // const foundIndex = aiScriptResultConverted.findIndex((el) => el.intent === element.intent)
              // not in array
              const intentFoundIndex = aiScriptResultConverted.findIndex((el) => el.intent === element.intent)
              aiScriptResultConverted[intentFoundIndex].sentences.unshift({
                score: element.score,
                content: element.sent
              })
            }
          }
        }
        console.log('aiScriptResultConverted', aiScriptResultConverted)
        setAiScriptResult(aiScriptResultConverted)
      } catch (error) {
        setError(error)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, []) // Empty dependency array ensures the effect runs only once on component mount

  const [highlightedParagraph, setHighlightedParagraph] = useState(null)
  // const [highlightedWordIndex, setHighlightedWordIndex] = useState(null)
  useEffect(() => {
    if (!currentTime || !paragraphs || paragraphs.length === 0) return

    const currentTimeInMiliseconds = Math.round(currentTime * 1000)
    const currentParagraph = paragraphs.find((paragraph) => {
      return (
        currentTimeInMiliseconds >= paragraph.words[0].start &&
        currentTimeInMiliseconds < paragraph.words[paragraph.words.length - 1].end
      )
    })

    if (currentParagraph && currentParagraph?.words?.length) {
      const currentWordIndex = currentParagraph.words.findIndex(
        (el) => currentTimeInMiliseconds >= el.start && currentTimeInMiliseconds < el.end
      )
      if (currentWordIndex > 0) setHighlightWordIndex(currentWordIndex)
    }

    setHighlightedParagraph(currentParagraph)
  }, [paragraphs, currentTime])

  const handleDoubleClick = (startTime: number) => {
    wavesurfer && wavesurfer.seekTo(startTime / 1000 / wavesurfer.getDuration())
  }

  const handleClickSentences = (content: any) => {
    const contentReplace = content.replaceAll('_', ' ').replaceAll(' ', '')

    paragraphs.forEach((para) => {
      const wordsArray = para.words.map((item: any) => item.text.trim()).join('')
      if (wavesurfer && wordsArray.includes(contentReplace)) {
        if (para.words[0].start) {
          const timeStart = para.words[0].start / 1000 / wavesurfer.getDuration()
          wavesurfer.seekTo(timeStart)
          onPlayPause()
        }
      }
    })
  }

  if (loading) {
    return <p>Loading...</p>
  }

  if (error) {
    return <p>Error: {error.message}</p>
  }

  return (
    <>
      <div className='flex flex-col h-[600px]'>
        <div className='bg-red-50'>
          {containerRef ? <div ref={containerRef} /> : <p>Audio loading...</p>}

          <div className='flex items-center px-4 py-2'>
            {isWavesurferReady ? (
              <button onClick={onPlayPause} className='text-blue-600'>
                {isPlaying ? <PauseCircleOutlined className='text-xl' /> : <PlayCircleOutlined className='text-xl' />}
              </button>
            ) : (
              <Spin />
            )}
            <p className='ml-6'>{formatTime(currentTime)}</p>
          </div>
        </div>
        <div className='flex flex-row flex-1 overflow-y-hidden'>
          <div className='flex-1 p-4 overflow-y-auto bg-gray-100'>
            {paragraphs ? (
              paragraphs.map((paragraph, paragraphIndex) => (
                <div key={`paragraph-${paragraphIndex}`}>
                  <p className='text-xs text-gray-400'>
                    <i>{formatTime(paragraph.words[0].start)}</i>
                  </p>
                  <p>
                    {paragraph.words.map((word, wordIndex) => (
                      <span
                        key={`word-${wordIndex}`}
                        data-start={word.start}
                        data-end={word.end}
                        onDoubleClick={() => handleDoubleClick(word.start)}
                        className={
                          highlightedParagraph === paragraph && highlightWordIndex == wordIndex ? 'text-red-500' : ''
                        }
                      >
                        {word.text}
                      </span>
                    ))}
                    {/* <div>Speaker: {paragraph.speaker}</div> */}
                  </p>
                </div>
              ))
            ) : (
              <p>Chưa chạy AI chuyển đổi audio sang văn bản</p>
            )}
          </div>
          <aside className='p-4 overflow-y-auto bg-gray-200 w-80'>
            {isAiScriptDone ? (
              aiScriptResult.length > 0 ? (
                <ul>
                  {aiScriptResult.map((el) => {
                    return (
                      el.sentences.length > 0 && (
                        <li key={`intent-${el.intent}`} className=''>
                          <div>
                            <img src='/icon/success.png' width={20} height={20} alt='img'></img>
                            <b>{el.intent_display}:</b>
                          </div>
                          {el.sentences && el.sentences.length > 0 ? (
                            <ul>
                              {el.sentences.map((sentence, index) => {
                                return (
                                  <li
                                    onDoubleClick={() => handleClickSentences(sentence.content)}
                                    key={`intent-${el.intent}-sentence-${index}`}
                                  >
                                    <blockquote>
                                      <span className='mr-1'>{sentence.score.toFixed(2)}</span>- {sentence.content}
                                    </blockquote>
                                  </li>
                                )
                              })}
                            </ul>
                          ) : (
                            <i>Không tìm thấy câu</i>
                          )}
                        </li>
                      )
                    )
                  })}
                </ul>
              ) : (
                'AI chưa phát hiện có tư vấn kịch bản'
              )
            ) : (
              <p>Chưa chạy AI kịch bản</p>
            )}
          </aside>
        </div>
      </div>
    </>
  )
}

export default AIRecordDetail
