/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button, DatePicker, DatePickerProps, Form, notification, Select, Steps } from "antd"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { addStaffSkill, getListSkillLevel, getStaffInfo, getStaffSkillList, updateStaffSkill } from "./api"
import './style.scss'
import dayjs from "dayjs"
import { EditOutlined } from "@ant-design/icons"
import ModalChangeSkill from "./modalSkill"
import { isDateSameOrAfter } from "utils/helpers"



const ChangeSkillLevel = () => {
    const { id } = useParams()
    const [loading, setLoading] = useState(false)
    const [levelList, setLevelList] = useState([])
    const [skillPointsList, setSkillPointsList] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [staffSkill, setStaffSkill] = useState([])
    const [isEdit, setIsEdit] = useState(false);
    const [selectedSkill, setSelectedSkill] = useState()
    const [selectedSkillName, setSelectedSkillName] = useState("")
    const [selectedSkillPoint, setSelectedSkillPoint] = useState()
    const [selectedSkillPointId, setSelectedSkillPointId] = useState()
    const [selectedDate, setSelectedDate] = useState("")
    const [disabledDate, setDisabledDate] = useState("")
    const [data, setData] = useState<any>([])
    const [isChange, setIsChange] = useState(false)
    const today = dayjs();
    // Lấy danh sách kỹ năng
    const getListLevel = async () => {
        if (loading) return
        setLoading(true)
        try {
            const response: any = await getListSkillLevel()
            if (response.data) {
                setLevelList(response.data)
                setLoading(false)
            }
        } catch (error) {
            notification.error({
                message: 'Thông báo!',
                description: <>Lỗi không lấy được danh sách kỹ năng!</>
            })
            setLoading(false)
            console.log(error)
        }
    }
    // Lấy danh sách kỹ năng nhân sự
    const getListStaffLevel = async (id) => {
        if (loading) return
        setLoading(true)
        try {
            const response: any = await getStaffSkillList(id)
            if (response.data.skillInfo) {
                setStaffSkill(response.data.skillInfo)
                setLoading(false)
            }
        } catch (error) {
            notification.error({
                message: 'Thông báo!',
                description: <>Lỗi không lấy được danh sách kỹ năng nhân sự!</>
            })
            console.log(error)
            setLoading(false)
        }
    }
    // Lấy thông tin nhân sự
    const getStaffInfoData = async (id) => {
        if (loading) return
        setLoading(true)
        try {
            const response: any = await getStaffInfo(id)
            if (response.data) {
                setData(response.data)
                setLoading(false)
            }
        } catch (error) {
            notification.error({
                message: 'Thông báo!',
                description: <>Lỗi không lấy được thông tin nhân sự!</>
            })
            console.log(error)
            setLoading(false)
        }
    }
    // thêm kỹ năng 
    const onAddStaffSkill = async () => {
        if (loading) return
        if (!(selectedSkillPoint && selectedDate)) {
            notification.error({
                message: 'Thông báo!',
                description: <>Chưa điền đủ thông tin!</>
            })
            return
        }
        setLoading(true)
        const data = {
            skillId: selectedSkill,
            skillPointId: selectedSkillPoint,
            startDate: selectedDate
        }
        try {
            const response: any = await addStaffSkill(id, data)
            if (response.data) {
                getListStaffLevel(id)
                setSelectedSkillPoint(null)
                setSelectedDate(null)
                setLoading(false)
                notification.success({
                    message: 'Thông báo!',
                    description: <>{response.message}</>
                })
            }
        } catch (error) {
            notification.error({
                message: 'Thông báo!',
                description: <>{error.response.data.message}</>
            })
            console.log(error)
            setLoading(false)
        }
    }
    // cập nhật bậc kỹ năng 
    const onUpdateStaffSkill = async () => {
        if (loading) return
        if (!(selectedSkillPoint && selectedDate)) {
            notification.error({
                message: 'Thông báo!',
                description: <>Chưa điền đủ thông tin!</>
            })
            return
        }
        setLoading(true)
        const data = {
            skillId: selectedSkill,
            skillPointId: selectedSkillPoint,
            startDate: selectedDate
        }
        try {
            const response: any = await updateStaffSkill(id, selectedSkillPointId, data)
            if (response.data) {
                getListStaffLevel(id)
                setSelectedSkillPoint(null)
                setSelectedDate(null)
                setLoading(false)
            }
        } catch (error) {
            notification.error({
                message: 'Thông báo!',
                description: <>{error.response.data.message}</>
            })
            console.log(error)
            setLoading(false)
        }
    }
    
    const onSave = (type: any) => {
        if (type === 1) {
            onAddStaffSkill()
        } else {
            onUpdateStaffSkill()
        }
    };

    // Thay dổi loại kỹ năng
    const handleChange = (value: any) => {
        const data = levelList?.find((item: any) => item.skillId === value)?.skillPoints
        setSelectedSkill(value)
        setSelectedSkillName(levelList?.find((item: any) => item.skillId === value)?.name)
        setSkillPointsList(data)
    };

    // Xử lý khi ấn nút thêm
    const onClickAdd = () => {
        setIsModalOpen(true);
        if (staffSkill.find(item => item.skillId === selectedSkill)?.skillPoints?.length > 0) {
            const date = staffSkill?.find((item: any) => item.skillId === selectedSkill)?.skillPoints[0]?.startDate
            setDisabledDate(date)
        } else {
            setDisabledDate("")
        }
    };

    // Xử lý khi ấn nút sửa
    const onClickEdit = (data: any) => {
        setSelectedDate(data.startDate)
        setSelectedSkillPoint(data.id)
        setSelectedSkillPointId(data.staffSkillPointId)
        setIsEdit(true)
        setIsChange(true)
        setIsModalOpen(true)
        if (staffSkill.find(item => item.skillId === selectedSkill)?.skillPoints?.length > 1) {
            const date = staffSkill?.find((item: any) => item.skillId === selectedSkill)?.skillPoints[0]?.startDate
            setDisabledDate(date)
        } else {
            setDisabledDate("")
        }
    };

    useEffect(() => {
        getListLevel()
    }, [])

    useEffect(() => {
        if (!isModalOpen) {
            setSelectedDate(null)
            setSelectedSkillPoint(null)
            setSelectedSkillPoint(null)
            setIsChange(false)
            setIsEdit(false)
        }
    }, [isModalOpen])

    useEffect(() => {
        if (id) {
            getListStaffLevel(id)
            getStaffInfoData(id)
        }
    }, [id])

    useEffect(() => {
        if (levelList) {
            handleChange(levelList[0]?.skillId)
        }
    }, [levelList])

    return (
        <div className='min-h-[100vh] m-auto pb-12'>
            <div className='w-full px-5 py-5 search-wrapper' >
                <div className='text-3xl font-semibold'>Kỹ năng nhân viên</div>
                {data ?
                    <div className="grid w-full grid-cols-2 gap-4 mt-5">
                        <div className="w-full h-full ">
                            <div className="bg-white">
                                <Form
                                    className="p-4"
                                    labelCol={{ span: 8 }}
                                    wrapperCol={{ span: 16 }}
                                >
                                    <Form.Item
                                        label="Tên tài khoản"
                                    >
                                        {data.staffUserName}
                                    </Form.Item>
                                    <Form.Item
                                        label="Tên nhân viên"
                                    >
                                        {data.staffName}
                                    </Form.Item>
                                    <Form.Item
                                        label="Salon"
                                    >
                                        {data.salon}
                                    </Form.Item>
                                    <Form.Item
                                        label="Bộ phận"
                                    >
                                        {data.department}
                                    </Form.Item>
                                    <Form.Item
                                        label="Bậc lương"
                                    >
                                        {data.level}
                                    </Form.Item>
                                    <Form.Item
                                        label="Trạng thái"
                                    >
                                        {data.status}
                                    </Form.Item>

                                </Form>
                            </div>
                        </div>
                        <div className="flex items-center justify-center">
                            <img className="w-[300px] " src={data.avartaUrl} alt="" />
                        </div>
                        <div className="w-full p-4 mt-6 bg-white">
                            <div className="flex justify-between ">
                                {levelList &&
                                    <Select
                                        value={selectedSkill}
                                        style={{ width: "70%" }}
                                        onChange={(value) => handleChange(value)}
                                        options={levelList.map((item: any) => ({
                                            ...item,
                                            value: item.skillId,
                                            label: item.name,
                                        }))}
                                    />
                                }
                                <Button type="primary" onClick={onClickAdd}>Thêm bậc</Button>
                            </div>
                            {staffSkill && selectedSkill &&
                                <div className="mt-4 max-h-[400px] overflow-y-auto">
                                    <Steps
                                        className="p-2"
                                        progressDot
                                        current={0}
                                        direction="vertical"
                                        items={staffSkill
                                          ?.find((item: any) => item.skillId === selectedSkill)
                                          ?.skillPoints.map((item2: any, index: any) => {
                                            return {
                                              title: (
                                                <div className='flex justify-between'>
                                                  <div>{item2.skillPointName}</div>
                                                  {index === 0 && isDateSameOrAfter(dayjs(item2.startDate), today, 'day') && (
                                                    <Button
                                                      className='flex items-center justify-center'
                                                      icon={<EditOutlined />}
                                                      onClick={() => onClickEdit(item2)}
                                                    >
                                                      Sửa
                                                    </Button>
                                                  )}
                                                </div>
                                              ),
                                              description: `Ngày lên bậc: ${dayjs(item2.startDate).format('YYYY-MM-DD')}`
                                            }
                                          })}
                    
                                    />
                                </div>
                            }
                        </div>
                    </div>
                    :
                    null
                }
                <ModalChangeSkill
                    setSelectedSkillPoint={setSelectedSkillPoint}
                    setSelectedDate={setSelectedDate}
                    selectedSkillName={selectedSkillName}
                    skillPointsList={skillPointsList}
                    selectedDate={selectedDate}
                    selectedSkillPoint={selectedSkillPoint}
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    onSave={onSave}
                    disabledDate={disabledDate}
                    isEdit={isEdit}
                    loading={loading}
                    setIsChange={setIsChange}
                    isChange={isChange}
                />
            </div>
        </div>

    )
}

export default ChangeSkillLevel
