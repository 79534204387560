import { DeleteFilled, UploadOutlined } from '@ant-design/icons'
import { Alert, Button, Form, Table, TreeSelect, Typography, Upload, message, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import * as XLSX from 'xlsx'
import { getAllProductList, getAllSalonProductConfigList, importProductConfigList } from '../api'
import { ISalonArea } from '../type'
import { useAppSelector } from 'redux/store'
import convert from 'utils/helpers/convert'

const ProductSalaryConfig = () => {
  const [form] = Form.useForm()
  const [listSalon, setListSalon] = useState<ISalonArea[]>([])
  const [listProduct, setListProduct] = useState([])
  const [data, setData] = useState([])
  const [dataNoExistsListProduct, setDataNoExistsListProduct] = useState([])
  const user = useAppSelector((state) => state.user)

  const onValuesChange = (changedValues) => {
    console.log(changedValues, 'changedValues')
  }

  const onFinish = async (values: any) => {
    try {
      if (values.salonId.length) {
        let listImport = []

        data.map((product) => values.salonId.map((salon) => listImport.push({ ...product, salonId: salon, id: 0 })))

        listImport = listImport.map((item) => ({ ...item, createBy: user.userInfo.fullName }))
        const response: any = await importProductConfigList(listImport)
        if (response.statusCode === 200) {
          notification.success({
            description: 'Import file thành công!',
            message: 'Thành công'
          })
          form.resetFields()
          setData([])
          setDataNoExistsListProduct([])
        } else {
          notification.error({
            message: 'Lỗi',
            description: response?.message || 'Có lỗi xảy ra, vui lòng thử lại!'
          })
        }
      }
    } catch (error) {
      notification.error({
        message: 'Lỗi',
        description: 'Có lỗi xảy ra, vui lòng thử lại!'
      })
      console.log(error)
    }
  }

  const downloadExcel = () => {
    const data = [{ Id_product: '123', he_so_tang_ca: 1.5, tip: 23000 }]
    const header = ['Id sản phẩm', 'Hệ số tăng ca (Nếu có)', 'Tip (VND)']

    const worksheet = XLSX.utils.json_to_sheet(data)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.sheet_add_aoa(worksheet, [header], { origin: 'A1' })
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
    XLSX.writeFile(workbook, 'cau-hinh-tip-luong-san-pham-theo-salon.xlsx')
  }

  const beforeUpload = (file: any) => {
    try {
      const reader = new FileReader()

      reader.onload = (e) => {
        const binaryString = e.target.result
        const workbook = XLSX.read(binaryString, { type: 'binary' })

        // Lấy dữ liệu từ sheet đầu tiên của workbook
        if (!workbook.SheetNames[0]) throw new Error('Sheet names do not exits Sheet[0]')
        const firstSheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[firstSheetName]

        // Chuyển đổi dữ liệu từ worksheet thành mảng đối tượng
        const excelData = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
        if (excelData.length < 2) throw new Error('Excel cần chứa dữ liệu !!')
        // Lấy dữ liệu từ phần tử thứ 2 trở đi
        let newData = excelData.slice(1).map((row) => {
          return {
            productId: row[0],
            coefficientOverTime: row[1],
            tip: row[2]
          }
        })

        // kiểm tra danh sách sp đang import có tồn tại trong danh sách sp không
        newData = newData.map((productImport) => {
          let newProductImp = { ...productImport }
          listProduct.forEach((product) => {
            if (productImport.productId === product.productId) {
              newProductImp = { ...product, ...newProductImp, productIdInList: product.productId }
            }
          })
          return newProductImp
        })

        const newDataExists = [...newData].filter(
          (item: any) =>
            item.productId &&
            item.productIdInList &&
            typeof item.coefficientOverTime === 'number' &&
            typeof item.tip === 'number'
        )
        console.log(newData, 'newData')
        const newDataNoExists = [...newData].filter(
          (item: any) =>
            !item.productId ||
            !item.productIdInList ||
            typeof item.coefficientOverTime !== 'number' ||
            typeof item.tip !== 'number'
        )

        // Lưu giá trị vào state
        if (newDataExists.length) {
          setData(newDataExists)
        }
        setDataNoExistsListProduct(newDataNoExists)
      }

      reader.readAsBinaryString(file)
      return false
    } catch (e) {
      console.log(e)
    }
  }

  const getSalonList = async () => {
    try {
      const response: any = await getAllSalonProductConfigList()
      if (response.length) {
        const newResponse = response.map((item) => ({
          ...item,
          value: item.value * 10000,
          key: item.key * 10000
        }))
        setListSalon(newResponse)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getProductList = async () => {
    try {
      const response: any = await getAllProductList()
      if (response?.data?.data?.length) {
        setListProduct(response.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onClickDeleted = (productId) => {
    const newData = data.filter((item: any) => item.productId !== productId)
    setData(newData)
  }

  const column = [
    {
      title: 'ID sản phẩm',
      dataIndex: 'productId',
      key: 'productId'
    },
    {
      title: 'Hệ số tăng ca',
      dataIndex: 'coefficientOverTime',
      key: 'coefficientOverTime'
    },
    {
      title: 'Tip (VND)',
      dataIndex: 'tip',
      key: 'tip'
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => <DeleteFilled onClick={() => onClickDeleted(record.productId)} />
    }
  ]

  useEffect(() => {
    getSalonList()
    getProductList()
  }, [])

  return (
    <div className='min-h-[100vh] m-auto bg-[#F3F3F3] pb-12'>
      <div className='w-full px-5 py-5 search-wrapper'>
        <Form className='pb-10' form={form} onFinish={onFinish} onValuesChange={onValuesChange}>
          <h2 className='text-2xl font-bold mb-7'>Cấu hình tip lương sản phẩm theo salon</h2>
          <div className=''>
            <Form.Item
              name='salonId'
              label='Chọn Salon cấu hình lương'
              rules={[{ required: true, message: 'Bạn phải chọn Salon' }]}
            >
              <TreeSelect
                allowClear
                className='w-full'
                placeholder={'Chọn Salon'}
                treeData={listSalon}
                showSearch
                treeCheckable={true}
                virtual={false}
                filterTreeNode={(input, option) =>
                  convert.removeVietnameseAccent(option?.title).indexOf(convert.removeVietnameseAccent(input)) >= 0
                }
              />
            </Form.Item>
          </div>

          <div className='mt-2 flex items-center gap-2'>
            Import file{' '}
            <Typography.Link onClick={() => downloadExcel()} className='underline italic'>
              Download file mẫu
            </Typography.Link>
          </div>
          <div className='mt-2'>
            <Upload beforeUpload={beforeUpload} showUploadList={false}>
              <Button>
                <div className='flex gap-1 items-center'>
                  <UploadOutlined />
                  Upload File
                </div>
              </Button>
            </Upload>
          </div>
          {dataNoExistsListProduct.length ? (
            <div className='mt-2'>
              <Alert
                message='Cảnh báo'
                description={<div>Vui lòng kiểm tra lại dữ liệu file excel!</div>}
                type='warning'
                showIcon
                closable
              />
            </div>
          ) : null}
          <div
            className='mt-5 bg-white'
            style={{ boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px', borderRadius: 10 }}
          >
            <Table
              className='min-w-[1000px] overflow-auto'
              dataSource={data}
              columns={column}
              bordered
              pagination={false}
            />
          </div>
          <Button type='primary' className='mt-5' htmlType='submit'>
            Cập nhật cấu hình
          </Button>
        </Form>
      </div>
    </div>
  )
}

export default ProductSalaryConfig
