import { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Menu } from 'antd'
import { MENUS } from './menu/index'

import { MenuProps } from 'pages/staff/interface'
import { useAppSelector } from 'redux/store'
function getStringBeforeSlash(input) {
  const parts = input.split('/')
  if (input.startsWith('/')) {
    return parts[1]
  } else {
    return parts[0]
  }
}
const { SubMenu } = Menu
export default function MainMenu() {
  const navigate = useNavigate()
  const urlAccess = useAppSelector((state) => state.user.urlAccess)
  const [menu, setMenu] = useState([])
  const location = useLocation()
  const [selectedKeys, setSelectedKeys] = useState([getStringBeforeSlash(window.location.pathname)])
  useEffect(() => {
    setSelectedKeys([window.location.pathname.includes('/staff/detail') ? '/staff/detail' : window.location.pathname])
  }, [window.location.pathname])
  useEffect(() => {
    if (MENUS.length > 0 && urlAccess.length > 0) {
      setMenu(
        (MENUS || []).map((menu) => {
          const menuCheck = { ...menu }
          const checkMenuAccess = urlAccess.filter((i) => i.path.includes(menuCheck.key))
          if (checkMenuAccess.length === 0) return undefined

          menuCheck.nested = menuCheck.nested
            .map((item) => {
              const check = urlAccess.filter((i) => i.path == item.path)
              if (check.length > 0) {
                return item
              } else return undefined
            })
            .filter((x) => x)
          return menuCheck
        })
      )
    } else {
      navigate('/403')
    }
  }, [urlAccess])

  return (
    <Menu
      theme='dark'
      mode='inline'
      defaultOpenKeys={['/' + location.pathname.split('/')[1]]}
      selectedKeys={selectedKeys}
      defaultSelectedKeys={['']}
    >
      {(['development'].includes(process.env.REACT_APP_ENV) ? MENUS : menu).map((item: MenuProps) => {
        if (item) {
          if (item.nested?.length) {
            return (
              <SubMenu key={item.key} icon={item.icon} title={item.title}>
                {item.nested.map((nestedItem) => {
                  return (
                    <Menu.Item
                      style={{ display: nestedItem?.hidden ? 'none' : '' }}
                      key={nestedItem.key}
                      icon={nestedItem?.icon}
                    >
                      <Link to={nestedItem.path}>{nestedItem.title}</Link>
                    </Menu.Item>
                  )
                })}
              </SubMenu>
            )
          }
          return (
            <Menu.Item key={item.key} icon={item.icon}>
              <Link to={item.path}>{item.title}</Link>
            </Menu.Item>
          )
        }
      })}
    </Menu>
  )
}
