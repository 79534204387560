/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Row } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { getPopupNoti } from 'pages/pushNotification/Api/api';

interface Props {
    id: any,
    listSalon: any
}

const PositionTest = [
    {
        id: 1,
        name: 'Stylist'
    },
    {
        id: 2,
        name: 'Skinner'
    },
    {
        id: 5,
        name: 'Chuyên viên tư vấn'
    },
    {
        id: 6,
        name: 'Checkout'
    },
    {
        id: 9,
        name: 'Trưởng salon (SM)'
    },
]
const ViewNotification = (props: Props) => {
    const { id, listSalon } = props;
    const [data, setData] = useState<any>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [listSalonName, setListSalonName] = useState([]);
    const [listDepartmentName, setListDepartmentName] = useState([]);

    const showModal = () => {
        setIsModalOpen(true);
        getData()
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const getData = async () => {
        if (!id) return
        try {
            const response: any = await getPopupNoti(id)
            if (response) {
                setData(response)
            }
        } catch (error) {
            console.log(error)
        }
    }


    useEffect(() => {
        if (listSalon && data && PositionTest) {
            const listSalonSelected = data.salonIds?.split(',').map(Number);
            if (listSalonSelected) {
                const selectedSalons = listSalon.filter(salon => listSalonSelected.includes(salon.id)).map((item) => item.name);
                setListSalonName(selectedSalons)
            }
            const listDepartmentSelected = data.departmentIds?.split(',').map(Number);
            if (listDepartmentSelected) {
                const selectedStaffId = PositionTest.filter(staffId => listDepartmentSelected.includes(staffId.id)).map((item) => item.name);
                setListDepartmentName(selectedStaffId)
            }
        }

    }, [listSalon, data, PositionTest]);

    const checkStatus = (status: any) => {
        if (status !== undefined) {
            switch (status) {
                case 0:
                    break;
                case 1:
                    return "Đã lên lịch"
                case 2:
                    return "Đang xử lý"
                case 3:
                    return "Đã gửi"
                case 4:
                    return "Đã dừng"
                default:
                    break;
            }
        }
    }
    console.log(listDepartmentName, "listDepartmentName")
    return (
        <>
            <Button
                style={{ display: "flex", alignItems: "center", marginRight: 10 }}
                type="primary"
                icon={<EyeOutlined />}
                shape="round"
                onClick={showModal}
            >
                Xem
            </Button>
            <Modal
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                width={700}
            >
                {data &&
                    <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                        <div style={{ width: "60%" }}>
                            <div style={{ display: "flex", flexDirection: "row", marginBottom: "5px" }}>
                                <div style={{ width: "130px", marginRight: "10px" }}>
                                    Tên thông báo
                                </div>
                                <div style={{ display: "flex", fontWeight: "600" }} >
                                    {data.name}
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", marginBottom: "5px" }}>
                                <div style={{ width: "130px", marginRight: "10px" }}>
                                    Ngày bắn/ Ngày đã lên lịch bắn
                                </div>
                                <div style={{ display: "flex", fontWeight: "600" }}>
                                    {data.sentDate}
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", marginBottom: "5px" }}>
                                <div style={{ width: "130px", marginRight: "10px" }}>
                                    Trạng thái
                                </div>
                                <span style={{ fontWeight: "600" }}>
                                    {checkStatus(data.status)}
                                </span>
                            </div>
                            {listSalonName && data.departmentIds &&
                                <div>
                                    <div style={{ display: "flex", flexDirection: "row", marginBottom: "5px" }}>
                                        <div style={{ width: "130px", marginRight: "10px" }}>
                                            Danh sách salon
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", marginBottom: "5px" }}>
                                            {listSalonName?.map((salon, index) => {
                                                if (index < 3) {
                                                    return (
                                                        <span key={index} style={{ marginRight: "5px" }}>{salon},</span>
                                                    );
                                                } else if (index === 3) {
                                                    return (
                                                        <span key={index} style={{ marginRight: "5px" }}>..., (+{listSalonName.length - 3})</span>
                                                    );
                                                } else {
                                                    return null;
                                                }
                                            })}
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row", marginBottom: "5px" }}>
                                        <div style={{ width: "130px", marginRight: "10px" }}>
                                            Danh sách bộ phận
                                        </div>
                                        <div className='flex flex-wrap w-[230px]' >
                                            {listDepartmentName?.map((DepartmentName, index) => (
                                                <span key={index} style={{ marginRight: "5px" }}>{DepartmentName},</span>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            }
                            {data.fileName &&
                                <div style={{ display: "flex", flexDirection: "row", marginBottom: "5px" }}>
                                    <div style={{ width: "130px", marginRight: "10px" }}>
                                        File name
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center", fontWeight: "600" }}>
                                        {data.fileName}
                                    </div>
                                </div>
                            }
                        </div>
                        <div style={{ width: "40%" }}>
                            <div style={{ display: "flex", flexDirection: "row", marginBottom: "5px" }}>
                                <div style={{ width: "130px", marginRight: "10px" }}>
                                    Người tạo
                                </div>
                                <div style={{ display: "flex", alignItems: "center", fontWeight: "600" }}>
                                    {data.createdBy}
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", marginBottom: "5px" }}>
                                <div style={{ width: "130px", marginRight: "10px" }}>
                                    Tổng data
                                </div>
                                <div style={{ display: "flex", alignItems: "center", fontWeight: "600" }}>
                                    {data.totalData}
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", marginBottom: "5px" }}>
                                <div style={{ width: "130px", marginRight: "10px" }}>
                                    Lượt click
                                </div>
                                <div style={{ display: "flex", alignItems: "center", fontWeight: "600" }}>
                                    {data.totalClick}
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </Modal>
        </>
    );
}
export default ViewNotification
