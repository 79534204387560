import { useState, useEffect } from 'react'
import { useAppSelector } from 'redux/store'
import FormComponent from 'pages/base/Form'
import { ColumnConfig, SearchConfig, TypeSearch } from 'pages/base/util'
import { Checkbox, Button, Modal, notification, InputNumber } from 'antd'
import { updateSalonProductConfig, listProductDatatable, listCategoryProducts, downloadExcelSalonProducts } from './api'
import { useLocation, useNavigate } from 'react-router-dom'
import { EditOutlined } from '@ant-design/icons'
import { getSalons } from 'api/catalog'

export const ProductConfiguration = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const [dataSelectSalon, setDataListSalon] = useState<any[]>([])
  const { resource } = useAppSelector((state) => state.staff)
  const [reload, setReload] = useState(false)
  const [listCategoryProduct, setListCategoryProduct] = useState([])
  const [modalData, setModalData] = useState<any>()
  const [isModalOpen, setIsModalOpen] = useState(false)
  // dữ liệu form
  const [Price, setPrice] = useState<any>()
  const [Discount, setDiscount] = useState<any>()
  const [IsUse, setIsUse] = useState<boolean>(false)
  const [IsInventoryCheck, setIsInventoryCheck] = useState<boolean>(false)
  const getListCategoryProducts = async () => {
    try {
      const response: any = await listCategoryProducts()
      const { statusCode, data } = response
      if (statusCode === 0) {
        setListCategoryProduct(data)
      }
    } catch (error) {
      console.error('Error:', error.message)
    }
  }
  const GetListSalon = async () => {
    try {
      const response: any = await getSalons()
      const { statusCode, data } = response
      if (statusCode === 200) {
        setDataListSalon(data)
      }
    } catch (error) {
      console.error('Error:', error.message)
    }
  }
  useEffect(() => {
    getListCategoryProducts()
    GetListSalon()
  }, [])
  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleOpenEdit = (record: any) => {
    const dataModal = {
      text: record.Name,
      id: record.Id,
      salon: record.SalonName
    }
    setPrice(record.Price)
    setDiscount(record.Discount)
    setIsUse(record.IsUse)
    setIsInventoryCheck(record.IsInventoryCheck)
    setModalData(dataModal)
    showModal()
  }

  const handleOk = async () => {
    try {
      const body = {
        Price: Price || null,
        Discount: Discount || null,
        IsUse: IsUse,
        IsInventoryCheck: IsInventoryCheck
      }
      // console.log(body, 'body')
      const response = await updateSalonProductConfig(modalData.id, body)
      if (response) {
        notification.success({
          description: 'Bạn đã thay đổi dữ liệu Cấu hình salon - sản phẩm',
          message: 'Thành công'
        })
        setReload(true)
      } else {
        notification.warning({
          description: 'Không thể thay đổi dữ liệu',
          message: 'Lỗi'
        })
      }
      setPrice(null)
      setDiscount(null)
      setIsUse(null)
      setIsInventoryCheck(null)
      setIsModalOpen(false)
    } catch (error) {
      console.error('Error:', error)
      notification.error({
        description: error.message,
        message: 'Lỗi'
      })
    }
  }

  const handleCancel = () => {
    setIsModalOpen(false)
    setPrice(null)
    setDiscount(null)
    setIsUse(null)
    setIsInventoryCheck(null)
  }
  const column: ColumnConfig[] = [
    {
      title: 'STT',
      key: 'index',
      render: (item, index, i) => {
        return <span>{i + 1}</span>
      }
    },
    {
      title: 'Tên sản phẩm',
      dataIndex: 'Name',
      key: 'Name'
    },
    {
      title: 'Mã sản phẩm',
      dataIndex: 'Code',
      key: 'Code'
    },
    {
      title: 'Đơn vị',
      dataIndex: 'Unit',
      key: 'Unit'
    },
    {
      title: 'Mô tả',
      dataIndex: 'Description',
      key: 'Description'
    },
    {
      title: 'Giá bán chung',
      dataIndex: 'PriceOrigin',
      key: 'PriceOrigin'
    },
    {
      title: 'Giá bán',
      key: 'Price',
      render: (record) => (
        <div className='flex justify-center align-center'>
          <div>{record.Price ? record.Price : record.PriceOrigin}</div>
        </div>
      )
    },
    {
      title: 'Chiết khấu lương(%)',
      key: 'Discount',
      render: (record) => (
        <div className='flex justify-center align-center'>
          <div>{record.Discount ? record.Discount : 0}</div>
        </div>
      )
    },
    {
      title: 'Sử dụng',
      key: 'IsUse',
      render: (record) => (
        <div className='flex justify-center'>
          <Checkbox checked={record.IsUse} />
        </div>
      )
    },
    {
      title: 'Cho phép kiểm',
      key: 'IsInventoryCheck',
      render: (record) => (
        <div className='flex justify-center'>
          <Checkbox checked={record.IsInventoryCheck} />
        </div>
      )
    },
    {
      title: 'Chỉnh sửa',
      key: 'edit',
      render: (record) => (
        <Button onClick={() => handleOpenEdit(record)}>
          <EditOutlined />
        </Button>
      )
    }
  ]
  const columnSearch: SearchConfig[] = [
    {
      label: 'Chọn Salon',
      param: 'SalonId',
      type: TypeSearch.select,
      options: dataSelectSalon,
      placeholder: 'Chọn Salon',
      fieldNames: { label: 'name', value: 'id' }
    },
    {
      label: 'Danh mục sản phẩm',
      param: 'CategoryId',
      type: TypeSearch.treeSelect,
      options: listCategoryProduct,
      placeholder: 'Chọn danh mục',
      fieldNames: { label: 'label', value: 'value' }
    },
    {
      label: 'Chọn Sản phẩm/ Vật tư',
      param: 'productType',
      type: TypeSearch.select,
      defaultValue: 1,
      options: [
        { value: 1, label: 'Sản phẩm' },
        { value: 2, label: 'Vật tư' }
      ],
      placeholder: 'trạng thái',
      fieldNames: { label: 'label', value: 'value' }
    },
    {
      label: 'Tên Sản phẩm',
      type: TypeSearch.input,
      param: 'Name',
      placeholder: 'Nhập tên sản phẩm'
    },
    {
      label: 'Mã Sản phẩm',
      type: TypeSearch.input,
      param: 'Code',
      placeholder: 'Nhập mã sản phẩm'
    }
  ]
  const handleChangeValue = (changedValues, onReset: (field: string[]) => void) => {
    if (changedValues?.salonId) {
      onReset(['statusName'])
    }
  }
  const exportData = async () => {
    let param = ''
    const Name = searchParams.get('Name')
    const Code = searchParams.get('Code')
    const SalonId = searchParams.get('SalonId')
    const CategoryId = searchParams.get('CategoryId')
    if (Name) {
      param += `Name=${Name}`
    }
    if (Code) {
      param += `&Code=${Code}`
    }
    if (SalonId) {
      param += `&SalonId=${SalonId}`
    }
    if (CategoryId) {
      param += `&CategoryId=${CategoryId}`
    }
    await downloadExcelSalonProducts(param)
  }
  return (
    <div className='min-h-[100vh] m-auto bg-[#F3F3F3] pb-12'>
      <FormComponent
        currentUrl='/product/config'
        title='CẤU HÌNH SẢN PHẨM - VẬT TƯ CỦA SALON'
        column={column}
        columnSearch={columnSearch}
        fetchData={listProductDatatable}
        handleChangeValue={handleChangeValue}
        linkADD=''
        reload={reload}
        exportCsv={true}
        setReload={setReload}
        exportData={exportData}
      ></FormComponent>
      {modalData && (
        <Modal
          title='Cập nhật dữ liệu cấu hình sản phẩm - salon'
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <p>
            Cập nhật cấu hình cho sản phẩm<strong className='ml-1'>{modalData.text}</strong> thuộc Salon:{' '}
            <strong className='ml-1'>{modalData.salon}</strong>
          </p>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '10px 0' }}>
            <div style={{ width: '50%' }}>
              <p>Nhập giá bán</p>{' '}
              <InputNumber style={{ width: '80%' }} value={Price} onChange={(value) => setPrice(value)}></InputNumber>
            </div>
            <div style={{ width: '50%' }}>
              <p>Nhập chiết khấu lương</p>{' '}
              <InputNumber
                style={{ width: '80%' }}
                value={Discount}
                onChange={(value) => setDiscount(value)}
              ></InputNumber>
              (%)
            </div>
          </div>

          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ width: '50%' }}>
              <Checkbox checked={IsUse} onChange={() => setIsUse(!IsUse)}></Checkbox>
              <span className='ml-2'>Sử dụng</span>
            </div>
            <div style={{ width: '50%' }}>
              <Checkbox checked={IsInventoryCheck} onChange={() => setIsInventoryCheck(!IsInventoryCheck)}></Checkbox>
              <span className='ml-2'>Cho phép kiểm</span>
            </div>
          </div>
        </Modal>
      )}
    </div>
  )
}
