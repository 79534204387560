/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Item from './Item';

const Spending = (props: any) => {
  const { memberName, memberSpending } = props;
  return (<>
    {memberSpending.current && <Item current={memberSpending.current} memberName={memberName}></Item>}
    {memberSpending.upgrade?.map((item: any, index: any) => {
      return (
        <div key={index}>
          <Item current={item} memberName={item.memberName} isUpgrade={true}></Item>
        </div>
      )
    })}
  </>)
}

export default Spending;