/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCusInfo, getCusPhone } from "../api";
import { Button, Col, Input, Modal, notification, Row, Tag } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { SearchProps } from "antd/es/input";
import Search from "antd/es/input/Search";
import dayjs from "dayjs";
import Spending from "./Spending";
import BoxRequest from "./boxRequest";


const ModalPopupAddRequest = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState<boolean>(false)
    const [dataMember, setDataMember] = useState<any>([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [oldPhone, setOldPhone] = useState("");

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    // xử lý tìm kiếm 
    const onSearch = (value) => {
        if (value.length === 10) {
            getCusPhoneInfo(value)
            setOldPhone(value)
        } else {
            notification.error({
                message: 'Thông báo!',
                description: <>Số điện thoại phải đủ 10 số!</>
            })
        }
    }

    // Tìm kiếm khách hàng theo SĐT
    const getCusPhoneInfo = async (phone) => {
        if (loading) return
        setLoading(true)
        try {
            const response: any = await getCusPhone(phone)
            if (response?.data?.customerId) {
                getCusMemberInfo(response?.data?.customerId)
            } else {
                setLoading(false)
                notification.error({
                    message: 'Thông báo!',
                    description: <>Không tìm thấy khách hàng!</>
                })
            }
        } catch (error) {
            notification.error({
                message: 'Thông báo!',
                description: <>Lỗi hệ thống không tìm được thông tin KH!</>
            })
            console.error('Error:', error.message)
            setLoading(false)
        }
    }

    // Lấy thông tin khách hàng
    const getCusMemberInfo = async (id) => {
        try {
            const { data } = await getCusInfo({
                customerId: id,
                loadBrandTag: false,
                loadDataBrand: true,
                loadFeedback: false,
                loadServed: true,
            })
            if (data?.member) {
                console.log(data, "datadatadata")
                setDataMember(data)
            } else {
                notification.error({
                    message: 'Thông báo!',
                    description: <>Không tìm thấy thông tin member khách hàng!</>
                })
            }
            setLoading(false)
        } catch (error) {
            notification.error({
                message: 'Thông báo!',
                description: <>Lỗi hệ thống không tìm được thông tin member KH!</>
            })
            console.error('Error:', error.message)
            setLoading(false)
        }
    }

    const CheckDate = (date) => {
        return dayjs(date).diff(dayjs(), 'day');
    };

    useEffect(() => {
        if (!isModalOpen) {
            setDataMember([])
        }
    }, [isModalOpen])

    return (
        <div>
            <Button
                type='default'
                onClick={showModal}
                className='w-[200px] bg-[#4096ff] text-white hover:bg-white'
            >
                <div className='flex items-center justify-center hover_icon'>
                    <PlusOutlined className='mr-1 text-white' />
                    Thêm yêu cầu
                </div>
            </Button>
            <Modal title="Thêm mới yêu cầu" open={isModalOpen} onCancel={handleCancel} destroyOnClose={true} footer={false} width={1000} >
                <div className="min-h-[350px] flex flex-row justify-between w-full">
                    <div className="w-[65%]">
                        <div className="flex flex-row gap-2 items-center">
                            Tìm SĐT khách:
                            <Search
                                maxLength={10}
                                placeholder='Nhập SĐT khách'
                                onSearch={onSearch}
                                style={{ width: 200 }}
                            />
                        </div>

                        {dataMember?.member &&
                            <div className="p-3 border-[1px] mt-4">
                                {dataMember?.member?.expire?.map((item: any, index: number) => {
                                    return (
                                        <div key={index} className="grid grid-cols-3 gap-1 my-2">
                                            <div>{item.memberName} Member:</div>
                                            <div>
                                                từ {dayjs(item.startTime).format('DD/MM/YYYY')} -
                                                {dayjs(item.endTime).format('DD/MM/YYYY')}
                                            </div>
                                            <div>
                                                {CheckDate(item.endTime) > 0 ?
                                                    <Tag color="green" className="w-max">
                                                        Còn {CheckDate(item.endTime)} ngày
                                                    </Tag>
                                                    :
                                                    <Tag color="red" className="w-max">
                                                        Hết hạn
                                                    </Tag>
                                                }
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        }

                        {dataMember?.member &&
                            <div className="p-3 border-[1px] mt-4">
                                {dataMember?.member?.spending && <Spending memberSpending={dataMember?.member?.spending} memberName={dataMember?.member?.memberName} ></Spending>}
                            </div>
                        }
                    </div>
                    <div className="w-[30%]">
                        {dataMember?.member && CheckDate(dataMember?.member?.expire[0]?.endTime) > 0 &&
                            <BoxRequest oldPhone={oldPhone} onCloseModal={() => setIsModalOpen(false)} />
                        }
                    </div>

                </div>
            </Modal>
        </div>
    )
}

export default ModalPopupAddRequest


