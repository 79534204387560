export const BIReportTopupPage = () => {
  return (
    <div className={`h-[calc(100vh-64px)]`}>
      <iframe
        title='Doanh số Topup'
        src='https://app.powerbi.com/view?r=eyJrIjoiOTJiZGIxMzItMjkxNC00MTg4LWFhYTItMzkyNGUxYTA2MjlmIiwidCI6ImZkNjE5MTU3LWM5MGItNDUwZC1hODI5LTg1OTE2YmExNmRhMyIsImMiOjEwfQ%3D%3D'
        className='!block w-full h-full'
        frameBorder='0'
      ></iframe>
    </div>
  )
}
