/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { DeleteFilled, EditFilled } from '@ant-design/icons'
import { Button, Modal, notification } from 'antd'
import Table, { ColumnsType, TableProps } from 'antd/es/table'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { deleteStaffLevel } from '../api'

interface Props {
    data: any,
    params: any
}

const TableLevel = (props: Props) => {
    const { data, params } = props
    const navigate = useNavigate()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false)
    const [dataTable, setDataTable] = useState([])
    const [selectedId, setSelectedId] = useState(null)

    const showModal = (id) => {
        setSelectedId(id)
        setIsModalOpen(true);
    };

    const handleOk = () => {
        getData(selectedId)
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setSelectedId(null)
    };

    const columns: ColumnsType<any> = [
        {
            title: 'STT',
            dataIndex: 'index',
            width: 70,
            key: 'index',
            render: (record) => (
                <div>
                    {((params.pageIndex - 1) * params.pageSize) + record + 1}
                </div>
            ),
        },
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Bậc kỹ năng',
            width: 200,
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Vị trí gán với bậc',
            width: 300,
            dataIndex: '',
            key: '',
            render: (value, record) => (
                <div className='flex flex-wrap' >
                    {record?.departments?.map((item: any, index: number) => {
                        return (
                            <div className='flex flex-row' key={index}>
                                {item?.name}
                                <div className={`${index + 1 === record?.departments?.length ? "hidden " : "mr-[4px]"}`}>,</div>
                            </div>
                        )
                    })}
                </div>
            )
        },
        {
            title: 'Mô tả',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Thao tác',
            width: 150,
            dataIndex: '',
            key: '',
            render: (value, record) => (
                <div className='flex flex-row justify-between' >
                    <Button
                        className='flex items-center justify-center border-0 '
                        onClick={() => {
                            navigate(`/staff-skill-salary/skill-level-list/${record.id}`)
                        }}
                        disabled={record.isDelete != 0 || value.isDelete}
                    >
                        <EditFilled className='text-lg' />
                    </Button>
                    <Button
                        className='flex items-center justify-center border-0'
                        disabled={value.isApply || value.isDelete}
                        onClick={() => showModal(value.id)}
                    >
                        <DeleteFilled className='text-lg' />
                    </Button>
                </div>
            )
        },
    ]

    const onChange: TableProps<any>['onChange'] = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra)
    }

    const getData = async (id: any) => {
        if (loading) return
        setLoading(true)
        try {
            const response: any = await deleteStaffLevel(id)
            if (response?.statusCode === 200) {
                notification.success({
                    description: 'Xóa bậc kỹ năng thành công!',
                    message: 'Thành công'
                })
                const newData = data.filter((item: any) => item.id != id)
                setDataTable(newData)
                setLoading(false)
            }
        } catch (error) {
            notification.error({
                message: 'Thông báo!',
                description: <>Lỗi không xóa được bậc kỹ năng!</>
            })
            setLoading(false)
        }
    }

    useEffect(() => {
        if (data) {
            setDataTable(data)
        }
    }, [data])

    return (
        <div  >
            <>
                <Modal title="Xóa bậc" open={isModalOpen} footer={false}>
                    Bạn có chắc chắc là muốn xóa bậc này?
                    <div className='flex justify-end gap-5 mt-5'>
                        <Button
                            type="primary"
                            onClick={handleOk}
                            danger
                        >
                            Xóa
                        </Button>
                        <Button
                            onClick={handleCancel}
                        >
                            Hủy
                        </Button>
                    </div>
                </Modal>
            </>
            <Table columns={columns} dataSource={dataTable} onChange={onChange} pagination={false} bordered />
        </div>
    )
}
export default TableLevel
