import getInstanceAxios from 'utils/requestManager/RequestGlobalConfig'

export const cataLogServiceApi = getInstanceAxios(process.env.REACT_APP_CATALOG_SERVICE)
export const StaffRequestView = getInstanceAxios(process.env.REACT_APP_LINK_STAFF_SERVICE_VIEW)


export const convertParamsAxios = (params: any) => {
    const queryString = Object.keys(params)
        .map((key) => {
            const value = params[key]
            if (Array.isArray(value)) {
                return value.map((item) => `${key}=${item}`).join('&')
            }
            return value !== undefined ? `${key}=${value}` : undefined
        })
        .filter((i) => i !== undefined)
        .join('&')
    return queryString
}

export const convertQueryStringToObject = (queryString) => {
    const params = {}

    if (queryString) {
        const pairs = queryString.split('&')

        pairs.forEach((pair) => {
            const [key, value] = pair.split('=')
            const newValue = Number.isInteger(Number(value)) ? parseInt(value, 10) : value
            if (key && value) {
                if (['PageSize', 'PageIndex', 'Keyword', 'groupId'].includes(key)) {
                    params[key] = decodeURIComponent(newValue)
                }
                // eslint-disable-next-line no-prototype-builtins
                else if (params.hasOwnProperty(key)) {
                    if (!Array.isArray(params[key])) {
                        params[key] = [params[key]]
                    }
                    params[key].push(newValue)
                } else {
                    params[key] = [newValue]
                }
            }
        })
    }

    return params
}

export const getListStaffSalary = (data: any) => {
    return cataLogServiceApi.post(`/v1/staff-salary/paging`, data)
}

export const getStaffType = (groupId: number) => {
    return StaffRequestView.get(`/v1/staff/types?groupId=${groupId}`)
}

export const getStaffSkillLevel = () => {
    return StaffRequestView.get(`/v1/staff/skill-levels`)
}