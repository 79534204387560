/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button, Input, notification } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { postCusPhoneUpdate } from "../api";


const BoxRequest = (props: any) => {
    const { oldPhone, onCloseModal } = props;
    const navigate = useNavigate()
    const [loading, setLoading] = useState<boolean>(false)
    const [data, setData] = useState<any>({
        oldPhone: "",
        newPhone: "",
        teleSaleNote: ""
    })

    const AddText = (text: string) => {
        let textData = data.teleSaleNote || "";

        if (textData.includes(text)) {
            textData = textData.replace(text, "");
            setData({ ...data, teleSaleNote: textData.trim() });
        } else {
            setData({ ...data, teleSaleNote: text + " " + textData });
        }
    };

    const getCusMemberInfo = async () => {
        try {
            const response: any = await postCusPhoneUpdate(data)
            if (response.statusCode === 200) {
                notification.success({
                    message: 'Thông báo!',
                    description: <>Gửi yêu cầu thành công!</>
                })
                onCloseModal()
            } else {
                notification.error({
                    message: 'Thông báo!',
                    description: <>{response.message}</>
                })
            }
            setLoading(false)
        } catch (error) {
            notification.error({
                message: 'Thông báo!',
                description: <>Lỗi hệ không gửi được yêu cầu!</>
            })
            console.error('Error:', error.message)
            setLoading(false)
        }
    }

    useEffect(() => {
        if (oldPhone) {
            setData({ ...data, oldPhone: oldPhone });
        }
    }, [oldPhone])

    return (
        <div className="flex flex-col gap-2">
            <span>Thông tin để đổi ShineMember</span>
            <div className="flex flex-row justify-between">
                <div>SĐT mới <span className="text-red-600">*</span></div>
                <Input
                    style={{ width: 200 }}
                    maxLength={10}
                    placeholder='Nhập SĐT khách'
                    value={data?.newPhone || undefined}
                    onChange={(e) => {
                        setData({ ...data, ['newPhone']: e.target.value })
                    }}
                />
            </div>

            <div className="flex flex-row justify-between">
                <span>Lý do</span>
                <div className="flex flex-col gap-2">
                    <div className="flex flex-row gap-2">
                        <span
                            className="rounded-2xl border-2 p-1"
                            onClick={() => AddText("Khách đổi SĐT")}
                        >
                            Khách đổi SĐT
                        </span>
                        <span
                            className="rounded-2xl border-2 p-1"
                            onClick={() => AddText("Nhập nhầm SĐT")}
                        >
                            Nhập nhầm SĐT
                        </span>
                    </div>
                    <TextArea
                        autoSize={{ minRows: 5, maxRows: 10 }}
                        maxLength={260}
                        value={data?.teleSaleNote || undefined}
                        onChange={(e) => {
                            setData({ ...data, ['teleSaleNote']: e.target.value })
                        }} />
                </div>
            </div>
            <div className="flex justify-center items-center">
                <Button
                    type='default'
                    onClick={() => getCusMemberInfo()}
                    className='w-[150px] bg-[#4096ff] text-white hover:bg-white'
                    disabled={!(data?.newPhone?.length === 10)}
                >
                    <div className='flex items-center justify-center hover_icon'>
                        Xác nhận
                    </div>
                </Button>
            </div>
        </div>
    )
}

export default BoxRequest


