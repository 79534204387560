import { author } from './config'
import axios from 'axios'
import Cookies from 'js-cookie'
import { catalog } from 'pages/Products/api/config'

interface paramsProps {
  page: number
  pageSize: number
}
export const getListServices = (params: paramsProps) => {
  return author.get(`/api/v1/service-manage/services-manage-list`, {
    params: params
  })
}
export const getListService = (params) => {
  return params?.salonId
    ? catalog.get(`/v1/salon-service/service/${params.serviceId}?salonId=${params.salonId}`)
    : catalog.get(`/v1/salon-service/service/${params.serviceId}`)
}
export const getListServiceSalonV2 = (params) => {
  return params?.serviceIds
    ? catalog.get(`/v1/salon-service/${params.salonId}?serviceIds=${params.serviceId}`)
    : catalog.get(`/v1/salon-service/${params.salonId}`)
}
export const updateServices = (servicesId: string, body: any) => {
  return author.put(`/api/v1/service-manage/${servicesId}/update`, body)
}

export const updateServicesSalary = (body) => {
  return catalog.post(`salary`, body)
}

export const createServices = (body: any) => {
  return author.post(`/api/v1/service-manage/create`, body)
}

const getAccessTokenFromCookie = () => {
  return Cookies.get('accessToken') // Thay đổi key này nếu cần
}

export const downloadExcel = async (params: string) => {
  // Thêm kiểu trả về là Blob
  const accessToken = getAccessTokenFromCookie()
  const url = process.env.REACT_APP_API_WEBFORM_ERP + `/api/v1/service-manage/services-manage-list/export?${params}`

  axios({
    url: url,
    method: 'GET',
    responseType: 'blob', // Để nhận dữ liệu dưới dạng blob

    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${accessToken}`
    }
  })
    .then((response) => {
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      })
      const url = URL.createObjectURL(blob)

      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.download = `export_data.xlsx`

      document.body.appendChild(a)
      a.click()

      URL.revokeObjectURL(url)
      document.body.removeChild(a)
    })
    .catch((error) => {
      console.error('Error downloading Excel:', error)
    })
}

export const GetListServices = () => {
  return author.get(`/api/v1/list-select/services-list`)
}

export const getDetailServices = (salonId: string) => {
  return author.get(`/api/v1/service-manage/${salonId}/detail`)
}

export const getListTreeServices = () => {
  return author.get(`/api/v1/list-select/category-list`)
}

export const getListServiceSalon = (params: paramsProps) => {
  return author.get(`/api/salon-service/list`, {
    params: params
  })
}

// list config
export const getListTblConfig = (key: string) => {
  return author.get(`/api/v1/list-select/processing-list?key=${key}`)
}
export const getListSalonFlowService = (params, serviceId: number) => {
  return catalog.get(`/v1/salon-service/service/${serviceId}`, {
    params: params
  })
}
export const getListServicesFlowSalon = (params, salonId: number) => {
  return catalog.get(`/v1/salon-service/${salonId}`, {
    params: params
  })
}
export const GetListServicesV2 = () => {
  return catalog.get(`/v1/service?pageSize=0&pageIndex=0`)
}
export const GetSalaryServices = (params) => {
  return catalog.get(`/Salary?salonId=${params?.salonId}&serviceId=${params?.serviceId}`)
}
export const getListServiceSelect = () => {
  return catalog.get(`/v1/service`)
}
export const getListSalonSelectV2 = () => {
  return catalog.get(`/Salon`)
}
export const listProductDatatable = (params: paramsProps) => {
  return author.get(`/api/v1/products/salon-products`, {
    params: params
  })
}
export const updateSalonProductConfig = (salonProductId: string, body: any) => {
  return author.patch(`/api/v1/products/salon-products/update/${salonProductId}`, body)
}

export const downloadExcelSalonProducts = async (params: string) => {
  // Thêm kiểu trả về là Blob
  const accessToken = getAccessTokenFromCookie()
  const url = process.env.REACT_APP_API_WEBFORM_ERP + `/api/v1/products/salon-products/export?${params}`

  axios({
    url: url,
    method: 'GET',
    responseType: 'blob', // Để nhận dữ liệu dưới dạng blob

    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${accessToken}`
    }
  })
    .then((response) => {
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      })
      const url = URL.createObjectURL(blob)

      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.download = `export_data.xlsx`

      document.body.appendChild(a)
      a.click()

      URL.revokeObjectURL(url)
      document.body.removeChild(a)
    })
    .catch((error) => {
      console.error('Error downloading Excel:', error)
    })
}

export const listProducts = (params: paramsProps) => {
  return author.get(`/api/v1/products/products-list`, {
    params: params
  })
}
export const listCategoryProducts = () => {
  return author.get(`/api/v1/list-select/category-list`)
}

export const updateProduct = (productId: string, body: any) => {
  return author.put(`/api/v1/products/${productId}/update`, body)
}

export const createProduct = (body: any) => {
  return author.post(`/api/v1/products/create`, body)
}

export const processingList = (key: string) => {
  return author.get(`/api/v1/list-select/processing-list?key=${key}`)
}

export const getDetailProduct = (productId: string) => {
  return author.get(`/api/v1/products/${productId}/detail`)
}

export const getListProductGroup = () => {
  return author.get(`/api/v1/list-select/product-group-list`)
}

// danh sách thương hiệu
export const getListProductsBrand = () => {
  return author.get(`/api/v1/list-select/product-brands-list`)
}
// list sản phẩm cho thẻ selector
export const getListProductsSelector = () => {
  return author.get(`/api/v1/list-select/products-list`)
}
