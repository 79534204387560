import { message } from 'antd'
import UploadEndpoint from './config'

export const handleUploadS3 = async (fileName: string, file: any, folder: string): Promise<any | Error> => {
  try {
    const bodyKeyS3 = {
      key: folder + '/' + fileName
    }
    const response = await UploadEndpoint.getKeyS3EndPoint(bodyKeyS3)
    console.log(response, 'response')
    if (response) {
      try {
        const urlEndPoint = response.url
        const responS3 = await UploadEndpoint.uploadImageS3(urlEndPoint, file)
        if (responS3 && responS3.status === 200) {
          return bodyKeyS3.key
        }
      } catch (error) {
        console.log(error)
      }
    }
  } catch (error) {
    if (error instanceof Error) {
      return error
    } else {
      return new Error('Unexpected error')
    }
  }
}
