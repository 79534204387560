export const removeVietnamese = (str: any) => {
  // Kiểm tra nếu chuỗi là null hoặc undefined
  if (str == null || str === undefined || str === 'null' || str === 'undefined') {
    return ''
  }
  // chuyển thành chữ
  const newStr = str.toString()
  // Chuyển đổi chữ hoa thành chữ thường
  let newString = newStr.toLowerCase()
  // Loại bỏ dấu tiếng Việt
  newString = newString
    .replace(/[àáạảãâầấậẩẫăằắặẳẵ]/g, 'a')
    .replace(/[èéẹẻẽêềếệểễ]/g, 'e')
    .replace(/[ìíịỉĩ]/g, 'i')
    .replace(/[òóọỏõôồốộổỗơờớợởỡ]/g, 'o')
    .replace(/[ùúụủũưừứựửữ]/g, 'u')
    .replace(/[ỳýỵỷỹ]/g, 'y')
    .replace(/đ/g, 'd')

  return newString
}
