/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Table, { ColumnsType, TableProps } from 'antd/es/table'
import convert from 'utils/helpers/convert'

interface Props {
    data: any,
    listStaffType: any,
    levelList: any
}

const TabelPreViewFile = (props: Props) => {
    const { data, listStaffType, levelList } = props

    const columns: ColumnsType<any> = [
        {
            title: 'STT',
            dataIndex: 'index',
            key: 'index',
        },
        {
            title: 'Vị trí',
            dataIndex: 'departmentId',
            key: 'departmentId',
            render: (record) => (
                <div>
                    {listStaffType && listStaffType.filter((item: any) => item.id === Number(record))[0]?.name}
                </div>
            ),
        },
        {
            title: 'Level',
            dataIndex: 'levelId',
            key: 'levelId',
            render: (record) => (
                <div>
                    {levelList && levelList.filter((item: any) => item.id === Number(record))[0]?.name}
                </div>
            ),
        },
        {
            title: 'Lương cứng',
            dataIndex: 'fixedSalary',
            key: 'fixedSalary',
            render: (record) => {
                return (
                    <div>
                        {record > 0 &&
                            <span> {convert.moneyFormat(record?.toString())} </span>
                        }
                    </div>
                )
            }
        },
    ]
    const onChange: TableProps<any>['onChange'] = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra)
    }

    return (
        <div
            className='mt-5 bg-white'
            style={{ boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px', borderRadius: 10 }}
        >
            <Table columns={columns} dataSource={data} onChange={onChange} bordered />

        </div>
    )
}
export default TabelPreViewFile
