import { convertParamsAxios } from 'pages/staff/api'
import { StaffRequest } from 'utils/ManagementEndpointRequest/TimelineSocket'
export const getListStylistApproval = (params: any) => {
  return StaffRequest.get(`/v1/staff-approval-info?${convertParamsAxios(params)}`)
}
export const updateListStylistApproval = (id: number, statusId: number) => {
  return StaffRequest.put(`/v1/staff-approval/${id}`, { statusId, data: '' })
}
export const updateStylistIntroduction = (id: number, data: string) => {
  return StaffRequest.put(`/v1/staff-approval/sm-modify/${id}`, { data })
}

export const getUploadedInfo = (params: any) => {
  return StaffRequest.get(`/v1/staff-approval-detail/${params}`)
}

export const getUploadedStatus = (params: any) => {
  return StaffRequest.get(`/v1/staff-approval-status/`, { params })
}

export const postNewUpdateRequest = (data: any) => {
  return StaffRequest.post(`/v1/create-staff-approval/`, data)
}
