import { UploadOutlined } from '@ant-design/icons'
import { Button, DatePicker, Upload, UploadFile, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import * as XLSX from 'xlsx'
import TabelPreViewFile from './components/TabelPreViewFile'
import { getStaffSkillLevel, getStaffType, importStaffLevelConfig } from './api'
import dayjs from 'dayjs'
import convert from 'utils/helpers/convert'

const ConfigStaffSkillLevel = () => {
    const [dataUpload, setDataUpload] = useState([])
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [loading, setLoading] = useState(false)
    const [levelList, setLevelList] = useState([])
    const [warning, setWarning] = useState(false)
    const [time, setTime] = useState(dayjs());
    const [listStaffType, setListStaffType] = useState([])

    // Lấy danh sách level
    const getStaffSkillLevelData = async () => {
        if (loading) return
        setLoading(true)
        try {
            const response: any = await getStaffSkillLevel()
            if (response.data) {
                setLevelList(response.data)
                setLoading(false)

            }
        } catch (error) {
            notification.error({
                message: 'Thông báo!',
                description: <>Lỗi không lấy được danh sách level!</>
            })
            console.error('Error:', error.message)
            setLoading(false)
        }
    }
    // lấy danh sách vị trí
    const getStaffTypeData = async () => {
        setLoading(true)
        try {
            const response: any = await getStaffType(1)
            if (response.data) {
                const newListStaffType = response.data.filter((item: any) => item.skillLevelIds.length > 0)
                setListStaffType(newListStaffType)
                setLoading(false)
            }
        } catch (error) {
            console.error('Error:', error.message)
            notification.error({
                message: 'Thông báo!',
                description: <>Lỗi không lấy được danh sách vị trí!</>
            })
            setLoading(false)
        }
    }

    const onFinish = async () => {
        if (dataUpload?.length <= 0) {
            notification.warning({
                message: 'Cảnh báo',
                description: 'File không có dữ liệu'
            })
            return
        }
        if (warning) {
            notification.warning({
                message: 'Cảnh báo',
                description: 'File chứa dữ liệu không hợp lệ'
            })
            return
        }
        if (loading) return
        setLoading(true)
        try {
            const listData = dataUpload.map((item: any, index: number) => {
                return {
                    staffId: Number(item.staffId),
                    staffName: item.staffName,
                    departmentName: item.departmentName,
                    salonName: item.salonName,
                    levelId: Number(item.levelId),
                };
            })
            const payload = {
                data: listData,
                modifiedDate: dayjs(time).format("YYYY-MM-DD")
            }
            const response: any = await importStaffLevelConfig(payload)
            if (response.statusCode === 200) {
                notification.success({
                    description: 'Import file thành công!',
                    message: 'Thành công'
                })
                setDataUpload([])
                setFileList([])
                setLoading(false)
            } else {
                notification.error({
                    message: 'Lỗi',
                    description: response?.message || 'Có lỗi xảy ra, vui lòng thử lại!'
                })
                setLoading(false)

            }
            setLoading(false)
        } catch (error) {
            notification.error({
                message: 'Lỗi',
                description: 'Có lỗi xảy ra, vui lòng thử lại!'
            })
            setLoading(false)
        }
    }

    // Kiểm tra dữ liệu trong file
    const CheckValid = (item: any, index: number) => {
        if (!(levelList.some((x: any) => x.id === Number(item.staffLevel)))) {
            notification.error({
                message: 'Thông báo!',
                description: <>STT {index + 1} level hiện tại không hợp lệ!</>
            })
            setWarning(true)
            return
        }
        if (!(levelList.some((x: any) => x.id === Number(item.levelId)))) {
            notification.error({
                message: 'Thông báo!',
                description: <>STT {index + 1} level không tồn tại!</>
            })
            setWarning(true)
            return
        }
        if (Number(item.staffLevel) === Number(item.levelId)) {
            notification.error({
                message: 'Thông báo!',
                description: <>STT {index + 1} level hiện tại trùng level muốn lên!</>
            })
            setWarning(true)
            return
        }
        if (!(item?.staffId)) {
            console.log(item.staffId, "item.staffId")
            notification.error({
                message: 'Thông báo!',
                description: <>STT {index + 1} không có id nhân sự!</>
            })
            setWarning(true)
            return
        }

        if (listStaffType.some((x: any) => checkName(x.name) === checkName(item.departmentName))) {
            const list: any = listStaffType.filter((x: any) => checkName(x.name) === checkName(item.departmentName))
            if (!list[0].skillLevelIds.some((x: any) => x.id === Number(item.staffLevel))) {
                notification.error({
                    message: 'Thông báo!',
                    description: <>STT {index + 1} Level hiện tại không hợp lệ!</>
                })
                setWarning(true)
                return
            }
            if (!list[0].skillLevelIds.some((x: any) => x.id === Number(item.levelId))) {
                notification.error({
                    message: 'Thông báo!',
                    description: <>STT {index + 1} Level lên không hợp lệ!</>
                })
                setWarning(true)
                return
            }
        } else {
            notification.error({
                message: 'Thông báo!',
                description: <>STT {index + 1} tên vị trí không hợp lệ!</>
            })
            setWarning(true)
            return
        }
    }
    function checkName(name: any) {
        const Name = convert.removeVietnameseAccent(name.toLowerCase())
        return Name;
    }
    // Kiểm tra trung lặp dữ liệu trong file
    function checkDuplicates(listdata: any) {
        const seen = new Set();
        for (let i = 0; i < listdata.length; i++) {
            const pair = `${listdata[i].staffId}`;
            if (seen.has(pair)) {
                return true; // Có cặp trùng lặp
            }
            seen.add(pair);
        }
        return false; // Không có cặp trùng lặp
    }

    // Xử lý file import
    const props = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setWarning(false)
            const newFileList = [file];
            // setFileList([...fileList, file]);
            setFileList(newFileList);
            // var name = file.name;
            const reader = new FileReader();
            reader.onload = (evt) => {
                const data = evt.target.result;
                const workbook = XLSX.read(data, {
                    type: "binary",
                    cellText: false,
                    cellDates: true,
                });
                const first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
                const dataParse = XLSX.utils.sheet_to_json(first_worksheet, {
                    header: 0,
                    raw: false,
                    dateNF: "yyyy-mm-dd",
                });
                if (checkDuplicates(dataParse)) {
                    notification.error({
                        message: 'Thông báo!',
                        description: <>Xuất hiện staffId trùng nhau</>
                    })
                    return
                }
                const newData = dataParse.filter((item: any) => item.staffId && item.levelId && item.staffLevel)
                    .map((item: any, index: number) => {
                        CheckValid(item, index)
                        return {
                            ...item,
                            staffId: Number(item.staffId),
                            staffName: item.staffName,
                            departmentName: item.departmentName,
                            salonName: item.salonName,
                            staffLevel: Number(item.staffLevel),
                            levelId: Number(item.levelId),
                            index: index + 1,
                            key: index + 1,
                        };
                    });
                setDataUpload(newData);
            };
            reader.readAsBinaryString(file);
            return false;
        },
        fileList,
    };
    // Tạo data select

    useEffect(() => {
        getStaffSkillLevelData()
        getStaffTypeData()
    }, [])

    const onChange = (date: any) => {
        setTime(date)
    };
    return (
        <div className='min-h-[100vh] m-auto bg-[#F3F3F3] pb-12'>
            <div className='w-full px-5 py-5 search-wrapper'>
                <div className='p-5 bg-white'>
                    <h2 className='text-2xl font-bold mb-7'>Import lên bậc lương nhân sự</h2>
                    <div className='flex gap-4'>
                        <span className='flex items-center justify-center'>Ngày lên bậc: </span>
                        <DatePicker onChange={onChange} value={time} format={"YYYY-MM-DD"} allowClear={false} />
                        <Button type="primary" ghost onClick={() => setTime(dayjs())}>
                            Hôm nay
                        </Button>
                        <Button type="primary" ghost onClick={() => setTime(dayjs().subtract(1, 'days'))}>
                            Hôm qua
                        </Button>
                        <Button
                            type="primary"
                            ghost
                            onClick={() => setTime(dayjs().subtract(2, 'days'))}
                        >
                            Hôm kia
                        </Button>
                    </div>
                </div>
                <div className='flex items-center gap-2 mt-2 italic'>
                    Import file{' '}
                    <a
                        href="https://storage.30shine.com/staff/bac-nhan-su/file_example_import_staff_level.csv"
                        type="media_type"
                        className="py-2 text-[#1890FF] underline-offset-4 underline "
                    >
                        Download file mẫu
                    </a>
                </div>
                <div className='mt-2 flex flex-row  items-center gap-5'>
                    <Upload
                        {...props}
                        fileList={fileList}
                        style={{ width: 100, marginLeft: 4 }}
                        accept=".csv"
                    >
                        <Button icon={<UploadOutlined />}>Import file</Button>
                    </Upload>
                    <span className='text-red-500'>Lưu ý tên vị trí viết không dấu</span>
                </div>
                <div>
                    <TabelPreViewFile data={dataUpload} levelList={levelList} />
                </div>
                <Button className='mt-5' onClick={() => onFinish()} disabled={loading || warning}>
                    Cập nhật cấu hình
                </Button>
            </div>
        </div>
    )
}

export default ConfigStaffSkillLevel
