/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { Button, Col, Modal, Row, notification } from 'antd';
import { ExclamationCircleOutlined, EyeOutlined, PauseOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { stopNoti } from 'pages/pushNotification/Api/api';

interface Props {
    data: any,
    setIsStop: any
}
export default function DeleteNotification(props: Props) {
    const { data, setIsStop } = props;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState<boolean>(false)
    const [isStopNoti, setIsStopNoti] = useState<boolean>(false)

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        DeleteNoti()
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const DeleteNoti = async () => {
        if (loading) return
        setLoading(true)
        try {
            const response: any = await stopNoti(data.id);
            if (response.message.statusCode === 200) {
                setIsStop(data.id)
                setIsModalOpen(false);
                setIsStopNoti(true)
                notification.success({
                    message: 'Thông báo!',
                    description: <>Dừng thông báo thành công</>
                })
            }
            setLoading(false)
        } catch (error) {
            console.error('Error:', error.message)
            setLoading(false)
        }
    }

    return (
        <>
            <Button
                style={{ display: "flex", alignItems: "center" }}
                shape="round"
                onClick={showModal}
                disabled={data.status === 3 || data.status === 4 || isStopNoti}
            >
                <PauseOutlined />
                <span>Dừng</span>
            </Button>
            <Modal
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                closeIcon={true}
                width={500}
                okText="Đồng ý"
                cancelText="Hủy"
            >
                <div style={{ display: "flex", alignItems: "center" }}>
                    <ExclamationCircleOutlined style={{ color: "#1890FF", fontSize: "22px" }} />
                    <span style={{ lineHeight: "30px", marginLeft: "10px", fontSize: "22px", fontWeight: "500" }}>Xác nhận</span>
                </div>
                <div style={{ marginLeft: "32px", marginBottom: "20px", marginTop: "10px" }}>
                    <span style={{ lineHeight: "22px", fontSize: "16px" }}>
                        Bạn có chắc chắn muốn dừng lịch bắn thông báo
                        <span style={{ fontWeight: "500" }}> {data.name} </span>
                        được lên lịch bắn vào
                        <span style={{ fontWeight: "500" }}> {data?.sentDate ? dayjs(data?.sentDate).format("YYYY-MM-DD HH-mm-ss") : null}</span>
                        ?
                    </span>
                </div>
            </Modal>
        </>
    );
};