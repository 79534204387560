import { DatePicker, Radio, RadioChangeEvent, Select, TimePicker } from 'antd'
import { useEffect, useState } from 'react'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { DatePickerProps, RangePickerProps } from 'antd/es/date-picker'
import dayjs, { Dayjs } from 'dayjs'
dayjs.extend(customParseFormat)

interface Props {
    setSelectedTime: any
    setPushNow: any
    selectedDateTime: any
    setIsWarning: any
    pushNow: any
}
export default function SelectTimePush(props: Props) {
    const { setSelectedTime, setPushNow, selectedDateTime, setIsWarning, pushNow } = props
    const [valueRadio, setValueRadio] = useState(null)
    const [showSelectDate, setShowSelectDate] = useState(false)
    const [selectDate, setSelectDate] = useState('')
    const [selectTime, setSelectTime] = useState('')
    const [time, setTime] = useState('')
    const currentDate = dayjs().format('YYYY-MM-DD')
    const [warning, setWarning] = useState('')

    //radio chọn tg bắn
    const onChange = (e: RadioChangeEvent) => {
        setValueRadio(e.target.value)
        if (e.target.value === 2) {
            setWarning("")
            setIsWarning(false)
        }
    }

    //giới hạn ngày bắn
    const disabledDate: RangePickerProps['disabledDate'] = (current) => {
        const minDate = dayjs().startOf('day')
        const maxDate = dayjs().add(30, 'day').endOf('day')
        return current && (current < minDate || current > maxDate)
    }

    // thay đổi ngày
    const onChangeDate = (value: DatePickerProps['value'], dateString: string) => {
        setSelectDate(dateString)
        setSelectTime("")
    }

    // thay đổi giờ
    const onChangeTime = (time: Dayjs, timeString: string) => {
        if (selectDate === currentDate) {
            if (dayjs(time).diff(dayjs().add(20, 'minutes')) > 0) {
                setWarning("")
                setIsWarning(false)
                setSelectTime(timeString)
            } else {
                setWarning("Thời gian lên lịch phải lớn hơn thời gian hiện tại ít nhất 20 phút")
                setIsWarning(true)
                setSelectTime("")
            }
        } else {
            setSelectTime(timeString)
        }
    }

    // ghép ngày và giờ
    useEffect(() => {
        if (selectTime && selectDate) {
            setTime(selectDate + 'T' + selectTime)
        }
    }, [selectTime, selectDate])

    // lấy thời gian từ id cần sửa
    useEffect(() => {
        if (pushNow) {
            setValueRadio(1)
            return
        }
        if (selectedDateTime) {
            setValueRadio(2)
            const dateTime = dayjs(selectedDateTime)
            const Sdate = dateTime.format('YYYY-MM-DD')
            const Stime = dateTime.format('HH:mm:ss')
            setSelectDate(Sdate)
            setSelectTime(Stime)
            if (time) {
                setSelectedTime(time)
            }
            return
        }
    }, [selectedDateTime, pushNow])

    //gán thời gian khi chọn đẩy ngay hoặc lên lịch
    useEffect(() => {
        if (valueRadio === 1) {
            setShowSelectDate(false)
            setSelectedTime('')
            setPushNow(true)
        } else if (valueRadio === 2) {
            setPushNow(false)
            setShowSelectDate(true)
            if (time) {
                setSelectedTime(time)
            }
        }
    }, [valueRadio, time])

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Radio.Group onChange={onChange} value={valueRadio}>
                <Radio value={1}>Bắn noti ngay</Radio>
                <Radio value={2}>Lên lịch bắn</Radio>
            </Radio.Group>
            <div style={{ display: showSelectDate ? 'flex' : 'none', marginTop: '10px' }}>
                <DatePicker
                    style={{ width: '200px' }}
                    placeholder='Chọn ngày'
                    format='YYYY-MM-DD'
                    disabledDate={disabledDate}
                    onChange={onChangeDate}
                    showToday={false}
                    value={selectDate ? dayjs(selectDate, 'YYYY-MM-DD') : undefined}
                />
                <TimePicker
                    style={{ width: '200px' }}
                    placeholder='Chọn giờ'
                    onChange={onChangeTime}
                    format='HH:mm:ss'
                    disabled={selectDate?.length > 0 ? false : true}
                    showNow={false}
                    value={selectTime ? dayjs(selectTime, 'HH:mm:ss') : undefined}
                />
            </div>
            {warning ?
                <div className='text-red-600'>{warning}</div>
                :
                null
            }
        </div>
    )
}
