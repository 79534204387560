import { AddCSVCPage } from './add'
import { ListCSVC } from './list'
import { ProcessCSVC } from './Process'

type Router = {
  path: string
  element: JSX.Element
}[]
export const routerCSVC: Router = [
  {
    path: '/csvc/add',
    element: <AddCSVCPage />
  },
  {
    path: '/csvc/list',
    element: <ListCSVC />
  },
  {
    path: '/csvc/process',
    element: <ProcessCSVC />
  }
]
