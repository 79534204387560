/* eslint-disable @typescript-eslint/ban-ts-comment */
'use client'
import { Button, DatePicker, Form, Input, Space, Select, Spin, notification, Upload, Switch } from 'antd'
import { useContext, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { useNavigate, useParams } from 'react-router-dom'
import { AppContext } from 'layouts/DashboardLayout'
import { LoadingOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons'
import { updateSalon, getListCity, getListDistricts, getListTblConfig, getDetailSalon } from '../api'
import UploadCustom from 'utils/upload/index'
import { getSalons } from 'api/catalog'
const { TextArea } = Input

export const SalonDetailtPage: React.FC = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [form] = Form.useForm()

  const [initialValues, setInitialValues] = useState<any>()
  const [dataSelectSalon, setDataListSalon] = useState<any[]>([])
  const [dataCity, setDataCity] = useState<any[]>([])
  const [citySelect, setCitySelect] = useState('')
  const [dataDistricts, setDataDistricts] = useState<any[]>([])
  const [dataTypeSalon, setDataTypeSalon] = useState<any[]>([])
  const [publish, setPublish] = useState<boolean>(true)
  const [isSalonOTP, setIsSalonOTP] = useState<boolean>(false)
  const [isGroupSalon, setIsGroupSalon] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)
  const [dataImg, setDataImg] = useState<any[]>([])
  const [imageList, setImageList] = useState([])
  const [dataListImageORG, setDataListImageORG] = useState()
  const [Type, setType] = useState('')

  const GetdataDetailSalon = async (id) => {
    try {
      const response: any = await getDetailSalon(id)
      const { statusCode, data } = response
      if (data) {
        const initial = {
          Name: data.Name,
          ShortName: data.ShortName,
          ImeiOrMacIP: data.ImeiOrMacIP,
          Longitude: data.Longitude,
          Latitude: data.Latitude,
          CityId: data.CityId,
          DistrictId: data.DistrictId,
          Address: data.Address,
          Phone: data.Phone,
          Email: data.Email,
          Description: data.Description,
          SMS_Signature: data.SMS_Signature,
          OrderSalon: data.OrderSalon,
          Type: data.Type?.toString(),
          OpeningDate: dayjs(data.OpeningDate),
          ModifiedDate: dayjs(data.ModifiedDate),
          ManagerName: data.ManagerName,
          Fanpage: data.Fanpage,
          FanpageId: data.FanpageId
        }
        if (data.Images) {
          setDataListImageORG(data.Images)
          setDataImg(JSON.parse(data.Images))
          const transformedData = JSON.parse(data.Images).map((item) => ({
            ...item,
            fileList: [
              {
                url: item.url,
                response: item.url
              }
            ]
          }))
          setImageList(transformedData)
        }
        setPublish(data.Publish)
        setIsSalonOTP(data.SalonOTP)
        setIsGroupSalon(data.IsSalonHoiQuan)
        if (data.DistrictId) {
          GetListDistricts(data.CityId)
        }
        setInitialValues(initial)
      }
    } catch (error) {
      console.error('Error:', error.message)
    }
  }
  const GetListSalon = async () => {
    try {
      const key = 'salon_type'
      const response: any = await getSalons()
      const { statusCode, data } = response
      if (statusCode === 200) {
        setDataListSalon(data)
      }
    } catch (error) {
      console.error('Error:', error.message)
    }
  }
  const GetListCity = async () => {
    try {
      const response: any = await getListCity()
      const { statusCode, data } = response
      if (statusCode === 0) {
        setDataCity(data)
      }
    } catch (error) {
      console.error('Error:', error.message)
    }
  }
  const GetListDistricts = async (cityId: string) => {
    try {
      const response: any = await getListDistricts(cityId)
      const { statusCode, data } = response
      if (statusCode === 0) {
        setDataDistricts(data)
      }
    } catch (error) {
      console.error('Error:', error.message)
    }
  }
  const GetListTypeSalon = async () => {
    try {
      const key = 'salon_type'
      const response: any = await getListTblConfig(key)
      const { statusCode, data } = response
      if (statusCode === 0) {
        setDataTypeSalon(data)
      }
    } catch (error) {
      console.error('Error:', error.message)
    }
  }
  useEffect(() => {
    if (id) {
      GetListSalon()
      GetListCity()
      GetListTypeSalon()
      GetListDistricts('')
      GetdataDetailSalon(id)
    }
  }, [id])
  const handleOnChangeCity = (value: string) => {
    setCitySelect(value)
    GetListDistricts(value)
  }

  const onFinish = async (values: any) => {
    console.log('Success:', values)
    try {
      const body = {
        Name: values.Name,
        ShortName: values.ShortName,
        SalonBackup: values.SalonBackup,
        ImeiOrMacIP: values.ImeiOrMacIP,
        Longitude: values.Longitude,
        Latitude: values.Latitude,
        CityId: values.CityId,
        DistrictId: values.DistrictId,
        Address: values.Address,
        Phone: values.Phone,
        Email: values.Email,
        Description: values.Description,
        SMS_Signature: values.SMS_Signature,
        OrderSalon: values.OrderSalon,
        Type: values.Type,
        OpeningDate: dayjs(values.OpeningDate),
        ManagerName: values.ManagerName,
        Fanpage: values.Fanpage,
        FanpageId: values.FanpageId,
        // Images: dataListImageORG, k cập nhật hình ảnh
        Publish: publish,
        SalonOTP: isSalonOTP,
        IsSalonHoiQuan: isGroupSalon
      }
      const response = await updateSalon(id, body)
      if (response) {
        notification.success({
          description: 'Bạn đã cập nhật dữ liệu của Salon',
          message: 'Thành công'
        })
      } else {
        notification.warning({
          description: 'Không thể thay đổi dữ liệu của Salon',
          message: 'Lỗi'
        })
      }
    } catch (error) {
      console.error('Error:', error)
      notification.error({
        description: error.message,
        message: 'Lỗi'
      })
    }
  }

  const onValuesChange = async (changedValues: any) => {
    //   else setIsSalon(true)
    // }
  }
  const [imageUrl, setImageUrl] = useState('')

  const addImageUpload = () => {
    const newImageInfo = {
      title: '',
      description: '',
      fileList: []
    }
    setImageList([...imageList, newImageInfo])
  }

  const deleteImageUpload = (index) => {
    const updatedImageList = [...imageList]
    updatedImageList.splice(index, 1)
    setImageList(updatedImageList)
  }
  const handleTitleChange = (index, value) => {
    const updatedImageList = [...imageList]
    updatedImageList[index].title = value
    setImageList(updatedImageList)
  }

  const handleDescriptionChange = (index, value) => {
    const updatedImageList = [...imageList]
    updatedImageList[index].description = value
    setImageList(updatedImageList)
  }
  return (
    <div style={{ minHeight: 'calc(100vh)' }} className='m-auto pb-28 bg-[#F3F3F3] '>
      <Form
        key={JSON.stringify(initialValues)}
        className='xl:ml-[1.5%] 2xl:ml-[1.5%] custom-form-details'
        onValuesChange={onValuesChange}
        form={form}
        name='basic'
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={initialValues}
        onFinish={onFinish}
        autoComplete='off'
      >
        <h3 className='font-bold text-[22px] mt-3 ml-10 mb-8'>THÔNG TIN SALON</h3>
        <div className='custom-form-details-form'>
          <Form.Item
            label='Tên salon /Tên viết tắt:'
            name='Name'
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 16 }}
            rules={[
              {
                required: true,
                message: 'Không được để trống'
              }
            ]}
          >
            <div className='' style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Form.Item
                name='Name'
                style={{ width: 'calc(50% - 10px)' }}
                rules={[
                  {
                    required: true,
                    message: 'Không được để trống'
                  },
                  { max: 60, message: 'Không quá 50 ký tự' }
                ]}
              >
                <Input placeholder='Nhập tên Salon' style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item
                name='ShortName'
                style={{ width: 'calc(50% - 10px)' }}
                rules={[
                  {
                    required: true,
                    message: 'Không được để trống'
                  },
                  { max: 60, message: 'Không quá 50 ký tự' }
                ]}
              >
                <Input placeholder='Nhập tên viết tắt' style={{ width: '100%' }} />
              </Form.Item>
            </div>
          </Form.Item>
          <Form.Item label='SalonBackup /MacIP:' labelCol={{ span: 4 }} wrapperCol={{ span: 16 }} name=''>
            <div className='' style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Form.Item name='' style={{ width: 'calc(50% - 10px)' }}>
                <Select
                  showSearch
                  optionFilterProp='children'
                  filterOption={(input, option) => (option?.name ?? '').includes(input)}
                  // filterSort={(optionA, optionB) =>
                  //   (optionA?.name ?? '').toLowerCase().localeCompare((optionB?.name ?? '').toLowerCase())
                  // }
                  style={{ width: '100%' }}
                  // onChange={handleChange}
                  placeholder='Lựa chọn Salon Backup'
                  options={dataSelectSalon}
                  fieldNames={{ label: 'name', value: 'id' }}
                />
              </Form.Item>

              <Form.Item name='ImeiOrMacIP' style={{ width: 'calc(50% - 10px)' }}>
                <Input placeholder='Nhập Imei hoặc MacIP' style={{ width: '100%' }} />
              </Form.Item>
            </div>
          </Form.Item>
          <Form.Item label='Tọa độ:' name='' labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
            <div className='' style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Form.Item name='Longitude' style={{ width: 'calc(50% - 10px)' }}>
                <Input placeholder='Nhập kinh độ' style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item name='Latitude' style={{ width: 'calc(50% - 10px)' }}>
                <Input placeholder='Nhập vĩ độ' style={{ width: '100%' }} />
              </Form.Item>
            </div>
          </Form.Item>
          <Form.Item label='Địa chỉ:' name='' labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
            <div className='' style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
              <Form.Item label='' name='CityId' style={{ width: 'calc(50% - 10px)' }}>
                <Select
                  showSearch
                  optionFilterProp='children'
                  filterOption={(input, option) => (option?.name ?? '').includes(input)}
                  // filterSort={(optionA, optionB) =>
                  //   (optionA?.name ?? '').toLowerCase().localeCompare((optionB?.name ?? '').toLowerCase())
                  // }
                  style={{ width: '100%' }}
                  onChange={handleOnChangeCity}
                  placeholder='Lựa chọn Tỉnh/ thành'
                  options={dataCity}
                  fieldNames={{ label: 'name', value: 'id' }}
                />
              </Form.Item>

              <Form.Item label='' name='DistrictId' style={{ width: 'calc(50% - 10px)' }}>
                <Select
                  showSearch
                  optionFilterProp='children'
                  filterOption={(input, option) => (option?.name ?? '').includes(input)}
                  style={{ width: '100%' }}
                  onChange={handleOnChangeCity}
                  placeholder='Lựa chọn quận/ huyện'
                  options={dataDistricts}
                  // disabled={citySelect ? false : true}
                  fieldNames={{ label: 'name', value: 'id' }}
                />
              </Form.Item>
            </div>
            <Form.Item
              label=''
              name='Address'
              style={{ width: 'calc(100%)' }}
              rules={[
                {
                  required: true,
                  message: 'Không được để trống'
                }
              ]}
            >
              <Input placeholder='Nhập địa chỉ' />
            </Form.Item>
          </Form.Item>
          <Form.Item label='Số điện thoại /Email:' name='' labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
            <div className='' style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Form.Item label='' name='Phone' style={{ width: 'calc(50% - 10px)' }}>
                <Input placeholder='Nhập số điện thoại' style={{ width: '100%' }} />
              </Form.Item>

              <Form.Item label='' name='Email' style={{ width: 'calc(50% - 10px)' }}>
                <Input placeholder='Nhập địa chỉ email' style={{ width: '100%' }} />
              </Form.Item>
            </div>
          </Form.Item>
          <Form.Item label='Mô Tả:' name='Description' labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
            <TextArea maxLength={600} placeholder='Nhập mô tả cho salon' />
          </Form.Item>
          <Form.Item label='Chữ ký SMS:' name='SMS_Signature' labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
            <Input placeholder='Chữ ký SMS' />
          </Form.Item>
          <Form.Item label='Thứ tự/Loại salon:' name='' labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
            <div className='' style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Form.Item label='' name='OrderSalon' style={{ width: 'calc(50% - 10px)' }}>
                <Input placeholder='Ví dụ: 1' />
              </Form.Item>
              <Form.Item label='' name='Type' style={{ width: 'calc(50% - 10px)' }}>
                <Select
                  style={{ width: '100%' }}
                  placeholder='Lựa chọn loại Salon'
                  options={dataTypeSalon}
                  // fieldNames={{ label: 'name', value: 'id' }}
                />
              </Form.Item>
            </div>
          </Form.Item>
          <Form.Item label='Thời gian khởi tạo:' name='OpeningDate' labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
            <DatePicker placeholder='Chọn thời gian' style={{ width: 'calc(50% - 10px)' }} />
          </Form.Item>
          <Form.Item
            label='Thời gian chỉnh sửa cuối cùng:'
            name='ModifiedDate'
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 16 }}
          >
            <DatePicker disabled placeholder='Chọn thời gian' style={{ width: 'calc(50% - 10px)' }} />
          </Form.Item>
          <Form.Item label='Lựa chọn salon:' name='' labelCol={{ span: 4 }} wrapperCol={{ span: 16 }} className='mt-5'>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: 10 }}>Publish</span>
                <Switch defaultChecked={publish} onChange={() => setPublish(!publish)} />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', margin: '0 30px' }}>
                <span style={{ marginRight: 10 }}>Salon hội quán</span>{' '}
                <Switch defaultChecked={isSalonOTP} onChange={() => setIsSalonOTP(!isSalonOTP)} />
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: 10 }}>Salon OTP</span>{' '}
                <Switch defaultChecked={isGroupSalon} onChange={() => setIsGroupSalon(!isGroupSalon)} />
              </div>
            </div>
          </Form.Item>
          <div className='flex items-baseline gap-6 mt-5 mb-5'>
            <div className='w-[345px] text-lg text-[#000] text-right font-bold'>CẤU HÌNH CHO MOBILE APP</div>
          </div>
          <Form.Item label='Quản lý salon:' name='ManagerName' labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
            <Input placeholder='Tên quản lý Salon' style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item label='Fanpage:' name='Fanpage' labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
            <Input placeholder='Link Fanpage' style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item label='Fanpage ID:' name='FanpageId' labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
            <Input placeholder='VD: 3789100098941520' style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            label='Thêm ảnh Salon'
            name='idNo'
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 16 }}
            className='mt-8'
          >
            {imageList.map((item, index) => {
              return (
                <div key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Space direction='vertical' style={{ width: '80%' }}>
                    <Form.Item label={`Tiêu đề ${index + 1}`} name={['images', index, 'title']}>
                      <Input value={item.title} onChange={(e) => handleTitleChange(index, e.target.value)} />
                    </Form.Item>
                    <Form.Item label={`Mô tả ${index + 1}`} name={['images', index, 'description']}>
                      <Input.TextArea
                        value={item.description}
                        onChange={(e) => handleDescriptionChange(index, e.target.value)}
                        style={{ marginLeft: 10, width: '100%' }}
                      />
                    </Form.Item>
                    <Form.Item label={`Hình ảnh ${index + 1}`}>
                      <UploadCustom
                        setImageUrl={setImageUrl}
                        fileList={item.fileList}
                        setFileList={(newFileList) => {
                          const updatedImageList = [...imageList]
                          updatedImageList[index].fileList = newFileList
                          setImageList(updatedImageList)
                        }}
                        classCustomAdd=''
                        classCustomDetail=''
                      />
                    </Form.Item>
                  </Space>
                  <Button type='dashed' icon={<DeleteOutlined />} onClick={() => deleteImageUpload(index)}>
                    Delete
                  </Button>
                </div>
              )
            })}
            <Button type='primary' icon={<PlusOutlined />} onClick={addImageUpload}>
              Thêm ảnh
            </Button>
          </Form.Item>
        </div>
        <Form.Item label='' name=''>
          <Button
            className='mt-16 mb-12 bg-[#4096ff] text-white button-submit'
            type='primary'
            htmlType='submit'
            // disabled={loading}
          >
            {!loading ? 'Cập nhật' : <Spin />}
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}
