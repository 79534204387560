import { StaffRequest, StaffRequestView, AuthorRequest } from 'utils/ManagementEndpointRequest/TimelineSocket'
import {
  ParamsSearchType,
  PayloadCreate,
  PayloadUpdateWorking,
  PayloadUpdatePermission,
  PayloadUpdateEmployee
} from './interface'
export const convertParamsAxios = (params: any) => {
  const queryString = Object.keys(params)
    .map((key) => {
      const value = params[key]
      if (Array.isArray(value)) {
        return value.map((item) => `${key}=${item}`).join('&')
      }
      return value !== undefined ? `${key}=${value}` : undefined
    })
    .filter((i) => i !== undefined)
    .join('&')
  return queryString
}
export const convertQueryStringToObject = (queryString) => {
  const params = {}

  if (queryString) {
    const pairs = queryString.split('&')

    pairs.forEach((pair) => {
      const [key, value] = pair.split('=')
      const newValue = Number.isInteger(Number(value)) ? parseInt(value, 10) : value
      if (key && value) {
        if (['PageSize', 'PageIndex', 'Keyword', 'groupId'].includes(key)) {
          params[key] = decodeURIComponent(newValue)
        }
        // eslint-disable-next-line no-prototype-builtins
        else if (params.hasOwnProperty(key)) {
          if (!Array.isArray(params[key])) {
            params[key] = [params[key]]
          }
          params[key].push(newValue)
        } else {
          params[key] = [newValue]
        }
      }
    })
  }

  return params
}
export const getStaffType = (groupId: number) => {
  return StaffRequestView.get(`/v1/staff/types?groupId=${groupId}`)
}
export const getStaffSkillLevel = () => {
  return StaffRequestView.get(`/v1/staff/skill-levels`)
}
export const getCity = () => {
  return StaffRequestView.get(`/v1/cities`)
}
export const getDistrict = (idCity: number) => {
  return StaffRequestView.get(`/v1/city/${idCity}/districts`)
}
export const getWard = (idDistrict: number) => {
  return StaffRequestView.get(`/v1/district/${idDistrict}/ward`)
}
export const getRecruitmentSource = () => {
  return StaffRequestView.get(`/v1/staff/recruitment-source`)
}
export const getPermission = () => {
  return StaffRequestView.get(`/v1/staff/permission`)
}
export const getProfileType = () => {
  return StaffRequestView.get(`/v1/staff/profile-types`)
}
export const getContractType = () => {
  return StaffRequestView.get(`/v1/staff/contract-types`)
}
export const getASMS = () => {
  return StaffRequestView.get(`/v1/asms`)
}
export const getManagers = () => {
  return StaffRequestView.get(`/v1/staff/managers?limit=1000`)
}
export const createStaff = (payload: PayloadCreate) => {
  return StaffRequest.post(`/v1/staffs`, payload)
}
export const getDetailStaff = (id: string) => {
  return StaffRequest.get(`/v1/staff/${id}/detail`)
}
export const getWorkingStaff = (id: string) => {
  return StaffRequest.get(`/v1/staff/${id}/working`)
}
export const getPermissionStaff = (id: string) => {
  return StaffRequest.get(`/v1/staff/${id}/permission`)
}

export const switchMFAAuthentication = (id: string, requiredValid2FA: boolean) => {
  return StaffRequest.put(`/v1/staff/${id}/toggle-mfa-preference`, requiredValid2FA)
}

export const getListStaff = (params: ParamsSearchType) => {
  return StaffRequest.get(`/v1/staffs?${convertParamsAxios(params)}`)
}
export const editWorkingStaff = (id: string, payload: PayloadUpdateWorking) => {
  return StaffRequest.put(`/v1/staff/${id}/working`, payload)
}
export const editDetailStaff = (id: string, payload: PayloadUpdateEmployee) => {
  return StaffRequest.put(`/v1/staff/${id}/detail`, payload)
}
export const editPermissionStaff = (id: string, payload: PayloadUpdatePermission) => {
  return StaffRequest.put(`/v1/staff/${id}/permission`, payload)
}
export const switchSalonStaff = (
  id: string,
  idSalonNew: string,
  payload: {
    id: string
    idSalonNew: string
    newSalonName: string
  }
) => {
  return StaffRequest.put(`/v1/staff/${id}/salon/${idSalonNew}`, payload)
}
export const exportCSVListStaff = (params: ParamsSearchType) => {
  return StaffRequestView.get(`/v1/staff/excel?${convertParamsAxios(params)}`)
}
export const resetPassword = (id: string, staffId: number) => {
  return AuthorRequest.get(`/api/login/generate-password/${id}?staffId=${staffId}`)
}

export const getLogHistory = (id: string) => {
  return StaffRequestView.get(`/v1/staff/${id}/logs`)
}
export const getLogWorkingHistory = (id: string, type: number) => {
  return StaffRequestView.get(`/v1/staff/${id}/working-logs/${type}`)
}
export const getListStaffChange = (params: ParamsSearchType) => {
  return StaffRequest.get(`/v1/staff/fluctuation?${convertParamsAxios(params)}`)
}
export const exportListStaffChange = (params: ParamsSearchType) => {
  return StaffRequest.get(`/v1/staff/fluctuation/export?${convertParamsAxios(params)}`)
}
export const getListDepartments = () => {
  return StaffRequestView.get('/v1/departments')
}
