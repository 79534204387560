import { Table } from 'antd'

const columns = [
    {
        title: 'Đội',
        dataIndex: 'group',
        key: 'group',
    },
    {
        title: 'Bộ phận',
        dataIndex: 'staffType',
        key: 'staffType',
    },
    {
        title: 'ID',
        dataIndex: 'staffId',
        key: 'staffId',
    },
    {
        title: 'Tên',
        dataIndex: 'staffName',
        key: 'staffName',
    },
    {
        title: 'Ca',
        dataIndex: 'workTime',
        key: 'workTime',
    },
    {
        title: 'Trạng thái',
        dataIndex: 'status',
        key: 'status',
        render: (value, record) => (
            <div dangerouslySetInnerHTML={{ __html: value }}>
            </div>
        ),
    },
    {
        title: 'Dịch vụ khách',
        dataIndex: 'services',
        key: 'services',
        render: (value, record) => (
            <div dangerouslySetInnerHTML={{ __html: value }}>
            </div>
        ),
    }
]
interface Props {
  staffInfoList: any,
}

export default function StaffTeamInfo(props: Props) {
  const { staffInfoList } = props

    return (
        <div>
            <div className='mt-5 text-xl'>THÔNG TIN NHÂN VIÊN THEO ĐỘI</div>
            <Table className='mt-2 ' scroll={{ x: 500 }} columns={columns} dataSource={staffInfoList} pagination={false} bordered />
        </div>
    )
}
