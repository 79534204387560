export const convertToNumberArray = (data) => {
  if (data === null || data === undefined || data === '') {
    return data
  }

  if (typeof data === 'string') {
    // Nếu dữ liệu là chuỗi, chuyển thành mảng số
    data = data.split(',').map(Number)
  } else if (!Array.isArray(data)) {
    // Nếu không phải là mảng, tạo mảng mới với một phần tử là data
    data = [data]
  }

  // Sử dụng forEach để kiểm tra và chuyển đổi các phần tử thành số
  data.forEach((element, index, array) => {
    if (element !== null && element !== undefined) {
      array[index] = Number(element)
    }
  })

  return data
}

export const convertToStringArray = (numberArray) => {
  if (numberArray === null || numberArray === undefined) {
    return numberArray
  }

  if (!Array.isArray(numberArray)) {
    // Nếu không phải là mảng, tạo mảng mới với một phần tử là numberArray
    numberArray = [numberArray]
  }

  // Sử dụng phương thức map để chuyển đổi mỗi số thành chuỗi
  // và sau đó sử dụng join để nối chuỗi với dấu phẩy
  return numberArray.map(String).join(',')
}
