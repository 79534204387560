/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { FunnelPlotOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, InputNumber, notification, Pagination, Row, Select, Spin } from "antd";
import { useEffect, useState } from "react";
import TableRequest from "./component/tableRequest";
import { convertParamsAxios, convertQueryStringToObject, getListRequest } from "./api";
import { useNavigate } from "react-router-dom";
import ModalPopupAddRequest from "./component/modalAddRequest";


const ChangeShineMember = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState<boolean>(false)
    const [data, setData] = useState([])
    const [totalItems, setTotalItems] = useState(0)
    const [form] = Form.useForm()
    const [permissionConfirm, setPermissionConfirm] = useState(false)
    const [params, setParams] = useState<any>({
        pageSize: 10,
        page: 1
    })

    const onFinish = () => {
        navigate(
            `/change-shine-member?${convertParamsAxios({
                ...params,
                phone: params?.phone,
                confirm: params?.confirm !== undefined ? Number(params?.confirm) : undefined,
                pageSize: params?.pageSize,
                page: params?.page,
            })}`
        )
    }

    const getDataByParamsSearch = () => {
        const paramsSearchResult: any = convertQueryStringToObject(window.location.search.replace('?', ''))
        if (Object.keys(paramsSearchResult).length > 0) {
            const dataSearch = {
                phone: paramsSearchResult?.phone ? paramsSearchResult?.phone[0] : undefined,
                confirm: paramsSearchResult?.confirm ? Number(paramsSearchResult?.confirm[0]) : undefined,
                pageSize: paramsSearchResult?.pageSize ? Number(paramsSearchResult?.pageSize[0]) : 10,
                page: paramsSearchResult?.page ? Number(paramsSearchResult?.page[0]) : 1
            }
            getListRequestData(dataSearch)
        }
    }

    const getListRequestData = async (dataSearch) => {
        if (loading) return
        setLoading(true)
        try {
            const response: any = await getListRequest(dataSearch)
            if (response?.data?.data) {
                const newData = response?.data?.data.map((item: any, index: number) => ({
                    ...item,
                    index: index,
                }))
                setTotalItems(response?.data?.total)
                setPermissionConfirm(response?.data?.permissionConfirm)
                setParams(dataSearch)
                setData(newData)
                setLoading(false)
            }
        } catch (error) {
            notification.error({
                message: 'Thông báo!',
                description: <>Lỗi không lấy được Danh sách lương cơ bản!</>
            })
            console.error('Error:', error.message)
            setLoading(false)
        }
    }

    useEffect(() => {
        if (window.location.search) {
            getDataByParamsSearch()
        }
    }, [window.location.search])

    return (
        <div>
            <Spin tip='Đang xử lý dữ liệu...' spinning={loading}>
                <div className='w-full px-5 py-5 search-wrapper'>
                    <div className="flex flex-row justify-between">
                        <div className='text-2xl font-semibold max-sm:pl-2'>Danh sách yêu cầu</div>
                        <div>
                            <ModalPopupAddRequest />
                        </div>
                    </div>
                    <div className='mt-3 bg-white ' style={{ padding: '20px 20px 100px 20px' }}>
                        <Form className='pb-10' form={form} onFinish={onFinish}>
                            <Row className="flex items-center">
                                <div className="flex items-start">
                                    <FunnelPlotOutlined className="flex items-center text-lg mr-2" />
                                    Lọc điều kiện
                                </div>
                                <div className={'flex flex-row gap-x-3 '}>

                                    <Form.Item label='' >
                                        <Select
                                            style={{ width: 150 }}
                                            placeholder='Chọn trạng thái'
                                            allowClear
                                            value={params?.confirm !== undefined ? Number(params?.confirm) : undefined}
                                            onChange={(e) => {
                                                setParams({ ...params, ['confirm']: e })
                                            }}
                                            options={[
                                                {
                                                    value: 1,
                                                    label: 'Đã duyệt'
                                                },
                                                {
                                                    value: 2,
                                                    label: 'Không duyệt'
                                                },
                                                {
                                                    value: 0,
                                                    label: 'Chưa duyệt'
                                                }
                                            ]}
                                        />
                                    </Form.Item>

                                    <Form.Item label='' >
                                        <Input
                                            style={{ width: 200 }}
                                            maxLength={10}
                                            placeholder='Nhập SĐT khách'
                                            value={params?.phone || undefined}
                                            onChange={(e) => {
                                                setParams({ ...params, ['phone']: e.target.value })
                                            }}
                                        />
                                    </Form.Item>
                                    <Button
                                        type='default'
                                        htmlType='submit'
                                        className='w-[200px] bg-[#4096ff] text-white hover:bg-white'
                                        disabled={loading}
                                    >
                                        <div className='flex items-center justify-center hover_icon'>
                                            Xem dữ liệu
                                        </div>
                                    </Button>
                                </div>
                            </Row>
                            <TableRequest ListData={data} params={params} permissionConfirm={permissionConfirm}/>
                            <div className='grid mt-8 justify-items-end'>
                                <Pagination
                                    className=''
                                    current={params.page}
                                    pageSize={params.pageSize}
                                    total={totalItems}
                                    onChange={(page: number, pageSize: number) => {
                                        navigate(
                                            `/change-shine-member?${convertParamsAxios({
                                                ...params,
                                                pageSize: pageSize,
                                                page: params.pageSize !== pageSize ? 1 : page
                                            })}`
                                        )
                                        setTimeout(
                                            () =>
                                                window.document.getElementById('body__page').scrollTo({
                                                    top: 0,
                                                    behavior: 'smooth'
                                                }),
                                            500
                                        )
                                    }}
                                />
                            </div>
                        </Form>
                    </div>
                </div>
            </Spin>
        </div>
    )
}

export default ChangeShineMember


