import { useAppDispatch, useAppSelector } from 'redux/store'
import { Button, Form, Input, Radio, Select, Spin, Modal } from 'antd'
import { editPermissionStaff, getPermissionStaff, resetPassword, switchMFAAuthentication } from '../api'
import { useContext, useEffect, useState } from 'react'
import { antIcon } from '../list/page'
import { PayloadUpdatePermission } from '../interface'
import { setStaff } from '../staffSlice'
import { AppContext } from 'layouts/DashboardLayout'
import { useParams } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { removeMFAByUser } from 'api/users'

type Props = {
  tab: string
}
export const MFA = ({ tab }: Props) => {
  const [form] = Form.useForm()
  const { notification } = useContext(AppContext)
  const [open, setOpen] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch()
  const { id } = useParams()
  const isMobile = useMediaQuery({
    query: '(max-width: 500px)'
  })
  const [mfaStatus, setMfastatus] = useState(false)
  // staff bat buoc phai dung MFA
  const [isStaffTypeRequireMFA, setIsStaffTypeRequireMFA] = useState(false)

  const { resource, staffDetail }: any = useAppSelector((state) => state.staff)

  const [staffTypeName, setStaffTypeName] = useState('')

  const removeMFA = async () => {
    if (!staffDetail?.permission?.userName) {
      notification('error', 'Lỗi', 'Không tìm thấy thông tin người dùng')
      return
    }

    try {
      setConfirmLoading(true)
      const res: any = await removeMFAByUser(staffDetail.permission.userName)
      if (res.statusCode === 200) {
        notification('success', 'Thành công', res.message || 'Tạo lại mã xác thực thành công')
      } else {
        notification('error', 'Thất bại', res.message)
      }
      setConfirmLoading(false)
      setOpen(false)
    } catch (error) {
      notification('error', 'Thất bại', 'Tạo lại mã xác thực thất bại')
      setConfirmLoading(false)
      setOpen(false)
    }
  }

  const switchMFA = async () => {
    if (!staffDetail?.permission?.userName) {
      notification('error', 'Lỗi', 'Không tìm thấy thông tin người dùng')
      return
    }

    try {
      setConfirmLoading(true)
      const newStatus = !mfaStatus
      const res: any = await switchMFAAuthentication(staffDetail.working.staffId, newStatus)
      if (res.statusCode === 0) {
        dispatch(
          setStaff({
            ...staffDetail,
            permission: {
              ...staffDetail.permission,
              requiredValid2FA: newStatus
            }
          })
        )
      }
      if (res.statusCode === 0) {
        notification('success', 'Thành công', res.message || 'Kích hoạt thành công')
      } else {
        notification('error', 'Thất bại', res.message)
      }
      setConfirmLoading(false)
      setOpen(false)
    } catch (error) {
      notification('error', 'Thất bại', 'Kích hoạt thất bại')
      setConfirmLoading(false)
      setOpen(false)
    }
  }

  const handleFetchStaff = async () => {
    const permission = await getPermissionStaff(id as string)
    dispatch(
      setStaff({
        ...staffDetail,
        permission: permission.data
      })
    )
  }

  useEffect(() => {
    handleFetchStaff()
  }, [])

  useEffect(() => {
    if (!staffDetail) return

    const workingData = staffDetail.working
    const permissionData = staffDetail.permission
    if (!permissionData || !workingData) return

    // cap nhat thong tin quyen "bao mat"
    const staffTypeId = workingData.staffTypeId || workingData.jobPosition[0].staffTypeId
    const isRequire = permissionData.staffTypeRequiredMFA
      ? permissionData.staffTypeRequiredMFA.includes(staffTypeId)
      : false
    setIsStaffTypeRequireMFA(isRequire)
    if (isRequire) {
      setMfastatus(isRequire)
    } else setMfastatus(permissionData.requiredValid2FA || false)

    if (resource.staffTypeId && resource.staffTypeId2) {
      const staffName =
        [...resource.staffTypeId, ...resource.staffTypeId2].find((i) => i.id === staffTypeId)?.name || ''
      setStaffTypeName(staffName)
    }
  }, [resource, staffDetail])

  useEffect(() => {
    form.setFieldsValue({
      userName: staffDetail.working?.userName,
      idName: staffDetail.working?.idName,
      groupId: staffDetail.working?.groupId || undefined,
      staffTypeId: staffDetail.working?.staffTypeId || undefined,
      permission: staffDetail.permission?.permission ? staffDetail.permission?.permission.filter((i) => i) : [],
      isAccountLogin: staffDetail.permission?.isAccountLogin
    })
  }, [staffDetail, tab])
  useEffect(() => {
    const layout__header = window.document.getElementById('layout__header')
    if (layout__header) {
      if (open) layout__header.style.zIndex = '999'
      else layout__header.style.zIndex = '9999'
    }
  }, [open])
  return (
    <>
      {isMobile ? (
        <Form
          form={form}
          name='basic'
          initialValues={{ remember: true }}
          autoComplete='off'
          style={{ width: 'calc(100vw - 40px)' }}
        >
          <div className='mt-2'>
            <div className='erp_role'>
              <div className='w-[200px] text-[#000] text-left font-bold text-xl'>Phân quyền ERP</div>
              <div className='w-[700px] text-xs text-[#898989] mb-5'>
                <span className='text-[red]'>(*)</span> : Bắt buộc điền thông tin!
              </div>
              <div>
                <Form.Item label='Tên đăng nhập:' name='userName'>
                  <span>{staffDetail.working?.userName}</span>
                </Form.Item>
                <Form.Item label='Họ và tên' name='idName'>
                  <span>{staffDetail.working?.idName}</span>
                </Form.Item>
                <Form.Item label='Khối:' name='groupId'>
                  <span>{resource.groupId.find((i) => i.id === staffDetail.working?.groupId).name || ''}</span>
                </Form.Item>
                <Form.Item label='Bộ phận:' name='staffTypeId'>
                  <span>{staffTypeName}</span>
                </Form.Item>
                <Form.Item label='Trạng thái bảo mật 2 lớp (MFA):' name='requiredValid2FA'>
                  <span>{isStaffTypeRequireMFA || mfaStatus ? 'Đã kích hoạt' : 'Chưa kích hoạt'}</span>
                  {isStaffTypeRequireMFA && (
                    <p style={{ color: 'red' }}>
                      <i>Tài khoản ASM không được hủy kích hoạt bảo mật 2 lớp</i>
                    </p>
                  )}
                </Form.Item>
              </div>
              <div className='flex justify-between w-[70%] float-right'>
                <Button className='mt-5 mb-5 bg-[#FEA800] hover:bg-white text-white' onClick={() => setOpen(true)}>
                  <span>{mfaStatus ? 'Hủy kích hoạt' : 'Kích hoạt'}</span>
                </Button>
                <Button
                  className='w-[90px] mt-5 mb-5 bg-[#4096ff] text-white'
                  type='primary'
                  htmlType='submit'
                  disabled={loading}
                >
                  {!loading ? 'Hoàn tất' : <Spin className='text-white' indicator={antIcon} />}
                </Button>
              </div>
            </div>
          </div>
          <Modal
            centered
            title='Kích hoạt'
            open={open}
            onOk={switchMFA}
            confirmLoading={confirmLoading}
            onCancel={() => setOpen(false)}
            okText='Hoàn tất'
            cancelText='Hủy'
          >
            <p>Xác nhận {mfaStatus ? 'hủy ' : ''}kích hoạt</p>
          </Modal>
        </Form>
      ) : (
        <Form
          form={form}
          name='basic'
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 17 }}
          style={{ maxWidth: 900, margin: 'auto' }}
          initialValues={{ remember: true }}
          autoComplete='off'
          className='float-left'
        >
          <div className='mt-14'>
            <div className='erp_role'>
              <div className='flex items-center gap-6 '>
                <div className='w-[220px] ml-[10px] mb-4 text-lg text-[#000] text-right font-bold'>
                  <div className='flex items-center justify-end gap-x-3'>
                    <img width={20} height={20} src='/edit.png' alt='icon_edit' />
                    Bảo mật
                  </div>
                </div>
                <div className='w-[700px] text-xs text-[#898989] mb-4'>
                  <span className='text-[red] '>(*)</span> : Bắt buộc điền thông tin!
                </div>
              </div>
              <div>
                <Form.Item label='Tên đăng nhập:' name='userName'>
                  <span>{staffDetail.working?.userName}</span>
                </Form.Item>
                <Form.Item label='Họ và tên' name='idName'>
                  <span>{staffDetail.working?.idName}</span>
                </Form.Item>
                <Form.Item label='Khối:' name='groupId'>
                  <span>{resource.groupId.find((i) => i.id === staffDetail.working?.groupId).name || ''}</span>
                </Form.Item>
                <Form.Item label='Bộ phận:' name='staffTypeId'>
                  <span>{staffTypeName}</span>
                </Form.Item>
                <Form.Item label='Trạng thái bảo mật 2 lớp (MFA):' name='requiredValid2FA'>
                  <span>{isStaffTypeRequireMFA || mfaStatus ? 'Đã kích hoạt' : 'Chưa kích hoạt'}</span>
                  {isStaffTypeRequireMFA && (
                    <p style={{ color: 'red' }}>
                      <i>Tài khoản ASM không được hủy kích hoạt bảo mật 2 lớp</i>
                    </p>
                  )}
                </Form.Item>
              </div>
              <div className='flex justify-between w-[70%] float-right'>
                <Button
                  className='mt-16 mb-12 bg-[#FEA800] hover:bg-white text-white'
                  onClick={() => setOpen(true)}
                  disabled={isStaffTypeRequireMFA && mfaStatus}
                >
                  <span>{mfaStatus ? 'Hủy kích hoạt' : 'Kích hoạt'}</span>
                </Button>
                <Button
                  className='mt-16 mb-12 bg-[#4096ff] text-white'
                  type='primary'
                  disabled={loading}
                  onClick={removeMFA}
                >
                  {!loading ? 'Tạo lại mã xác thực' : <Spin className='text-white' indicator={antIcon} />}
                </Button>
              </div>
            </div>
          </div>
          <Modal
            centered
            title='Kích hoạt'
            open={open}
            onOk={switchMFA}
            confirmLoading={confirmLoading}
            onCancel={() => setOpen(false)}
            okText='Hoàn tất'
            cancelText='Hủy'
          >
            <p>Xác nhận {mfaStatus ? 'hủy ' : ''}kích hoạt</p>
          </Modal>
        </Form>
      )}
    </>
  )
}
