import { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import { getListStaffChange } from 'pages/staff/api'
import FormComponent from 'pages/base/Form'
import { ColumnConfig, SearchConfig, TypeSearch } from 'pages/base/util'
import { AppContext } from 'layouts/DashboardLayout'
import { getCity } from './api'
import { Button, Table } from 'antd'
import { getSalons } from 'api/catalog'

export const ProcessCSVC = () => {
  const { notification } = useContext(AppContext)
  const [reload, setReload] = useState(false)
  const [salons, setSalons] = useState([])
  const [cities, setCities] = useState([])
  const [type, setType] = useState(false)

  const column: ColumnConfig[] = [
    {
      title: 'STT',
      dataIndex: 'index',
      key: 'index'
    },
    {
      title: 'Salon',
      dataIndex: 'Salon',
      key: 'Salon'
    },
    {
      title: 'Tổng',
      dataIndex: 'total',
      key: 'total'
    },
    {
      title: 'Hệ thống điện',
      dataIndex: 'electric',
      key: 'electric'
    },

    {
      title: 'Hệ thống nước',
      dataIndex: 'water',
      key: 'water'
    },
    {
      title: 'Hệ thống đèn',
      dataIndex: 'light',
      key: 'light'
    },
    {
      title: 'Ghế, gương cắt',
      dataIndex: 'chair',
      key: 'chair'
    },

    {
      title: 'Trần, tường sàn, cửa',
      dataIndex: 'door',
      key: 'door'
    },
    {
      title: 'Giường gội, xả',
      dataIndex: 'bed',
      key: 'bed'
    },
    {
      title: 'Hút mụn',
      dataIndex: 'hm',
      key: 'hm'
    },
    {
      title: 'Điều hoà',
      dataIndex: 'ac',
      key: 'ac'
    },
    {
      title: 'Thang máy',
      dataIndex: 'elevator',
      key: 'elevator'
    },
    {
      title: 'WC',
      dataIndex: 'wc',
      key: 'wc'
    },
    {
      title: 'Lễ tân',
      dataIndex: 'receptionist',
      key: 'receptionist'
    },
    {
      title: 'Bình nóng lạnh',
      dataIndex: 'bnl',
      key: 'bnl'
    },
    {
      title: 'Máy giặt, sấy',
      dataIndex: 'wm',
      key: 'wm'
    },
    {
      title: 'Khu vực khác',
      dataIndex: 'other_area',
      key: 'other_area'
    },
    {
      title: 'Loa, Amly',
      dataIndex: 'amly',
      key: 'amly'
    },
    {
      title: 'Ổ cắm',
      dataIndex: 'plug',
      key: 'plug'
    },
    {
      title: 'CB/Công tắc',
      dataIndex: 'switch',
      key: 'switch'
    },
    {
      title: 'Máy sấy',
      dataIndex: 'ms',
      key: 'ms'
    },
    {
      title: 'Máy giặt',
      dataIndex: 'mg',
      key: 'mg'
    },
    {
      title: 'Hệ Thống Nước uống',
      dataIndex: 'water_system',
      key: 'water_system'
    },
    {
      title: 'Quầy kệ',
      dataIndex: 'counter',
      key: 'counter'
    },
    {
      title: 'Tủ đồ, giá để khăn',
      dataIndex: 'table',
      key: 'table'
    },
    {
      title: 'PCCC',
      dataIndex: 'pccc',
      key: 'pccc'
    }
  ]
  const columnSearch: SearchConfig[] = [
    {
      label: 'Thời gian',
      param: 'date',
      type: TypeSearch.date,
      defaultDate: true
    },
    {
      label: 'Chọn khu vực',
      param: 'city',
      mode: 'multiple',
      type: TypeSearch.select,
      options: cities,
      placeholder: 'trạng thái',
      fieldNames: { label: 'name', value: 'id' }
    },
    {
      label: 'Chọn salon',
      param: 'salonId',
      type: TypeSearch.select,
      mode: 'multiple',
      options: salons,
      placeholder: 'Chọn salon',
      fieldNames: { label: 'name', value: 'id' }
    }
  ]
  const handleChangeValue = (changedValues, onReset: (field: string[]) => void) => {
    if (changedValues?.salonId) {
      onReset(['statusName'])
    }
  }
  const handleFetchOptions = async () => {
    try {
      const [salons, cities]: any = await Promise.all([getSalons(), getCity()])
      if (salons.statusCode === 200 && cities.statusCode === 0) {
        setSalons(salons.data)
        setCities(cities.data)
      }
    } catch (error) {
      notification('error', 'Thất bại', 'Có lỗi! Vui lòng kiểm tra lại')
    }
  }
  useEffect(() => {
    handleFetchOptions()
  }, [])

  return (
    <div className='min-h-[100vh] m-auto bg-[#F3F3F3] pb-12'>
      <FormComponent
        currentUrl='/csvc/process'
        title='Tiến độ hoàn thành'
        column={column}
        columnSearch={columnSearch}
        fetchData={getListStaffChange}
        handleChangeValue={handleChangeValue}
        reload={reload}
        setReload={setReload}
        middleComponent={
          <div className='flex flex-col'>
            <Button type='dashed' className='w-[175px]' onClick={() => setType(!type)}>
              {!type ? 'Hiển thị bảng thống kê' : 'Ẩn bảng thống kê'}
            </Button>
            {type && (
              <Table
                className='w-[500px] mt-2 overflow-auto'
                dataSource={[
                  { salonName: '30Shine', total: 420, vdh: 385, tld: 85.2 },
                  { salonName: 'HCM', total: 420, vdh: 385, tld: 85.2 }
                ]}
                columns={[
                  {
                    title: '',
                    dataIndex: 'salonName',
                    key: 'salonName'
                  },
                  {
                    title: `Tổng sự cố ( Không bị huỷ )`,
                    dataIndex: 'total',
                    key: 'total'
                  },
                  {
                    title: 'Việc đúng hạn',
                    dataIndex: 'vdh',
                    key: 'vdh'
                  },
                  {
                    title: 'Tỉ lệ đúng (%)',
                    dataIndex: 'tld',
                    key: 'tld'
                  }
                ]}
                pagination={false}
                bordered
              />
            )}
          </div>
        }
      />
    </div>
  )
}
