export const BIReportSubmitRevenuePage = () => {
  return (
    <div className={`h-[calc(100vh-64px)]`}>
      <iframe
        title='Báo cáo HD nộp doanh thu'
        src='https://app.powerbi.com/view?r=eyJrIjoiNDNhNDUxNjktZmQyMi00YWMzLWI2MzYtZTZkNWQwZjBkYTUxIiwidCI6ImZkNjE5MTU3LWM5MGItNDUwZC1hODI5LTg1OTE2YmExNmRhMyIsImMiOjEwfQ%3D%3D'
        className='!block w-full h-full'
        frameBorder='0'
      ></iframe>
    </div>
  )
}
