export const BIReportPerformancePage = () => {
  return (
    <div className={`h-[calc(100vh-64px)]`}>
      <iframe
        title='Năng suất'
        src='https://app.powerbi.com/view?r=eyJrIjoiMTc0OTEyNzktODc3MS00ZDViLThhOWQtNjFhYzFhZGQ0ZDgwIiwidCI6ImZkNjE5MTU3LWM5MGItNDUwZC1hODI5LTg1OTE2YmExNmRhMyIsImMiOjEwfQ%3D%3D'
        className='!block w-full h-full'
        frameBorder='0'
      ></iframe>
    </div>
  )
}
