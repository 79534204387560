/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Table, { ColumnsType, TableProps } from 'antd/es/table'

interface Props {
    data: any,
    levelList: any
}

const TabelPreViewFile = (props: Props) => {
    const { data, levelList } = props

    const columns: ColumnsType<any> = [
        {
            title: 'STT',
            dataIndex: 'index',
            key: 'index',
        },
        {
            title: 'ID nhân sự',
            dataIndex: 'staffId',
            key: 'staffId',
        },
        {
            title: 'Vị trí',
            dataIndex: 'departmentName',
            key: 'departmentName',
        },
        {
            title: 'Họ và tên',
            dataIndex: 'staffName',
            key: 'staffName',
        },
        {
            title: 'Salon',
            dataIndex: 'salonName',
            key: 'salonName',
        },
        {
            title: 'Level hiện tại',
            dataIndex: 'staffLevel',
            key: 'staffLevel',
            render: (record) => (
                <div>
                    {levelList && levelList.filter((item: any) => item.id === Number(record))[0]?.name}
                </div>
            ),
        },
        {
            title: 'Level lên',
            dataIndex: 'levelId',
            key: 'levelId',
            render: (record) => (
                <div>
                    {levelList && levelList.filter((item: any) => item.id === Number(record))[0]?.name}
                </div>
            ),
        },
    ]
    const onChange: TableProps<any>['onChange'] = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra)
    }

    return (
        <div
            className='mt-5 bg-white'
            style={{ boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px', borderRadius: 10 }}
        >
            <Table columns={columns} dataSource={data} onChange={onChange} bordered />

        </div>
    )
}
export default TabelPreViewFile
