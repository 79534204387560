/* eslint-disable @typescript-eslint/ban-ts-comment */
'use client'
import '../index.scss'
import {
  Button,
  TreeSelect,
  Table,
  Form,
  Input,
  Radio,
  Select,
  Spin,
  message,
  Upload,
  Checkbox,
  InputNumber,
  notification
} from 'antd'
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { AppContext } from 'layouts/DashboardLayout'
import { DeleteOutlined, PlusOutlined, PlusCircleOutlined } from '@ant-design/icons'
import UploadCustom from 'utils/upload/index'
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface'
import { createServices, GetListServices, getDetailServices, getListTreeServicesV2, GenCodeService } from '../api'
import { removeVietnamese } from '../../../utils/selector/removeVietnamese'

const { TextArea } = Input
export const AddServicePage: React.FC = () => {
  const [initialValues, setInitialValues] = useState<any>()
  const navigate = useNavigate()
  const { id } = useParams()
  const [form] = Form.useForm()
  const [code, setCode] = useState<any>()
  const [IsPrimary, setIsPrimary] = useState<boolean>(true)
  const [ShowOnApp, setShowOnApp] = useState<boolean>(false)
  const [IsCheckKCS, setIsCheckKCS] = useState<boolean>(false)
  const [CheckCombo, setCheckCombo] = useState<boolean>(false)
  const [IsCourse, setIsCourse] = useState<boolean>(false)
  const [IsCheckCurling, setIsCheckCurling] = useState<boolean>(false)
  const [Publish, setPublish] = useState<boolean>(true)
  const [Status, setStatus] = useState<boolean>(true)
  const [IsFreeService, setIsFreeService] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)
  const [ConfigDepartment, setConfigDepartment] = useState<number>(0)
  const [ServiceType, setServiceType] = useState<string>()
  const [dataVideoURL, setDataVideoURL] = useState<any>()
  const [dataVideoTitle, setDataVideoTitle] = useState<any>()
  const [dataVideoDescription, setDataVideoDescription] = useState<any>()
  const [listMapingServices, setListMapingServices] = useState<any[]>([])
  const [selectedServices, setSelectedServices] = useState([])
  const [dataSelectServices, setDataListServices] = useState<any[]>([])
  const [MaintainId, setMaintainId] = useState(null)
  const handleListServices = async () => {
    try {
      const response: any = await GetListServices()
      const { statusCode, data } = response
      if (statusCode === 0) {
        setDataListServices(data)
      }
    } catch (error) {
      console.error('Error:', error.message)
    }
  }
  const genCateChildren = (itemChild: any[], prefix: string) => {
    return itemChild.map((item) => {
      if (item.children && item.children.length > 0) {
        return {
          title: item.title,
          key: prefix + item.value,
          value: genCateChildren(item.children, 'child')
        }
      } else
        return {
          title: item.title,
          value: prefix + item.value
        }
    })
  }
  const handleListTreeServices = async () => {
    try {
      const response: any = await getListTreeServicesV2()
      const { statusCode, data } = response
      if (statusCode === 200) {
        const dataCate = data.map((item) => {
          if (item.children && item.children.length > 0) {
            return {
              title: item.title,
              value: 'parent-' + item.value,
              children: genCateChildren(item.children, 'child-')
            }
          } else
            return {
              title: item.title,
              value: 'parent-' + item.value
            }
        })
        setListTreeServices(dataCate)
      }
    } catch (error) {
      console.error('Error:', error.message)
    }
  }

  const handleCreateCode = async () => {
    const responseCode: any = await GenCodeService()
    if (responseCode.statusCode === 200) {
      setCode(responseCode.data)
    } else {
      notification.error({
        description: responseCode.message,
        message: 'Lỗi'
      })
    }
  }

  useEffect(() => {
    handleListServices()
    handleListTreeServices()
    handleCreateCode()
  }, [])
  function extractNumbers(inputString) {
    // Sử dụng regular expression để tìm và lấy số đầu tiên từ chuỗi
    const match = inputString.match(/\d+/)

    // Kiểm tra nếu có số được tìm thấy
    if (match) {
      // Chuyển đổi số từ chuỗi thành số nguyên và trả về
      return Number(match[0])
    } else {
      return null // Trả về null nếu không tìm thấy số nào
    }
  }
  const onFinish = async (values: any) => {
    console.log('Success:', values)
    if (!valueTreeServices) {
      return notification.warning({
        description: 'Danh mục dịch vụ không thể bỏ trống !',
        message: 'Thiếu dữ liệu'
      })
    }
    if (!ServiceType) {
      return notification.warning({
        description: 'Loại dịch vụ không thể bỏ trống !',
        message: 'Thiếu dữ liệu'
      })
    }
    try {
      const Videos = JSON.stringify([{ url: dataVideoURL, title: dataVideoTitle, description: dataVideoDescription }])
      let MapIdService = null

      if (listMapingServices) {
        // const Ids = listMapingServices.map((service) => service.Id)
        // MapIdService = Ids.join(',')
        MapIdService = listMapingServices.map((service) => service.Id)
      }

      const body = {
        Name: values.Name,
        Code: code,
        Description: values.Description,
        Price: values.Price,
        VoucherPercent: values.VoucherPercent,
        // CoefficientRating: values.CoefficientRating,
        StylistTimeEstimate: Number(values.StylistTimeEstimate),
        SkinnerTimeEstimate: Number(values.SkinnerTimeEstimate),
        Order: values.Order,
        CategoryId: extractNumbers(valueTreeServices),
        // Images: dataListImageORG, k cập nhật hình ảnh
        isCombo: CheckCombo,
        ConfigDepartment: ConfigDepartment,
        Status: Status,
        IsFreeService: IsFreeService,
        Publish: Publish,
        IsPrimary: IsPrimary,
        ShowOnApp: ShowOnApp,
        ServiceType: ServiceType,
        IsCheckKCS: IsCheckKCS,
        IsCheckCurling: IsCheckCurling,
        IsCourse: IsCourse,
        Videos: Videos,
        serviceCombo: MapIdService,
        MaintainId: MaintainId
      }
      // console.log(body, 'body')
      const { data, message, statusCode }: any = await createServices(body)
      if (statusCode === 200) {
        notification.success({
          description: 'Bạn tạo Dịch vụ mới thành công',
          message: 'Thành công'
        })
        navigate(`/service/detail/${data.id}`)
      } else {
        notification.warning({
          description: 'Không thể tạo Dịch vụ mới',
          message: 'Lỗi'
        })
      }
    } catch (error) {
      console.error('Error:', error)
      notification.error({
        description: error.message,
        message: 'Lỗi'
      })
    }
  }
  const onValuesChange = async (changedValues: any) => {
    console.log('oke')
  }
  const handleChangeConfigDepartment = (value: number) => {
    setConfigDepartment(value)
  }
  const handleServiceType = (value: string) => {
    setServiceType(value)
  }
  const handleChangeServices = (value: string[]) => {
    setSelectedServices(value)
  }
  const columns = [
    {
      title: 'STT',
      key: 'index',
      render: (item, index, i) => {
        return <span>{i + 1}</span>
      }
    },
    {
      title: 'Tên dịch vụ',
      dataIndex: 'Name',
      key: 'Name'
    },
    {
      title: 'Mã dịch vụ',
      dataIndex: 'Code',
      key: 'Code'
    },
    {
      title: 'Giá',
      dataIndex: 'Price',
      key: 'Price'
    },
    {
      title: 'Xoá',
      key: 'Price',
      render: (item, index, i) => (
        <Button onClick={() => handleDeleteListMappingServices(i)}>
          <DeleteOutlined />
        </Button>
      )
    }
  ]
  const handleDeleteListMappingServices = (index) => {
    const newListMapping = listMapingServices.filter((item, i) => i !== index)
    setListMapingServices(newListMapping)
  }
  const handleAddListMappingServices = () => {
    if (!selectedServices || selectedServices.length === 0) {
      return
    }

    // Lọc và thêm các dịch vụ đã chọn vào danh sách ánh xạ
    const itemsMappingServices = selectedServices.map((selectedServiceId) => {
      const serviceMapping = dataSelectServices.find((obj) => obj.Id === selectedServiceId)

      if (serviceMapping) {
        return {
          Id: serviceMapping.Id,
          Name: serviceMapping.Name,
          Code: serviceMapping.Code,
          Price: serviceMapping.Price
        }
      }

      return null
    })

    // Lọc ra các mục không null (các dịch vụ hợp lệ) và thêm vào danh sách ánh xạ
    const validItemsMappingServices = itemsMappingServices.filter((item) => item !== null)

    if (validItemsMappingServices.length > 0) {
      if (listMapingServices) {
        setListMapingServices([...listMapingServices, ...validItemsMappingServices])
      } else {
        setListMapingServices(validItemsMappingServices)
      }
    }
    // sau khi ấn thêm xong thì sẽ đưa thẻ selector về giá trị ban đầu
    setSelectedServices([])
  }
  const [valueTreeServices, setValueTreeServices] = useState<string>()
  const [listTreeServices, setListTreeServices] = useState<any[]>([])
  const onChangeTreeServices = (newValue: string) => {
    setValueTreeServices(newValue)
  }
  return (
    <div style={{ minHeight: 'calc(100vh)' }} className='m-auto pb-28 bg-[#F3F3F3] '>
      <Form
        key={JSON.stringify(initialValues)}
        className='xl:ml-[5%] 2xl:ml-[5%] custom-form-details'
        onValuesChange={onValuesChange}
        form={form}
        name='basic'
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={initialValues}
        onFinish={onFinish}
        autoComplete='off'
      >
        <h3 className='font-bold text-[22px] mt-3 ml-10'>TẠO MỚI THÔNG TIN DỊCH VỤ</h3>
        <div className='mt-12 custom-form-details-form'>
          <Form.Item
            label='Tên dịch vụ:'
            name='Name'
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 16 }}
            rules={[
              {
                required: true,
                message: 'Không được để trống'
              }
            ]}
          >
            <div className='flex items-center'>
              <Input placeholder='Nhập tiêu đề dịch vụ' />
              <div className='flex items-center w-[310px] ml-5'>
                <span>Mã dịch vụ:</span>
                <Input
                  placeholder='Code'
                  className='w-[100px] ml-4'
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  disabled
                />
              </div>
            </div>
          </Form.Item>
          <Form.Item label='Mô Tả:' name='Description' labelCol={{ span: 5 }} wrapperCol={{ span: 16 }}>
            <TextArea maxLength={600} placeholder='Nhập mô tả thông tin dịch vụ' style={{ height: 120 }} />
          </Form.Item>
          <Form.Item label='Dịch vụ Combo:' name='' labelCol={{ span: 5 }} wrapperCol={{ span: 16 }}>
            <Checkbox style={{ marginTop: 5 }} checked={CheckCombo} onChange={() => setCheckCombo(!CheckCombo)}>
              Combo
            </Checkbox>
          </Form.Item>
          {CheckCombo && (
            <Form.Item label='Lựa chọn Dịch vụ' name='' labelCol={{ span: 5 }} wrapperCol={{ span: 16 }}>
              <div>
                <Select
                  mode='multiple'
                  showSearch
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    (removeVietnamese(option?.label) ?? '').includes(removeVietnamese(input))
                  }
                  style={{ width: '50%' }}
                  onChange={handleChangeServices}
                  placeholder='Lựa chọn Dịch vụ'
                  options={dataSelectServices}
                  value={selectedServices}
                  // fieldNames={{ label: 'name', value: 'id' }}
                />
                <Button
                  type='default'
                  className='ml-10'
                  disabled={selectedServices ? false : true}
                  onClick={handleAddListMappingServices}
                >
                  Thêm dịch vụ
                </Button>
              </div>
              {listMapingServices && listMapingServices.length > 0 && (
                <Table dataSource={listMapingServices} columns={columns} pagination={false} className='mt-5 mb-5' />
              )}
            </Form.Item>
          )}
          <Form.Item
            label={
              <>
                <span style={{ color: 'red', marginRight: 5 }}>*</span>Giá
              </>
            }
            name=''
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 16 }}
          >
            <Form.Item
              label=''
              name='Price'
              style={{ width: '50%', display: 'inline-block' }}
              rules={[
                {
                  required: true,
                  message: 'Không được để trống'
                }
              ]}
            >
              <InputNumber placeholder='Ví dụ: 1000000' style={{ width: '100%' }} />
            </Form.Item>
            <span style={{ marginLeft: 20, position: 'relative', top: 5 }}>VNĐ</span>
          </Form.Item>
          <Form.Item label='Khuyến mại giảm giá:' name='' labelCol={{ span: 5 }} wrapperCol={{ span: 16 }}>
            <Form.Item label='' name='VoucherPercent' style={{ width: '50%', display: 'inline-block' }}>
              <Input placeholder='Ví dụ: 5' style={{ width: '100%' }} />
            </Form.Item>
            <span style={{ marginLeft: 20, position: 'relative', top: 5 }}>%</span>
          </Form.Item>
          <Form.Item
            label='Thời gian phục vụ Stylist (phút):'
            name='StylistTimeEstimate'
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 16 }}
            rules={[
              {
                required: true,
                message: 'Không được để trống'
              }
            ]}
          >
            <Input placeholder='Ví dụ: 1' style={{ width: '50%' }} />
          </Form.Item>
          <Form.Item
            label='Thời gian phục vụ Skinner (phút):'
            name='SkinnerTimeEstimate'
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 16 }}
            rules={[
              {
                required: true,
                message: 'Không được để trống'
              }
            ]}
          >
            <Input placeholder='Ví dụ: 1' style={{ width: '50%' }} />
          </Form.Item>
          <Form.Item label='Cấu hình bộ phận hiển thị:' name='' labelCol={{ span: 5 }} wrapperCol={{ span: 16 }}>
            <Select
              defaultValue={0}
              style={{ width: '20%' }}
              onChange={handleChangeConfigDepartment}
              value={ConfigDepartment}
              options={[
                { value: 0, label: '---Cả 2---' },
                { value: 1, label: 'Stylist' },
                { value: 2, label: 'Skiner' }
              ]}
            />
          </Form.Item>
          <Form.Item
            label={
              <>
                <span style={{ color: 'red', marginRight: 5 }}>*</span>Danh mục dịch vụ
              </>
            }
            name='CategoryId'
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 16 }}
          >
            <TreeSelect
              showSearch
              style={{ width: '100%' }}
              value={valueTreeServices}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              placeholder='Please select'
              onChange={onChangeTreeServices}
              treeData={listTreeServices}
              treeNodeFilterProp='title'
              allowClear
              treeDefaultExpandAll
            />
          </Form.Item>
          <Form.Item label='Dịch vụ bảo hành:' name='' labelCol={{ span: 5 }} wrapperCol={{ span: 16 }}>
            <div>
              <Select
                placeholder='Chọn dịch vụ bảo hành'
                style={{ width: '50%' }}
                onChange={(value) => setMaintainId(value)}
                value={MaintainId}
                options={[
                  { value: 894, label: '(894) Bảo hành DV Cắt' },
                  { value: 895, label: '(895) Bảo hành DV Uốn/ Duỗi' },
                  { value: 896, label: '(896) Bảo hành DV Nhuộm' }
                ]}
              />
            </div>
          </Form.Item>
          <Form.Item label='Nổi bật:' name='Status' labelCol={{ span: 5 }} wrapperCol={{ span: 16 }}>
            <Checkbox checked={Status} onChange={() => setStatus(!Status)} />
          </Form.Item>
          <Form.Item
            label='Phụ trợ ( Miễn phí ):'
            name='IsFreeService'
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 16 }}
          >
            <Checkbox checked={IsFreeService} onChange={() => setIsFreeService(!IsFreeService)} />
          </Form.Item>
          <Form.Item label='Publish:' name='Publish' labelCol={{ span: 5 }} wrapperCol={{ span: 16 }}>
            <Checkbox checked={Publish} onChange={() => setPublish(!Publish)} />
          </Form.Item>
          <div className='flex items-baseline gap-6 mb-5'>
            <div className='w-[345px] text-lg text-[#000] text-right font-bold'>CẤU HÌNH CHO MOBILE APP</div>
          </div>
          <Form.Item label='Link Video:' name='' labelCol={{ span: 5 }} wrapperCol={{ span: 16 }}>
            <Input
              placeholder='Link Youtube'
              style={{ width: '100%', display: 'block' }}
              value={dataVideoURL}
              onChange={(e) => setDataVideoURL(e.target.value)}
            />
            <Input
              placeholder='Tiêu đề video'
              style={{ width: '100%', display: 'block', margin: '10px 0px' }}
              value={dataVideoTitle}
              onChange={(e) => setDataVideoTitle(e.target.value)}
            />
            <TextArea
              id='dataVideoDescription'
              placeholder='Mô tả video'
              style={{ width: '100%', display: 'block' }}
              value={dataVideoDescription}
              onChange={(e) => setDataVideoDescription(e.target.value)}
            />
          </Form.Item>
          <Form.Item label='Thứ tự:' name='Order' labelCol={{ span: 5 }} wrapperCol={{ span: 16 }}>
            <InputNumber placeholder='Số thứ tự sắp xếp' style={{ width: '20%', display: 'block' }} />
          </Form.Item>
          <Form.Item label='Dịch vụ chính:' name='' labelCol={{ span: 5 }} wrapperCol={{ span: 16 }}>
            <Checkbox checked={IsPrimary} onChange={() => setIsPrimary(!IsPrimary)} />
          </Form.Item>
          <Form.Item label='Hiện trên app:' name='' labelCol={{ span: 5 }} wrapperCol={{ span: 16 }}>
            <Checkbox checked={ShowOnApp} onChange={() => setShowOnApp(!ShowOnApp)} />
          </Form.Item>
          <Form.Item
            label={
              <>
                <span style={{ color: 'red', marginRight: 5 }}>*</span>Loại dịch vụ
              </>
            }
            name=''
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 16 }}
          >
            <div>
              <Select
                placeholder='Chọn loại dịch vụ'
                style={{ width: '50%' }}
                onChange={handleServiceType}
                value={ServiceType}
                options={[
                  // { value: 0, label: 'Lựa chọn loại dịch vụ' },
                  { value: 1, label: 'Cắt Uốn' },
                  { value: 2, label: 'Nhuộm Tẩy' },
                  { value: 3, label: 'Chăm Sóc' },
                  { value: 4, label: 'Khác' }
                ]}
              />
            </div>
          </Form.Item>
          <Form.Item label='Có chấm SCSC:' name='' labelCol={{ span: 5 }} wrapperCol={{ span: 16 }}>
            <Checkbox checked={IsCheckKCS} onChange={() => setIsCheckKCS(!IsCheckKCS)} />
          </Form.Item>
          <Form.Item label='Có chấm uốn:' name='' labelCol={{ span: 5 }} wrapperCol={{ span: 16 }}>
            <Checkbox checked={IsCheckCurling} onChange={() => setIsCheckCurling(!IsCheckCurling)} />
          </Form.Item>
          <Form.Item label='Chụp ảnh liệu trình:' name='' labelCol={{ span: 5 }} wrapperCol={{ span: 16 }}>
            <Checkbox checked={IsCourse} onChange={() => setIsCourse(!IsCourse)} />
          </Form.Item>
        </div>
        <Form.Item label='' name=''>
          <Button
            className='mt-16 mb-12 bg-[#4096ff] text-white button-submit'
            type='primary'
            htmlType='submit'
            disabled={loading}
          >
            {!loading ? 'Hoàn tất' : <Spin />}
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}
