import { StaffRequestView } from 'utils/ManagementEndpointRequest/TimelineSocket'
import getInstanceAxios from 'utils/requestManager/RequestGlobalConfig'

export const ApiRepord = getInstanceAxios(process.env.REACT_APP_API_REPORT)

export const convertParamsAxios = (params: any) => {
  const queryString = Object.keys(params)
    .map((key) => {
      const value = params[key]
      if (Array.isArray(value)) {
        return value.map((item) => `${key}=${item}`).join('&')
      }
      return value !== undefined ? `${key}=${value}` : undefined
    })
    .filter((i) => i !== undefined)
    .join('&')
  return queryString
}
// lấy thông tin bill
export const getListSCSC = (params: any) => {
  return ApiRepord.get(`/api/scsc-check/Gets?${convertParamsAxios(params)}`)
}
// lấy thông tin scsc theo salon
export const getListAdminSCSC = (params: any) => {
  return ApiRepord.get(`/api/scsc-check/get-static-scsc-check?${convertParamsAxios(params)}`)
}
// Lấy danh sách nhân sự được phân công
export const getListStaff = () => {
  return ApiRepord.get('/api/scsc-check/get-staff-check')
}
// Lấy thông tin chi tiết scsc
export const getDetailSCSC = (params: any) => {
  return ApiRepord.get(`/api/scsc-check/get-detail?id=${params}`)
}
// Lấy dữ liệu chấm scsc
export const getDefaultSCSCForm = () => {
  return ApiRepord.get('/api/scsc-check/get-point-check')
}

export const postImageError = (params: any) => {
  return ApiRepord.post('/api/scsc-check/save-image-error')
}

export const refreshImageError = (params: any) => {
  return ApiRepord.get('/api/scsc-check/refresh-image', { params })
}

export const postSCSCCheck = (params: any, searchParams: any) => {
  console.log(searchParams, 'searchParams')
  if (!searchParams) {
    const url = `/api/scsc-check`
    return ApiRepord.post(url, params)
  } else {
    const queryParams = Object.keys(searchParams)
      .filter((key) => searchParams[key] !== undefined) // Lọc bỏ các trường có giá trị undefined
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(searchParams[key])}`) // Mã hóa key và value
      .join('&') // Tạo chuỗi query string
    const url = `/api/scsc-check?${queryParams}`
    return ApiRepord.post(url, params)
  }
  
}

