/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState } from "react"
import { Button, Form, notification, Pagination, Row, Select, Spin } from "antd"
import { getSalons } from 'api/catalog'
import convert from 'utils/helpers/convert'
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons"
import { SpinIndicator } from "antd/es/spin"
import TableSalary from "./components/TableSalary"
import { convertParamsAxios, convertQueryStringToObject, getListStaffSalary, getStaffSkillLevel, getStaffType } from "./api/api"
import { useNavigate } from "react-router-dom"

const BasicSalary = () => {
    const [form] = Form.useForm()
    const [data, setData] = useState([])
    const [listSalon, setListSalon] = useState([])
    const [listStaffType, setListStaffType] = useState([])
    const [levelList, setLevelList] = useState([])
    const [totalItems, setTotalItems] = useState(0)
    const navigate = useNavigate()
    const [params, setParams] = useState<any>({
        pageSize: 10,
        pageIndex: 1
    })
    const antIcon: SpinIndicator = <LoadingOutlined style={{ fontSize: 24 }} spin />
    const [loading, setLoading] = useState(false)
    // ấn nút xem dữ liệu
    const onFinish = () => {
        navigate(
            `/staff-skill-salary/basic-salary?${convertParamsAxios({
                ...params,
                salonId: params?.salonId !== undefined ? Number(params?.salonId) : undefined,
                levelId: params?.levelId,
                departmentId: params?.departmentId,
                pageSize: params?.pageSize,
                pageIndex: params?.pageIndex,
            })}`
        )
    }
    // ấn nút reset lọc
    const onReset = () => {
        form.resetFields()
        setParams({
            ...params,
            salonId: undefined,
            levelId: undefined,
            departmentId: undefined,
        })
    }
    // Lấy danh sách salon
    const getListSalon = async () => {
        if (loading) return
        setLoading(true)
        try {
            const response: any = await getSalons()
            const { statusCode, data } = response
            if (statusCode === 200) {
                setListSalon([
                    {
                        id: 0, name: "Salon mặc định"
                    },
                    ...data,
                ]);
                setLoading(false)
            }
        } catch (error) {
            console.error('Error:', error.message)
            setLoading(false)
        }
    }
    // lấy danh sách vị trí
    const getStaffTypeData = async () => {
        if (loading) return
        setLoading(true)
        try {
            const response: any = await getStaffType(1)
            if (response.data) {
                const newListStaffType = response.data.filter((item: any) => item.skillLevelIds.length > 0)
                setListStaffType(newListStaffType)
            }
        } catch (error) {
            console.error('Error:', error.message)
            notification.error({
                message: 'Thông báo!',
                description: <>Lỗi không lấy được danh sách vị trí!</>
            })
            setLoading(false)
        }
    }
    // Lấy danh sách level
    const getStaffSkillLevelData = async () => {
        if (loading) return
        setLoading(true)
        try {
            const response: any = await getStaffSkillLevel()
            if (response.data) {
                setLevelList(response.data)
            }
        } catch (error) {
            notification.error({
                message: 'Thông báo!',
                description: <>Lỗi không lấy được danh sách level!</>
            })
            console.error('Error:', error.message)
            setLoading(false)
        }
    }
    // Lấy dữ liệu bảng lương 
    const getListStaffSalaryData = async (dataSearch) => {
        if (loading) return
        setLoading(true)
        const dataPayload = {
            departmentId: dataSearch.departmentId || undefined,
            levelId: dataSearch.levelId || undefined,
            salonId: params?.salonId !== undefined ? Number(params?.salonId) : undefined,
            pagingParams: {
                orderBy: "",
                keyword: "",
                pageIndex: dataSearch.pageIndex,
                isPaging: true,
                pageSize: dataSearch.pageSize
            }
        }
        try {
            const response: any = await getListStaffSalary(dataPayload)
            if (response?.data?.data) {
                const newData = response?.data?.data.map((item: any, index: number) => ({
                    ...item,
                    index: index,
                }))
                setData(newData)
                setTotalItems(response?.data?.meta?.total)
                setLoading(false)
            }
        } catch (error) {
            notification.error({
                message: 'Thông báo!',
                description: <>Lỗi không lấy được Danh sách lương cơ bản!</>
            })
            console.error('Error:', error.message)
            setLoading(false)
        }
    }
    // Kiểm tra từ khóa tìm kiếm trên param
    const getDataByParamsSearch = () => {
        const paramsSearchResult: any = convertQueryStringToObject(window.location.search.replace('?', ''))
        if (Object.keys(paramsSearchResult).length > 0) {
            const dataSearch = {
                salonId: paramsSearchResult?.salonId ? Number(paramsSearchResult?.salonId[0]) : undefined,
                levelId: paramsSearchResult?.levelId ? paramsSearchResult?.levelId[0] : undefined,
                departmentId: paramsSearchResult?.departmentId ? paramsSearchResult?.departmentId[0] : undefined,
                pageSize: paramsSearchResult?.pageSize ? Number(paramsSearchResult?.pageSize[0]) : 10,
                pageIndex: paramsSearchResult?.pageIndex ? Number(paramsSearchResult?.pageIndex[0]) : 1
            }
            setParams(dataSearch)
            getListStaffSalaryData(dataSearch)
        }
    }

    useEffect(() => {
        getListSalon()
        getStaffSkillLevelData()
        getStaffTypeData()
    }, [])

    useEffect(() => {
        if (window.location.search) {
            getDataByParamsSearch()
        }
    }, [window.location.search])
    
    return(
        <div className='min-h-[100vh] m-auto pb-12'>
            <div className='w-full px-5 py-5 search-wrapper' >
                <Form className='bg-white ' form={form} onFinish={onFinish}>
                    <div className="p-5">
                        <h2 className='text-2xl font-bold bg-white mb-7'>Danh sách lương cơ bản</h2>
                        <Row className=''>
                            <Form.Item label='Chọn Salon' className='min-w-[200px]' >
                                <Select
                                    allowClear
                                    value={params?.salonId !== undefined ? Number(params?.salonId) : undefined}
                                    className='w-full'
                                    placeholder={'Chọn Salon'}
                                    fieldNames={{ label: 'name', value: 'id' }}
                                    options={listSalon}
                                    showSearch
                                    optionFilterProp='children'
                                    onChange={(e) => {
                                        setParams({ ...params, ['salonId']: e })
                                    }}
                                    filterOption={(input, option) =>
                                        convert
                                            .removeVietnameseAccent(option.name)
                                            .toLowerCase()
                                            .includes(convert.removeVietnameseAccent(input))
                                    }
                                />
                            </Form.Item>

                            <Form.Item label='Level' className='w-[200px]'>
                                <Select
                                    allowClear
                                    value={params?.levelId || undefined}
                                    className='w-full'
                                    placeholder={'Chọn level'}
                                    fieldNames={{ label: 'name', value: 'id' }}
                                    options={levelList}
                                    showSearch
                                    optionFilterProp='children'
                                    onChange={(e) => {
                                        setParams({ ...params, ['levelId']: e })
                                    }}
                                    filterOption={(input, option) =>
                                        convert
                                            .removeVietnameseAccent(option.name)
                                            .toLowerCase()
                                            .includes(convert.removeVietnameseAccent(input))
                                    }
                                />
                            </Form.Item>

                            <Form.Item label='Vị trí' className='w-[200px]'>
                                <Select
                                    allowClear
                                    value={params?.departmentId || undefined}
                                    className='w-full'
                                    placeholder={'Chọn vị trí'}
                                    fieldNames={{ label: 'name', value: 'id' }}
                                    options={listStaffType}
                                    showSearch
                                    optionFilterProp='children'
                                    onChange={(e) => {
                                        setParams({ ...params, ['departmentId']: e })
                                    }}
                                    filterOption={(input, option) =>
                                        convert
                                            .removeVietnameseAccent(option.name)
                                            .toLowerCase()
                                            .includes(convert.removeVietnameseAccent(input))
                                    }
                                />
                            </Form.Item>
                            <Button
                                type='default'
                                htmlType='submit'
                                className='w-[160px] bg-[#4096ff] text-white hover:bg-white'
                                disabled={loading}
                            >
                                {!loading ? (
                                    <div className='flex items-center justify-center hover_icon'>
                                        <SearchOutlined className='mr-1 text-white' />
                                        Xem dữ liệu
                                    </div>
                                ) : (
                                    <Spin indicator={antIcon} />
                                )}
                            </Button>
                            <Button
                                onClick={() => onReset()}
                                type='default'
                                className='w-[160px] bg-[#4096ff] text-white hover:bg-white'
                            >
                                Reset lọc
                            </Button>
                        </Row>
                    </div>
                </Form>
                <div className="mt-10 bg-white">
                    <TableSalary listSalon={listSalon} data={data} params={params} />
                    <div className="grid py-8 justify-items-end">
                        <Pagination
                            className=''
                            current={params.pageIndex}
                            pageSize={params.pageSize}
                            total={totalItems}
                            onChange={(page: number, pageSize: number) => {
                                navigate(
                                    `/staff-skill-salary/basic-salary?${convertParamsAxios({
                                        ...params,
                                        pageSize: pageSize,
                                        pageIndex: params.pageSize !== pageSize ? 1 : page
                                    })}`
                                )
                                setTimeout(
                                    () =>
                                        window.document.getElementById('body__page').scrollTo({
                                            top: 0,
                                            behavior: 'smooth'
                                        }),
                                    500
                                )
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>

    )
}

export default BasicSalary