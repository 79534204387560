/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import dayjs from 'dayjs';

const Item = (props: any) => {
  const { memberName, current, isUpgrade } = props;

  const needMore = current.minSpending < current.totalSpending ? 0 : current.minSpending - current.totalSpending

  return (<div className='p-2 border-b border-gray-200'>
    <div className='flex justify-between'>
      {isUpgrade ?
        <span className='font-bold'>Chi tiêu lên hạng <span className='capitalize'>{memberName.toLowerCase()}</span>:</span> : <span className='font-bold'>Chi tiêu:</span>}
      {needMore > 0 ? <div className='border rounded-md border-red-700 py-1 px-3 text-xs'>
        <span className="text-red-700">Chưa đạt</span>
      </div> : <div className='border rounded-md border-green-700 py-1 px-3 text-xs'>
        <span className="text-green-700">Đạt</span>
      </div>}
    </div>
    <p>
      <b className='text-red-700'>{(current.totalSpending / 1000).toFixed(0)}K</b>/
      <b>{((current.minSpending) / 1000).toFixed(0)}K</b>
    </p>
    <p className='text-gray-700'>Chi tiêu thêm <b>{(needMore / 1000).toFixed(0)}K</b> trước ngày <b>{dayjs(current.expire).format('DD/MM/YYYY')}</b> để {isUpgrade ? 'lên' : 'duy trì'} hạng <span className='capitalize'>{memberName.toLowerCase()}</span></p>
  </div>)
}

export default Item;