/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable jsx-a11y/anchor-has-content */
import { useContext } from 'react'
import { FileExcelOutlined, LoadingOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons'
import {
  TreeSelect,
  Form,
  Button,
  Row,
  Table,
  Pagination,
  Spin,
  DatePicker,
  Col,
  Select,
  Input,
  Radio,
  AutoComplete
} from 'antd'
import { useEffect, useRef, useState } from 'react'
import { SpinIndicator } from 'antd/es/spin'
import { AppContext } from 'layouts/DashboardLayout'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { makeId } from 'utils/helpers'
import {
  ColumnConfig,
  SearchConfig,
  convertQueryStringToObjectBase,
  transformObject,
  JSONToCsv,
  DateFormat,
  convertParamsAxiosBase,
  TypeSearch,
  AxiosResponseList,
  ParamsSearchDefault
} from './util'
const { RangePicker } = DatePicker
export const antIcon: SpinIndicator = <LoadingOutlined style={{ fontSize: 24 }} spin />

type Props = {
  currentUrl: string
  title: string | JSX.Element
  column: ColumnConfig[]
  columnSearch: SearchConfig[]
  fetchData: (params) => any
  handleChangeValue?: (value: any, onReset: (field: string[]) => void) => void
  exportCsv?: boolean
  linkADD?: string
  reload?: boolean
  setReload?: (reload: boolean) => void
  middleComponent?: JSX.Element
  exportData?: any
}
export default function FormComponentV3({
  title,
  column,
  columnSearch,
  exportCsv = false,
  fetchData,
  handleChangeValue,
  linkADD = '',
  currentUrl,
  reload,
  setReload,
  middleComponent,
  exportData
}: Props) {
  const { notification } = useContext(AppContext)
  const searchParams = new URLSearchParams(window.location.search)
  const PageIndex = searchParams.get('PageIndex') || 1
  const PageSize = searchParams.get('PageSize') || 10
  const CategoryId = searchParams.get('CategoryId') || undefined
  const [currentPage, setCurrentPage] = useState(1)
  const navigate = useNavigate()
  const refCSV = useRef<HTMLAnchorElement>()
  const [form] = Form.useForm()
  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page)
    const params = new URLSearchParams(window.location.search)
    params.set('PageIndex', page)
    params.set('PageSize', pageSize)
    const newUrl = `${window.location.pathname}?${params.toString()}`
    navigate(newUrl)
  }
  const [params, setParams] = useState<ParamsSearchDefault & any>({
    PageSize: 10,
    PageIndex: 1
  })
  console.log(params, 'params')
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([
    {
      index: 0,
      old_phone: '0156156123',
      customer: 'customer',
      bill_id: 1,
      new_phone: '0156156654',
      suggester: 'suggester',
      note: 'note',
      status: 0,
      reason: 'reason'
    }
  ])
  const [total, setTotal] = useState(0)
  const [checkCSV, setCheckCSV] = useState<boolean>(false)
  const [dataCSV, setDataCSV] = useState<any>()
  const [mount, setMount] = useState(false)

  const handleFetchListStaff = async (paramsSearch: ParamsSearchDefault & any) => {
    try {
      console.log(paramsSearch, 'paramsSearch')
      setLoading(true)
      if (CategoryId) {
        const newCategoryId = Number(CategoryId.replace('parent-', '').replace('child-', ''))
        const res: AxiosResponseList & any = await fetchData({ ...paramsSearch, CategoryId: newCategoryId })
        console.log(res.data.data, 'resresres')
        setLoading(false)

        setTotal(res.data.totalRecords)
        setData(res.data.data)
      } else {
        const res: AxiosResponseList & any = await fetchData({ ...paramsSearch })
        console.log(res.data.data, 'resresres')
        setLoading(false)

        setTotal(res.data.totalRecords)
        setData(res.data.data)
      }
    } catch (error) {
      notification('error', 'Thất bại', 'Có lỗi! Vui lòng kiểm tra lại')
    }
    setLoading(false)
    setReload(false)
  }

  const prefetchDataStaffList = () => {
    const check = {}
    const paramv2 = window.location.search.replace('?', '')
    console.log(paramv2, 'paramv2')
    let paramsSearchResult
    if (!paramv2.includes('date')) {
      paramsSearchResult = convertQueryStringToObjectBase(window.location.search.replace('?', ''), columnSearch)
    } else {
      const column = [
        { label: 'Thời gian 1', param: 'date_from', type: TypeSearch.date, buttonDate: true },
        { label: 'Thời gian 2', param: 'date_to', type: TypeSearch.date, buttonDate: true }
      ]
      console.log(columnSearch, 'columnSearch')
      paramsSearchResult = convertQueryStringToObjectBase(window.location.search.replace('?', ''), [
        ...columnSearch,
        ...column
      ])
    }
    console.log(paramsSearchResult, 'paramsSearchResult')
    if (Object.keys(paramsSearchResult).length > 0) {
      const result: ParamsSearchDefault & any = { ...transformObject(check), ...paramsSearchResult }
      console.log(result, 'result')
      setParams(result)
      handleFetchListStaff(result)
    } else {
      setData([])
    }
    if (!mount) setMount(true)
  }

  const onExportStaff = async () => {
    // try {
    //   setDataCSV(JSONToCsv(data))
    //   setCheckCSV(!checkCSV)
    // } catch (error) {
    //   notification('error', 'Thất bại', 'Có lỗi xảy ra!')
    // }
    exportData()
  }

  const onFinish = () => {
    const result: ParamsSearchDefault = {
      PageSize: 0,
      PageIndex: 0
    }
    const value = form.getFieldsValue()
    result.PageSize = params.PageSize
    result.PageIndex = params.PageIndex
    Object.keys(value).map((i) => {
      if (
        typeof value[i] === 'object' &&
        value[i]?.length === 2 &&
        value[i].every((item) => typeof item === 'object')
      ) {
        result[`${i}_from`] = dayjs(value[i][0]).format(DateFormat)
        result[`${i}_to`] = dayjs(value[i][1]).format(DateFormat)
      } else if (typeof value[i] === 'object') {
        result[i] = dayjs(value[i]).format(DateFormat)
      } else result[i] = value[i]
    })
    for (const key in result) {
      if (result[key] === null || result[key] === undefined) {
        delete result[key]
      }
    }
    console.log(result, 'result')
    navigate(
      `${currentUrl}?${convertParamsAxiosBase({
        ...result,
        Keyword: result.Keyword ? encodeURIComponent(result.Keyword) : '',
        PageIndex: 1
      })}`
    )
  }

  const RenderSearchComponent = (type: string, config?: SearchConfig) => {
    const result = {
      DatePicker: (
        <Col>
          <Form.Item
            name={config.param}
            label={config?.label}
            rules={[{ required: !!config?.require, message: 'Trường bắt buộc phải nhập' }]}
          >
            <RangePicker
              placeholder={['Từ ngày', 'Đến ngày']}
              format={'DD-MM-YYYY'}
              className='w-full'
              defaultValue={config?.defaultDate ? [dayjs().startOf('month'), dayjs()] : null}
              value={
                params[config.param]
                  ? [dayjs(params[config.param][0] || null), dayjs(params[config.param][1] || null)]
                  : undefined
              }
              onChange={(e) => {
                setParams({ ...params, [config.param]: e })
                form.setFieldValue(config.param, e)
                form.validateFields([config.param])
              }}
            />
            {config?.buttonDate && (
              <div className='flex justify-between mt-2 gap-x-1'>
                <div className='flex flex-col w-[100px] justify-center items-center gap-y-1'>
                  <Button
                    onClick={() => {
                      setParams({
                        ...params,
                        [`${config.param}`]: [dayjs().subtract(2, 'day'), dayjs().subtract(2, 'day')]
                      })
                      form.setFieldValue(`${config.param}`, [dayjs().subtract(2, 'day'), dayjs().subtract(2, 'day')])
                    }}
                    style={{ padding: '4px 8px' }}
                    type='default'
                  >
                    {'Hôm kia'}
                  </Button>
                  {/* <span>{dayjs().subtract(2, 'day').format('DD-MM-YYYY')}</span> */}
                </div>
                <div className='flex flex-col w-[100px] justify-center items-center gap-y-1'>
                  <Button
                    onClick={() => {
                      setParams({
                        ...params,
                        [`${config.param}`]: [dayjs().subtract(1, 'day'), dayjs().subtract(1, 'day')]
                      })
                      form.setFieldValue(`${config.param}`, [dayjs().subtract(1, 'day'), dayjs().subtract(1, 'day')])
                    }}
                    style={{ padding: '4px 8px' }}
                    type='default'
                  >
                    {'Hôm qua'}
                  </Button>
                  {/* <span>{dayjs().subtract(1, 'day').format('DD-MM-YYYY')}</span> */}
                </div>
                <div className='flex flex-col w-[100px] justify-center items-center gap-y-1'>
                  <Button
                    onClick={() => {
                      setParams({ ...params, [`${config.param}`]: [dayjs(), dayjs()] })
                      form.setFieldValue(`${config.param}`, [dayjs(), dayjs()])
                    }}
                    style={{ padding: '4px 8px' }}
                    className=''
                    type='default'
                  >
                    {'Hôm nay'}
                  </Button>
                  {/* <span>{dayjs().format('DD-MM-YYYY')}</span> */}
                </div>
              </div>
            )}
          </Form.Item>
        </Col>
      ),
      Date: (
        <Col>
          <Form.Item
            name={config.param}
            label={config?.label}
            rules={[{ required: !!config?.require, message: 'Trường bắt buộc phải nhập' }]}
          >
            <DatePicker
              placeholder={config?.placeholder}
              format={'DD-MM-YYYY'}
              className='w-full'
              value={params[config.param] || undefined}
              defaultValue={config?.dateDefault ? dayjs() : null}
              onChange={(e) => {
                setParams({ ...params, [config.param]: e })
                form.setFieldValue(config.param, e)
                form.validateFields([config.param])
              }}
            />
            {config?.buttonDate && (
              <div className='flex justify-between pr-4 mt-2 gap-x-1'>
                <div className='flex flex-col w-[100px] justify-center items-center gap-y-1'>
                  <Button
                    onClick={() => {
                      setParams({
                        ...params,
                        [`${config.param}`]: [dayjs().subtract(2, 'day'), dayjs().subtract(2, 'day')]
                      })
                      form.setFieldValue(`${config.param}`, [dayjs().subtract(2, 'day'), dayjs().subtract(2, 'day')])
                    }}
                    style={{ padding: '4px 8px' }}
                    type='primary'
                  >
                    {'Hôm kia'}
                  </Button>
                  <span>{dayjs().subtract(2, 'day').format('DD-MM-YYYY')}</span>
                </div>
                <div className='flex flex-col w-[100px] justify-center items-center gap-y-1'>
                  <Button
                    onClick={() => {
                      setParams({
                        ...params,
                        [`${config.param}`]: [dayjs().subtract(1, 'day'), dayjs().subtract(1, 'day')]
                      })
                      form.setFieldValue(`${config.param}`, [dayjs().subtract(1, 'day'), dayjs().subtract(1, 'day')])
                    }}
                    style={{ padding: '4px 8px' }}
                    type='primary'
                  >
                    {'Hôm qua'}
                  </Button>
                  <span>{dayjs().subtract(1, 'day').format('DD-MM-YYYY')}</span>
                </div>
                <div className='flex flex-col w-[100px] justify-center items-center gap-y-1'>
                  <Button
                    onClick={() => {
                      setParams({ ...params, [`${config.param}`]: [dayjs(), dayjs()] })
                      form.setFieldValue(`${config.param}`, [dayjs(), dayjs()])
                    }}
                    style={{ padding: '4px 8px' }}
                    className=''
                    type='primary'
                  >
                    {'Hôm nay'}
                  </Button>
                  <span>{dayjs().format('DD-MM-YYYY')}</span>
                </div>
              </div>
            )}
          </Form.Item>
        </Col>
      ),
      Select: (
        <Col className='w-[300px]'>
          <Form.Item
            name={config.param}
            label={config.label}
            rules={[{ required: !!config?.require, message: 'Trường bắt buộc phải nhập' }]}
          >
            <Select
              allowClear
              // @ts-ignore: Unreachable code error
              mode={config?.mode}
              className='w-[500px]'
              value={params[config.param]}
              placeholder={config?.placeholder}
              options={config?.options}
              fieldNames={config?.fieldNames}
              showSearch
              optionFilterProp='children'
              onChange={(e) => {
                form.setFieldValue(config.param, e)
                setParams({ ...params, [config.param]: e })
              }}
              filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
            />
          </Form.Item>
        </Col>
      ),
      TreeSelect: (
        <Col className='w-[400px]'>
          <Form.Item
            name={config.param}
            label={config.label}
            rules={[{ required: !!config?.require, message: 'Trường bắt buộc phải nhập' }]}
          >
            <TreeSelect
              allowClear
              // @ts-ignore: Unreachable code error
              mode={config?.mode}
              className='w-[500px]'
              value={params[config.param]}
              placeholder={config?.placeholder}
              treeData={config?.options}
              fieldNames={config?.fieldNames}
              showSearch
              optionFilterProp='children'
              onChange={(value, label, extra) => {
                // console.log(value, label, extra)
                form.setFieldValue(config.param, value)
                setParams({ ...params, [config.param]: value })
              }}
              filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
            />
          </Form.Item>
        </Col>
      ),
      Input: (
        <Col className='w-[300px] ml-5'>
          <Form.Item
            name={config.param}
            label={config?.label}
            rules={[{ required: !!config?.require, message: 'Trường bắt buộc phải nhập' }]}
          >
            <Input value={params[config.param]} placeholder={config?.placeholder} />
          </Form.Item>
        </Col>
      ),
      Radio: (
        <Col span={config?.col || 3}>
          <Form.Item
            name={config.param}
            label={config?.label}
            rules={[{ required: !!config?.require, message: 'Trường bắt buộc phải nhập' }]}
          >
            <Radio.Group value={params[config.param]}>
              {(config?.options || []).map((i) => (
                <Radio key={makeId()} value={i.value}>
                  {i.label}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        </Col>
      ),
      Break: <Col span={4} />,
      AutoComplete: (
        <Col span={config?.col || 3}>
          <Form.Item
            name={config.param}
            label={config?.label}
            rules={[{ required: !!config?.require, message: 'Trường bắt buộc phải nhập' }]}
          >
            <AutoComplete options={config?.options || []}>
              <Input placeholder={config?.placeholder} />
            </AutoComplete>
          </Form.Item>
        </Col>
      )
    }
    return result[type]
  }
  const onReset = (field: string[]) => {
    form.resetFields([...field])
  }
  const onValuesChange = (changedValues) => {
    const keyName = Object.keys(changedValues)[0]
    const value = changedValues[keyName]

    if (keyName.toLowerCase().includes('date')) {
      setParams({
        ...params,
        [keyName]: value ? [dayjs(value[0]).format(DateFormat), dayjs(value[1]).format(DateFormat)] : undefined
      })
    } else {
      setParams({ ...params, ...changedValues })
    }
    handleChangeValue(changedValues, onReset)
  }
  useEffect(() => {
    prefetchDataStaffList()
  }, [window.location.search])
  useEffect(() => {
    if (dataCSV) {
      refCSV.current.click()
    }
  }, [checkCSV])
  useEffect(() => {
    if (mount) {
      columnSearch.forEach((element) => {
        if (element?.type) {
          if (element?.type === TypeSearch.date) {
            if (element.defaultDate) {
              form.setFieldValue(`${element.param}`, [dayjs().startOf('month'), dayjs()])
            } else {
              const newParams = params[element.param] ? params[element.param].map((i) => dayjs(i)) : ''
              form.setFieldValue(`${element.param}`, newParams)
            }
          } else {
            if (element?.defaultValue) {
              form.setFieldValue(`${element.param}`, element.defaultValue)
            } else form.setFieldValue(`${element.param}`, params[element.param])
          }
        }
      })
    }
  }, [mount])
  useEffect(() => {
    if (reload) prefetchDataStaffList()
  }, [reload])
  // const customTotal = () => {
  //   return <span className='pagination-table-title'>Tổng {total}</span>
  // }
  return (
    <div className='w-full px-5 py-5 search-wrapper'>
      <Form layout='vertical' className='pb-10' form={form} onFinish={onFinish} onValuesChange={onValuesChange}>
        <h2 className='text-2xl font-semibold mb-7'>{title}</h2>
        <Row className=' gap-y-4 xl:w-[81%] 2xl:w-[90%]'>
          {columnSearch.map((item) => {
            return RenderSearchComponent(item.type, item)
          })}
        </Row>
        <Row>{middleComponent}</Row>
        <div className='mt-8 bg-white ' style={{ padding: '20px 20px 100px 20px' }}>
          <Row className='flex gap-x-3'>
            <Button
              type='default'
              htmlType='submit'
              className='w-[200px] bg-[#4096ff] text-white hover:bg-white'
              disabled={loading}
            >
              {!loading ? (
                <div className='flex items-center justify-center hover_icon'>
                  <SearchOutlined className='mr-1 text-white' />
                  Xem dữ liệu
                </div>
              ) : (
                <Spin indicator={antIcon} />
              )}
            </Button>
            {exportCsv && (
              <>
                <Button
                  onClick={onExportStaff}
                  type='default'
                  className='w-[200px] bg-[#28A745] text-white hover:bg-white hover_icon'
                >
                  <div className='flex items-center justify-center'>
                    <FileExcelOutlined className='mr-1 text-white' />
                    Xuất excel
                  </div>
                </Button>
                <a
                  ref={refCSV}
                  style={{ display: 'none' }}
                  href={`data:text/csv;charset=utf-8,${encodeURIComponent('\uFEFF' + dataCSV)}`}
                  download='danh_sách.csv'
                />
              </>
            )}
            {linkADD && (
              <Button
                onClick={() => navigate(linkADD)}
                type='default'
                className='w-[200px] bg-[#4096ff] text-white hover:bg-white flex items-center justify-center'
              >
                <PlusOutlined className='mr-1 text-white' />
                Thêm mới
              </Button>
            )}
            <Button
              onClick={() => form.resetFields()}
              type='default'
              className='w-[200px] bg-[#4096ff] text-white hover:bg-white'
            >
              Reset Filter
            </Button>
          </Row>

          <Row style={{ display: 'contents' }} gutter={18}>
            <div className='flex items-center justify-between'>
              <h3>
                {'Tổng số : '}
                <span className='text-[red] font-semibold'>{total}</span>
              </h3>
              <Pagination
                current={currentPage}
                onChange={handlePageChange}
                total={total}
                pageSize={Number(PageSize) || 10}
                className='float-right pb-8 mt-8'
                pageSizeOptions={[10, 50, 100, 200, 500, 1000]}
              />
            </div>
            <Table
              className='min-w-[1000px] mt-2 overflow-auto'
              dataSource={data}
              columns={column}
              pagination={false}
              bordered
            />
            <Pagination
              current={currentPage}
              onChange={handlePageChange}
              total={total}
              pageSize={Number(PageSize) || 10}
              className='float-right pb-8 mt-8'
              pageSizeOptions={[10, 50, 100, 200, 500, 1000]}
            />
          </Row>
        </div>
      </Form>
    </div>
  )
}
