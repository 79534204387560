import '../index.scss'
import { useState, useEffect } from 'react'
import { ColumnConfig, SearchConfig, TypeSearch } from '../../base/util'
import FormComponent from '../../base/Form'
import { Switch, Modal, Button, notification } from 'antd'
import { getListSalon, getListTblConfig, getListCity, downloadExcel, updateSalonStatus } from '../api'
import { useLocation, useNavigate } from 'react-router-dom'
import { EditOutlined } from '@ant-design/icons'

export const List = () => {
  const [reload, setReload] = useState(false)
  const [modalData, setModalData] = useState<any>()
  const handleChangeStatus = (record: any, state: string) => {
    let text = ''
    let body = {}
    switch (state) {
      case 'Publish':
        if (record.Publish) {
          text = `Thay đổi Publish SALON ${record.Name} sang ẩn ?`
        } else {
          text = `Thay đổi Publish SALON ${record.Name} sang hiện ?`
        }
        body = {
          Publish: !record.Publish
        }
        break
      case 'IsSalonHoiQuan':
        if (record.IsSalonHoiQuan) {
          text = `Bỏ xác nhận SALON ${record.Name} là Salon Hội Quán ?`
        } else {
          text = `Lựa chọn SALON ${record.Name} là Salon Hội Quán ?`
        }
        body = {
          IsSalonHoiQuan: !record.IsSalonHoiQuan
        }
        break
      case 'SalonOTP':
        if (record.SalonOTP) {
          text = `Bỏ xác nhận SALON ${record.Name} là Salon OTP ? `
        } else {
          text = `Lựa chọn SALON ${record.Name} là Salon OTP ?`
        }
        body = {
          SalonOTP: !record.SalonOTP
        }
        break
    }
    const dataModal = {
      text: text,
      SalonId: record.Id,
      body: body
    }
    setModalData(dataModal)
    showModal()
  }
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const PageIndex = searchParams.get('PageIndex')
  const PageSize = searchParams.get('PageSize')
  const [dataTypeSalon, setDataTypeSalon] = useState([])
  const [dataCity, setDataCity] = useState([])
  const navigate = useNavigate()
  const GetListTypeSalon = async () => {
    try {
      const key = 'salon_type'
      const response: any = await getListTblConfig(key)
      const { statusCode, data } = response
      if (statusCode === 0) {
        setDataTypeSalon(data)
      }
    } catch (error) {
      console.error('Error:', error.message)
    }
  }
  const GetListCity = async () => {
    try {
      const response: any = await getListCity()
      const { statusCode, data } = response
      if (statusCode === 0) {
        setDataCity(data)
      }
    } catch (error) {
      console.error('Error:', error.message)
    }
  }
  useEffect(() => {
    GetListTypeSalon()
    GetListCity()
  }, [])
  const column: ColumnConfig[] = [
    {
      title: 'STT',
      key: 'index',
      render: (item, index, i) => {
        return <span>{PageIndex ? i + (Number(PageIndex) - 1) * Number(PageSize) + 1 : i + 1}</span>
      }
    },
    {
      title: 'Salon',
      dataIndex: 'Name',
      key: 'name'
    },
    {
      title: 'Salon Backup',
      dataIndex: 'SalonBackup',
      key: 'salonBackup'
    },
    {
      title: 'Tên tắt',
      dataIndex: 'ShortName',
      key: 'shortName'
    },
    {
      title: 'Địa chỉ',
      dataIndex: 'Address',
      key: 'address'
    },
    {
      title: 'Loại salon',
      dataIndex: 'Type',
      key: 'typeSalon'
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'Phone',
      key: 'phone'
    },
    {
      title: 'Publish',
      key: 'Publish',
      render: (record) => <Switch checked={record.Publish} onClick={() => handleChangeStatus(record, 'Publish')} />
    },
    {
      title: 'Salon Hội Quán',
      key: 'IsSalonHoiQuan',
      render: (record) => (
        <Switch checked={record.IsSalonHoiQuan} onClick={() => handleChangeStatus(record, 'IsSalonHoiQuan')} />
      )
    },
    {
      title: 'Salon OTP',
      key: 'SalonOTP',
      render: (record) => <Switch checked={record.SalonOTP} onClick={() => handleChangeStatus(record, 'SalonOTP')} />
    },
    {
      title: 'Chỉnh sửa',
      key: 'Edit',
      render: (record) => (
        <Button onClick={() => navigate(`/salon/list/${record.Id}`)}>
          <EditOutlined />
        </Button>
      )
    }
  ]
  const columnSearch: SearchConfig[] = [
    {
      label: 'Tên Salon',
      type: TypeSearch.input,
      param: 'Name',
      placeholder: 'Nhập tên Salon, Salonbackup'
      // require: true
    },
    {
      label: 'Loại Salon',
      param: 'Type',
      type: TypeSearch.select,
      options: dataTypeSalon,
      placeholder: 'Loại Salon',
      fieldNames: { label: 'label', value: 'value' }
    },
    {
      label: 'Khu vực/Tỉnh',
      param: 'Address',
      type: TypeSearch.select,
      options: dataCity,
      placeholder: 'Tỉnh/ thành',
      fieldNames: { label: 'name', value: 'id' }
    },
    {
      label: 'Publish',
      param: 'Publish',
      type: TypeSearch.radio,
      options: [
        { value: true, label: 'Có' },
        { value: false, label: 'Không' }
      ]
    },
    {
      label: 'Salon Hội Quán',
      param: 'IsSalonHoiQuan',
      type: TypeSearch.radio,
      options: [
        { value: true, label: 'Có' },
        { value: false, label: 'Không' }
      ]
    },
    {
      label: 'Salon OTP',
      param: 'SalonOTP',
      type: TypeSearch.radio,
      options: [
        { value: true, label: 'Có' },
        { value: false, label: 'Không' }
      ]
    }
  ]
  const handleChangeValue = (changedValues, onReset: (field: string[]) => void) => {
    if (changedValues?.salonId) {
      onReset(['statusName'])
    }
  }
  const exportData = async () => {
    let param = ''
    const Name = searchParams.get('Name')
    const Type = searchParams.get('Type')
    const Address = searchParams.get('Address')
    const Publish = searchParams.get('Publish')
    const IsSalonHoiQuan = searchParams.get('IsSalonHoiQuan')
    const SalonOTP = searchParams.get('SalonOTP')
    if (Name) {
      param += `Name=${Name}`
    }
    if (Type) {
      param += `&Type=${Type}`
    }
    if (Address) {
      param += `&Address=${Address}`
    }
    if (Publish) {
      param += `&Publish=${Publish}`
    }
    if (IsSalonHoiQuan) {
      param += `&IsSalonHoiQuan=${IsSalonHoiQuan}`
    }
    if (SalonOTP) {
      param += `&SalonOTP=${SalonOTP}`
    }
    await downloadExcel(param)
  }
  const [isModalOpen, setIsModalOpen] = useState(false)

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = async () => {
    try {
      const body = modalData.body
      // console.log(body, 'body')
      const response = await updateSalonStatus(modalData.SalonId, body)
      console.log(response, 'response')
      if (response) {
        notification.success({
          description: 'Bạn đã thay đổi trạng thái của Salon',
          message: 'Thành công'
        })
        setReload(true)
      } else {
        notification.warning({
          description: 'Không thể thay đổi trạng thái của Salon',
          message: 'Lỗi'
        })
      }
      setIsModalOpen(false)
    } catch (error) {
      console.error('Error:', error)
      notification.error({
        description: error.message,
        message: 'Lỗi'
      })
    }
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }
  return (
    <div className='min-h-[100vh] m-auto bg-[#F3F3F3] pb-12'>
      <FormComponent
        currentUrl='/salon/list'
        title='Danh sách Salon'
        column={column}
        columnSearch={columnSearch}
        fetchData={getListSalon}
        handleChangeValue={handleChangeValue}
        exportCsv={true}
        linkADD='/salon/list/add'
        reload={reload}
        setReload={setReload}
        exportData={exportData}
      ></FormComponent>
      {modalData && (
        <Modal title='Xác nhận cập nhật' open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
          <p>{modalData.text}</p>
        </Modal>
      )}
    </div>
  )
}
