import getInstanceAxios from 'utils/requestManager/RequestGlobalConfig'

export const BillingViewService = getInstanceAxios(process.env.REACT_APP_API_BILLINGVIEW_SERVICE)

const getInfoSalon = (param) => {
    return BillingViewService.get(`/api/v1.0/support-lean?salonId=${param}`)
}

export default {
    getInfoSalon,
}