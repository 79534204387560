export const AIRecordStatistics = () => {
  return (
    <div className={`h-[calc(100vh-64px)]`}>
      <iframe
        title='Thống kê sử dụng mic ghi âm'
        src='https://app.powerbi.com/view?r=eyJrIjoiNDE3ZDg4NTYtNTllNi00MDBiLWJlN2ItYWFkNjBiODZiYTNkIiwidCI6ImZkNjE5MTU3LWM5MGItNDUwZC1hODI5LTg1OTE2YmExNmRhMyIsImMiOjEwfQ%3D%3D'
        className='!block w-full h-full'
        frameBorder='0'
      ></iframe>
    </div>
  )
}
