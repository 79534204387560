import { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { ColumnConfig } from '../../base/util'
import { SpinIndicator } from 'antd/es/spin'
import type { UploadProps } from 'antd'
import { Spin, notification, Select, InputNumber, TreeSelect, Form, Row, Table, Button, message, Upload } from 'antd'
import { UploadOutlined, SearchOutlined, LoadingOutlined, DeleteOutlined } from '@ant-design/icons'
import {
  getListServices,
  getListSalonFlowService,
  getListServicesFlowSalon,
  GetListServicesV2,
  getListServiceMemberService,
  getListServiceMemberSalon,
  updateMemberServices
} from '../api'
import { getSalons } from 'api/catalog'
import * as XLSX from 'xlsx'
import { removeVietnamese } from '../../../utils/selector/removeVietnamese'

export const ServiceConfigMemberPrice = () => {
  const navigate = useNavigate()
  const [total, setTotal] = useState(0)
  const [reload, setReload] = useState(false)
  const [loading, setLoading] = useState(false)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const antIcon: SpinIndicator = <LoadingOutlined style={{ fontSize: 24 }} spin />
  const PageIndex = searchParams.get('PageIndex')
  const PageSize = searchParams.get('PageSize')
  const [form] = Form.useForm()
  const currentUrl = '/service/config-member'
  const [listSalon, setListSalon] = useState([])
  const [listServices, setListServices] = useState([])
  const [mount, setMount] = useState(false)
  const [data, setData] = useState([])
  const [loadingTable, setLoadingTable] = useState(false)
  const [modalData, setModalData] = useState<any>()
  const [dataChange, setDataChange] = useState([])
  const handleListSalon = async () => {
    try {
      const response: any = await getSalons()
      const { statusCode, data } = response
      if (statusCode === 200) {
        setListSalon(data)
      }
    } catch (error) {
      console.error('Error:', error.message)
    }
  }
  const handleListServices = async () => {
    try {
      const response: any = await GetListServicesV2()
      if (response?.statusCode === 200) {
        setListServices(response?.data?.data?.filter((c) => c.publish === true))
      }
    } catch (error) {
      console.error('Error:', error.message)
    }
  }
  useEffect(() => {
    handleListSalon()
    handleListServices()
  }, [])
  function updateOrAddProductById(products, updatedProduct) {
    // Sử dụng map để tạo một mảng mới với phần tử được cập nhật
    const updatedProducts = products.map((product) => {
      // Kiểm tra nếu productId và salonId của phần tử trùng với productId và salonId của sản phẩm cần cập nhật
      if (product.serviceId === updatedProduct.serviceId && product.salonId === updatedProduct.salonId) {
        // Thực hiện cập nhật
        return { ...product, ...updatedProduct }
      }
      // Nếu không phải sản phẩm cần cập nhật, giữ nguyên phần tử
      return product
    })

    // Kiểm tra xem sản phẩm có tồn tại trong mảng ban đầu không
    const isProductExist = updatedProducts.some(
      (product) => product.serviceId === updatedProduct.serviceId && product.salonId === updatedProduct.salonId
    )

    // Nếu sản phẩm không tồn tại, thêm nó vào cuối mảng
    if (!isProductExist) {
      updatedProducts.push(updatedProduct)
    }

    return updatedProducts
  }
  const handleOnChange = (item: any, field: string, value?: any) => {
    const newData = [...data]
    const targetItem = newData.find(
      (dataItem) => dataItem.serviceId === item.serviceId && dataItem.salonId === item.salonId
    )
    // console.log(item, field, value)
    if (targetItem) {
      switch (field) {
        case 'IsCheckKCS':
          targetItem.IsCheckKCS = !targetItem.IsCheckKCS
          setData(newData)
          break
        case 'silverPrice':
          targetItem.silverPrice = value
          setData(newData)
          break
        case 'goldPrice':
          targetItem.goldPrice = value
          setData(newData)
          break
        case 'diamondPrice':
          targetItem.diamondPrice = value
          setData(newData)
          break
        default:
          break
      }
      const newDataArrayUpdate = updateOrAddProductById(dataChange, targetItem)
      setDataChange(newDataArrayUpdate)
    }
  }
  console.log(dataChange, 'dataChange')
  const column: ColumnConfig[] = [
    {
      title: 'Id Salon',
      dataIndex: 'salonId',
      key: 'salonId'
    },
    {
      title: 'Salon',
      dataIndex: 'salonName',
      key: 'salonName'
    },
    {
      title: 'ID dịch vụ',
      dataIndex: 'serviceId',
      key: 'serviceId'
    },
    {
      title: 'Dịch vụ',
      dataIndex: 'serviceName',
      key: 'serviceName'
    },
    {
      title: 'Giá Salon',
      dataIndex: 'salonPrice',
      key: 'salonPrice'
    },
    {
      title: 'Giá Sliver',
      key: 'silverPrice',
      render: (record) => (
        <InputNumber
          value={record.silverPrice}
          onChange={(value: number) => handleOnChange(record, 'silverPrice', value)}
        />
      )
    },
    {
      title: 'Giá Gold',
      key: 'goldPrice',
      render: (record) => (
        <InputNumber
          value={record.goldPrice}
          onChange={(value: number) => handleOnChange(record, 'goldPrice', value)}
        />
      )
    },
    {
      title: 'Giá Diamond',
      key: 'diamondPrice',
      render: (record) => (
        <InputNumber
          value={record.diamondPrice}
          onChange={(value: number) => handleOnChange(record, 'diamondPrice', value)}
        />
      )
    }
  ]

  const handleChangeValue = (changedValues, onReset: (field: string[]) => void) => {
    if (changedValues?.salonId) {
      onReset(['statusName'])
    }
  }
  const onValuesChange = (changedValues) => {
    const keyName = Object.keys(changedValues)[0]
    const value = changedValues[keyName]

    if (keyName.toLowerCase().includes('date')) {
      setParams({
        ...params
      })
    } else {
      setParams({ ...params, ...changedValues })
    }
    handleChangeValue(changedValues, onReset)
  }
  const onReset = (field: string[]) => {
    form.resetFields([...field])
  }
  const handleFetchListStaff = async (paramsSearch: any) => {
    try {
      console.log(paramsSearch, 'paramsSearch')
      setLoading(true)
      setLoadingTable(true)
      const res: any = await getListServices(paramsSearch)
      setLoading(false)
      if (res) {
        setTotal(res.totalRecords)
        setData(res.data.listData)
      }
    } catch (error) {
      notification.error({
        description: 'Không thể thao tác, vui lòng thử lại sau',
        message: 'Lỗi'
      })
    }
    setLoading(false)
    setReload(false)
    setLoadingTable(false)
  }
  const [params, setParams] = useState<any>({
    PageSize: 10,
    PageIndex: 1
  })
  const onFinish = async () => {
    try {
      console.log('running')
      const value = form.getFieldsValue()
      console.log(value, 'value')
      const result = {
        PageSize: 0,
        PageIndex: 0
      }

      if (!value.serviceId && !value.salonId) {
        return
      }
      result.PageSize = params.PageSize
      result.PageIndex = params.PageIndex

      if (isChooseService) {
        console.log('value.salonId', value.salonId)
        setLoadingTable(true)
        const paramss = { salonIds: value.salonId ? value.salonId.toString() : undefined }
        const response: any = await getListServiceMemberService(value.serviceId, paramss)
        console.log(response)
        setData(response.data)
      } else {
        console.log('key2')
        setLoadingTable(true)
        const paramss = { serviceIds: value.serviceId ? value.serviceId.toString() : undefined }
        const response: any = await getListServiceMemberSalon(value.salonId, paramss)
        console.log(response)
        setData(response.data)
      }
      setLoadingTable(false)
    } catch (error) {
      console.log(error)
      setLoadingTable(false)
    }
  }

  const prefetchDataStaffList = () => {
    const paramsSearchResult = window.location.search.replace('?', '')
    console.log(paramsSearchResult, 'paramsSearchResult')
    if (Object.keys(paramsSearchResult).length > 0) {
      const params = new URLSearchParams(paramsSearchResult)
      const paramsObject = {}
      params.forEach(function (value, key) {
        paramsObject[key] = value
      })

      handleFetchListStaff(paramsObject)
    } else {
      setData([])
    }
    if (!mount) setMount(true)
  }

  useEffect(() => {
    prefetchDataStaffList()
  }, [window.location.search])

  const beforeUpload = (file: any) => {
    try {
      const reader = new FileReader()

      reader.onload = (e) => {
        const binaryString = e.target.result
        const workbook = XLSX.read(binaryString, { type: 'binary' })

        // Lấy dữ liệu từ sheet đầu tiên của workbook
        if (!workbook.SheetNames[0]) throw new Error('Sheet names do not ewxits Sheet[0]')
        const firstSheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[firstSheetName]

        // Chuyển đổi dữ liệu từ worksheet thành mảng đối tượng
        const excelData = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
        if (excelData.length < 2) throw new Error('Excel cần chứa dữ liệu !!')
        // Lấy dữ liệu từ phần tử thứ 2 trở đi
        const newData = excelData.slice(1).map((row) => {
          return {
            salonId: row[0],
            salon: listSalon.find((c) => c.id === row[0])?.name,
            serviceId: row[1],
            silverPrice: row[2],
            goldPrice: row[3],
            diamondPrice: row[4]
          }
        })

        // Lưu giá trị vào state
        setData(newData)
      }

      reader.readAsBinaryString(file)
      return false
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    prefetchDataStaffList()
    // cập nhật các value cho thẻ selector
    if (searchParams.get('salonId')) {
      setSalonId(Number(searchParams.get('salonId')))
    }
    if (searchParams.get('salonIds')) {
      const arrayValue = searchParams.get('salonIds')?.split(',')?.map(Number)
      setSalonId(arrayValue)
      setIsChooseService(true)
    }
    if (searchParams.get('serviceId')) {
      setServiceId(Number(searchParams.get('serviceId')))
    }
    if (searchParams.get('serviceIds')) {
      const arrayValue = searchParams.get('serviceIds')?.split(',')?.map(Number)
      setServiceId(arrayValue)
      setIsChooseSalon(true)
    }
  }, [window.location.search])
  // logic khi chọn salon - service
  const [salonId, setSalonId] = useState<any>(null)
  const [serviceId, setServiceId] = useState<any>(null)
  const [loadingSelectSalon, setLoadingSelectSalon] = useState<boolean>(false)
  const [loadingSelectService, setLoadingSelectService] = useState<boolean>(false)
  const [isChooseSalon, setIsChooseSalon] = useState<boolean>(false)
  const [isChooseService, setIsChooseService] = useState<boolean>(false)
  const handleOnchangeServices = async (e: any) => {
    console.log(e)
    if (!isChooseSalon && e) {
      try {
        // console.log(body, 'body')
        setIsChooseService(true)
        setLoadingSelectSalon(true)
        const response = await getListSalonFlowService({}, e)
        // console.log(response, 'response')
        if (response.data) {
          const listSalon = response.data?.map((data) => {
            return {
              id: data.salonId,
              name: data.salonName
            }
          })
          console.log(listSalon, 'listSalon')
          setListSalon(listSalon)
        }
        setLoadingSelectSalon(false)
      } catch (error) {
        setLoadingSelectSalon(false)
        console.error('Error:', error)
        notification.error({
          description: error.message,
          message: 'Lỗi'
        })
      }
    }
    form.setFieldValue('serviceId', e)
    setParams({ ...params, ['serviceId']: e })
  }
  const handleOnchangeSalon = async (e: any) => {
    if (e) {
      if (!isChooseService) {
        try {
          // console.log(body, 'body')
          setIsChooseSalon(true)
          setLoadingSelectService(true)
          const response = await getListServicesFlowSalon({}, e)
          // console.log(response, 'response')
          if (response.data) {
            const listServices = response.data?.map((data) => {
              return {
                value: data.serviceId,
                label: data.serviceName
              }
            })
            console.log(listServices, 'listServices')
            setListServices(listServices)
          }
          setLoadingSelectService(false)
        } catch (error) {
          setLoadingSelectService(false)
          console.error('Error:', error)
          notification.error({
            description: error.message,
            message: 'Lỗi'
          })
        }
      }
    }

    form.setFieldValue('salonId', e)
    setParams({ ...params, ['salonId']: e })
  }
  const handleUpdateConfig = async () => {
    try {
      const bodyFilter = data.map((data) => {
        return {
          salonId: data.salonId,
          serviceId: data.serviceId,
          silverPrice: data.silverPrice,
          goldPrice: data.goldPrice,
          diamondPrice: data.diamondPrice
        }
      })
      const response: any = await updateMemberServices(bodyFilter)
      if (response?.statusCode === 200) {
        notification.success({
          message: response.message
        })
      } else {
        notification.error({
          description: response.message,
          message: 'Error'
        })
      }
    } catch (e) {
      notification.error({
        description: e,
        message: 'Error'
      })
    }
  }
  const handleResetFilter = () => {
    form.resetFields()
    setSalonId(null)
    setServiceId(null)
    setLoadingSelectSalon(false)
    setLoadingSelectService(false)
    setIsChooseSalon(false)
    setIsChooseService(false)
  }
  return (
    <div className='min-h-[100vh] m-auto bg-[#F3F3F3] pb-12'>
      <div className='w-full px-5 py-5 search-wrapper'>
        <Form className='pb-10' form={form} onFinish={onFinish} onValuesChange={onValuesChange}>
          <h2 className='text-2xl font-bold mb-7'>Giá member dịch vụ</h2>

          <Row className=''>
            <Form.Item
              name='salonId'
              label='Chọn Salon'
              className='w-[300px]'
              // key={salonId}
            >
              {isChooseService ? (
                <Select
                  allowClear
                  className='w-full'
                  defaultValue={salonId ? salonId : undefined}
                  placeholder={'Chọn Salon'}
                  fieldNames={{ label: 'name', value: 'id' }}
                  options={listSalon}
                  showSearch
                  optionFilterProp='children'
                  onChange={(e) => handleOnchangeSalon(e)}
                  filterOption={(input, option) => removeVietnamese(option?.name).includes(removeVietnamese(input))}
                  loading={loadingSelectSalon}
                  // disabled={loadingSelectSalon}
                  mode='multiple'
                />
              ) : (
                <Select
                  allowClear
                  className='w-full'
                  defaultValue={salonId ? salonId : undefined}
                  placeholder={'Chọn Salon'}
                  fieldNames={{ label: 'name', value: 'id' }}
                  options={listSalon}
                  showSearch
                  optionFilterProp='children'
                  onChange={(e) => handleOnchangeSalon(e)}
                  filterOption={(input, option) => removeVietnamese(option?.name).includes(removeVietnamese(input))}
                  loading={loadingSelectSalon}
                  // disabled={loadingSelectSalon}
                />
              )}
            </Form.Item>
            <Form.Item
              name='serviceId'
              label='Chọn Dịch vụ'
              className='w-[400px] ml-10'
              key={JSON.stringify(serviceId)}
            >
              {isChooseSalon ? (
                <Select
                  allowClear
                  className='w-full'
                  defaultValue={serviceId ? serviceId : undefined}
                  placeholder={'Chọn dịch vụ'}
                  options={listServices}
                  showSearch
                  optionFilterProp='children'
                  onChange={(e) => handleOnchangeServices(e)}
                  filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  loading={loadingSelectService}
                  // disabled={loadingSelectService}
                  mode='multiple'
                />
              ) : (
                <Select
                  allowClear
                  className='w-full'
                  fieldNames={{ label: 'name', value: 'serviceId' }}
                  defaultValue={serviceId ? serviceId : undefined}
                  placeholder={'Chọn dịch vụ'}
                  options={listServices}
                  showSearch
                  optionFilterProp='children'
                  onChange={(e) => handleOnchangeServices(e)}
                  filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
                  loading={loadingSelectService}
                  // disabled={loadingSelectService}
                />
              )}
            </Form.Item>
            <Button
              type='default'
              htmlType='submit'
              className='w-[160px] bg-[#4096ff] text-white hover:bg-white'
              disabled={loading}
            >
              {!loading ? (
                <div className='flex items-center justify-center hover_icon'>
                  <SearchOutlined className='mr-1 text-white' />
                  Xem dữ liệu
                </div>
              ) : (
                <Spin indicator={antIcon} />
              )}
            </Button>
            <Button
              onClick={() => {
                form.resetFields()
                setIsChooseSalon(undefined)
                setIsChooseService(undefined)
              }}
              type='default'
              className='w-[160px] bg-[#4096ff] text-white hover:bg-white'
            >
              Reset Filter
            </Button>
          </Row>
          <Row className=''>
            <h3>Import file</h3>{' '}
            <a href='/excel/demo-import-member/service.xlsx' className='ml-3 italic underline'>
              Download file mẫu
            </a>
          </Row>
          <Row className='mt-2'>
            <Upload beforeUpload={beforeUpload} showUploadList={false}>
              <Button icon={<UploadOutlined style={{ position: 'relative', top: '-4px' }} />}>Upload File</Button>
            </Upload>
          </Row>
          <div
            className='mt-5 bg-white'
            style={{ boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px', borderRadius: 10 }}
          >
            <Row style={{ display: 'contents' }} gutter={18}>
              <Table
                scroll={{ x: 1500, y: 550 }}
                className='min-w-[1000px] overflow-auto'
                dataSource={data}
                columns={column}
                pagination={false}
                bordered
                loading={loadingTable}
              />
            </Row>
          </div>
        </Form>
        <Button type='primary' onClick={handleUpdateConfig}>
          Cập nhật cấu hình
        </Button>
      </div>
    </div>
  )
}
