/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button, Table } from "antd"
import dayjs from "dayjs"
import { useMediaQuery } from 'react-responsive'
import { useAppSelector } from "redux/store"


const TableListBySalon = (props: any) => {
    const { ListData, params, setIsShowMore } = props
    const isMobile = useMediaQuery({
        query: '(max-width: 500px)'
    })
    const { listSalonDefault } = useAppSelector((state) => state.SCSCSlice)

    const columnsSalon = [
        {
            title: 'Ngày chia',
            dataIndex: 'date',
            key: 'date',
            render: (value, record) => (
                <div>
                    {value}
                </div>
            ),
        },
        {
            title: 'Salon',
            dataIndex: 'salonId',
            key: 'salonId',
            render: (value, record) => (
                <div>
                    {listSalonDefault &&
                        listSalonDefault.find((item: any) => item.id === value)?.name
                    }
                </div>
            ),
        },
        {
            title: 'Sl Bill đã chấm',
            dataIndex: 'numberBillMarked',
            key: 'numberBillMarked',
        },
        {
            title: 'Sl Bill còn lại',
            dataIndex: 'numberBillUnMarked',
            key: 'numberBillUnMarked',
        },
        {
            title: 'Tổng bill',
            dataIndex: 'numberBill',
            key: 'numberBill',
        },
        {
            title: 'Hành động',
            dataIndex: '',
            key: '',
            render: (item, record) => (
                <>
                    <div
                        className='cursor-pointer w-fit text-[#1890FF]'
                        onClick={() => setIsShowMore(record?.salonId, record?.date)}
                    >
                        Xem
                    </div>
                </>
            ),
        },
    ]
    return (
        <div >
            <Table className={isMobile ? 'mt-2 p-2' : 'mt-'} columns={columnsSalon} dataSource={ListData} pagination={false} bordered />
        </div>
    )
}
export default TableListBySalon
