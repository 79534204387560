/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Table, { ColumnsType, TableProps } from 'antd/es/table'
import convert from 'utils/helpers/convert'

interface Props {
    listSalon: any,
    data: any,
    params: any
}

const TableSalary = (props: Props) => {
    const { listSalon, data, params } = props
    console.log(data,)
    const columns: ColumnsType<any> = [
        {
            title: 'STT',
            dataIndex: 'index',
            key: 'index',
            render: (record) => (
                <div>
                    {((params.pageIndex - 1) * params.pageSize) + record + 1}
                </div>
            ),
        },
        {
            title: 'Salon',
            dataIndex: 'salonName',
            key: 'salonName',
            render: (value, record) => (
                <div>
                    {record.salonId === 0 ?
                        <div>Salon mặc định</div>
                        :
                        <div>{value}</div>
                    }
                </div>
            ),
        },
        {
            title: 'Vị trí',
            dataIndex: 'departmentName',
            key: 'departmentName',
        },
        {
            title: 'Level',
            dataIndex: 'levelName',
            key: 'levelName',
        },
        {
            title: 'Lương cứng',
            dataIndex: 'fixedSalary',
            key: 'fixedSalary',
            render: (record) => {
                return (
                    <div >
                        <span> {convert.moneyFormat(record?.toString())} </span>
                    </div>
                )
            }
        },
    ]
    const onChange: TableProps<any>['onChange'] = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra)
    }

    return (
        <div  >
            <Table columns={columns} dataSource={data} onChange={onChange} pagination={false} bordered />
        </div>
    )
}
export default TableSalary
