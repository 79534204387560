import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getUser } from 'api/users'

export const fetchUser = createAsyncThunk('user/fetchUser', async () => {
  const response: any = await getUser()
  const { data, status } = response
  if (status === 0) {
    localStorage.setItem('user', JSON.stringify(response))
    return response
  }
  return undefined
})

const innitState = {
  userInfo: { listSalon: [], staffType: { id: 0 }, fullName: '', userId: '' },
  urlAccess: [],
  role: ''
}

const userSlice = createSlice({
  name: 'user',
  initialState: innitState,
  reducers: {
    setUrlAccess: (state, action) => {
      state.urlAccess = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      state.userInfo = action.payload
      state.userInfo.listSalon = action.payload.listSalon.map((i) => ({ id: i.id, name: i.salonName }))
      state.role = action.payload.listSalon.length > 1 ? 'ADMIN' : 'SM'
    })
  }
})

const { reducer: userReducer, actions } = userSlice
export const { setUrlAccess } = actions
export default userReducer
