import { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs().tz('Europe/London')

import { SearchConfig, TypeSearch } from '../../base/util'
import FormComponent from '../../base/Form'
import { Button, Modal } from 'antd'
import { EyeOutlined } from '@ant-design/icons'
import { getListRecord, getCameraAction } from '../api'
import AiCameraDetail from './Detail'
import { getSalons } from 'api/catalog'

function convertSecondsToTime(seconds: number) {
  const hours = Math.floor(seconds / 3600)
  const remainingSeconds = seconds % 3600
  const minutes = Math.floor(remainingSeconds / 60)
  const newSeconds = remainingSeconds % 60
  const formattedTime =
    hours.toString().padStart(2, '0') +
    ':' +
    minutes.toString().padStart(2, '0') +
    ':' +
    newSeconds.toString().padStart(2, '0')

  return formattedTime
}

export const AiCameraList = () => {
  const [reload, setReload] = useState(false)
  const [listSalon, setListSalon] = useState([])
  const [loadingBtn, setLoadingBtn] = useState()
  const [cameraAction, setCameraAction] = useState([])

  const handleFetchListSalon = async () => {
    try {
      const response: any = await getSalons()
      const { statusCode, data } = response
      if (statusCode === 200) {
        setListSalon(data)
      }
    } catch (error) {
      console.error('Error:', error.message)
    }
  }
  const handleFetchCameraAction = async () => {
    try {
      const response: any = await getCameraAction()
      if (response) {
        setCameraAction(response)
      }
    } catch (error) {
      console.error('Error:', error.message)
    }
  }

  useEffect(() => {
    handleFetchListSalon()
    handleFetchCameraAction()
  }, [])

  const [column, setColumn] = useState<any>([])

  useEffect(() => {
    const dynamicColumnsConfig = cameraAction.map((action, index) => ({
      title: action.Name,
      key: action.Slug,
      render: (record) => {
        const found = record.results.find((el) => el.AiCameraActionId === action.Id)
        if (found.HasAction) {
          return <img src='/icon/success.png' width={20} height={20} alt='img'></img>
        } else {
          return <img src='/icon/fail.png' width={20} height={20} alt='img'></img>
        }
      },
      width: 120
    }))

    // Sử dụng spread operator để ghép mảng và set vào state
    setColumn([
      {
        title: 'Salon ID',
        dataIndex: 'SalonId',
        key: 'SalonId'
      },
      {
        title: 'ID nhân viên',
        dataIndex: 'StaffId',
        key: 'StaffId'
      },
      {
        title: 'ID Bill',
        dataIndex: 'BillId',
        key: 'BillId'
      },
      {
        title: 'ID Khách',
        dataIndex: 'customerId',
        key: 'customerId'
      },
      {
        title: 'Device ID',
        dataIndex: 'deviceId',
        key: 'deviceId'
      },
      {
        title: 'Bắt đầu lúc',
        dataIndex: 'StartTime',
        key: 'StartTime',
        render: (record) => <>{convertSecondsToTime(record)}</>,
        width: 20
      },
      {
        title: 'Kết thúc lúc',
        dataIndex: 'EndTime',
        key: 'EndTime',
        render: (record) => <>{convertSecondsToTime(record)}</>,
        width: 20
      },
      ...dynamicColumnsConfig,
      {
        title: 'Ngày',
        key: 'RecordDate',
        render: (record) => <p>{dayjs(record.CreatedTime).format('DD/MM/YYYY')}</p>
      },
      {
        title: 'Công cụ',
        key: 'action',
        render: (record) => {
          const disabled = record.results ? record.results.some((el) => !el.HasAction) : true
          return (
            <Button onClick={() => handleShowRecord(record.Id)} className='mt-2' disabled={disabled}>
              <EyeOutlined style={{ fontSize: 20, position: 'relative', top: '-3px' }} />
            </Button>
          )
        }
      }
    ])
  }, [cameraAction])

  const handleShowRecord = async (id: number) => {
    setAiCameraId(id)
    setIsModalOpen(true)
  }
  const columnSearch: SearchConfig[] = [
    {
      label: 'Ngày',
      param: 'RecordDate',
      type: TypeSearch.date,
      buttonDate: true
    },
    {
      label: 'Chọn Salon',
      type: TypeSearch.select,
      param: 'SalonId',
      placeholder: 'Chọn Salon',
      options: listSalon,
      fieldNames: { label: 'name', value: 'id' }
      // require: true
    },
    {
      label: 'ID nhân viên',
      type: TypeSearch.input,
      param: 'StaffId',
      placeholder: 'Nhập vào ID nhân viên'
      // require: true
    },
    {
      label: 'ID Bill',
      type: TypeSearch.input,
      param: 'BillId',
      placeholder: 'Nhập vào ID Bill'
      // require: true
    }
  ]

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [aiCameraId, setAiCameraId] = useState(null)
  const handleCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <div className='min-h-[100vh] m-auto bg-[#F3F3F3] pb-12'>
      <FormComponent
        currentUrl='/staff/ai-camera'
        title='Danh sách bản hình AI'
        column={column}
        columnSearch={columnSearch}
        fetchData={getListRecord}
        handleChangeValue={null}
        exportCsv={false}
        linkADD=''
        reload={reload}
        setReload={setReload}
        exportData={null}
      ></FormComponent>

      <Modal title='Xem kết quả' open={isModalOpen} onCancel={handleCancel} footer={null} destroyOnClose width={1200}>
        {aiCameraId && <AiCameraDetail aiCameraId={aiCameraId} />}
      </Modal>
    </div>
  )
}
