export const BIReportServicePage = () => {
  return (
    <div className={`h-[calc(100vh-64px)]`}>
      <iframe
        title='Doanh số dịch vụ'
        src='https://app.powerbi.com/view?r=eyJrIjoiOTFkYWFlZjctOTVlYy00ZTA3LWEyNDItOTFkYWU4YTdmYjBkIiwidCI6ImZkNjE5MTU3LWM5MGItNDUwZC1hODI5LTg1OTE2YmExNmRhMyIsImMiOjEwfQ%3D%3D'
        className='!block w-full h-full'
        frameBorder='0'
      ></iframe>
    </div>
  )
}
