import { useAppDispatch, useAppSelector } from 'redux/store'
import { Form, Input, DatePicker, Select, Radio, Button, Spin } from 'antd'
import dayjs from 'dayjs'
import { editWorkingStaff, getWorkingStaff, getListDepartments } from '../api'
import { useContext, useEffect, useState, useRef } from 'react'
import { antIcon } from '../list/page'
import { PayloadUpdateWorking, SelectASMSType, SelectType } from '../interface'
import { setResource, setStaff } from '../staffSlice'
import { useParams } from 'react-router-dom'
import { AppContext } from 'layouts/DashboardLayout'
import { useMediaQuery } from 'react-responsive'
import { fetchDataByApiKey } from '../hook/fetchViewStaff'
import SlectDepartments from './WorkingEdit'
import { LIST_ID_READONLY_LARK_EMAIL } from 'utils/helpers'

type Props = {
  tab: string
}
export const Working = ({ tab }: Props) => {
  const [form] = Form.useForm()
  const { id } = useParams()
  const ref = useRef<any>()
  const dispatch = useAppDispatch()
  const isMobile = useMediaQuery({
    query: '(max-width: 500px)'
  })
  const [loading, setLoading] = useState(false)
  const [isAddedType, setIsAddedType] = useState(0)
  const [isActive, setIsActive] = useState(0)
  const [isGroup, setIsGroup] = useState(0)
  const [isReadOnlyLarkMail, setIsReadOnlyLarkMail] = useState(false);
  
  const { notification } = useContext(AppContext)

  const { resource, staffDetail }: any = useAppSelector((state) => state.staff)
  const { role, userInfo }: any = useAppSelector((state) => state.user)

  const [jobPosition, setJobPosition] = useState([])
  const [levelList, setLevelList] = useState([])
  const [isChangeType, setIsChangeType] = useState(false)

  const onValuesChange = async (changedValues: any) => {
    if (changedValues?.salonId !== undefined) {
      const result = resource.asms.filter((i: SelectASMSType) => i.salonIds.includes(changedValues?.salonId))
      form.setFieldValue('managerId', result.length > 0 ? result[0].id : null)
    }
    if (changedValues?.addedType !== undefined) {
      setIsAddedType(changedValues?.addedType)
    }
    if (changedValues?.active !== undefined) {
      const isActive = LIST_ID_READONLY_LARK_EMAIL?.includes(changedValues?.active);
      if(isActive) {
        setIsReadOnlyLarkMail(true)
      } else {
        setIsReadOnlyLarkMail(false)
      }
      setIsActive(changedValues?.active)
    }
    if (changedValues?.groupId !== undefined) {
      setIsGroup(changedValues?.groupId)
    }
    if (changedValues?.staffTypeId !== undefined) {
      form.setFieldValue('skillLevel', null)
      const result = [...resource.staffTypeId, ...resource.staffTypeId2].find(
        (i) => i.id === changedValues?.staffTypeId
      )
      const data = result ? result.skillLevelIds : []
      dispatch(setResource({ ...resource, skillLevel: data }))
      form.resetFields(['skillLevelDate'])
    }
    if (changedValues?.skillLevel !== undefined) {
      if (changedValues?.skillLevel === staffDetail?.working?.skillLevel) {
        form.resetFields(['skillLevelDate'])
      } else {
        form.setFieldValue('skillLevelDate', dayjs())
      }
    }
  }

  const onFinish = async (values: any) => {
    let newListJobPosition = []
    if (
      jobPosition[jobPosition?.length - 1]?.departmentId === 0 ||
      jobPosition[jobPosition?.length - 1]?.staffTypeId === 0
    ) {
      newListJobPosition = jobPosition?.filter((item, index) => index !== jobPosition?.length - 1)
      setJobPosition(newListJobPosition)
    }
    const {
      groupId,
      salonId,
      larkRegistrationEmail,
      workingDate,
      seniorityDate,
      addedType,
      requireEnroll,
      appearence,
      staffTypeId,
      skillLevel,
      managerId,
      active,
      parentId,
      recruitmentSourceId,
      deactiveNote,
      skillLevelDate
    } = values

    const payload: PayloadUpdateWorking = {
      jobPosition: newListJobPosition?.length > 0 ? newListJobPosition : jobPosition,
      groupId,
      salonId: groupId === 1 ? salonId : undefined,
      larkRegistrationEmail,
      workingDate: dayjs(workingDate).format('YYYY-MM-DD'),
      seniorityDate: dayjs(seniorityDate).format('YYYY-MM-DD'),
      addedType,
      requireEnroll,
      appearence,
      staffTypeId,
      managerId,
      active,
      parentId: Number(parentId),
      recruitmentSourceId,
      deactiveNote,
      salonName: resource.salonId.find((i: SelectType) => i.id === (groupId === 1 ? salonId : undefined))?.name,
      staffTypeName: [...resource.staffTypeId, ...resource.staffTypeId2].find((i: SelectType) => i.id === staffTypeId)
        ?.name
    }
    if (skillLevel) {
      payload.skillLevel = skillLevel
      payload.skillLevelName = resource.skillLevel.find((i: SelectType) => i.id === skillLevel)?.name
      payload.skillLevelDate = dayjs(skillLevelDate).format('YYYY-MM-DD')
    }
    try {
      setLoading(true)
      const res: any = await editWorkingStaff(id as string, payload)
      notification(
        res.statusCode === 0 ? 'success' : 'error',
        res.statusCode === 0 ? 'Thành công' : 'Thất bại',
        res.statusCode === 0 ? 'Chỉnh sửa nhân sự thành công' : res.message
      )
      if (res.statusCode === 0) {
        dispatch(
          setStaff({
            ...staffDetail,
            working: {
              ...staffDetail.working,
              ...payload,
              skillLevel: payload?.skillLevel,
              skillLevelDate: payload?.skillLevelDate
            }
          })
        )
      }
      setLoading(false)
    } catch (error) {
      if (error.response.status === 403) notification('error', 'Thất bại', 'Bạn không có quyền thực thi')
      setLoading(false)
    }
  }

  const handleFetchStaff = async () => {
    const working = await getWorkingStaff(id as string)
    dispatch(
      setStaff({
        ...staffDetail,
        working: working.data
      })
    )
  }

  const handleFetchData = async () => {
    try {
      await Promise.all([fetchDataByApiKey('listDepartments', getListDepartments, dispatch, notification)])
    } catch (error) {
      notification('error', 'Thất bại', 'Có lỗi xảy ra!')
    }
  }

  useEffect(() => {
    handleFetchStaff()
    handleFetchData()
    if (window.location.search) ref.current.focus()
  }, [])

  useEffect(() => {
    form.setFieldsValue({
      ...staffDetail.working,
      groupId: staffDetail.working?.groupId || undefined,
      salonId: staffDetail.working?.salonId || undefined,
      staffTypeId: staffDetail.working?.staffTypeId || undefined,
      managerId: staffDetail.working?.managerId || undefined,
      skillLevel: staffDetail.working?.skillLevel || undefined,
      recruitmentSourceId: staffDetail.working?.recruitmentSourceId || undefined,
      workingDate: dayjs(staffDetail.working?.workingDate),
      seniorityDate: dayjs(staffDetail.working?.seniorityDate),
      skillLevelDate: staffDetail.working?.skillLevelDate ? dayjs(staffDetail.working?.skillLevelDate) : undefined
    })
    setIsAddedType(staffDetail.working?.addedType)
    setIsGroup(staffDetail.working?.groupId)
    setIsActive(staffDetail.working?.active)
    form.setFieldsValue({
      parentId: staffDetail.working?.parentId
    })
  }, [staffDetail, tab])
  useEffect(() => {
    if (staffDetail?.working?.staffTypeId && resource.allSkillLevel.length > 0) {
      const result = [...resource.staffTypeId, ...resource.staffTypeId2].find(
        (i) => i.id === staffDetail.working.staffTypeId
      )
      dispatch(setResource({ ...resource, skillLevel: result ? result.skillLevelIds : [] }))
    }
  }, [staffDetail, resource.allSkillLevel])

  useEffect(() => {
    if (isAddedType && staffDetail?.working.addedType !== isAddedType) {
      setIsChangeType(!isChangeType)
    }
  }, [isAddedType])

  useEffect(() => {
    if (jobPosition[0]?.staffTypeId !== staffDetail?.working?.jobPosition[0]?.staffTypeId) {
      form.resetFields(['skillLevel'])
      form.resetFields(['skillLevelDate'])
    } else {
      form.setFieldsValue({
        skillLevel: staffDetail.working?.skillLevel || undefined,
        skillLevelDate: staffDetail.working?.skillLevelDate ? dayjs(staffDetail.working?.skillLevelDate) : undefined
      })
    }
  }, [levelList])

  useEffect(() => {
    if (isReadOnlyLarkMail) {
      form.setFields([
        {
          name: 'larkRegistrationEmail',
          errors: [],
        },
      ]);
    }
  }, [isReadOnlyLarkMail]);

  return (
    <>
      {isMobile ? (
        <Form
          onValuesChange={onValuesChange}
          onFinish={onFinish}
          form={form}
          name='basic'
          initialValues={{ remember: true }}
          autoComplete='off'
          className=''
          style={{ width: 'calc(100vw - 40px)' }}
        >
          <div className='working_information'>
            <div className=''>
              <div className='w-[200px] text-[#000] text-left font-bold text-xl'>Thông tin làm việc</div>
              <div className='w-[700px] text-xs text-[#898989] mb-5'>
                <span className='text-[red]'>(*)</span> : Bắt buộc điền thông tin!
              </div>
            </div>
            <div>
              <Form.Item label='Tên đăng nhập:' name='userName'>
                <span>{staffDetail.working?.userName}</span>
                {/* <Input disabled /> */}
              </Form.Item>

              <Form.Item label='Họ và tên nhân viên:' name='idName'>
                <span>{staffDetail.working?.idName}</span>
                {/* <Input disabled placeholder='Họ và tên theo CCCD' /> */}
              </Form.Item>

              {/*  */}
              <Form.Item
                label='Loại mã nhân viên:'
                name='addedType'
                rules={[{ required: true, message: 'Không được để trống' }]}
              >
                <Radio.Group options={resource.addedType} />
              </Form.Item>
              {isAddedType === 2 && (
                <Form.Item
                  label='Nhập ID mã chính:'
                  name='parentId'
                  rules={[{ required: true, message: 'Không được để trống' }]}
                >
                  <Input type='number' />
                </Form.Item>
              )}
              <Form.Item label='Khối:' name='groupId' rules={[{ required: true, message: 'Không được để trống' }]}>
                <Select
                  placeholder='Chọn khối nhân sự'
                  options={resource.groupId}
                  fieldNames={{ label: 'name', value: 'id' }}
                  onChange={(e) => {
                    form.resetFields(['managerId', 'salonId', 'staffTypeId'])
                  }}
                />
              </Form.Item>
              {/* mới */}
              <SlectDepartments
                defaultData={staffDetail?.working}
                listDepartments={resource?.listDepartments}
                group={isGroup}
                defaultgGroup={staffDetail?.working?.groupId}
                listGroupSalon={resource.staffTypeId}
                listGroupOffice={resource.staffTypeId2}
                jobPosition={jobPosition}
                setJobPosition={setJobPosition}
                setLevelList={setLevelList}
                isAddedType={isAddedType}
                setLoading={setLoading}
                isChangeType={isChangeType}
              />
              {isGroup === 1 && levelList?.length > 0 && (
                <Form.Item
                  label={
                    <div>
                      <span className='text-[#ff4d4f]'>*</span> Bậc kỹ năng
                    </div>
                  }
                >
                  <div className='flex gap-x-3'>
                    <Form.Item
                      className='w-[35%]'
                      name='skillLevel'
                      rules={[{ required: true, message: 'Không được để trống' }]}
                    >
                      <Select
                        placeholder='Chọn bậc kỹ năng'
                        options={levelList}
                        fieldNames={{ label: 'name', value: 'id' }}
                      />
                    </Form.Item>
                    <Form.Item
                      className='w-full'
                      label='Ngày lên bậc:'
                      name='skillLevelDate'
                      rules={[{ required: true, message: 'Không được để trống' }]}
                    >
                      <DatePicker
                        clearIcon={false}
                        placeholder='dd-mm-yyyy'
                        format='DD-MM-YYYY'
                        className='w-[135px]'
                      />
                    </Form.Item>
                  </div>
                </Form.Item>
              )}
              {/*  */}

              <Form.Item
                label='Ngày vào làm việc:'
                name='workingDate'
                rules={[{ required: true, message: 'Không được để trống' }]}
              >
                <DatePicker clearIcon={false} placeholder='dd-mm-yyyy' format='DD-MM-YYYY' className='w-[135px]' />
              </Form.Item>
              <Form.Item
                label='Ngày tính thâm niên:'
                name='seniorityDate'
                rules={[{ required: true, message: 'Không được để trống' }]}
              >
                <DatePicker clearIcon={false} placeholder='dd-mm-yyyy' format='DD-MM-YYYY' className='w-[135px]' />
              </Form.Item>

              <Form.Item label='Trạng thái:' name='active' rules={[{ required: true, message: 'Không được để trống' }]}>
                <Radio.Group options={resource.active} />
              </Form.Item>
              <Form.Item
                name='larkRegistrationEmail'
                className='w-[-webkit-fill-available]'
                rules={
                  isReadOnlyLarkMail ? [] : [
                    {
                      pattern: /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$/,
                      message: 'Sai định dạng mail'
                    }
                  ]
                }
                label={<div>Email Lark</div>}
              >
                <Input placeholder='Email Lark' readOnly={isReadOnlyLarkMail}/>
              </Form.Item>
              <Form.Item label='Ghi chú:' name='deactiveNote'>
                <Input ref={ref} />
              </Form.Item>

              <Form.Item
                label='Yêu cầu điểm danh:'
                name='requireEnroll'
                rules={[{ required: true, message: 'Không được để trống' }]}
              >
                <Radio.Group options={resource.requireEnroll} />
              </Form.Item>
              <Form.Item
                label='Đánh giá ngoại hình:'
                name='appearence'
                rules={[{ required: true, message: 'Không được để trống' }]}
              >
                <Radio.Group options={resource.appearence} />
              </Form.Item>
              <Form.Item label='Nguồn lao động:' name='recruitmentSourceId'>
                <Select allowClear options={resource.recruitmentSource} fieldNames={{ label: 'name', value: 'id' }} />
              </Form.Item>
            </div>
            <Button
              className='float-right w-[90px] mt-5 mb-5 bg-[#4096ff] text-white'
              type='primary'
              htmlType='submit'
              disabled={loading}
            >
              {!loading ? 'Hoàn tất' : <Spin className='text-white' indicator={antIcon} />}
            </Button>
          </div>
        </Form>
      ) : (
        <Form
          onValuesChange={onValuesChange}
          onFinish={onFinish}
          form={form}
          name='basic'
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 17 }}
          style={{ maxWidth: 900, margin: 'auto' }}
          initialValues={{ remember: true }}
          autoComplete='off'
          className='float-left'
        >
          <div className='working_information mt-14'>
            <div className='flex items-center gap-6 mb-4'>
              <div className='w-[200px] text-lg text-[#000] ml-[25px] text-right font-bold'>
                <div className='flex items-center justify-end gap-x-3'>
                  <img width={20} height={20} src='/edit.png' alt='icon_edit' />
                  Thông tin làm việc
                </div>
              </div>
              <div className='w-[700px] text-xs text-[#898989]'>
                <span className='text-[red]'>(*)</span> : Bắt buộc điền thông tin!
              </div>
            </div>
            <div>
              <Form.Item label='Tên đăng nhập:' name='userName'>
                <span>{staffDetail.working?.userName}</span>
                {/* <Input disabled /> */}
              </Form.Item>

              <Form.Item label='Họ và tên nhân viên:' name='idName'>
                <span>{staffDetail.working?.idName}</span>
                {/* <Input disabled placeholder='Họ và tên theo CCCD' /> */}
              </Form.Item>

              {/*  */}
              <Form.Item
                label='Loại mã nhân viên:'
                name='addedType'
                rules={[{ required: true, message: 'Không được để trống' }]}
              >
                <Radio.Group options={resource.addedType} />
              </Form.Item>
              {isAddedType === 2 && (
                <Form.Item
                  label='Nhập ID mã chính:'
                  name='parentId'
                  rules={[{ required: true, message: 'Không được để trống' }]}
                >
                  <Input type='number' />
                </Form.Item>
              )}
              <Form.Item label='Khối:' name='groupId' rules={[{ required: true, message: 'Không được để trống' }]}>
                <Select
                  placeholder='Chọn khối nhân sự'
                  options={resource.groupId}
                  fieldNames={{ label: 'name', value: 'id' }}
                  onChange={(e) => {
                    form.resetFields(['managerId', 'salonId', 'staffTypeId'])
                  }}
                />
              </Form.Item>
              {/* mới */}

              <SlectDepartments
                defaultData={staffDetail?.working}
                listDepartments={resource?.listDepartments}
                group={isGroup}
                defaultgGroup={staffDetail?.working?.groupId}
                listGroupSalon={resource.staffTypeId}
                listGroupOffice={resource.staffTypeId2}
                jobPosition={jobPosition}
                setJobPosition={setJobPosition}
                setLevelList={setLevelList}
                isAddedType={isAddedType}
                setLoading={setLoading}
                isChangeType={isChangeType}
              />
              {isGroup === 1 && levelList?.length > 0 && (
                <Form.Item
                  label={
                    <div>
                      <span className='text-[#ff4d4f]'>*</span> Bậc kỹ năng
                    </div>
                  }
                >
                  <div className='flex gap-x-3'>
                    <Form.Item
                      className='w-[35%]'
                      name='skillLevel'
                      rules={[{ required: true, message: 'Không được để trống' }]}
                    >
                      <Select
                        placeholder='Chọn bậc kỹ năng'
                        options={levelList}
                        fieldNames={{ label: 'name', value: 'id' }}
                      />
                    </Form.Item>
                    <Form.Item
                      className='w-full'
                      label='Ngày lên bậc:'
                      name='skillLevelDate'
                      rules={[{ required: true, message: 'Không được để trống' }]}
                    >
                      <DatePicker
                        clearIcon={false}
                        placeholder='dd-mm-yyyy'
                        format='DD-MM-YYYY'
                        className='w-[135px]'
                      />
                    </Form.Item>
                  </div>
                </Form.Item>
              )}

              {/*  */}

    
              <Form.Item
                label='Ngày vào làm việc:'
                name='workingDate'
                rules={[{ required: true, message: 'Không được để trống' }]}
              >
                <DatePicker clearIcon={false} placeholder='dd-mm-yyyy' format='DD-MM-YYYY' className='w-[135px]' />
              </Form.Item>
              <Form.Item
                label='Ngày tính thâm niên:'
                name='seniorityDate'
                rules={[{ required: true, message: 'Không được để trống' }]}
              >
                <DatePicker clearIcon={false} placeholder='dd-mm-yyyy' format='DD-MM-YYYY' className='w-[135px]' />
              </Form.Item>

              <Form.Item label='Trạng thái:' name='active' rules={[{ required: true, message: 'Không được để trống' }]}>
                <Radio.Group options={resource.active} />
              </Form.Item>
              <Form.Item
                name='larkRegistrationEmail'
                className='w-[-webkit-fill-available]'
                rules={
                  isReadOnlyLarkMail ? [] : [
                    {
                      pattern: /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$/,
                      message: 'Sai định dạng mail'
                    }
                  ]
                }
                label={<div>Email Lark</div>}
              >
                <Input placeholder='Email Lark' disabled={isReadOnlyLarkMail}/>
              </Form.Item>
              <Form.Item label='Ghi chú:' name='deactiveNote'>
                <Input ref={ref} />
              </Form.Item>

              <Form.Item
                label='Yêu cầu điểm danh:'
                name='requireEnroll'
                rules={[{ required: true, message: 'Không được để trống' }]}
              >
                <Radio.Group options={resource.requireEnroll} />
              </Form.Item>
              <Form.Item
                label='Đánh giá ngoại hình:'
                name='appearence'
                rules={[{ required: true, message: 'Không được để trống' }]}
              >
                <Radio.Group options={resource.appearence} />
              </Form.Item>
              <Form.Item label='Nguồn lao động:' name='recruitmentSourceId'>
                <Select allowClear options={resource.recruitmentSource} fieldNames={{ label: 'name', value: 'id' }} />
              </Form.Item>
            </div>
            <Button
              className='float-right w-[90px] mt-16 mb-12 bg-[#4096ff] text-white'
              type='primary'
              htmlType='submit'
              disabled={loading}
            >
              {!loading ? 'Hoàn tất' : <Spin className='text-white' indicator={antIcon} />}
            </Button>
          </div>
        </Form>
      )}
    </>
  )
}
