/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState } from "react";
import iconIos from '../../../image/ios-icon.svg'
import iconAndroid from '../../../image/android-icon.svg'
import icon30Shine from '../../../image/icon-30Shine.svg'
interface Props {
    name: string,
    note: string,
    image: string,
    type: number,

}
const DemoNoti = (props: Props) => {
    const { name, note, image, type } = props

    return (
        <div>
            {type === 1 ?
                <div>
                    <div className="flex justify-center" >
                        <img src={iconIos} alt="" />
                    </div>
                    <div className="w-[100%] drop-shadow-xl bg-white rounded-md">
                        <div className="p-2">
                            <div className="flex flex-row h-max">
                                <img src={icon30Shine} alt="" className="rounded-md w-[25px]" />
                                <span className="pl-[5px] overflow-y-hidden font-bold">{name ? name : "Tên thông báo"}</span>
                            </div>
                            <div className="pl-[30px] h-max  w-[100%]">
                                <pre className="break-words whitespace-pre-wrap ">
                                    {note}
                                </pre>
                            </div>
                        </div>
                        <div className="">
                            {image ?
                                <img src={image} alt="" className="w-[100%] rounded-b-md" />
                                :
                                null
                            }
                        </div>
                    </div>
                </div>
                :
                <div>
                    <div className="flex justify-center" >
                        <img src={iconAndroid} alt="" />
                    </div>
                    <div className="w-[100%] drop-shadow-xl bg-white rounded-md">
                        <div className="p-2">
                            <div className="flex flex-row h-max">
                                <img src={icon30Shine} alt="" className="rounded-md w-[25px]" />
                                <span className="pl-[5px] overflow-y-hidden font-bold">{name ? name : "Tên thông báo"}</span>
                            </div>
                            <div className="pl-[30px] h-max break-words w-[100%]">
                                <pre className="break-words whitespace-pre-wrap ">
                                    {note}
                                </pre>
                            </div>
                        </div>
                        <div className="">
                            {image ?
                                <img src={image} alt="" className="w-[100%] p-3 rounded-b-md" />
                                :
                                null
                            }
                        </div>
                    </div>
                </div>
            }

        </div>

    )
}
export default DemoNoti
