import { useAppDispatch, useAppSelector } from 'redux/store'
import { Button, Form, Radio, Select, Spin, Modal } from 'antd'
import { editPermissionStaff, getPermissionStaff, resetPassword, getWorkingStaff } from '../api'
import { useContext, useEffect, useState, CSSProperties } from 'react'
import { antIcon } from '../list/page'
import { PayloadUpdatePermission } from '../interface'
import { setStaff } from '../staffSlice'
import { AppContext } from 'layouts/DashboardLayout'
import { useParams, useSearchParams, useNavigate } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

type Props = {
  tab: string
}
const loaderStyle: CSSProperties = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.8)', // Optional: translucent background
  zIndex: 1000 // Ensure it's on top
}
export const Permission = ({ tab }: Props) => {
  const [form] = Form.useForm()
  const { notification } = useContext(AppContext)
  const [open, setOpen] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isSpin, setIsSpin] = useState(true)
  const [staffData, setStaffData] = useState(null)
  const [isModalNewPassOpen, setIsModalNewPassOpen] = useState(false)
  const [newPass, setNewPass] = useState()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { id } = useParams()
  const [params] = useSearchParams()
  const tabDefault = params.get('tab')
  const isMobile = useMediaQuery({
    query: '(max-width: 500px)'
  })
  const { resource, staffDetail }: any = useAppSelector((state) => state.staff)
  const onFinish = async () => {
    const { isAccountLogin, permission } = form.getFieldsValue()
    if (isAccountLogin === undefined) return
    const payload: PayloadUpdatePermission = { isAccountLogin, permission }
    try {
      setLoading(true)
      const res: any = await editPermissionStaff(id as string, payload)
      notification(
        res.statusCode === 0 ? 'success' : 'error',
        res.statusCode === 0 ? 'Thành công' : 'Thất bại',
        res.statusCode === 0 ? 'Chỉnh sửa nhân sự thành công' : res.message
      )
      if (res.statusCode === 0) {
        dispatch(setStaff({ ...staffDetail, permission: payload }))
      }
      setLoading(false)
    } catch (error) {
      if (error.response.status === 403) notification('error', 'Thất bại', 'Bạn không có quyền thực thi')
      setLoading(false)
    }
  }
  const handleResetPassword = async () => {
    try {
      setConfirmLoading(true)
      const res: any = await resetPassword(id as string, staffData?.staffId as number)
      console.log(res)
      notification(
        res.statusCode === 200 ? 'success' : 'error',
        res.statusCode === 200 ? 'Thành công' : 'Thất bại',
        res.statusCode === 200
          ? 'Tạo mật khẩu mới thành công. Vui lòng sử dụng mật khẩu mới để đăng nhập.'
          : res.message
      )
      if (res.statusCode === 200) {
        setNewPass(res.data)
        setIsModalNewPassOpen(true)
      } else {
        if (tabDefault === '3') {
          navigate(`/staff/detail/${id}`)
        }
      }
      setConfirmLoading(false)
      setOpen(false)
      setIsSpin(false)
    } catch (error) {
      notification('error', 'Thất bại', 'Tạo mật khẩu mới thất bại')
      setConfirmLoading(false)
      setOpen(false)
      setIsSpin(false)
      if (tabDefault === '3') {
        navigate(`/staff/detail/${id}`)
      }
    }
  }
  const handleFetchStaff = async () => {
    const permission = await getPermissionStaff(id as string)
    const working = await getWorkingStaff(id as string)
    dispatch(
      setStaff({
        ...staffDetail,
        working: working.data,
        permission: permission.data
      })
    )
    setStaffData(permission.data)
  }

  useEffect(() => {
    handleFetchStaff()
  }, [])
  useEffect(() => {
    if (tabDefault && staffData) {
      handleResetPassword()
    }
  }, [staffData])
  useEffect(() => {
    form.setFieldsValue({
      userName: staffDetail.working?.userName,
      idName: staffDetail.working?.idName,
      groupId: staffDetail.working?.groupId || undefined,
      staffTypeId: staffDetail.working?.staffTypeId || undefined,
      permission: staffDetail.permission?.permission ? staffDetail.permission?.permission.filter((i) => i) : [],
      isAccountLogin: staffDetail.permission?.isAccountLogin
    })
  }, [staffDetail, tab])
  useEffect(() => {
    const layout__header = window.document.getElementById('layout__header')
    if (layout__header) {
      if (open) layout__header.style.zIndex = '999'
      else layout__header.style.zIndex = '9999'
    }
  }, [open])
  return (
    <>
      {isSpin === true && tabDefault === '3' && (
        <div style={loaderStyle}>
          <Spin tip='Đang tiến hành tạo mới mật khẩu đăng nhập cho nhân viên, vui lòng chờ trong giây lát'>
            Đang tiến hành tạo mới mật khẩu đăng nhập cho nhân viên, vui lòng chờ trong giây lát
          </Spin>
        </div>
      )}
      {isMobile ? (
        <Form
          onFinish={onFinish}
          form={form}
          name='basic'
          initialValues={{ remember: true }}
          autoComplete='off'
          style={{ width: 'calc(100vw - 40px)' }}
        >
          <Spin size='large' />
          <Modal
            title='Mật khẩu mới của bạn là:'
            open={isModalNewPassOpen}
            onOk={async () => {
              try {
                await navigator.clipboard.writeText(newPass)
                notification('success', 'Copied!')
              } catch (err) {
                notification('error', 'Copy error!')
              }
            }}
            onCancel={() => {
              setIsModalNewPassOpen(false)
              if (tabDefault === '3') {
                navigate(`/staff/detail/${id}`)
              }
            }}
            cancelText='Close'
            okText='Copy'
          >
            <p>{newPass}</p>
          </Modal>
          <div className='mt-2'>
            <div className='erp_role'>
              <div className='w-[200px] text-[#000] text-left font-bold text-xl'>Phân quyền ERP</div>
              <div className='w-[700px] text-xs text-[#898989] mb-5'>
                <span className='text-[red]'>(*)</span> : Bắt buộc điền thông tin!
              </div>
              <div>
                <Form.Item label='Tên đăng nhập:' name='userName'>
                  {/* <Input disabled /> */}
                  <span>{staffDetail.working?.userName}</span>
                </Form.Item>
                <Form.Item label='Họ và tên' name='idName'>
                  {/* <Input disabled placeholder='Họ và tên theo CCCD' /> */}
                  <span>{staffDetail.working?.idName}</span>
                </Form.Item>
                <Form.Item label='Khối:' name='groupId'>
                  <span>{resource.groupId.find((i) => i.id === staffDetail.working?.groupId).name || ''}</span>
                  {/* <Select
                disabled
                placeholder='Chọn khối nhân sự'
                options={resource.groupId}
                fieldNames={{ label: 'name', value: 'id' }}
              /> */}
                </Form.Item>
                <Form.Item label='Bộ phận:' name='staffTypeId'>
                  <span>
                    {[...resource.staffTypeId, ...resource.staffTypeId2].find(
                      (i) => i.id === staffDetail.working?.staffTypeId
                    )?.name || ''}
                  </span>

                  {/* <Select
                disabled
                placeholder='Chọn bộ phận'
                options={
                  staffDetail.working?.groupId
                    ? staffDetail.working?.groupId === 1
                      ? resource.staffTypeId
                      : resource.staffTypeId2
                    : []
                }
                fieldNames={{ label: 'name', value: 'id' }}
              /> */}
                </Form.Item>
                <Form.Item
                  label='Loại tài khoản:'
                  name='isAccountLogin'
                  rules={[{ required: true, message: 'Please input your username!' }]}
                >
                  <Radio.Group options={resource.isAccountLogin} />
                </Form.Item>
                <Form.Item label='Phân quyền:' name='permission'>
                  <Select
                    allowClear
                    mode='multiple'
                    placeholder='Chọn phân quyền'
                    options={resource.permission}
                    fieldNames={{ label: 'name', value: 'id' }}
                    showSearch
                    optionFilterProp='children'
                    filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
                  />
                </Form.Item>
              </div>
              <div className='flex justify-between w-[70%] float-right'>
                <Button className='mt-5 mb-5 bg-[#FEA800] hover:bg-white text-white' onClick={() => setOpen(true)}>
                  Tạo mật khẩu mới
                </Button>
                <Button
                  className='w-[90px] mt-5 mb-5 bg-[#4096ff] text-white'
                  type='primary'
                  htmlType='submit'
                  disabled={loading}
                >
                  {!loading ? 'Hoàn tất' : <Spin className='text-white' indicator={antIcon} />}
                </Button>
              </div>
            </div>
          </div>
          <Modal
            centered
            title='Tạo mật khẩu mới'
            open={open}
            onOk={handleResetPassword}
            confirmLoading={confirmLoading}
            onCancel={() => setOpen(false)}
            okText='Hoàn tất'
            cancelText='Hủy'
          >
            <p>Xác nhận tạo mật khẩu mới</p>
          </Modal>
          <Modal
            centered
            title='Mật khẩu mới'
            open={open}
            onOk={handleResetPassword}
            confirmLoading={confirmLoading}
            onCancel={() => setOpen(false)}
            okText='Hoàn tất'
            cancelText='Hủy'
          >
            <p>Xác nhận tạo mật khẩu mới</p>
          </Modal>
        </Form>
      ) : (
        <Form
          onFinish={onFinish}
          form={form}
          name='basic'
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 17 }}
          style={{ maxWidth: 900, margin: 'auto' }}
          initialValues={{ remember: true }}
          autoComplete='off'
          className='float-left'
        >
          <Modal
            title={`Mật khẩu mới của bạn là: ${newPass}`}
            open={isModalNewPassOpen}
            onOk={async () => {
              try {
                await navigator.clipboard.writeText(newPass)
                notification('success', 'Copied!')
              } catch (err) {
                notification('error', 'Copy error!')
              }
            }}
            onCancel={() => {
              setIsModalNewPassOpen(false)
              if (tabDefault === '3') {
                navigate(`/staff/detail/${id}`)
              }
            }}
            cancelText='Close'
            okText='Copy'
          ></Modal>
          <div className='mt-14'>
            <div className='erp_role'>
              <div className='flex items-center gap-6 '>
                <div className='w-[220px] ml-[10px] mb-4 text-lg text-[#000] text-right font-bold'>
                  <div className='flex items-center justify-end gap-x-3'>
                    <img width={20} height={20} src='/edit.png' alt='icon_edit' />
                    Phân quyền ERP
                  </div>
                </div>
                <div className='w-[700px] text-xs text-[#898989] mb-4'>
                  <span className='text-[red] '>(*)</span> : Bắt buộc điền thông tin!
                </div>
              </div>
              <div>
                <Form.Item label='Tên đăng nhập:' name='userName'>
                  {/* <Input disabled /> */}
                  <span>{staffDetail.working?.userName}</span>
                </Form.Item>
                <Form.Item label='Họ và tên' name='idName'>
                  {/* <Input disabled placeholder='Họ và tên theo CCCD' /> */}
                  <span>{staffDetail.working?.idName}</span>
                </Form.Item>
                <Form.Item label='Khối:' name='groupId'>
                  <span>{resource.groupId.find((i) => i.id === staffDetail?.working?.groupId)?.name || ''}</span>
                  {/* <Select
                disabled
                placeholder='Chọn khối nhân sự'
                options={resource.groupId}
                fieldNames={{ label: 'name', value: 'id' }}
              /> */}
                </Form.Item>
                <Form.Item label='Bộ phận:' name='staffTypeId'>
                  <span>
                    {[...resource.staffTypeId, ...resource.staffTypeId2].find(
                      (i) => i.id === staffDetail.working?.staffTypeId
                    )?.name || ''}
                  </span>

                  {/* <Select
                disabled
                placeholder='Chọn bộ phận'
                options={
                  staffDetail.working?.groupId
                    ? staffDetail.working?.groupId === 1
                      ? resource.staffTypeId
                      : resource.staffTypeId2
                    : []
                }
                fieldNames={{ label: 'name', value: 'id' }}
              /> */}
                </Form.Item>
                <Form.Item
                  label='Loại tài khoản:'
                  name='isAccountLogin'
                  rules={[{ required: true, message: 'Please input your username!' }]}
                >
                  <Radio.Group options={resource.isAccountLogin} />
                </Form.Item>
                <Form.Item label='Phân quyền:' name='permission'>
                  <Select
                    allowClear
                    mode='multiple'
                    placeholder='Chọn phân quyền'
                    options={resource.permission}
                    fieldNames={{ label: 'name', value: 'id' }}
                    showSearch
                    optionFilterProp='children'
                    filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
                  />
                </Form.Item>
              </div>
              <div className='flex justify-between w-[70%] float-right'>
                <Button className='mt-16 mb-12 bg-[#FEA800] hover:bg-white text-white' onClick={() => setOpen(true)}>
                  Tạo mật khẩu mới
                </Button>
                <Button
                  className='w-[90px] mt-16 mb-12 bg-[#4096ff] text-white'
                  type='primary'
                  htmlType='submit'
                  disabled={loading}
                >
                  {!loading ? 'Hoàn tất' : <Spin className='text-white' indicator={antIcon} />}
                </Button>
              </div>
            </div>
          </div>
          <Modal
            centered
            title='Tạo mật khẩu mới'
            open={open}
            onOk={handleResetPassword}
            confirmLoading={confirmLoading}
            onCancel={() => setOpen(false)}
            okText='Hoàn tất'
            cancelText='Hủy'
          >
            <p>Xác nhận tạo mật khẩu mới</p>
          </Modal>
        </Form>
      )}
    </>
  )
}
