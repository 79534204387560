import getInstanceAxios from 'utils/requestManager/RequestGlobalConfig'

interface paramsProps {
  page: number
  pageSize: number
}

export const convertParamsAxios = (params: any) => {
  const queryString = Object.keys(params)
    .map((key) => {
      const value = params[key]
      if (Array.isArray(value)) {
        return value.map((item) => `${key}=${item}`).join('&')
      }
      return value !== undefined ? `${key}=${value}` : undefined
    })
    .filter((i) => i !== undefined)
    .join('&')
  return queryString
}

export const ApiReport = getInstanceAxios(process.env.REACT_APP_API_REPORT)

export const getListReport = (params: any) => {
  return ApiReport.get(`/api/debt-report?${convertParamsAxios(params)}`)
}

export const exportToExecl = (params: any) => {
  return ApiReport.get(`/api/debt-report/excel?${convertParamsAxios(params)}`)
}
