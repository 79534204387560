import { StaffRequestView } from 'utils/ManagementEndpointRequest/TimelineSocket'
import getInstanceAxios from 'utils/requestManager/RequestGlobalConfig'

export const ApiNotification = getInstanceAxios(process.env.REACT_APP_API_NOTIFICATION)
// export const UploadExcel = getInstanceAxios(process.env.REACT_APP_API_UPLOAD_CSV_DATA)

export const convertParamsAxios = (params: any) => {
  const queryString = Object.keys(params)
    .map((key) => {
      const value = params[key]
      if (Array.isArray(value)) {
        return value.map((item) => `${key}=${item}`).join('&')
      }
      return value !== undefined ? `${key}=${value}` : undefined
    })
    .filter((i) => i !== undefined)
    .join('&')
  return queryString
}

export const getSalons = () => {
  return StaffRequestView.get(`/v1/salons`)
}

export const createNoti = (param: any) => {
  return ApiNotification.post(`/api/notification`, param)
}

export const getListNoti = (params: any) => {
  return ApiNotification.get(`/api/notification/list-noti?${convertParamsAxios(params)}`)
}

export const getPopupNoti = (param: any) => {
  return ApiNotification.get(`api/notification/detail-by-id?id=${param}`)
}

// export const uploadExcel = (param: any, formData: any) => {
//   return UploadExcel.post(`/notification/import?notiId=${param}`, formData, {
//     headers: {
//       'Content-Type': 'multipart/form-data',
//     },
//   })
// }

export const getPopupNotiEdit = (param: any) => {
  return ApiNotification.get(`/api/notification?id=${param}`)
}

export const updateNoti = (param) => {
  return ApiNotification.put(`/api/notification`, param)
}

export const stopNoti = (param: any) => {
  return ApiNotification.get(`/api/notification/stop?id=${param}`)
}