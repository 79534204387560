import { useAppDispatch, useAppSelector } from 'redux/store'
import { Form, Input, Select, Table, Tabs } from 'antd'
import { useContext, useEffect } from 'react'
import { getLogHistory, getLogWorkingHistory } from '../api'
import { setStaff } from '../staffSlice'
import { AppContext } from 'layouts/DashboardLayout'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { useMediaQuery } from 'react-responsive'
type Props = {
  tab: string
}
export const History = ({ tab }: Props) => {
  const [form] = Form.useForm()
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const { notification } = useContext(AppContext)
  const { resource, staffDetail }: any = useAppSelector((state) => state.staff)
  const isMobile = useMediaQuery({
    query: '(max-width: 500px)'
  })
  const handleFetchDataHistory = async () => {
    if (id) {
      try {
        const [upgrade, changeStatus, changePosition, impact] = await Promise.all([
          getLogWorkingHistory(id, 1),
          getLogWorkingHistory(id, 2),
          getLogWorkingHistory(id, 3),
          getLogHistory(id)
        ])
        dispatch(
          setStaff({
            ...staffDetail,
            log: {
              upgrade: upgrade.data,
              changeStatus: changeStatus.data,
              changePosition: changePosition.data,
              impact: impact.data
            }
          })
        )
      } catch (error) {
        notification('error', 'Thất bại', 'Có lỗi xảy ra!')
      }
    }
  }

  useEffect(() => {
    form.setFieldsValue({
      userName: staffDetail.working?.userName,
      idName: staffDetail.working?.idName,
      groupId: staffDetail.working?.groupId || undefined,
      staffTypeId: staffDetail.working?.staffTypeId || undefined
    })
  }, [staffDetail, tab])
  useEffect(() => {
    if (tab === '4') handleFetchDataHistory()
  }, [tab])
  const columns = {
    upgrade: [
      {
        title: 'Ngày lên bậc',
        dataIndex: 'logDate',
        key: 'logDate',
        render: (logDate) => dayjs(logDate).format('YYYY-MM-DD')
      },
      {
        title: 'Người thực hiện',
        dataIndex: 'userAction',
        key: 'userAction'
      },
      {
        title: 'Mô tả',
        dataIndex: 'data',
        key: 'data',
        render: (data) => (
          <div className='bg-[#e6f7ff] rounded w-fit text-[#1890ff] border border-[#91d5ff] pl-2 pr-2'>{data}</div>
        )
      }
    ],
    changeStatus: [
      {
        title: 'Thời gian',
        dataIndex: 'logDate',
        key: 'logDate',
        render: (logDate) => dayjs(logDate).format('YYYY-MM-DD HH:mm:ss')
      },
      {
        title: 'Người thực hiện',
        dataIndex: 'userAction',
        key: 'userAction'
      },
      {
        title: 'Mô tả',
        dataIndex: 'data',
        key: 'data',
        render: (data) => (
          <div className='bg-[#e6f7ff] rounded w-fit text-[#1890ff] border border-[#91d5ff] pl-2 pr-2'>{data}</div>
        )
      }
    ],
    changePosition: [
      {
        title: 'Thời gian',
        dataIndex: 'logDate',
        key: 'logDate',
        render: (logDate) => dayjs(logDate).format('YYYY-MM-DD HH:mm:ss')
      },
      {
        title: 'Người thực hiện',
        dataIndex: 'userAction',
        key: 'userAction'
      },
      {
        title: 'Mô tả',
        dataIndex: 'data',
        key: 'data',
        render: (data) => (
          <div className='bg-[#e6f7ff] rounded w-fit text-[#1890ff] border border-[#91d5ff] pl-2 pr-2'>{data}</div>
        )
      }
    ],
    impact: [
      {
        title: 'Thời gian',
        dataIndex: 'logDate',
        key: 'logDate',
        render: (logDate) => dayjs(logDate).format('YYYY-MM-DD HH:mm:ss')
      },
      {
        title: 'Người thực hiện',
        dataIndex: 'userAction',
        key: 'userAction'
      },
      {
        title: 'Mô tả',
        dataIndex: 'description',
        key: 'description',
        render: (description) => <div className='max-w-[228px]'>{description}</div>
      }
    ]
  }

  return (
    <>
      {isMobile ? (
        <Form
          form={form}
          name='basic'
          initialValues={{ remember: true }}
          autoComplete='off'
          style={{ width: 'calc(100vw - 40px)' }}
        >
          <div className='mb-2'>
            <div className='flex items-baseline gap-6 mt-3'>
              <div className='w-[220px] mb-3 text-left text-lg text-[#000] font-bold'>Lịch sử</div>
            </div>
            <Form.Item labelAlign='left' label='Tên đăng nhập:' name='userName'>
              {/* <Input disabled /> */}
              <span>{staffDetail.working?.userName}</span>
            </Form.Item>
            <Form.Item labelAlign='left' label='Họ và tên' name='idName'>
              {/* <Input disabled placeholder='Họ và tên theo CCCD' /> */}
              <span>{staffDetail.working?.idName}</span>
            </Form.Item>
            <Form.Item labelAlign='left' label='Khối:' name='groupId'>
              <span>{resource.groupId.find((i) => i.id === staffDetail.working?.groupId).name || ''}</span>
            </Form.Item>
            <Form.Item labelAlign='left' label='Bộ phận:' name='staffTypeId'>
              <span>
                {[...resource.staffTypeId, ...resource.staffTypeId2].find(
                  (i) => i.id === staffDetail.working?.staffTypeId
                )?.name || ''}
              </span>
            </Form.Item>
            <Tabs
              className='px-2 pb-2 m-auto mt-4 bg-white rounded '
              defaultActiveKey='1'
              centered
              items={[
                { label: 'Lịch sử lên bậc', value: 'upgrade' },
                { label: 'Lịch sử thay đổi trạng thái', value: 'changeStatus' },
                { label: 'Lịch sử thay đổi vị trí', value: 'changePosition' },
                { label: 'Lịch sử tác động', value: 'impact' }
              ].map((item: any) => {
                return {
                  label: item.label,
                  key: item.value,
                  children: (
                    <div style={{ padding: '5px 10px' }}>
                      {staffDetail.log && (
                        <Table dataSource={staffDetail.log[`${item.value}`]} columns={columns[item.value]} />
                      )}
                    </div>
                  )
                }
              })}
            />
          </div>
        </Form>
      ) : (
        <Form
          form={form}
          name='basic'
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 17 }}
          style={{ width: 655, margin: 'auto' }}
          initialValues={{ remember: true }}
          autoComplete='off'
          className='float-left'
        >
          <div className='mt-[60px] mb-2'>
            <div className='flex items-baseline gap-6 mt-15'>
              <div className='w-[220px] mb-3 text-left text-lg text-[#000] font-bold'>Lịch sử</div>
            </div>
            <Form.Item labelAlign='left' label='Tên đăng nhập:' name='userName'>
              {/* <Input disabled /> */}
              <span>{staffDetail.working?.userName}</span>
            </Form.Item>
            <Form.Item labelAlign='left' label='Họ và tên' name='idName'>
              {/* <Input disabled placeholder='Họ và tên theo CCCD' /> */}
              <span>{staffDetail.working?.idName}</span>
            </Form.Item>
            <Form.Item labelAlign='left' label='Khối:' name='groupId'>
              <span>{resource.groupId.find((i) => i.id === staffDetail.working?.groupId).name || ''}</span>

              {/* <Select
            disabled
            placeholder='Chọn khối nhân sự'
            options={resource.groupId}
            fieldNames={{ label: 'name', value: 'id' }}
          /> */}
            </Form.Item>
            <Form.Item labelAlign='left' label='Bộ phận:' name='staffTypeId'>
              <span>
                {[...resource.staffTypeId, ...resource.staffTypeId2].find(
                  (i) => i.id === staffDetail.working?.staffTypeId
                )?.name || ''}
              </span>
              {/* <Select
            disabled
            placeholder='Chọn bộ phận'
            options={
              staffDetail.working?.groupId
                ? staffDetail.working?.groupId === 1
                  ? resource.staffTypeId
                  : resource.staffTypeId2
                : []
            }
            fieldNames={{ label: 'name', value: 'id' }}
          /> */}
            </Form.Item>
            <Tabs
              className='w-[655px] bg-white rounded px-2 pb-2 m-auto float-right mt-4 '
              defaultActiveKey='1'
              centered
              items={[
                { label: 'Lịch sử lên bậc', value: 'upgrade' },
                { label: 'Lịch sử thay đổi trạng thái', value: 'changeStatus' },
                { label: 'Lịch sử thay đổi vị trí', value: 'changePosition' },
                { label: 'Lịch sử tác động', value: 'impact' }
              ].map((item: any) => {
                return {
                  label: item.label,
                  key: item.value,
                  children: (
                    <div style={{ padding: '5px 10px' }}>
                      {staffDetail.log && (
                        <Table dataSource={staffDetail.log[`${item.value}`]} columns={columns[item.value]} />
                      )}
                    </div>
                  )
                }
              })}
            />
          </div>
        </Form>
      )}
    </>
  )
}
