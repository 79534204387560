import React, { useState, Key, Dispatch, SetStateAction, useRef } from 'react'
import { Button, Modal, Input, Form, notification, InputNumber, Select } from 'antd'
import { validateHairStyleName } from '../utils/validate'
import ApiEndpoint from '../api'
import './style.scss'

type FieldType = {
  title: string
}
interface TitleBoxProps {
  setLoading: Dispatch<SetStateAction<boolean>>
  data: any
  handleGetData: any
}

const TitleBox: React.FC<TitleBoxProps> = ({ setLoading, data, handleGetData }) => {
  const formRef = useRef(null)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [name, setName] = useState('')
  const [initialValues, setInitialValues] = useState({
    title: '',
    type: null,
    standardTime: null
  })
  const [standardTime, setStandardTime] = useState<number>(null)
  const [type, setType] = useState(null)

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    setIsModalOpen(false)
    setLoading(true)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const onFinish = async (values: FieldType) => {
    // Xử lý khi Form hoàn thành
    console.log('Received values:', values)
    setLoading(true)
    setIsModalOpen(false)
    try {
      const body = {
        name: values.title,
        type: type,
        standardTime: standardTime ? standardTime.toString() : null,
        isDelete: false,
        createdBy: 'Admin',
        hairStyleDetails: []
      }
      const response: any = await ApiEndpoint.createUpdateHairStyles(body)
      const { statusCode, data, message } = response
      if (statusCode === 200) {
        // lấy lại dữ liệu kiểu tóc
        notification.success({
          message: 'Thành công',
          description: 'Bạn đã thành công thêm mới kiểu tóc'
        })
        formRef.current.resetFields() // reset form
        setType(null)
        setStandardTime(null)
        handleGetData()
        setLoading(false)
      }
    } catch (error) {
      console.error('Error checking Name:', error)
      setLoading(false)
    }
  }

  const onFinishFailed = (errorInfo: {
    values: FieldType
    errorFields: { name: Key[]; errors: string[] }[]
    outOfDate: boolean
  }) => {
    console.log('Failed:', errorInfo)
  }

  const preventEnterSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setName(value)
  }

  const onChange = (value: number) => {
    console.log('changed', value)
    setStandardTime(value)
  }

  const handleChange = (value: string) => {
    console.log(`selected ${value}`)
    setType(value)
    setStandardTime(null)
    setInitialValues({
      title: '',
      type: null,
      standardTime: null
    })
  }

  return (
    <>
      <div className='title'>
        <h3 className='title-text'>Bộ sưu tập tư vấn kiểu tóc</h3>
        <Button type='primary' className='title-button' onClick={showModal}>
          Thêm kiểu tóc
        </Button>
      </div>
      <Modal title='Thêm kiểu tóc' open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null}>
        <Form
          name='basic'
          initialValues={initialValues}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete='off'
          ref={formRef}
        >
          <div>Tên kiểu tóc</div>
          <Form.Item label='' name='title' rules={[{ validator: (_, value) => validateHairStyleName(value) }]}>
            <Input placeholder='Nhập tên kiểu tóc' onChange={handleInputChange} />
          </Form.Item>

          <div>Thuộc nhóm</div>
          <Form.Item label='' name='type'>
            <Select
              style={{ width: '100%' }}
              defaultValue={null}
              onChange={handleChange}
              options={[
                { value: null, label: 'Kiểu cắt' },
                { value: 1, label: 'Kiểu uốn' },
                { value: 2, label: 'Kiểu nhuộm' }
              ]}
            />
          </Form.Item>
          <div>Thời gian tiêu chuẩn (Phút)</div>
          <Form.Item
            label=''
            name='standardTime'
            rules={[{ required: !standardTime && !type, message: 'Vui lòng nhập số thời gian tiêu chuẩn' }]}
          >
            <InputNumber
              placeholder='Nhập thời gian tiêu chuẩn'
              style={{ width: '100%' }}
              min={1}
              value={standardTime}
              onChange={onChange}
            />
          </Form.Item>
          <Button type='primary' className={'button-submit'} htmlType='submit'>
            Lưu
          </Button>
        </Form>
      </Modal>
    </>
  )
}

export default TitleBox
