/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button, Tag } from 'antd'
import Table, { ColumnsType, TableProps } from 'antd/es/table'
import dayjs from 'dayjs'
import { EditFilled, EditTwoTone } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import convert from 'utils/helpers/convert'

interface Props {
  listNoti?: any
  listDataCN?: any
  setIsStop?: any
  params?: any
}

const TableListCongNo = (props: Props) => {
  const { listNoti, listDataCN, setIsStop, params } = props

  const navigate = useNavigate()
  const checkTime = (time) => {
    const checkTime = dayjs(time).diff(dayjs().add(20, 'minutes')) > 0
    return !checkTime
  }
  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      dataIndex: 'index',
      key: 'index',
      width: 100,
      render: (index, record) => {
        return <div>{(params.PageIndex === 0 ? 0 : params.PageIndex - 1) * params.PageSize + index + 1}</div>
      }
    },
    {
      title: 'Salon',
      dataIndex: 'salonName',
      width: 200,
      key: 'salonName',
      render: (salonName) => {
        return (
          <div>
            <span> {salonName} </span>
          </div>
        )
      }
    },
    {
      title: 'Tổng tiền KH mua TOPUP',
      dataIndex: 'totalMoneyBuyTopup',
      width: 200,
      key: 'totalMoneyBuyTopup',
      render: (totalMoneyBuyTopup) => {
        return (
          <div className='flex justify-center'>
            <span> {convert.moneyFormat(totalMoneyBuyTopup?.toString())} </span>
          </div>
        )
      }
    },
    {
      title: 'Tổng tiền KH tiêu bằng TOPUP',
      dataIndex: 'totalMoneySpendTopup',
      width: 250,
      key: 'totalMoneySpendTopup',
      render: (totalMoneySpendTopup) => {
        return (
          <div className='flex justify-center'>
            <span> {convert.moneyFormat(totalMoneySpendTopup?.toString())} </span>
          </div>
        )
      }
    },
    {
      title: 'Công nợ phải trả 30shine',
      dataIndex: 'totalMoneyPayable',
      width: 200,
      key: 'totalMoneyPayable',
      render: (totalMoneyPayable) => {
        return (
          <div className='flex justify-center'>
            <span> {convert.moneyFormat(totalMoneyPayable?.toString())} </span>
          </div>
        )
      }
    },
    {
      title: 'Công nợ 30Shine cần trả',
      dataIndex: 'totalMoneyReceive',
      width: 200,
      key: 'totalMoneyReceive',
      render: (totalMoneyReceive) => {
        return (
          <div className='flex justify-center'>
            <span> {convert.moneyFormat(totalMoneyReceive?.toString())} </span>
          </div>
        )
      }
    }
  ]
  const onChange: TableProps<any>['onChange'] = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra)
  }

  return (
    <div className='mt-5'>
      <Table
        columns={columns}
        dataSource={listDataCN}
        onChange={onChange}
        pagination={false}
        bordered
        style={{ textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}
      />
    </div>
  )
}
export default TableListCongNo
