/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button, DatePicker, DatePickerProps, Form, Modal, Select, Steps } from "antd"
import { RangePickerProps } from "antd/es/date-picker";
import dayjs from "dayjs";
import { useState } from "react";
import { isDateSameOrAfter } from "utils/helpers";

interface Props {
    setSelectedSkillPoint: any,
    setSelectedDate: any,
    selectedSkillName: any,
    skillPointsList: any,
    isModalOpen: boolean,
    setIsModalOpen: any,
    selectedDate: any,
    selectedSkillPoint: any,
    onSave: any,
    disabledDate: any,
    isEdit: boolean,
    loading: boolean,
    setIsChange: any,
    isChange: boolean
}

const ModalChangeSkill = (props: Props) => {
    const {
        setSelectedSkillPoint,
        setSelectedDate,
        selectedSkillName,
        skillPointsList,
        isModalOpen,
        setIsModalOpen,
        selectedDate,
        selectedSkillPoint,
        onSave,
        disabledDate,
        isEdit,
        loading,
        setIsChange,
        isChange
    } = props

    const disabledDateCheck: RangePickerProps['disabledDate'] = (current) => {
        const compareDate = isEdit ? dayjs(selectedDate) : null; // So sánh nếu isEdit là true
        const today = dayjs();
      
        // Nếu không có ngày nào được kiểm tra, trả về false (cho phép chọn)
        if (!current) return false; 
      
        // Nếu không phải chế độ chỉnh sửa, cho phép chọn từ ngày hiện tại
        if (!isEdit) {
          return current.isBefore(today, 'day'); // Disable các ngày trước hôm nay
        }
      
        // Nếu là chế độ chỉnh sửa và compareDate lớn hơn hoặc bằng ngày hiện tại
        if (compareDate && compareDate.isSameOrAfter(today, 'day')) {
          return current.isBefore(today, 'day'); // Disable các ngày trước hôm nay
        }
      
        // Nếu không, disable tất cả ngày
        return true; 
      };

    const handleOk = (type: number) => {
        onSave(type)
        setIsModalOpen(false)
    };

    const handleCancel = () => {
        setIsModalOpen(false)
    };

    const handleChangeSkill = (value: any) => {
        setSelectedSkillPoint(value)
        setIsChange(false)
    };

    const onChangeDate = (date: any) => {
        setSelectedDate(dayjs(date).format("YYYY-MM-DD"))
        setIsChange(false)
    };

    return (
        <Modal
            title={isEdit ? "Sửa bậc kỹ năng" : "Thêm bậc kỹ năng"}
            okText="Lưu"
            cancelText="Hủy"
            open={isModalOpen}
            onOk={() => handleOk(isEdit ? 2 : 1)}
            okButtonProps={{ disabled: loading || (isEdit && isChange) }}
            onCancel={handleCancel}
        >
            <Form
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
            >
                <Form.Item
                    label="Chọn kỹ năng"
                >
                    {selectedSkillName}
                </Form.Item>
                <Form.Item
                    label="Bậc kỹ năng"
                >
                    <Select
                        value={selectedSkillPoint}
                        placeholder="Chọn bậc kỹ năng"
                        style={{ width: "70%" }}
                        onChange={(value) => handleChangeSkill(value)}
                        options={skillPointsList?.map((item: any) => ({
                            value: item.id,
                            label: item.name,
                        }))}
                    />
                </Form.Item>
                <Form.Item
                    label="Ngày lên bậc"
                >
                    <DatePicker
                        value={selectedDate ? dayjs(selectedDate, "YYYY-MM-DD") : undefined}
                        placeholder="Chọn ngày"
                        format={'YYYY-MM-DD'}
                        style={{ width: "70%" }}
                        allowClear={false}
                        disabledDate={disabledDateCheck}
                        onChange={onChangeDate}
                    />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default ModalChangeSkill
