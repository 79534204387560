import { Table } from 'antd'

const columns = [
    {
        title: 'ID',
        dataIndex: 'staffId',
        key: 'staffId',
    },
    {
        title: 'Tên',
        dataIndex: 'staffName',
        key: 'staffName',
    },
    {
        title: 'Đội',
        dataIndex: 'group',
        key: 'group',
    },
    {
        title: 'Trạng thái',
        dataIndex: 'status',
        key: 'status',
    },
    {
        title: 'Thời gian sẵn sàng',
        dataIndex: 'timeReady',
        key: 'timeReady',
    }
]
const columns2 = [
    {
        title: 'ID',
        dataIndex: 'staffId',
        key: 'staffId',
    },
    {
        title: 'Tên',
        dataIndex: 'staffName',
        key: 'staffName',
    },
    {
        title: 'Đội',
        dataIndex: 'group',
        key: 'group',
    },
    {
        title: 'Trạng thái',
        dataIndex: 'status',
        key: 'status',
    },
]
interface Props {
    skinnerReadyInfoList: any,
    stylistReadyInfoList: any,
    supporterInfoList: any,
}

export default function StaffReady(props: Props) {
    const { skinnerReadyInfoList, stylistReadyInfoList, supporterInfoList } = props

    return (
        <div>
            <div className='mt-5 text-xl'>THÔNG TIN NHÂN VIÊN THEO THỜI GIAN SẴN SÀNG</div>
            <div className='mt-5 text-lg'>SKINNER</div>
            <Table className='mt-2 ' scroll={{ x: 500 }} columns={columns} dataSource={skinnerReadyInfoList} pagination={false} bordered />
            <div className='mt-5 text-lg'>STYLIST</div>
            <Table className='mt-2 ' scroll={{ x: 500 }} columns={columns} dataSource={stylistReadyInfoList} pagination={false} bordered />
            <div className='mt-5 text-lg'>SUPPORTER</div>
            <Table className='mt-2 ' scroll={{ x: 500 }} columns={columns2} dataSource={supporterInfoList} pagination={false} bordered />

        </div>
    )
}
