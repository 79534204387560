/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { Button, notification, Pagination } from "antd"
import { useEffect, useState } from "react"
import { convertParamsAxios, convertQueryStringToObject, getListStaffSkillLevel } from "./api"
import { useNavigate } from "react-router-dom"
import TableLevel from "./components/TableLevel"

const StaffSkillLevelList = () => {
    const navigate = useNavigate()
    const [data, setData] = useState([])
    const [totalItems, setTotalItems] = useState(0)
    const [params, setParams] = useState<any>({
        pageSize: 10,
        pageIndex: 1
    })
    const [loading, setLoading] = useState(false)
    
    const getData = () => {
        navigate(
            `/staff-skill-salary/skill-level-list?${convertParamsAxios({
                ...params,
                pageSize: 10,
                pageIndex: 1,
            })}`
        )
    }

    // Lấy dữ liệu 
    const getListStaffSalaryData = async (dataSearch) => {
        if (loading) return
        setLoading(true)
        const dataPayload = {
            pagingParams: {
                orderBy: "",
                keyword: "",
                pageIndex: dataSearch.pageIndex,
                isPaging: true,
                pageSize: dataSearch.pageSize
            }
        }
        try {
            const response: any = await getListStaffSkillLevel(dataPayload)
            if (response?.data?.data) {
                const newData = response?.data?.data.map((item: any, index: number) => ({
                    ...item,
                    index: index,
                }))
                setData(newData)
                setTotalItems(response?.data?.meta?.total)
                setLoading(false)
            }
        } catch (error) {
            notification.error({
                message: 'Thông báo!',
                description: <>Lỗi không lấy được Danh sách lương cơ bản!</>
            })
            console.error('Error:', error.message)
            setLoading(false)
        }
    }

    const getDataByParamsSearch = () => {
        const paramsSearchResult: any = convertQueryStringToObject(window.location.search.replace('?', ''))
        if (Object.keys(paramsSearchResult).length > 0) {
            const dataSearch = {
                pageSize: paramsSearchResult?.pageSize ? Number(paramsSearchResult?.pageSize[0]) : 10,
                pageIndex: paramsSearchResult?.pageIndex ? Number(paramsSearchResult?.pageIndex[0]) : 1
            }
            setParams(dataSearch)
            getListStaffSalaryData(dataSearch)
        }
    }

    useEffect(() => {
        if (window.location.search) {
            getDataByParamsSearch()
        }
    }, [window.location.search])

    return (
        <div className='min-h-[100vh] m-auto pb-12'>
            <div className='w-full px-5 py-5 search-wrapper' >
                <div className="p-5 bg-white">
                    <h2 className='text-2xl font-bold mb-7'>Danh sách bậc nhân sự</h2>
                    <div className="flex flex-row gap-5">
                        <Button onClick={() => getData()} type="primary">Xem dữ liệu</Button>
                        <Button onClick={() => navigate(`/staff-skill-salary/skill-level-list/add`)} type="primary">Thêm mới</Button>
                    </div>
                </div>
                <div className="mt-10 bg-white">
                    <TableLevel data={data} params={params} />
                    <div className="grid py-8 justify-items-end">
                        <Pagination
                            className=''
                            current={params.pageIndex}
                            pageSize={params.pageSize}
                            total={totalItems}
                            onChange={(page: number, pageSize: number) => {
                                navigate(
                                    `/staff-skill-salary/skill-level-list?${convertParamsAxios({
                                        ...params,
                                        pageSize: pageSize,
                                        pageIndex: params.pageSize !== pageSize ? 1 : page
                                    })}`
                                )
                                setTimeout(
                                    () =>
                                        window.document.getElementById('body__page').scrollTo({
                                            top: 0,
                                            behavior: 'smooth'
                                        }),
                                    500
                                )
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>

    )
}

export default StaffSkillLevelList