/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { SearchOutlined } from "@ant-design/icons"
import { Button, DatePicker, Form, Input, Pagination, Row, Select, Spin } from "antd"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { useMediaQuery } from "react-responsive"
import { useAppDispatch, useAppSelector } from "redux/store"
import { getListSCSC, getListStaff, convertParamsAxios } from "../../api/api"
import { setListStaff, setSearchParamsSCSC } from '../../slides/SCSCSlice';
import convert from "utils/helpers/convert";
import { useNavigate } from "react-router-dom"
import TableListBill from "./components/tableListBill"
import { DatePickerProps, RangePickerProps } from "antd/es/date-picker"
import { convertQueryStringToObject } from "pages/staff/api"

const ListCheckSCSC = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [form] = Form.useForm()
    const { userInfo } = useAppSelector((state) => state.user)
    const [loading, setLoading] = useState<boolean>(false)
    const { listStaffSCSC, searchParamsSCSC } = useAppSelector((state) => state.SCSCSlice)
    const [listStaffCustom, setListStaffCustom] = useState([])
    const [listIteamStaff, setListItemByStaff] = useState([])
    const [totalItems, setTotalItems] = useState(0)

    const isMobile = useMediaQuery({
        query: '(max-width: 500px)'
    })

    const getListStaffSCSC = async () => {
        if (loading) return
        setLoading(true)
        try {
            const response = await getListStaff();
            if (response) {
                dispatch(setListStaff(response))
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)

        }
    }
    //giới hạn ngày bắn
    const disabledDate: RangePickerProps['disabledDate'] = (current) => {
        // const minDate = dayjs().subtract(1, 'day').startOf('day')
        const maxDate = dayjs().endOf('day')
        // return current && (current < minDate || current > maxDate)
        return current && (current > maxDate)
    }
    // done form
    const onFinish = () => {
        const newUrlParams = convertParamsAxios({
            ...searchParamsSCSC,
            userName: searchParamsSCSC.userName,
            Date: searchParamsSCSC?.Date,
            PageSize: searchParamsSCSC.PageSize,
            PageIndex: searchParamsSCSC.PageIndex,
            Status: searchParamsSCSC?.Status === undefined ? undefined : searchParamsSCSC?.Status
        })

        const currentUrlParams = window.location.search.substring(1)

        if (newUrlParams === currentUrlParams) {
            // getDataByParamsSearch()
            return
        } else {
            navigate(
                `/quality/check-scsc/list?${convertParamsAxios({
                    ...searchParamsSCSC,
                    userName: searchParamsSCSC.userName,
                    Date: searchParamsSCSC?.Date,
                    PageSize: searchParamsSCSC.PageSize,
                    PageIndex: searchParamsSCSC.PageIndex,
                    Status: searchParamsSCSC?.Status === undefined ? undefined : searchParamsSCSC?.Status,
                })}`
            )
        }
    }
    // lay data
    const handleFetchSCSC = async (dataSearch) => {
        setLoading(true)
        try {
            const response: any = await getListSCSC({
                userName: dataSearch.userName || undefined,
                date: dataSearch?.Date,
                isCheck: dataSearch?.Status === undefined ? undefined : dataSearch?.Status,
                pageSize: dataSearch.PageSize,
                page: dataSearch.PageIndex
            })
            const data = response?.data || null
            if (data) {
                const value = data
                setListItemByStaff(value.map((item, index) => {
                    return {
                        ...item,
                        index: index
                    }
                }))
                setTotalItems(response?.totalData)
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
        }
    }

    const onChangeDate = (value: DatePickerProps['value']) => {
        console.log(value, "valuevalue")
        dispatch(
            setSearchParamsSCSC({
                ...searchParamsSCSC,
                Date: value ? dayjs(value).format("DD/MM/YYYY") : undefined,
            })
        )
    }

    const handleChangeStaff = (value: string) => {
        console.log(`selected ${value}`)
        dispatch(
            setSearchParamsSCSC({
                ...searchParamsSCSC,
                userName: value,
            })
        )
    }

    const getDataByParamsSearch = () => {
        const paramsSearchResult: any = convertQueryStringToObject(window.location.search.replace('?', ''))
        if (Object.keys(paramsSearchResult).length > 0 && paramsSearchResult?.userName) {
            const dataSearch = {
                Date: paramsSearchResult?.Date ? paramsSearchResult?.Date[0] : undefined,
                userName: paramsSearchResult?.userName[0],
                Status: paramsSearchResult?.Status ? paramsSearchResult?.Status[0].includes("true") : undefined,
                PageSize: paramsSearchResult?.PageSize ? Number(paramsSearchResult?.PageSize) : 10,
                PageIndex: paramsSearchResult?.PageIndex ? Number(paramsSearchResult?.PageIndex) : 1
            }
            dispatch(
                setSearchParamsSCSC({
                    ...searchParamsSCSC,
                    ...dataSearch
                })
            )
            console.log(dataSearch, "dataSearch")
            handleFetchSCSC(dataSearch)
        } else {
            setListItemByStaff([])
        }
    }

    useEffect(() => {
        if (!listStaffSCSC) {
            getListStaffSCSC()
        } else {
            setListStaffCustom(listStaffSCSC)
        }
    }, [listStaffSCSC])

    useEffect(() => {
        if (userInfo && listStaffSCSC) {
            const user = listStaffSCSC?.find((item: any) => item?.id === Number(userInfo?.userId))
            dispatch(
                setSearchParamsSCSC({
                    ...searchParamsSCSC,
                    userName: user?.userName,
                })
            )
        }
    }, [userInfo, listStaffSCSC])

    useEffect(() => {
        if (searchParamsSCSC.userName) {
            if (window.location.search) {
                getDataByParamsSearch()
            } else {
                const dataSearch = {
                    Date: searchParamsSCSC?.Date ? searchParamsSCSC?.Date : dayjs().format("DD/MM/YYYY"),
                    userName: searchParamsSCSC?.userName,
                    Status: searchParamsSCSC?.Status === undefined ? undefined : searchParamsSCSC?.Status,
                    PageSize: searchParamsSCSC?.PageSize || 10,
                    PageIndex: searchParamsSCSC?.PageIndex || 1
                }
                dispatch(
                    setSearchParamsSCSC({
                        ...searchParamsSCSC,
                        ...dataSearch
                    })
                )
                handleFetchSCSC(dataSearch)
            }
        }
    }, [searchParamsSCSC.userName, window.location.search])

    return (
        <div>
            <Spin tip='Đang xử lý dữ liệu...' spinning={loading}>
                <div className={isMobile ? 'w-full search-wrapper' : 'w-full px-5 py-5 search-wrapper'}>
                    <Form className='pb-10' form={form} onFinish={onFinish}>
                        <div className='text-2xl font-semibold max-sm:pl-2'>Check SCSC</div>
                        <div className='mt-3 bg-white ' style={{ padding: isMobile ? '20px 0px 50px 0px' : '20px 20px 100px 20px' }}>
                            <Row >
                                <div className={isMobile ? 'flex flex-wrap px-4 justify-between	' : 'flex flex-row gap-x-3'}>
                                    <Form.Item
                                        label=''
                                        style={{ width: isMobile ? '45%' : '100%' }}
                                    >
                                        <DatePicker
                                            value={searchParamsSCSC?.Date ? dayjs(searchParamsSCSC.Date, "DD/MM/YYYY") : undefined}
                                            disabledDate={disabledDate}
                                            style={{ width: '100%' }}
                                            placeholder="Chọn ngày"
                                            format={"DD/MM/YYYY"}
                                            onChange={onChangeDate}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label=''
                                        style={{ width: isMobile ? '45%' : '100%' }}
                                    >
                                        <Select
                                            showSearch
                                            allowClear
                                            value={searchParamsSCSC?.userName}
                                            placeholder="Chọn người chấm"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                convert.removeVietnameseAccent(option?.label ?? '')
                                                    .indexOf(convert.removeVietnameseAccent(input)) >= 0
                                            }
                                            options={listStaffCustom.map((item, index) => {
                                                return {
                                                    ...item,
                                                    key: index,
                                                    value: item.userName,
                                                    label: `(${item.id}) ${item.userName}`,
                                                };
                                            })}
                                            onChange={handleChangeStaff}
                                            disabled
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label=''
                                        style={{ width: isMobile ? '45%' : '100%' }}
                                    >
                                        <Select
                                            placeholder="Chọn trạng thái"
                                            allowClear
                                            value={searchParamsSCSC?.Status}
                                            onChange={
                                                (e) => {
                                                    dispatch(
                                                        setSearchParamsSCSC({
                                                            ...searchParamsSCSC,
                                                            Status: e === undefined ? undefined : e,
                                                        })
                                                    )
                                                }
                                            }
                                            options={[
                                                {
                                                    value: true,
                                                    label: 'Đã chấm',
                                                },
                                                {
                                                    value: false,
                                                    label: 'Chưa chấm',
                                                },
                                            ]}
                                        />
                                    </Form.Item>
                                    <Button
                                        type='default'
                                        htmlType='submit'
                                        className='w-[200px] bg-[#4096ff] text-white hover:bg-white'
                                        disabled={loading || searchParamsSCSC?.userName?.length === 0}
                                    >
                                        <div className='flex items-center justify-center hover_icon'>
                                            <SearchOutlined className='mr-1 text-white' />
                                            Xem dữ liệu
                                        </div>

                                    </Button>
                                </div>
                            </Row>
                            <TableListBill ListData={listIteamStaff} params={searchParamsSCSC} />
                            <div className="grid mt-8 justify-items-end">
                                <Pagination
                                    className=''
                                    current={searchParamsSCSC.PageIndex}
                                    pageSize={searchParamsSCSC.PageSize}
                                    total={totalItems}
                                    onChange={(page: number, pageSize: number) => {
                                        dispatch(
                                            setSearchParamsSCSC({
                                                ...searchParamsSCSC,
                                                PageSize: pageSize,
                                                PageIndex: searchParamsSCSC.PageSize !== pageSize ? 1 : page
                                            })
                                        )
                                        navigate(
                                            `/quality/check-scsc/list/admin?${convertParamsAxios({
                                                ...searchParamsSCSC,
                                                PageSize: pageSize,
                                                PageIndex: searchParamsSCSC.PageSize !== pageSize ? 1 : page
                                            })}`
                                        )
                                        setTimeout(
                                            () =>
                                                window.document.getElementById('body__page').scrollTo({
                                                    top: 0,
                                                    behavior: 'smooth'
                                                }),
                                            500
                                        )
                                    }}
                                />
                            </div>
                        </div>
                    </Form>
                </div>
            </Spin>
        </div>
    )
}

export default ListCheckSCSC


