import React, { Suspense } from 'react'
import { useRoutes } from 'react-router-dom'
import Page404 from '../pages/errors/404'
import Page403 from '../pages/errors/403'
import Page500 from '../pages/errors/500'
import ScrollToTop from 'components/SrollToTop'
import Loading from '../components/Loading'
import { SupportLean } from 'pages/SupportLean'
import { useAppSelector } from 'redux/store'

const AuthorizationPage = (element, url: string) => {
  const urlAccess = useAppSelector((state) => state.user.urlAccess)
  if (urlAccess) {
    return urlAccess.find((i) => url.includes(i.path)) ? element : <Page403 />
  }
}

function DefaultRouter() {
  const routes = useRoutes([
    {
      path: '/support-lean',
      element: AuthorizationPage(<SupportLean />, '/support-lean')
    },
    {
      path: '/403',
      element: <Page403 />
    },
    {
      path: '/404',
      element: <Page404 />
    },
    {
      path: '/500',
      element: <Page500 />
    },
   
  ])
  return (
    <>
      <ScrollToTop />
      <Suspense fallback={<Loading />}>{routes}</Suspense>
    </>
  )
}

export default DefaultRouter
