import { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { useAppSelector } from 'redux/store'
import { getListStaffChange } from 'pages/staff/api'
import { ColumnConfig, SearchConfig, TypeSearch } from '../../base/util'
import FormComponent from '../../base/FormV2'
import { Checkbox, Button, notification, Modal } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { getListServiceCatalog, updateStatus, updateServices } from '../api'

export const ServiceListPage = () => {
  const navigate = useNavigate()
  const [reload, setReload] = useState(false)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const PageIndex = searchParams.get('PageIndex')
  const PageSize = searchParams.get('PageSize')
  const column: ColumnConfig[] = [
    // {
    //   title: 'STT',
    //   key: 'index',
    //   render: (item, index, i) => {
    //     return <span>{PageIndex ? i + (Number(PageIndex) - 1) * Number(PageSize) + 1 : i + 1}</span>
    //   }
    // },
    {
      title: 'ID dịch vụ',
      dataIndex: 'serviceId',
      key: 'serviceId'
    },
    {
      title: 'Tên dịch vụ',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Mã dịch vụ',
      dataIndex: 'code',
      key: 'code'
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: 'Giá',
      dataIndex: 'price',
      key: 'price'
    },
    {
      title: 'Publish',
      key: 'publish',
      render: (record) => <Checkbox checked={record.publish} onClick={() => handleChangeStatus(record, 'publish')} />
    },
    {
      title: 'Chấm SCSC',
      key: 'isCheckKCS',
      render: (record) => (
        <Checkbox checked={record.isCheckKCS} onClick={() => handleChangeStatus(record, 'isCheckKCS')} />
      )
    },
    {
      title: 'Chấm Uốn',
      key: 'isCheckCurling',
      render: (record) => (
        <Checkbox checked={record.isCheckCurling} onClick={() => handleChangeStatus(record, 'isCheckCurling')} />
      )
    },
    {
      title: 'Công cụ',
      key: 'Publish',
      render: (record) => (
        <Button onClick={() => navigate(`/service/detail/${record.id}`)}>
          <EditOutlined />
        </Button>
      )
    }
  ]
  const columnSearch: SearchConfig[] = [
    {
      label: 'Tên dịch vụ, ID dịch vụ',
      type: TypeSearch.input,
      param: 'filter',
      placeholder: 'Nhập vào tên dịch vụ, ID dịch vụ'
      // require: true
    }
  ]
  const handleChangeValue = (changedValues, onReset: (field: string[]) => void) => {
    if (changedValues?.salonId) {
      onReset(['statusName'])
    }
  }
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalData, setModalData] = useState<any>()
  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }
  const handleChangeStatus = (record: any, state: string) => {
    let text = ''
    let body = {}
    console.log(state, 'state')
    console.log(record, 'record')
    switch (state) {
      case 'publish':
        if (record.publish) {
          text = `Thay đổi Publish dịch vụ: ${record.name} sang ẩn ?`
        } else {
          text = `Thay đổi Publish dịch vụ: ${record.name} sang hiện ?`
        }
        body = {
          publish: !record.publish
        }
        break
      case 'isCheckKCS':
        if (record.isCheckKCS) {
          text = `Bỏ xác nhận dịch vụ: ${record.name} là có Chấm SCSC ? `
        } else {
          text = `Xác nhận dịch vụ: ${record.name} là có Chấm SCSC ?`
        }
        body = {
          isCheckKCS: !record.isCheckKCS
        }
        break
      case 'isCheckCurling':
        if (record.isCheckCurling) {
          text = `Bỏ xác nhận dịch vụ: ${record.name} là có Chấm uốn ? `
        } else {
          text = `Xác nhận dịch vụ: ${record.name} là có Chấm uốn ?`
        }
        body = {
          isCheckCurling: !record.isCheckCurling
        }
        break
    }
    const dataModal = {
      text: text,
      servicesId: record.id,
      body: body
    }
    setModalData(dataModal)
    showModal()
  }
  const handleOk = async () => {
    try {
      const body = modalData.body
      // console.log(body, 'body')
      const response = await updateStatus(modalData.servicesId, body)
      // console.log(response, 'response')
      if (response) {
        notification.success({
          description: 'Bạn đã thay đổi trạng thái của Dịch vụ',
          message: 'Thành công'
        })
        setReload(true)
      } else {
        notification.warning({
          description: 'Không thể thay đổi trạng thái của Dịch vụ',
          message: 'Lỗi'
        })
      }
      setIsModalOpen(false)
    } catch (error) {
      console.error('Error:', error)
      notification.error({
        description: error.message,
        message: 'Lỗi'
      })
    }
  }

  return (
    <div className='min-h-[100vh] m-auto bg-[#F3F3F3] pb-12'>
      <FormComponent
        currentUrl='/service/list'
        title='QUẢN LÝ DỊCH VỤ'
        column={column}
        columnSearch={columnSearch}
        fetchData={getListServiceCatalog}
        handleChangeValue={handleChangeValue}
        exportCsv={false}
        linkADD='/service/list/add'
        reload={reload}
        setReload={setReload}
        exportData={null}
      ></FormComponent>
      {modalData && (
        <Modal title='Xác nhận cập nhật' open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
          <p>{modalData.text}</p>
        </Modal>
      )}
    </div>
  )
}
