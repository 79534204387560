/* eslint-disable @typescript-eslint/ban-ts-comment */
'use client'
import './index.scss'
import React, { useContext, useEffect, useState } from 'react'
import { Button, TreeSelect, Form, Input, Select, Spin, InputNumber, Checkbox, notification, Table } from 'antd'
import dayjs from 'dayjs'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { DeleteOutlined } from '@ant-design/icons'
import {
  createProduct,
  getListProductsBrand,
  processingList,
  getListUnit,
  GenerateCodeProduct,
  listProducts,
  getListBranchProducts,
  getListProductsSelector,
  getListProductInventory,
  getListProducMaterialType,
  getListGroupProduct,
  getListCategoryProducts
} from './api'
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface'
import UploadCustom from 'utils/upload/index'
import { removeVietnamese } from '../../utils/selector/removeVietnamese'
import { convertToNumberArray, convertToStringArray } from '../../utils/selector/Array'
import FormItem from 'antd/es/form/FormItem'

const { TextArea } = Input
export const AddProductsPage: React.FC = () => {
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [error, setError] = useState<any>({
    listInventoryType: null,
    // IvMaterialType: null,
    CheckPro: null,
    unit: null,
    CategoryId: null,
    TopupMoney: null,
    // GroupProductId: null,
    Name: null,
    Price: null,
    CoefficientOfWaitingDays: null
  })
  const [initialValues, setInitialValues] = useState<any>()
  // phần thẻ selector
  const [InventoryType, setInventoryType] = useState<any>(1)
  const [listInventoryType, setListInventoryType] = useState([])
  const [IvMaterialType, setIvMaterialType] = useState<string>('')
  const [listIvMaterialType, setListIvMaterialType] = useState([])
  const [unit, setUnit] = useState<string>('')
  const [listUnit, setListUnit] = useState([])
  const [GroupProductId, setGroupProductId] = useState(null)
  const [listGroupProductId, setListGroupProductId] = useState([])
  const [CategoryId, setCategoryId] = useState<string>('')
  const [listCategoryId, setListCategoryId] = useState([])
  const [listBrandId, setListBrandId] = useState([])
  const [BrandId, setBrandId] = useState<string>('')
  // const [code, setCode] = useState<any>()
  // mapping products
  const [listMapingServices, setListMapingServices] = useState<any[]>([])
  const [selectedServices, setSelectedServices] = useState([])
  const [dataSelectServices, setDataListServices] = useState<any[]>([])
  const [listProductsInCombo, setListProductsInCombo] = useState<any[]>([])
  // phần giá trị boolean
  const [loading, setLoading] = useState(false)
  const [imageUrl, setImageUrl] = useState<string>()
  // các trường bolean
  const [typeCheck, setTypeCheck] = useState<number>(1)
  const [CheckCombo, setCheckCombo] = useState<boolean>(false)
  // const [Status, setStatus] = useState<boolean>(true)
  const [Publish, setPublish] = useState<boolean>(true)
  // ghi nhận tiền thu trước
  const [isPrepaid, setIsPrepaid] = useState<boolean>(false)
  // Sản phẩm tồn kho (Hóa chất)
  const [CheckInventoryHC, setCheckInventoryHC] = useState<boolean>(false)
  // Theo dõi mức dùng vật tư
  const [IsCheckVatTu, setIsCheckVatTu] = useState<boolean>(false)
  // Sản phẩm ngừng kinh doanh
  const [StopBusiness, setStopBusiness] = useState<boolean>(false)
  // Sản phẩm topup tiền
  const [isTopupMoneyProduct, setIsTopupMoneyProduct] = useState<boolean>(false)
  const [topupValue, setTopupValue] = useState<any>()
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [code, setCode] = useState<string>('')

  const handleGetListProductsType = async () => {
    try {
      const response: any = await getListProductInventory()
      const { statusCode, data } = response
      if (statusCode === 200) {
        setListInventoryType(data)
      }
    } catch (error) {
      console.error('Error:', error.message)
    }
  }
  const handleGetListIvMaterialType = async () => {
    try {
      const response: any = await getListProducMaterialType()
      const { statusCode, data } = response
      if (statusCode === 200) {
        setListIvMaterialType(data)
      }
    } catch (error) {
      console.error('Error:', error.message)
    }
  }
  const handleGetListUnit = async () => {
    try {
      const response: any = await getListUnit()
      const { statusCode, data } = response
      console.log(data, 'data')
      if (statusCode === 200) {
        setListUnit(data)
      }
    } catch (error) {
      console.error('Error:', error.message)
    }
  }
  const handleGetListProductsGroup = async () => {
    try {
      const response: any = await getListGroupProduct()
      const { statusCode, data } = response
      if (statusCode === 200) {
        setListGroupProductId(data)
      }
    } catch (error) {
      console.error('Error:', error.message)
    }
  }

  const genCateChildren = (itemChild: any[], prefix: string) => {
    return itemChild.map((item) => {
      if (item.children && item.children.length > 0) {
        return {
          title: item.title,
          key: prefix + item.value,
          value: genCateChildren(item.children, 'child')
        }
      } else
        return {
          title: item.title,
          value: prefix + item.value
        }
    })
  }

  const handleGetListCategory = async () => {
    try {
      const response: any = await getListCategoryProducts()
      if (response.statusCode === 200) {
        const dataCate = response.data.map((item) => {
          if (item.children && item.children.length > 0) {
            return {
              title: item.title,
              value: 'parent-' + item.value,
              children: genCateChildren(item.children, 'child-')
            }
          } else
            return {
              title: item.title,
              value: 'parent-' + item.value
            }
        })

        setListCategoryId(dataCate)
      }
    } catch (error) {
      console.error('Error:', error.message)
    }
  }
  const getListBrands = async () => {
    try {
      const response: any = await getListBranchProducts()
      const { statusCode, data } = response
      if (statusCode === 200) {
        setListBrandId(data)
      }
    } catch (error) {
      console.error('Error:', error.message)
    }
  }

  const getListProducts = async () => {
    try {
      const response: any = await listProducts({ page: 0, pageSize: 0 })
      const { statusCode, data } = response
      if (statusCode === 200) {
        const dataselect = data?.data.map((item) => {
          return {
            label: item.name,
            value: item.productId
          }
        })
        setDataListServices(dataselect)
      }
    } catch (error) {
      console.error('Error:', error.message)
    }
  }

  // const generateCode = async () => {
  //   try {
  //     const response: any = await GenerateCodeProduct()
  //     const { statusCode, data } = response
  //     if (statusCode === 200) {
  //       setCode(data)

  //       const initial = {
  //         Code: data
  //       }
  //       setInitialValues(initial)
  //     }
  //   } catch (error) {
  //     console.error('Error:', error.message)
  //   }
  // }

  const hanldeCreateCode = async () => {
    const responseCode: any = await GenerateCodeProduct()
    // console.log(responseCode, 'responseCode')
    if (responseCode.statusCode === 200) {
      setCode(responseCode.data)
    } else {
      notification.error({
        description: responseCode.message,
        message: 'Lỗi sinh mã sản phẩm'
      })
    }
  }

  useEffect(() => {
    handleGetListProductsType()
    handleGetListUnit()
    handleGetListProductsGroup()
    handleGetListCategory()
    getListBrands()
    getListProducts()
    handleGetListIvMaterialType()
    hanldeCreateCode()
  }, [])

  const onFinish = async (values: any) => {
    console.log('Success:', values)
    try {
      setError({
        typeOfSupplies: null,
        listInventoryType: null,
        // IvMaterialType: null,
        CheckPro: null,
        unit: null,
        CategoryId: null,
        TopupMoney: null,
        // GroupProductId: null,
        Name: null,
        Price: null,
        CoefficientOfWaitingDays: null
      })
      const currentError = {
        typeOfSupplies: null,
        listInventoryType: null,
        // IvMaterialType: null,
        CheckPro: null,
        unit: null,
        CategoryId: null,
        TopupMoney: null,
        // GroupProductId: null,
        Name: null,
        Price: null,
        CoefficientOfWaitingDays: null
      }
      if (!InventoryType) {
        currentError.listInventoryType = 'Vui lòng chọn loại sản phẩm'
      }
      if (InventoryType === 2 && !IvMaterialType) {
        currentError.typeOfSupplies = 'Vui lòng chọn loại vật tư'
      }
      // if (!IvMaterialType) {
      //   currentError.IvMaterialType = 'Vui lòng chọn loại sản phẩm'
      // }
      if (typeCheck !== 1 && typeCheck !== 2 && typeCheck !== 0) {
        currentError.CheckPro = 'Vui lòng chọn nhịp kiểm hàng ngày'
      }
      if (!unit) {
        currentError.unit = 'Vui lòng nhập đơn vị sản phẩm'
      }
      if (!CategoryId) {
        currentError.CategoryId = 'Vui lòng nhập danh mục sản phẩm'
      }
      if (isTopupMoneyProduct && topupValue <= 0) {
        currentError.TopupMoney = 'Vui lòng nhập giá trị Topup tiền'
      }
      // if (!GroupProductId) {
      //   currentError.GroupProductId = 'Vui lòng nhập nhóm sản phẩm'
      // }
      if (!values.Name) {
        currentError.Name = 'Vui lòng nhập tên sản phẩm'
      }
      if (!values.Price) {
        currentError.Price = 'Vui lòng nhập giá bán sản phẩm'
      }
      if (!values.CoefficientOfWaitingDays) {
        currentError.CoefficientOfWaitingDays = 'Vui lòng nhập hệ số ngày chờ hàng'
      }

      setError(currentError)
      if (
        !InventoryType ||
        (InventoryType === 2 && !IvMaterialType) ||
        (typeCheck !== 1 && typeCheck !== 2 && typeCheck !== 0) ||
        !unit ||
        !CategoryId ||
        (isTopupMoneyProduct && topupValue <= 0) ||
        // !GroupProductId ||
        !values.Name ||
        !values.Price ||
        !values.CoefficientOfWaitingDays
      ) {
        console.log('co1 loi xay ra trong qua trinh validate')
        return
      }
      // let codePro = '0'
      // const responseCode: any = await GenerateCodeProduct()
      // if (responseCode.statusCode === 200) {
      //   codePro = responseCode.data
      // } else {
      //   notification.error({
      //     description: responseCode.message,
      //     message: 'Lỗi sinh mã sản phẩm'
      //   })
      // }

      const newCategoryId = Number(CategoryId.replace('parent-', '').replace('child-', ''))
      const body = {
        name: values.Name,
        code: code,
        barCode: values.BarCode,
        onWebId: values.OnWebId,
        modelName: values.ModelName,
        volume: values.Volume,
        description: values.Description,
        cost: parseInt(values.Cost),
        order: parseInt(values.Order),
        price: parseFloat(values.Price),
        voucherPercent: values.VoucherPercent,
        forSalary: parseFloat(values.ForSalary),
        coefficientOfWaitingDays: parseInt(values.CoefficientOfWaitingDays),
        defaultMaxNorm: values.DefaultMaxNorm,
        campID: parseInt(values.CampID),
        expDate: values.ExpDate,
        // Images: dataListImageORG, k cập nhật hình ảnh
        inventoryType: InventoryType.toString(),
        ivMaterialType: Number(IvMaterialType),
        unit: unit.toString(),
        groupProductId: GroupProductId,
        categoryId: newCategoryId,
        topupValue: newCategoryId === 156 ? topupValue : undefined,
        brandId: Number(BrandId),
        isCombo: CheckCombo,
        typeCheck: typeCheck,
        // status: Status,
        isPrepaid: isPrepaid ? 1 : 0,
        checkInventoryHC: CheckInventoryHC,
        isCheckVatTu: IsCheckVatTu,
        stopBusiness: StopBusiness ? 1 : 0,
        productCombo: listProductsInCombo,
        image: imageUrl,
        publish: Publish
      }
      console.log(body, 'body')
      const { data, message, statusCode }: any = await createProduct(body)
      if (statusCode === 200) {
        notification.success({
          description: 'Bạn đã thành công tạo mới Sản phẩm',
          message: 'Thành công'
        })
        navigate(`/product/detail/${data.id}`)
      } else {
        notification.error({
          description: message,
          message: 'Lỗi'
        })
      }
    } catch (error) {
      console.error('Error:', error)
      notification.error({
        description: error.message,
        message: 'Lỗi'
      })
    }
  }

  const onValuesChange = async (changedValues: any) => {
    console.log('oke')
  }
  const columns = [
    {
      title: 'STT',
      key: 'index',
      render: (item, index, i) => {
        return <span>{i + 1}</span>
      }
    },
    {
      title: 'Tên sản phẩm',
      dataIndex: 'Name',
      key: 'Name'
    },
    {
      title: 'Mã sản phẩm',
      dataIndex: 'Code',
      key: 'Code'
    },
    {
      title: 'Đơn giá nhập',
      dataIndex: 'Cost',
      key: 'Cost'
    },
    {
      title: 'Xoá',
      key: 'remove',
      render: (item, index, i) => (
        <Button onClick={() => handleDeleteListMappingServices(i)}>
          <DeleteOutlined />
        </Button>
      )
    }
  ]
  const handleDeleteListMappingServices = (index) => {
    const newListMapping = listMapingServices.filter((item, i) => i !== index)
    setListMapingServices(newListMapping)
  }
  const handleAddListMappingServices = () => {
    if (!selectedServices || selectedServices.length === 0) {
      return
    }
    const itemsMappingServices = selectedServices.map((selectedServiceId) => {
      const serviceMapping = dataSelectServices.find((obj) => obj.Id === selectedServiceId)
      if (serviceMapping) {
        return {
          Id: serviceMapping.Id,
          Name: serviceMapping.Name,
          Code: serviceMapping.Code,
          Cost: serviceMapping.Cost
        }
      }
      return null
    })
    const validItemsMappingServices = itemsMappingServices.filter((item) => item !== null)
    if (validItemsMappingServices.length > 0) {
      if (listMapingServices) {
        setListMapingServices([...listMapingServices, ...validItemsMappingServices])
      } else {
        setListMapingServices(validItemsMappingServices)
      }
    }
    // sau khi ấn thêm xong thì sẽ đưa thẻ selector về giá trị ban đầu
    setSelectedServices([])
  }

  const onChangeCategory = (newValue: string) => {
    setCategoryId(newValue)
    console.log(newValue, 'newValue')
  }

  useEffect(() => {
    if (InventoryType) {
      setIvMaterialType(null)
    }
  }, [InventoryType])

  useEffect(() => {
    if (CategoryId) {
      const newCategoryId = Number(CategoryId.replace('parent-', '').replace('child-', ''))
      if (newCategoryId === 156) {
        setIsTopupMoneyProduct(true)
      } else {
        setIsTopupMoneyProduct(false)
      }
    }
  }, [CategoryId])

  useEffect(() => {
    if (selectedServices) {
      const listCombo = dataSelectServices.filter((item) => selectedServices.includes(item.value))
      setListProductsInCombo(
        listCombo.map((item) => {
          return {
            id: item.value,
            number: 1,
            name: item.label
          }
        })
      )
    }
  }, [selectedServices])

  return (
    <div style={{ minHeight: 'calc(100vh)', margin: 'auto' }} className='m-auto pb-28 bg-[#F3F3F3] '>
      <Form
        key={JSON.stringify(initialValues, error)}
        className='xl:ml-[1.5%] 2xl:ml-[1.5%] custom-form-details'
        onValuesChange={onValuesChange}
        form={form}
        name='basic'
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={onFinish}
        autoComplete='off'
        initialValues={initialValues}
      >
        <h3 className='font-bold text-[22px] mt-3 ml-10'>TẠO MỚI THÔNG TIN SẢN PHẨM</h3>
        <div className='mt-12 custom-form-details-form'>
          <div>
            <Form.Item
              label={
                <>
                  <span className='mr-2 text-red-600'>(*)</span> Loại sản phẩm
                </>
              }
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 16 }}
            >
              <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                <div>
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      removeVietnamese(option?.label ?? '').includes(removeVietnamese(input))
                    }
                    value={InventoryType ? InventoryType : undefined}
                    style={{ width: '220px' }}
                    onChange={(value) => setInventoryType(value)}
                    placeholder='Chọn loại sản phẩm'
                    options={listInventoryType}
                  />
                  {error.listInventoryType && (
                    <div className='mt-1 text-xs text-red-600'>{error.listInventoryType}</div>
                  )}
                </div>
                <div>
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      removeVietnamese(option?.label ?? '').includes(removeVietnamese(input))
                    }
                    value={IvMaterialType ? IvMaterialType : undefined}
                    style={{ width: '220px', margin: '0 20px' }}
                    onChange={(value) => setIvMaterialType(value)}
                    placeholder='Chọn loại vật tư'
                    options={listIvMaterialType}
                  />
                  {/* <div className='mt-1 ml-5 text-xs text-red-600'>{error.IvMaterialType}</div> */}
                  {error.typeOfSupplies && <div className='mt-1 ml-5 text-xs text-red-600'>{error.typeOfSupplies}</div>}
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: 20 }}>
                  <span style={{ fontWeight: '500' }}>Combo</span>
                  <Checkbox
                    checked={CheckCombo}
                    style={{ marginLeft: 10, marginTop: 2 }}
                    onChange={() => setCheckCombo(!CheckCombo)}
                  />
                </div>
              </div>
            </Form.Item>
            <Form.Item label='Mã sản phẩm:' labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} key={JSON.stringify(code)}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Input className='w-[160px]' disabled value={code} />
              </div>
            </Form.Item>
            <Form.Item
              label={
                <>
                  <span className='mr-2 text-red-600'>(*)</span> Nhịp kiểm hàng
                </>
              }
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 16 }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: 0 }}>
                  <span style={{ fontWeight: '500' }}>Kiểm hàng ngày</span>
                  <Checkbox
                    checked={typeCheck === 1 ? true : false}
                    style={{ marginLeft: 10, marginTop: 2 }}
                    onChange={() => setTypeCheck(1)}
                  />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: 90 }}>
                  <span style={{ fontWeight: '500' }}>Kiểm khi salon cần</span>
                  <Checkbox
                    checked={typeCheck === 2 ? true : false}
                    style={{ marginLeft: 10, marginTop: 2 }}
                    onChange={() => setTypeCheck(2)}
                  />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: 90 }}>
                  <span style={{ fontWeight: '500' }}>Không cần kiểm</span>
                  <Checkbox
                    checked={typeCheck === 0 ? true : false}
                    style={{ marginLeft: 10, marginTop: 2 }}
                    onChange={() => setTypeCheck(0)}
                  />
                </div>
              </div>
              {error.CheckPro && <div className='mt-1 ml-5 text-xs text-red-600'>{error.CheckPro}</div>}
            </Form.Item>
            <Form.Item
              label={
                <>
                  <span className='mr-2 text-red-600'>(*)</span>Đơn vị
                </>
              }
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 16 }}
            >
              <div>
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    removeVietnamese(option?.label ?? '').includes(removeVietnamese(input))
                  }
                  value={unit ? Number(unit) : undefined}
                  style={{ width: '220px' }}
                  onChange={(value: any) => setUnit(value.toString())}
                  placeholder='Chọn Đơn vị'
                  options={listUnit}
                />
              </div>
              {error.unit && <div className='mt-1 text-xs text-red-600'>{error.unit}</div>}
            </Form.Item>
            <Form.Item
              label={
                <>
                  <span className='mr-2 text-red-600'>(*)</span>Tên danh mục/Tên nhóm sản phẩm
                </>
              }
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 16 }}
            >
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{ width: 'calc(50% - 10px)' }}>
                  <TreeSelect
                    showSearch
                    allowClear
                    className='w-full'
                    value={CategoryId ? CategoryId : null}
                    placeholder='Chọn danh mục'
                    treeData={listCategoryId}
                    onChange={onChangeCategory}
                    treeNodeFilterProp='title'
                    treeDefaultExpandAll
                  />
                  {error.CategoryId && <div className='mt-1 text-xs text-red-600'>{error.CategoryId}</div>}
                </div>
                <div style={{ width: 'calc(50% - 10px)' }}>
                  <Select
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                      removeVietnamese(option?.name ?? '').includes(removeVietnamese(input))
                    }
                    value={GroupProductId ? GroupProductId : undefined}
                    className='w-full'
                    onChange={(value) => setGroupProductId(value)}
                    placeholder='Chọn tên nhóm sản phẩm'
                    options={listGroupProductId}
                    fieldNames={{ label: 'name', value: 'id' }}
                  />
                  {/* {error.GroupProductId && <div className='mt-1 text-xs text-red-600'>{error.GroupProductId}</div>} */}
                </div>
              </div>
            </Form.Item>
            {/* Nhập tiền topup */}
            {isTopupMoneyProduct && (
              <Form.Item
                label={
                  <>
                    <span className='mr-2 text-red-600'>(*)</span>Tiền Topup
                  </>
                }
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 16 }}
              >
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <div style={{ width: 'calc(50% - 10px)' }}>
                    <Input
                      style={{ width: '100%' }}
                      onChange={(e) => setTopupValue(e.target.value.replace(' ', ''))}
                      value={topupValue}
                      placeholder='Nhập tiền Topup'
                    />
                    {error.TopupMoney && <div className='mt-1 text-xs text-red-600'>{error.TopupMoney}</div>}
                  </div>
                </div>
              </Form.Item>
            )}

            <Form.Item
              label={
                <>
                  <span className='mr-2 text-red-600'>(*)</span>Tên sản phẩm /Barcode
                </>
              }
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 16 }}
            >
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div className='w-[50%]'>
                  <Form.Item label='' name='Name' className='w-full'>
                    <Input style={{ width: '100%' }} placeholder='Nhập tên sản phẩm' />
                  </Form.Item>
                  {error.Name && <div className='mt-1 text-xs text-red-600'>{error.Name}</div>}
                </div>

                <Form.Item label='' name='BarCode' style={{ width: 'calc(50% - 10px)' }}>
                  <Input style={{ width: '100%' }} placeholder='Nhập barcode' />
                </Form.Item>
              </div>
            </Form.Item>
            <Form.Item label='Thương hiệu/Map 30shine Store:' labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Select
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    removeVietnamese(option?.label ?? '').includes(removeVietnamese(input))
                  }
                  value={BrandId ? BrandId : undefined}
                  style={{ width: 'calc(50% - 10px', marginTop: -7 }}
                  onChange={(value) => setBrandId(value)}
                  placeholder='Chọn thương hiệu'
                  options={listBrandId}
                />
                <Form.Item label='' name='OnWebId' style={{ width: 'calc(50% - 10px)' }}>
                  <Input style={{ width: '100%' }} placeholder='30shinestore Id' />
                </Form.Item>
              </div>
            </Form.Item>
            <Form.Item label='Tên model/Thể tích:' labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Form.Item label='' name='ModelName' style={{ width: 'calc(50% - 10px)' }}>
                  <Input style={{ width: '100%' }} placeholder='Tên Model' />
                </Form.Item>
                <Form.Item label='' name='Volume' style={{ width: 'calc(50% - 10px)' }}>
                  <Input style={{ width: '100%' }} placeholder='Thể tích' />
                </Form.Item>
              </div>
            </Form.Item>
            <Form.Item label='Mô tả:' name='Description' labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
              <TextArea maxLength={600} placeholder='Nhập mô tả' style={{ height: 120 }} />
            </Form.Item>
            {CheckCombo && (
              <Form.Item label='Sản phẩm:' labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
                <div>
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      removeVietnamese(option?.label ?? '').includes(removeVietnamese(input))
                    }
                    mode='multiple'
                    className='w-full'
                    onChange={(value) => setSelectedServices(value)}
                    placeholder='Lựa chọn Sản phẩm'
                    options={dataSelectServices}
                    value={selectedServices}
                    // fieldNames={{ label: 'name', value: 'id' }}
                  />
                  {/* <Button
                    type='default'
                    className='ml-10'
                    disabled={selectedServices ? false : true}
                    onClick={handleAddListMappingServices}
                  >
                    Thêm sản phẩm
                  </Button> */}
                </div>
                {listMapingServices && listMapingServices.length > 0 && (
                  <Table dataSource={listMapingServices} columns={columns} pagination={false} className='mt-5 mb-5' />
                )}
              </Form.Item>
            )}
            {listProductsInCombo.length > 0 && (
              <Form.Item label='Nhập số lượng sp trong combo' labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
                <div>
                  {listProductsInCombo.map((item: any, index: number) => {
                    return (
                      <div key={index} className='flex py-2 row'>
                        <div className='w-[70%]'>{item.name}:</div>
                        <Input
                          style={{ width: '30%', marginLeft: '10px' }}
                          defaultValue={1}
                          type='number'
                          placeholder='Ví dụ: 1'
                          onChange={(e) => {
                            const newListProductCombo = listProductsInCombo
                            const check = newListProductCombo.find((c) => c.id === item.id)
                            if (check) {
                              check.number = e.target.value
                              setListProductsInCombo(newListProductCombo)
                            }
                          }}
                        />
                      </div>
                    )
                  })}
                </div>
              </Form.Item>
            )}
            <Form.Item label='Giá nhập:' labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Form.Item label='' name='Cost' style={{ width: 'calc(50% - 10px)' }}>
                  <Input style={{ width: '100%' }} placeholder='Ví dụ: 1000000' />
                </Form.Item>
                <span style={{ fontWeight: '500', marginLeft: 20 }}>VNĐ</span>
              </div>
            </Form.Item>
            <Form.Item
              label={
                <>
                  <span className='mr-2 text-red-600'>(*)</span>Giá bán
                </>
              }
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 16 }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Form.Item label='' name='Price' style={{ width: 'calc(50% - 10px)' }}>
                  <Input style={{ width: '100%' }} placeholder='Ví dụ: 1000000' />
                </Form.Item>
                <span style={{ fontWeight: '500', marginLeft: 20 }}>VNĐ</span>
              </div>
              {error.Price && <div className='text-xs text-red-600'>{error.Price}</div>}
            </Form.Item>
            {/* <Form.Item label='Khuyến mại giảm giá:' labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Form.Item label='' name='VoucherPercent' style={{ width: 'calc(50% - 10px)' }}>
                  <Input style={{ width: '100%' }} placeholder='Ví dụ: 5' />
                </Form.Item>
                <span style={{ fontWeight: '500', marginLeft: 20 }}> ( % )</span>
              </div>
            </Form.Item> */}
            <Form.Item label='Chiết khấu lương:' labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Form.Item label='' name='ForSalary' style={{ width: 'calc(50% - 10px)' }}>
                  <Input style={{ width: '100%' }} placeholder='Ví dụ: 5' />
                </Form.Item>
                <span style={{ fontWeight: '500', marginLeft: 20 }}> ( % )</span>
              </div>
            </Form.Item>
            {/* <Form.Item label='Order:' labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
              <Form.Item label='' name='Order' style={{ width: 'calc(50% - 10px)' }}>
                <Input style={{ width: '100%' }} placeholder='Ví dụ: 1' />
              </Form.Item>
            </Form.Item> */}
            <Form.Item
              label={
                <>
                  <span className='mr-2 text-red-600'>(*)</span>Hệ số ngày chờ hàng
                </>
              }
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 16 }}
            >
              <Form.Item label='' name='CoefficientOfWaitingDays' style={{ width: 'calc(50% - 10px)' }}>
                <Input style={{ width: '100%' }} placeholder='Ví dụ: 1' />
              </Form.Item>
              {error.CoefficientOfWaitingDays && (
                <div className='mt-1 text-xs text-red-600'>{error.CoefficientOfWaitingDays}</div>
              )}
            </Form.Item>
            {/* <Form.Item label='Mặc định tồn max:' labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
              <Form.Item label='' name='DefaultMaxNorm' style={{ width: 'calc(50% - 10px)' }}>
                <Input style={{ width: '100%' }} placeholder='Ví dụ: 1' />
              </Form.Item>
            </Form.Item> */}
            <Form.Item label='CampaignID:' name='CampID' labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
              <Input style={{ width: 'calc(50% - 10px' }} placeholder='CampaignID' />
            </Form.Item>
            <Form.Item label='Số ngày sử dụng:' name='ExpDate' labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
              <InputNumber min={1} max={50000} placeholder={'3'} />
            </Form.Item>
            {/* <Form.Item label='Nổi bật:' labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
              <div>
                <Checkbox checked={Status} onChange={() => setStatus(!Status)} />
              </div>
            </Form.Item> */}
            <Form.Item label='Publish:' labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
              <div>
                <Checkbox checked={Publish} onChange={() => setPublish(!Publish)} />
              </div>
            </Form.Item>
            <Form.Item label='Ghi nhận tiền thu trước:' labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
              <div>
                <Checkbox checked={isPrepaid} onChange={() => setIsPrepaid(!isPrepaid)} />
              </div>
            </Form.Item>
            <Form.Item label='Sản phẩm tồn kho (Hóa chất):' labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
              <div>
                <Checkbox checked={CheckInventoryHC} onChange={() => setCheckInventoryHC(!CheckInventoryHC)} />
              </div>
            </Form.Item>
            <Form.Item label='Theo dõi mức dùng vật tư:' labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
              <div>
                <Checkbox checked={IsCheckVatTu} onChange={() => setIsCheckVatTu(!IsCheckVatTu)} />
              </div>
            </Form.Item>
            <Form.Item label='Sản phẩm ngừng kinh doanh:' labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
              <div>
                <Checkbox checked={StopBusiness} onChange={() => setStopBusiness(!StopBusiness)} />
              </div>
            </Form.Item>

            <Form.Item label='Hình ảnh:' name='idNo' labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} className='mt-8'>
              <UploadCustom
                setImageUrl={setImageUrl}
                fileList={fileList}
                setFileList={setFileList}
                classCustomAdd=''
                classCustomDetail=''
              />
            </Form.Item>
          </div>
        </div>
        <Form.Item label='' name='idNo' labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
          <Button
            className=' mt-16 mb-12 bg-[#4096ff] text-white button-submit'
            type='primary'
            htmlType='submit'
            disabled={loading}
          >
            {!loading ? 'Hoàn tất' : <Spin />}
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}
