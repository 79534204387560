/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button, Form, Input, notification, Select, Spin, Tree, TreeSelect } from 'antd'
import { useEffect, useState } from 'react'
import { getListDepartments, getSalons, postNewDepartment, putDepartment } from './api'
import './style.scss'
import { EditOutlined, PlusOutlined } from '@ant-design/icons'
import convert from 'utils/helpers/convert'
import { group } from 'console'

export default function DepartmentConfiguration() {
  const [loading, setLoading] = useState<boolean>(false)
  const [isParent, setIsParent] = useState<boolean>(false)
  const [title, setTitle] = useState('')
  const [treeData, setTreeData] = useState([])
  const [treeData2, setTreeData2] = useState([])
  const [expandedKeys, setExpandedKeys] = useState([])
  const [listDepartments, setListDepartments] = useState([])
  const [type, setType] = useState(0)
  const [listSalon, setListSalon] = useState([])
  const [selectedId, setSelectedId] = useState()
  const [form, setForm] = useState({
    name: '',
    parentId: 0,
    salonId: 0,
    group: 0
  })
  const [isFormChanged, setIsFormChanged] = useState(false)
  const [listGroup, setListGroup] = useState([{ value: 0, label: 'Chọn khối'}, { value: 1, label: 'Salon'}, { value: 2, label: 'Văn phòng'}])
  // Lấy danh sách salon
  const getListSalon = async () => {
    if (loading) return
    setLoading(true)
    try {
      const response: any = await getSalons()
      if (response?.data?.length > 0) {
        const list = response?.data?.map((item: any) => ({
          ...item,
          value: item.id,
          label: item.name
        }))
        const customItem = {
          id: 0,
          name: 'Không phải là Salon',
          value: 0,
          label: 'Không phải là Salon'
        }

        list?.unshift(customItem)
        setListSalon(list)
      }
      setLoading(false)
    } catch (error) {
      console.error('Error:', error.message)
      setLoading(false)
    }
  }
  // Lấy dnah sách phòng ban
  const getListStaffDepartments = async () => {
    if (loading) return
    setLoading(true)
    try {
      const response = await getListDepartments()
      if (response) {
        setListDepartments(response.data)
        const expanded = getExpandedKeys(response.data, 0)
        setExpandedKeys(expanded)
      }
    } catch (error) {
      console.error('Error fetching departments:', error)
    } finally {
      setLoading(false)
    }
  }
  // tạo tree data
  function createDepartmentTree(departments, parentId) {
    return departments
      .filter((item) => item.parentId === parentId)
      .map((item: any, index: number) => ({
        ...item,
        title: (
          <div className='tree-node-content' key={index}>
            <div
              className='flex-1'
              onClick={() => handleButtonClick(item, 3, createDepartmentTree(departments, item.id).length > 0)}
            >
              <span>{item.name}</span>
            </div>
            <div className='flex flex-row gap-2 action-button'>
              {item.salonId === 0 && (
                <Button
                  title='Thêm phòng ban con'
                  className='flex items-center justify-center'
                  onClick={() => handleButtonClick(item, 2, createDepartmentTree(departments, item.id).length > 0)}
                >
                  <PlusOutlined />
                </Button>
              )}
              <Button
                title='Sửa phòng ban'
                className='flex items-center justify-center'
                onClick={() => handleButtonClick(item, 3, createDepartmentTree(departments, item.id).length > 0)}
              >
                <EditOutlined />
              </Button>
            </div>
          </div>
        ),
        value: item.name,
        key: item.id,
        children: createDepartmentTree(departments, item.id)
      }))
  }
  // xử lý nút bấm
  const handleButtonClick = (item, type, isParent) => {
    const DepartmentList = createDepartmentTree(
      listDepartments.filter((x) => x.id != item.id),
      0
    )
    setTreeData2(DepartmentList)
    setType(type)
    if (type === 2) {
      setIsParent(isParent)
      setForm({ ...form, parentId: item.id })
    }
    if (type === 3) {
      setIsParent(isParent)
      setSelectedId(item.id)
      setForm({ ...form, parentId: item.parentId, name: item.name, salonId: item.salonId, group: item.group })
    }
  }

  function getExpandedKeys(departments, parentId) {
    return departments.filter((item) => item.parentId === parentId).map((item) => item.id)
  }

  useEffect(() => {
    if (listDepartments) {
      const DepartmentList = createDepartmentTree(listDepartments, 0)
      setTreeData(DepartmentList)
    }
  }, [listDepartments])

  useEffect(() => {
    getListStaffDepartments()
    getListSalon()
  }, [])
  // Text hiển thị
  useEffect(() => {
    if (type > 0) {
      switch (type) {
        case 1:
          setTitle('Tạo mới phòng ban')
          setForm({
            name: '',
            parentId: 0,
            salonId: 0,
            group: 0
          })
          break
        case 2:
          setTitle('Tạo mới phòng ban con')
          break
        case 3:
          setTitle('Sửa phòng ban')
          break
        default:
          break
      }
    }
  }, [type])
  // xử lý nút lưu
  const onFinish = (type) => {
    if (form.name.length === 0) {
      notification.error({
        description: 'Vui lòng nhập tên',
        message: 'Thông báo'
      })
      return
    }
    if (form.group === 0) {
      notification.error({
        description: 'Vui lòng chọn khối',
        message: 'Thông báo'
      })
      return
    }
    switch (type) {
      case 1:
        createNewDepartment()
        break
      case 2:
        createNewDepartment()
        break
      case 3:
        updateDepartment()
        break
      default:
        break
    }
    setIsFormChanged(false)
  }
  // Tạo phòng ban mới
  const createNewDepartment = async () => {
    if (loading) return
    setLoading(true)
    try {
      const response: any = await postNewDepartment(form)
      if (response.statusCode === 200) {
        const listDepartmentsNew = listDepartments.concat(response.data)
        setListDepartments(listDepartmentsNew)
        const expanded = getExpandedKeys(listDepartmentsNew, form.parentId)
        setExpandedKeys(expanded)
        setForm({
          name: '',
          parentId: 0,
          salonId: 0,
          group: 0
        })
        notification.success({
          description: 'Đã lưu thành công',
          message: 'Thông báo'
        })
      } else {
        notification.error({
          description: response.message,
          message: 'Thông báo'
        })
      }
    } catch (error) {
      notification.error({
        description: 'Lỗi tạo phòng ban',
        message: 'Thông báo'
      })
      return
    } finally {
      setLoading(false)
    }
  }
  // sửa phòng ban mới
  const updateDepartment = async () => {
    if (loading) return
    setLoading(true)
    try {
      const response: any = await putDepartment(selectedId, form)
      if (response.statusCode === 200) {
        const listDepartmentsNew = listDepartments
          .filter((item: any) => item.id != response.data.id)
          .concat(response.data)
        setListDepartments(listDepartmentsNew)
        const expanded = getExpandedKeys(listDepartmentsNew, form.parentId)
        setExpandedKeys(expanded)
        setForm({
          name: '',
          parentId: 0,
          salonId: 0,
          group: 0
        })
        setSelectedId(null)
        notification.success({
          description: 'Đã lưu thành công',
          message: 'Thông báo'
        })
      } else {
        notification.error({
          description: response.message,
          message: 'Thông báo'
        })
      }
    } catch (error) {
      notification.error({
        description: 'Lỗi tạo phòng ban',
        message: 'Thông báo'
      })
      return
    } finally {
      setLoading(false)
    }
  }

  function changeFormData(data) {
    setForm({ ...form, ...data })
    setIsFormChanged(true)
  }

  return (
    <Spin tip='Đang xử lý dữ liệu...' spinning={loading}>
      <div className='w-full px-5 py-5 search-wrapper'>
        <div className='text-2xl font-semibold max-sm:pl-2'>Cấu hình phòng ban</div>
        <div className='p-5 mt-3 bg-white'>
          <div className='grid grid-cols-2 gap-4 mt-3'>
            <div>
              <div className='text-lg'>Hệ thống phòng ban</div>
              <div className='border-2 p-2 max-h-[600px] overflow-y-auto'>
                {treeData.length > 0 && expandedKeys.length > 0 && (
                  <Tree treeData={treeData} defaultExpandedKeys={expandedKeys} />
                )}
                <div title='Thêm phòng ban' className='flex items-center cursor-pointer' onClick={() => setType(1)}>
                  <PlusOutlined className='mr-2' /> Thêm phòng ban mới
                </div>
              </div>
            </div>
            <div>
              {type > 0 && (
                <div className='flex flex-col'>
                  <div className='text-lg'>{title}</div>
                  <div>
                    <Form name='basic' labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} style={{ maxWidth: 600 }}>
                      <Form.Item
                        label='Tên phòng ban'
                        rules={[{ required: true, message: 'Vui lòng nhập tên phòng ban' }]}
                      >
                        <Input
                          onChange={(e) => changeFormData({ name: e.target.value })}
                          placeholder='Nhập tên phòng ban'
                          value={form.name}
                        />
                      </Form.Item>
                      <Form.Item label='Khối' rules={[{ required: true, message: 'Không được để trống' }]}>
                          <Select
                            showSearch
                            allowClear
                            onChange={(value) => changeFormData({ group: value })}
                            placeholder='Chọn khối'
                            value={form.group}                            
                            options={listGroup}
                          />
                        </Form.Item>

                      {treeData && type === 3 && (
                        <Form.Item label='Thuộc phòng ban' rules={[{ required: true, message: 'Không được để trống' }]}>
                          <TreeSelect
                            showSearch
                            value={form.parentId === 0 ? null : form.parentId}
                            placeholder='Chọn phòng ban'
                            onChange={(value) => changeFormData({ parentId: value })}
                            treeData={treeData2}
                            fieldNames={{ label: 'name', value: 'id' }}
                            filterTreeNode={(input, option) =>
                              convert
                                .removeVietnameseAccent(option?.value ?? '')
                                .indexOf(convert.removeVietnameseAccent(input)) >= 0
                            }
                          />
                        </Form.Item>
                      )}

                      {listSalon && !(isParent && type === 3) && (
                        <Form.Item label='Thông tin salon' rules={[{ required: true, message: 'Không được để trống' }]}>
                          <Select
                            showSearch
                            allowClear
                            onChange={(value) => changeFormData({ salonId: value })}
                            placeholder='Chọn Salon'
                            value={form.salonId}
                            filterOption={(input, option) =>
                              convert
                                .removeVietnameseAccent(option?.label ?? '')
                                .indexOf(convert.removeVietnameseAccent(input)) >= 0
                            }
                            options={listSalon}
                          />
                        </Form.Item>
                      )}
                      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button
                          disabled={!isFormChanged}
                          onClick={() => onFinish(type)}
                          htmlType='submit'
                          type='primary'
                        >
                          Lưu
                        </Button>
                      </Form.Item>
                    </Form>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Spin>
  )
}
