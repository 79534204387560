/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { DownOutlined, RightOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Form, Radio, RadioChangeEvent, Space, Typography, Upload, UploadFile, notification } from "antd";
import { useEffect, useState } from "react";
import ChoseSalon from "./compoment/choseSalon";
import ImportFile from "./compoment/importFile";


interface Props {
    onChangeForm: any,
    form: any,
    listSalon: any,
    isEdit: any,
}

const Step2 = (props: Props) => {
    const { onChangeForm, form, listSalon, isEdit } = props
    const [showBox, setShowBox] = useState(false);
    const [value, setValue] = useState(0);
    const [file, setFile] = useState(null)
    const [fileName, setFileName] = useState("")
    const [selectedSalonList, setSelectedSalonList] = useState([])
    const [selectedPositionList, setSelectedPositionList] = useState([])
    const [loading, setLoading] = useState<boolean>(false)

    const onChange = (e: RadioChangeEvent) => {
        setValue(e.target.value);
    };
    // hoàn tất step 2
    const onDoneStep2 = () => {
        if (loading) return
        setLoading(true)
        if (checkNull(value)) {
            setLoading(false)
            return
        }
        switch (value) {
            case 1:
                onChangeForm("file", file)
                onChangeForm("fileName", fileName)
                onChangeForm("typeObject", value)
                onChangeForm("salonIds", "")
                onChangeForm("departmentIds", "")
                break
            case 2:
                onChangeForm("salonIds", selectedSalonList)
                onChangeForm("departmentIds", selectedPositionList)
                onChangeForm("typeObject", value)
                onChangeForm("file", null)
                onChangeForm("fileName", "")
                break
            default:
                onChangeForm("typeObject", value)
                onChangeForm("salonIds", "")
                onChangeForm("departmentIds", "")
                onChangeForm("file", null)
                onChangeForm("fileName", "")
        }
        onChangeForm("step2", true)
        notification.success({
            message: 'Thông báo!',
            description: <>Tạo nội dung thông báo thành công</>
        })
        setLoading(false)
        setShowBox(false)
    };
    // check null
    const checkNull = (value: number) => {
        if (value === 0 || value === 6) {
            notification.error({
                message: 'Thông báo!',
                description: <>Vui lòng chọn đối tượng</>
            })
            return true
        } else if (value === 1) {
            if (!file) {
                notification.error({
                    message: 'Thông báo!',
                    description: <>Chưa tải file hoặc file không đúng định dạng!</>
                })
                return true
            }
        } else if (value === 2) {
            if (selectedSalonList.length === 0 || selectedPositionList.length === 0) {
                notification.error({
                    message: 'Thông báo!',
                    description: <>Vui lòng chọn salon và bộ phận</>
                })
                return true
            }
        } else {
            return false
        }
    }

    // lấy dữ liệu mặc định
    useEffect(() => {
        if (form && isEdit) {
            switch (form.typeObject) {
                case 1:
                    setValue(form.typeObject)
                    setFileName(form.fileName)
                    break
                case 2:
                    setValue(form.typeObject)
                    setSelectedSalonList(form.salonIds)
                    setSelectedPositionList(form.departmentIds)
                    break
                default:
                    setValue(form.typeObject)
            }
        }
    }, [isEdit])

    useEffect(() => {
        if (form.step1 && !form.step2) {
            setShowBox(true)
        }
    }, [form])
    
    return (
        <div className="p-[10px]" >
            <div className='p-[10px] flex flex-row justify-between drop-shadow-md' style={{ backgroundColor: showBox ? "#DAEEF9" : "white" }} onClick={() => setShowBox(!showBox)} >
                <div className="flex items-center justify-center">
                    <Typography.Title
                        level={5}
                        style={{ margin: 0 }}
                    >
                        Chọn đối tượng gửi thông báo
                    </Typography.Title>
                </div>
                <div className="flex items-center justify-center text-lg">
                    {showBox ?
                        <DownOutlined />
                        :
                        <RightOutlined />
                    }
                </div>
            </div>
            {showBox ? (
                <div className="bg-white drop-shadow-md">
                    <div className='p-[20px] body'>
                        <div className="flex flex-col">
                            <div className="p-2">
                                <Radio.Group onChange={onChange} value={value}>
                                    <Radio value={1}>Gửi bằng file upload</Radio>
                                </Radio.Group>
                                {value === 1 ?
                                    <div className="flex items-center justify-center">
                                        <ImportFile setFile={setFile} setFileName={setFileName} defaultName={form.fileName} />
                                    </div>
                                    :
                                    null
                                }
                            </div>
                            <div className="p-2">
                                <Radio.Group onChange={onChange} value={value}>
                                    <Radio value={2}>Gửi cho Salon - bộ phận</Radio>
                                </Radio.Group>
                                {value === 2 ?
                                    <div className="flex items-center justify-center">
                                        <ChoseSalon
                                            listSalon={listSalon}
                                            setSelectedSalonList={setSelectedSalonList}
                                            setSelectedPositionList={setSelectedPositionList}
                                            form={form}
                                        />
                                    </div>
                                    :
                                    null
                                }
                            </div>

                            <div className="p-2">
                                <Radio.Group onChange={onChange} value={value}>
                                    <Radio value={6}>Gửi cho khách hàng của brand</Radio>
                                </Radio.Group>
                                {value > 2 ?
                                    <div className="flex items-center justify-center">
                                        <Radio.Group onChange={onChange} value={value}>
                                            <Radio value={3}>30Shine</Radio>
                                            <Radio value={4}>Moyo</Radio>
                                            <Radio value={5}>Mr.Lion Barber</Radio>
                                        </Radio.Group>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                        </div>
                        <div className="pt-5">
                            <Button type="primary" onClick={onDoneStep2}>Done</Button>
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    )
}
export default Step2
