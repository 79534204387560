/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState } from "react";
import { Button, Col, Form, Input, Modal, notification, Radio, Row, Tag } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { ConfirmUpdateMember, postCusPhoneUpdate } from "../api";



const ModalApproveRequest = (props: any) => {
    const { data } = props;
    const [loading, setLoading] = useState<boolean>(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCheckDone, setIsCheckDone] = useState(false);
    const [dataCheck, setDataCheck] = useState<any>({
        confirm: 0,
        noteUpdate: ""
    })
    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    // thêm text khi ấn
    const AddText = (text: string) => {
        let textData = dataCheck.noteUpdate || "";

        if (textData.includes(text)) {
            textData = textData.replace(text, "");
            setDataCheck({ ...dataCheck, noteUpdate: textData.trim() });
        } else {
            setDataCheck({ ...dataCheck, noteUpdate: text + " " + textData });
        }
    };
    // Xử lý xác nhậnajn
    const Confirm = async () => {
        if (loading) return
        setLoading(true)
        try {
            const response: any = await ConfirmUpdateMember(data.id, dataCheck)
            if (response.statusCode === 200) {
                notification.success({
                    message: 'Thông báo!',
                    description: <>Xử lý yêu cầu thành công!</>
                })
                setIsModalOpen(false);
                setIsCheckDone(true)
            } else {
                notification.error({
                    message: 'Thông báo!',
                    description: <>{response.message}</>
                })
            }
            setLoading(false)
        } catch (error) {
            notification.error({
                message: 'Thông báo!',
                description: <>Lỗi hệ không gửi được yêu cầu!</>
            })
            setLoading(false)
        }
    }

    useEffect(() => {
        if (!isModalOpen) {
            setDataCheck({
                confirm: 0,
                noteUpdate: ""
            })
        }
    }, [isModalOpen])

    return (
        <div>
            <Button
                disabled={data.confirm != 0 || isCheckDone}
                type={data.confirm != 0 || isCheckDone ? "default" : "primary"}
                onClick={showModal}
            >
                <div className='flex items-center justify-center'>
                    Xác nhận
                </div>
            </Button>
            <Modal title="Duyệt ShineMember" open={isModalOpen} onCancel={handleCancel} destroyOnClose={true} footer={false} width={600} >
                <Form
                    name="basic"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                >
                    <Form.Item
                        label="Trạng thái"
                        rules={[{ required: dataCheck.confirm === 0 }]}
                    >
                        <Radio.Group
                            onChange={(e) => {
                                setDataCheck({ confirm: e.target.value, noteUpdate: "" })
                            }} value={dataCheck.confirm}
                        >
                            <Radio value={1}>Duyệt</Radio>
                            <Radio value={2}>Không duyệt</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        label="Lý do"
                        rules={[{ required: true, message: 'Please input your password!' }]}
                    >
                        <div className="flex flex-col gap-2">
                            {dataCheck.confirm != 0 &&
                                <div className="flex flex-row gap-2">
                                    {dataCheck.confirm === 1 ?
                                        < span
                                            className="rounded-2xl border-2 p-[5px]"
                                            onClick={() => AddText("Đã xác nhận thành công với KH")}
                                        >
                                            Đã xác nhận thành công với KH
                                        </span>
                                        :
                                        <div className="flex flex-wrap gap-2">
                                            <span
                                                className="rounded-2xl border-2 p-[5px]"
                                                onClick={() => AddText("SĐT mới không đúng")}
                                            >
                                                SĐT mới không đúng
                                            </span>  <span
                                                className="rounded-2xl border-2 p-[5px]"
                                                onClick={() => AddText("Khách không yêu cầu đổi")}
                                            >
                                                Khách không yêu cầu đổi
                                            </span>  <span
                                                className="rounded-2xl border-2 p-[5px]"
                                                onClick={() => AddText("Khách tạm hủy yêu cầu đổi")}
                                            >
                                                Khách tạm hủy yêu cầu đổi
                                            </span>  <span
                                                className="rounded-2xl border-2 p-[5px]"
                                                onClick={() => AddText("Không liên hệ được")}
                                            >
                                                Không liên hệ được
                                            </span>
                                        </div>
                                    }
                                </div>
                            }
                            <TextArea
                                autoSize={{ minRows: 5, maxRows: 10 }}
                                maxLength={260}
                                value={dataCheck?.noteUpdate || undefined}
                                onChange={(e) => {
                                    setDataCheck({ ...dataCheck, ['noteUpdate']: e.target.value })
                                }}
                            />
                        </div>
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button
                            type='default'
                            onClick={() => Confirm()}
                            className='w-[150px] bg-[#4096ff] text-white hover:bg-white'
                            disabled={dataCheck.confirm === 0 || loading || !dataCheck?.noteUpdate}
                        >
                            <div className='flex items-center justify-center hover_icon'>
                                Xác nhận
                            </div>
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div >
    )
}

export default ModalApproveRequest


