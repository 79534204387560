
const removeVietnameseAccent = (str) => {
  str = str.toLowerCase()
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i')
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
  str = str.replace(/đ/g, 'd')
  /* eslint-disable-next-line */
  str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, ' ')
  str = str.replace(/ + /g, ' ')
  str = str.trim()
  return str
}

const moneyFormat = (num) => {
  if (!num) return '';
  let newNum = num;
  newNum = newNum.replace(/\D+/g, '');
  let _dot = '.';
  let parts = newNum.toString().split(_dot);
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, _dot);
  let foo = parts.join(_dot);
  return foo;
};

export default {
  removeVietnameseAccent,
  moneyFormat
}
