/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { DownOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Form, Typography, notification } from "antd";
import { useEffect, useState } from "react";
import SelectTimePush from "./compoment/selectTimePush";

interface Props {
    onChangeForm: any,
    form: any,
    isEdit: any
}

const Step3 = (props: Props) => {
    const { onChangeForm, form, isEdit } = props
    const [showBox, setShowBox] = useState(false);
    const [pushNow, setPushNow] = useState(null)
    const [selectedTime, setSelectedTime] = useState('')
    const [isWarning, setIsWarning] = useState(false);
    const [loading, setLoading] = useState<boolean>(false)

    // lấy dữ liệu mặc định
    useEffect(() => {
        if (form && isEdit) {
            setPushNow(form.sentNow)
            setSelectedTime(form.sentDate)
        }
    }, [isEdit])

    const onDoneStep3 = () => {
        if (loading) return
        setLoading(true)
        if (isWarning) {
            notification.error({
                message: 'Thông báo!',
                description: <>Thời gian lên lịch phải lớn hơn thời gian hiện tại ít nhất 20 phút</>
            })
            setLoading(false)
            return
        }
        if (pushNow) {
            onChangeForm("sentNow", pushNow)
            onChangeForm("sentDate", "")
        } else {
            if (selectedTime) {
                onChangeForm("sentDate", selectedTime)
                onChangeForm("sentNow", false)
            } else {
                notification.error({
                    message: 'Thông báo!',
                    description: <>Vui lòng lên lịch ngày gửi Noti</>
                })
                setLoading(false)
                return
            }
        }
        onChangeForm("step3", true)
        notification.success({
            message: 'Thông báo!',
            description: <>Lên lịch thông báo thành công</>
        })
        setLoading(false)
        setShowBox(false)
    };

    useEffect(() => {
        if (form.step2 && !form.step3) {
            setShowBox(true)
        }
    }, [form])
    
    return (
        <div className="p-[10px]" >
            <div className='p-[10px] flex flex-row justify-between drop-shadow-md' style={{ backgroundColor: showBox ? "#DAEEF9" : "white" }} onClick={() => setShowBox(!showBox)} >
                <div className="flex items-center justify-center">
                    <Typography.Title
                        level={5}
                        style={{ margin: 0 }}
                    >
                        Lên lịch gửi thông báo
                    </Typography.Title>
                </div>
                <div className="flex items-center justify-center text-lg">
                    {showBox ?
                        <DownOutlined />
                        :
                        <RightOutlined />
                    }
                </div>
            </div>
            {showBox ? (
                <div className="bg-white drop-shadow-md">
                    <div className='p-[20px] body'>
                        <Form.Item
                            label='Lựa chọn lên lịch'
                            name='chooseTypePush'
                        >
                            <SelectTimePush
                                setPushNow={setPushNow}
                                selectedDateTime={form.sentDate}
                                setSelectedTime={setSelectedTime}
                                setIsWarning={setIsWarning}
                                pushNow={form.sentNow}
                            />
                        </Form.Item>

                        <div className="pt-5">
                            <Button type="primary" onClick={onDoneStep3}>Done</Button>
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    )
}
export default Step3
