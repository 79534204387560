import { Form, Input, DatePicker, Select, Button, Spin, Modal, Image } from 'antd'
import { CameraOutlined, DeleteFilled, EditFilled } from '@ant-design/icons'
import WrapForm from '../add/WrapForm'
import { useAppDispatch, useAppSelector } from 'redux/store'
import dayjs from 'dayjs'
import { useContext, useEffect, useState, useRef } from 'react'
import { editDetailStaff, getDetailStaff, getDistrict, getWard } from '../api'
import { antIcon } from '../list/page'
import { Contract_type, PayloadUpdateEmployee } from '../interface'
import { setResource, setStaff } from '../staffSlice'
import { AppContext } from 'layouts/DashboardLayout'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import Cookies from 'js-cookie'
import { resizeImage } from '../add/resizeImage'
import { useMediaQuery } from 'react-responsive'
type Props = {
  tab: string
}
export const Employee = ({ tab }: Props) => {
  const avatarInputRef = useRef(null)
  const idFrontInputRef = useRef(null)
  const idBackInputRef = useRef(null)
  const [form] = Form.useForm()
  const { id } = useParams()
  const { notification } = useContext(AppContext)
  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch()
  const { resource, staffDetail }: any = useAppSelector((state) => state.staff)
  const [portrait, setPortrait] = useState('')
  const [idFront, setIDFront] = useState('')
  const [idBack, setIDBack] = useState('')
  const isMobile = useMediaQuery({
    query: '(max-width: 500px)'
  })
  const onValuesChange = async (changedValues: any) => {
    if (changedValues?.provinceId !== undefined) {
      const res: any = await getDistrict(changedValues.provinceId)
      if (res.statusCode === 0) {
        dispatch(setResource({ ...resource, districtId: res.data, wardId: [] }))
      }
      form.resetFields(['districtId', 'wardId'])
    }
    if (changedValues?.districtId !== undefined) {
      const res: any = await getWard(changedValues.districtId)
      if (res.statusCode === 0) {
        dispatch(setResource({ ...resource, wardId: res.data }))
      }
      form.resetFields(['wardId'])
    }
  }
  const onFinish = async (values: any) => {
    const {
      idName,
      idNo,
      idIssuedDate,
      idIssuedLocation,
      birthday,
      provinceId,
      districtId,
      address,
      phone,
      email,
      insuranceNumber,
      taxCode,
      bankName,
      bankAccount,
      bankAccountName,
      bankBranch,
      profileTypes,
      jobDesc,
      gender,
      wardId
    } = values

    const payload: PayloadUpdateEmployee = {
      idName,
      idNo,
      idIssuedDate: dayjs(idIssuedDate).format('YYYY-MM-DD'),
      idIssuedLocation,
      birthday: dayjs(birthday).format('YYYY-MM-DD'),
      provinceId,
      districtId,
      address,
      phone,
      email,
      insuranceNumber,
      taxCode,
      bankName,
      bankAccount,
      bankAccountName,
      bankBranch,
      profileTypes,
      jobDesc,
      gender,
      wardId,
      contracts: resource.contractType
        .map((item: Contract_type) => {
          if (values[`contracts_${item.id}`]) {
            return {
              id: item.id,
              name: item.name,
              signedDate: dayjs(values[`contracts_${item.id}`]).format('YYYY-MM-DD')
            }
          }
        })
        .filter((i: Contract_type) => i !== undefined),
      images: [
        {
          type: 'PORTRAIT',
          url: portrait
        },
        {
          type: 'ID_FRONT',
          url: idFront
        },
        {
          type: 'ID_BACK',
          url: idBack
        }
      ]
    }
    try {
      setLoading(true)
      const res: any = await editDetailStaff(id as string, payload)
      notification(
        res.statusCode === 0 ? 'success' : 'error',
        res.statusCode === 0 ? 'Thành công' : 'Thất bại',
        res.statusCode === 0 ? 'Chỉnh sửa nhân sự thành công' : res.message
      )
      if (res.statusCode === 0) {
        dispatch(
          setStaff({ ...staffDetail, working: { ...staffDetail.working, idName: payload.idName }, detail: payload })
        )
      }
      setLoading(false)
    } catch (error) {
      if (error.response.status === 403) notification('error', 'Thất bại', 'Bạn không có quyền thực thi')
      notification('error', 'Thất bại', 'Có lỗi xảy ra')
      setLoading(false)
    }
  }
  const handlefieldDistrict = async () => {
    if (resource.provinceId.length > 0 && staffDetail.detail?.provinceId) {
      const res: any = await getDistrict(staffDetail.detail.provinceId)
      if (res.statusCode === 0) {
        const resWard: any = await getWard(staffDetail.detail?.districtId)
        if (resWard.statusCode === 0) {
          dispatch(setResource({ ...resource, districtId: res.data, wardId: resWard.data }))
        }
      }
    }
  }
  const handleFetchStaff = async () => {
    // cái thắng tùng cd nó không thèm viết try catch --> chán vcl
    try {
      const detail = await getDetailStaff(id as string)
      console.log(detail, 'detail')
      if (detail) {
        dispatch(
          setStaff({
            ...staffDetail,
            detail: {
              ...detail.data
            }
          })
        )
        const images = detail.data?.images
        // if (images.length) {
        //   setPortrait(detail.data?.images[0]?.url)
        //   setIDFront(detail.data?.images[1]?.url)
        //   setIDBack(detail.data?.images[2]?.url)
        // }
        if (images.length > 0) {
          images.forEach((image) => {
            const { type, url } = image
            if (type === 'PORTRAIT') {
              setPortrait(url)
            } else if (type === 'ID_FRONT') {
              setIDFront(url)
            } else if (type === 'ID_BACK') {
              setIDBack(url)
            }
          })
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    handleFetchStaff()
  }, [])
  useEffect(() => {
    if (tab === '2') {
      handlefieldDistrict()
    }
  }, [resource.provinceId, staffDetail.detail, tab])
  useEffect(() => {
    form.setFieldsValue({
      ...staffDetail.detail,
      userName: staffDetail.working?.userName,
      idName: staffDetail.working?.idName,
      provinceId: staffDetail.detail?.provinceId || undefined,
      wardId: staffDetail.detail?.wardId || undefined,
      districtId: staffDetail.detail?.districtId || undefined,
      profileTypes: staffDetail.detail?.profileTypes ? staffDetail.detail?.profileTypes.filter((i) => i) : [],
      birthday: dayjs(staffDetail.detail?.birthday),
      idIssuedDate: dayjs(staffDetail.detail?.idIssuedDate),
      workingDate: dayjs(staffDetail.detail?.workingDate),
      seniorityDate: dayjs(staffDetail.detail?.seniorityDate)
    })

    if (staffDetail.detail?.contracts) {
      staffDetail.detail.contracts.forEach((e: Contract_type) => {
        form.setFieldsValue({
          [`contracts_${e.id}`]: e.signedDate ? dayjs(e.signedDate) : null
        })
      })
    }
  }, [staffDetail, tab])
  const onFileChange = (url: string, type: string) => {
    switch (type) {
      case 'PORTRAIT':
        setPortrait(url)
        break
      case 'ID_FRONT':
        setIDFront(url)
        break
      case 'ID_BACK':
        setIDBack(url)
        break
    }
  }
  const uploadImage = async (resizedImage: Blob, type: string) => {
    const AccessToken = Cookies.get('AccessToken') || ''
    if (loading) return
    try {
      setLoading(true)
      const formData = new FormData()
      formData.append('image', resizedImage, 'resized.jpg')
      const response = await axios.post(`${process.env.REACT_APP_UPLOAD_STAFF}/common`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${AccessToken}`
        }
      })
      console.log(response, 'response')
      if (response.data) onFileChange(response.data[0], type)
    } catch (error) {
      notification.error({
        description: 'Có lỗi xảy ra, vui lòng thử lại sau',
        message: 'Thông báo'
      })
      throw error
    }
    setLoading(false)
  }
  const handleFileInputChange = async (event: React.ChangeEvent<HTMLInputElement>, type: string) => {
    const file = event.target.files[0]
    console.log(file, 'file')
    if (file) {
      try {
        setLoading(true)
        const resizedImage = await resizeImage(file, 1024, 1024)
        await uploadImage(resizedImage, type)
      } catch (error) {
        console.error('Error resizing image:', error)
        setLoading(false)
      }
    } else {
      console.log('ko có ảnh')
      notification.error({
        description: 'Vui lòng chọn ảnh',
        message: 'Thông báo'
      })
    }
  }
  const handleRemoveImage = (type: string) => {
    switch (type) {
      case 'PORTRAIT':
        setPortrait('')
        break
      case 'ID_FRONT':
        setIDFront('')
        break
      case 'ID_BACK':
        setIDBack('')
        break
    }
  }
  const [imageModal, setImageModal] = useState('')
  const [isModalVisible, setIsModalVisible] = useState(false)

  const handleCancel = () => {
    setIsModalVisible(false)
  }
  return (
    <>
      <Modal title='' open={isModalVisible} onCancel={handleCancel} footer={null}>
        <Image src={imageModal} />
      </Modal>
      {isMobile ? (
        <Form
          onValuesChange={onValuesChange}
          onFinish={onFinish}
          form={form}
          name='basic'
          initialValues={{ remember: true }}
          autoComplete='off'
          style={{ width: 'calc(100vw - 40px)' }}
        >
          <div className='mt-2'>
            <div className=''>
              <div className='w-[200px] text-[#000] text-left font-bold text-xl'>Thông tin cá nhân</div>
              <div className='w-[700px] text-xs text-[#898989] mb-5'>
                <span className='text-[red]'>(*)</span> : Bắt buộc điền thông tin!
              </div>
            </div>
            <div>
              <Form.Item
                label='Tên đăng nhập:'
                name='userName'
                rules={[
                  {
                    required: true,
                    message: 'Không được để trống'
                  },
                  { max: 50, message: 'Không quá 50 ký tự' }
                ]}
              >
                <span>{staffDetail.working?.userName}</span>
                {/* <Input disabled /> */}
              </Form.Item>

              <Form.Item
                label='Họ và tên nhân viên:'
                name='idName'
                rules={[
                  {
                    required: true,
                    message: 'Không được để trống'
                  },
                  { max: 200, message: 'Không quá 50 ký tự' }
                ]}
              >
                <Input placeholder='Họ và tên theo CCCD' />
              </Form.Item>
              <Form.Item
                label='Số CCCD:'
                name='idNo'
                rules={[
                  { required: true, message: 'Số CCCD không được để trống' },
                  {
                    pattern: /^(\d{12}|\d{9})$/,
                    message: 'Số CCCD/CMND gồm 9-12 số'
                  }
                ]}
              >
                <Input maxLength={12} placeholder='Nhập CCCD 12 chữ số' />
              </Form.Item>
              <Form.Item
                className='min-min-'
                label={
                  <div>
                    <span className='text-[red] text-[16px]'>{`* `}</span>
                    Ngày cấp / Nơi cấp
                  </div>
                }
              >
                <div className='flex gap-x-3'>
                  <Form.Item
                    name='idIssuedDate'
                    className='w-[205px]'
                    rules={[{ required: true, message: 'Không được để trống' }]}
                  >
                    <DatePicker clearIcon={false} placeholder='dd-mm-yyyy' format='DD-MM-YYYY' />
                  </Form.Item>
                  <Form.Item
                    className='w-[-webkit-fill-available]'
                    name='idIssuedLocation'
                    rules={[{ required: true, message: 'Không được để trống' }]}
                  >
                    <Input placeholder='Nhập nơi cấp' />
                  </Form.Item>
                </div>
              </Form.Item>
              <Form.Item
                className='min-'
                label={
                  <div>
                    <span className='text-[red] text-[16px]'>{`* `}</span>
                    Ngày sinh / Giới tính
                  </div>
                }
              >
                <div className='flex gap-x-3'>
                  <Form.Item
                    name='birthday'
                    className='w-[205px]'
                    rules={[{ required: true, message: 'Không được để trống' }]}
                  >
                    <DatePicker clearIcon={false} placeholder='dd-mm-yyyy' format='DD-MM-YYYY' />
                  </Form.Item>
                  <Form.Item
                    className='w-[-webkit-fill-available]'
                    name='gender'
                    rules={[{ required: true, message: 'Không được để trống' }]}
                  >
                    <Select options={resource.gender} placeholder='Chọn Giới tính' />
                  </Form.Item>
                </div>
              </Form.Item>
              <Form.Item
                className='min-'
                label={
                  <div>
                    <span className='text-[red] text-[16px]'>{`* `}</span>
                    Địa chỉ
                  </div>
                }
              >
                <div className='flex gap-x-3'>
                  <Form.Item
                    name='provinceId'
                    className='w-[100%]'
                    rules={[{ required: true, message: 'Không được để trống' }]}
                  >
                    <Select
                      options={resource.provinceId}
                      placeholder='Tỉnh / thành phố'
                      fieldNames={{ label: 'name', value: 'id' }}
                      showSearch
                      optionFilterProp='children'
                      filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
                    />
                  </Form.Item>
                  <Form.Item
                    className='w-[-webkit-fill-available]'
                    name='districtId'
                    rules={[{ required: true, message: 'Không được để trống' }]}
                  >
                    <Select
                      options={resource.districtId}
                      placeholder='Quận / huyện'
                      fieldNames={{ label: 'name', value: 'id' }}
                      showSearch
                      optionFilterProp='children'
                      filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
                    />
                  </Form.Item>
                </div>
              </Form.Item>

              <Form.Item
                className='min-'
                label={
                  <div>
                    <span className='text-[red] text-[16px]'>{`* `}</span>
                    Địa chỉ cụ thể:
                  </div>
                }
              >
                <div className='flex gap-x-3'>
                  <Form.Item
                    name='wardId'
                    className='w-[50%]'
                    rules={[{ required: true, message: 'Không được để trống' }]}
                  >
                    <Select
                      options={resource.wardId}
                      placeholder='Phường / xã / thị trấn'
                      fieldNames={{ label: 'name', value: 'id' }}
                      showSearch
                      clearIcon
                      optionFilterProp='children'
                      filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
                    />
                  </Form.Item>
                  <Form.Item
                    className='w-[50%]'
                    name='address'
                    rules={[{ required: true, message: 'Không được để trống' }]}
                  >
                    <Input placeholder='Số nhà, tên đường, phường / xã / thị trấn ...' />
                  </Form.Item>
                </div>
              </Form.Item>

              <Form.Item
                label={
                  <div>
                    <span className='text-[red] text-[16px]'>{`* `}</span>
                    SĐT / Mail
                  </div>
                }
              >
                <div className='flex gap-x-3'>
                  <Form.Item
                    name='phone'
                    rules={[
                      { required: true, message: 'Không được để trống' },
                      {
                        pattern: /^(\+84|\+840|84|084|840|0)([9235678]{1}[0-9]{8})$/,
                        message: 'Số điện thoại nhập sai định dạng'
                      }
                    ]}
                  >
                    <Input maxLength={10} className='w-[205px]' placeholder='Số điện thoại' />
                  </Form.Item>
                  <Form.Item
                    name='email'
                    className='w-[-webkit-fill-available]'
                    rules={[
                      {
                        pattern: /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$/,
                        message: 'Sai định dạng mail'
                      }
                    ]}
                  >
                    <Input placeholder='Mail' />
                  </Form.Item>
                </div>
              </Form.Item>
              <Form.Item label='Số BHXH/Thuế:'>
                <div className='flex gap-x-3'>
                  <Form.Item name='insuranceNumber'>
                    <Input className='w-[100%]' placeholder='MS BHXH' />
                  </Form.Item>
                  <Form.Item name='taxCode'>
                    <Input className='w-[100%]' placeholder='MS thuế' />
                  </Form.Item>
                </div>
              </Form.Item>
            </div>
            <WrapForm
              styleLabel=''
              title={<div className='flex items-center justify-end gap-x-3'>Thông tin ngân hàng</div>}
            >
              <div>
                <Form.Item label='Tên ngân hàng:' name='bankName'>
                  <Input />
                </Form.Item>
                <Form.Item label='Số tài khoản:' name='bankAccount'>
                  <Input />
                </Form.Item>
                <Form.Item label='Tên tài khoản:' name='bankAccountName'>
                  <Input />
                </Form.Item>
                <Form.Item label='Chi nhánh ngân hàng:' name='bankBranch'>
                  <Input />
                </Form.Item>
              </div>
            </WrapForm>
            <WrapForm styleLabel='ml-10' title={<div className='flex items-center justify-left'>Thông tin hồ sơ</div>}>
              <div>
                <Form.Item label='Hồ sơ lý lịch:' name='profileTypes'>
                  <Select
                    allowClear
                    mode='multiple'
                    options={resource.profileTypes}
                    fieldNames={{ label: 'name', value: 'id' }}
                    placeholder='Chọn loại hồ sơ'
                  />
                </Form.Item>
                <Form.Item label='Mô tả công việc:' name='jobDesc'>
                  <Input />
                </Form.Item>
              </div>
            </WrapForm>
            <WrapForm styleLabel='ml-10' title={<div className='flex items-center justify-left'>Ảnh</div>}>
              <div>
                <Form.Item label='Ảnh chân dung:' name='Avatar'>
                  {portrait?.length ? (
                    <div className='relative h-[160px] w-[160px]'>
                      <Button
                        className='bg-[#ffffff59] w-[40px] h-[40px] rounded-[50%] top-[35%] left-[65%] translate-x-[-50%] z-20 cursor-pointer absolute'
                        onClick={() => handleRemoveImage('PORTRAIT')}
                      >
                        <DeleteFilled className='relative text-xl top-[-5%] left-[50%] translate-x-[-50%]' />
                      </Button>
                      <div className='w-full cursor-pointer'>
                        <Image
                          src={portrait}
                          alt='Image 30shine'
                          className='image h-[160px] w-[160px] bg-cover bg-center bg-no-repeat rounded-md'
                          height={160}
                          width={160}
                          style={{ objectFit: 'cover' }}
                        />
                        <Button
                          onClick={() => avatarInputRef.current.click()}
                          className='bg-[#ffffff59] w-[40px] h-[40px] rounded-[50%] top-[36%] left-[33%] translate-x-[-50%] z-20 cursor-pointer absolute'
                        >
                          <EditFilled className='relative text-xl top-[-5%] left-[50%] translate-x-[-50%]' />
                        </Button>

                        <input
                          ref={avatarInputRef}
                          className='w-0 h-0 custom-file-input'
                          type='file'
                          accept='image/*'
                          onChange={(e) => handleFileInputChange(e, 'PORTRAIT')}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className='w-[160px] item-null'>
                      <label>
                        <img
                          className='cursor-pointer icon h-[160px] border-2 p-12 rounded-md'
                          src='/icon/upload_avatar.png'
                          alt='upload'
                        />

                        <input
                          className='w-0 h-0 custom-file-input'
                          type='file'
                          accept='image/*'
                          onChange={(e) => handleFileInputChange(e, 'PORTRAIT')}
                        />
                      </label>
                    </div>
                  )}
                </Form.Item>
                <Form.Item label='Ảnh CCCD:' name='IDCard'>
                  <div className='flex'>
                    {idFront?.length ? (
                      <div className='relative h-[160px] w-[240px]'>
                        <Button
                          className='bg-[#ffffff59] w-[40px] h-[40px] rounded-[50%] top-[35%] left-[65%] translate-x-[-50%] z-20 cursor-pointer absolute'
                          onClick={() => handleRemoveImage('ID_FRONT')}
                        >
                          <DeleteFilled className='relative text-xl top-[-5%] left-[50%] translate-x-[-50%]' />
                        </Button>
                        <div className='w-full cursor-pointer'>
                          <Image
                            src={idFront}
                            alt='Image 30shine'
                            className='image h-[160px] w-[240px] bg-cover bg-center bg-no-repeat rounded-md'
                            height={160}
                            width={240}
                            style={{ objectFit: 'cover' }}
                          />
                          <Button
                            onClick={() => idFrontInputRef.current.click()}
                            className='bg-[#ffffff59] w-[40px] h-[40px] rounded-[50%] top-[36%] left-[33%] translate-x-[-50%] z-20 cursor-pointer absolute'
                          >
                            <EditFilled className='relative text-xl top-[-5%] left-[50%] translate-x-[-50%]' />
                          </Button>

                          <input
                            ref={idFrontInputRef}
                            className='w-0 h-0 custom-file-input'
                            type='file'
                            accept='image/*'
                            onChange={(e) => handleFileInputChange(e, 'ID_FRONT')}
                          />
                        </div>
                      </div>
                    ) : (
                      <label className='h-[160px] w-[240px] item-null border-2 rounded-md relative cursor-pointer'>
                        <div className='absolute top-[30%] left-[50%] translate-x-[-50%] text-center text-[#9A9A9A]'>
                          <CameraOutlined className='text-4xl' />
                          <p>Mặt trước CCCD</p>
                        </div>
                        <input
                          className='w-0 h-0 custom-file-input'
                          type='file'
                          accept='image/*'
                          onChange={(e) => handleFileInputChange(e, 'ID_FRONT')}
                        />
                      </label>
                    )}
                  </div>
                  <div className='flex'>
                    {idBack?.length ? (
                      <div className='relative h-[160px] w-[240px] mt-3'>
                        <Button
                          className='bg-[#ffffff59] w-[40px] h-[40px] rounded-[50%] top-[35%] left-[65%] translate-x-[-50%] z-20 cursor-pointer absolute'
                          onClick={() => handleRemoveImage('ID_BACK')}
                        >
                          <DeleteFilled className='relative text-xl top-[-5%] left-[50%] translate-x-[-50%]' />
                        </Button>
                        <div className='w-full cursor-pointer'>
                          <Image
                            src={idBack}
                            alt='Image 30shine'
                            className='image h-[160px] w-[240px] bg-cover bg-center bg-no-repeat rounded-md'
                            height={160}
                            width={240}
                            style={{ objectFit: 'cover' }}
                          />
                          <Button
                            onClick={() => idBackInputRef.current.click()}
                            className='bg-[#ffffff59] w-[40px] h-[40px] rounded-[50%] top-[36%] left-[33%] translate-x-[-50%] z-20 cursor-pointer absolute'
                          >
                            <EditFilled className='relative text-xl top-[-5%] left-[50%] translate-x-[-50%]' />
                          </Button>

                          <input
                            ref={idBackInputRef}
                            className='w-0 h-0 custom-file-input'
                            type='file'
                            accept='image/*'
                            onChange={(e) => handleFileInputChange(e, 'ID_FRONT')}
                          />
                        </div>
                      </div>
                    ) : (
                      <label className='h-[160px] w-[240px] item-null border-2 rounded-md relative cursor-pointer mt-3'>
                        <div className='absolute top-[30%] left-[50%] translate-x-[-50%] text-center text-[#9A9A9A]'>
                          <CameraOutlined className='text-4xl' />
                          <p>Mặt sau CCCD</p>
                        </div>
                        <input
                          className='w-0 h-0 custom-file-input'
                          type='file'
                          accept='image/*'
                          onChange={(e) => handleFileInputChange(e, 'ID_BACK')}
                        />
                      </label>
                    )}
                  </div>
                </Form.Item>
              </div>
            </WrapForm>
            <WrapForm styleLabel='ml-10' title={<div className='flex items-center justify-left'>Hợp đồng</div>}>
              <div>
                {resource.contractType.map((item: Contract_type, index: number) => (
                  <Form.Item
                    key={`${staffDetail.working?.staffId || ''}_${index}`}
                    label={item.name}
                    name={`contracts_${item.id}`}
                    className='w-full'
                  >
                    <DatePicker placeholder='dd-mm-yyyy' format='DD-MM-YYYY' className='w-full' />
                  </Form.Item>
                ))}
              </div>
            </WrapForm>
            <Button
              className='float-right w-[90px] mt-5 mb-5 bg-[#4096ff] text-white'
              type='primary'
              htmlType='submit'
              disabled={loading}
            >
              {!loading ? 'Hoàn tất' : <Spin className='text-white' indicator={antIcon} />}
            </Button>
          </div>
        </Form>
      ) : (
        <Form
          onValuesChange={onValuesChange}
          onFinish={onFinish}
          form={form}
          name='basic'
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 17 }}
          style={{ maxWidth: 900, margin: 'auto' }}
          initialValues={{ remember: true }}
          autoComplete='off'
          className='float-left'
        >
          <div className='mt-14'>
            <div className='personal_information'>
              <div className='items-center flex gap-6 mb-4 pl-[45px]'>
                <div className='w-[200px] ml-[-20px] text-lg text-[#000] text-right font-bold '>
                  <div className='flex items-center justify-end gap-x-3'>
                    <img width={20} height={20} src='/edit.png' alt='icon_edit' />
                    Thông tin cá nhân
                  </div>
                </div>
                <div className='w-[700px] text-xs text-[#898989]'>
                  <span className='text-[red]'>(*)</span> : Bắt buộc điền thông tin!
                </div>
              </div>
              <div>
                <Form.Item
                  label='Tên đăng nhập:'
                  name='userName'
                  rules={[
                    {
                      required: true,
                      message: 'Không được để trống'
                    },
                    { max: 50, message: 'Không quá 50 ký tự' }
                  ]}
                >
                  <span>{staffDetail.working?.userName}</span>
                  {/* <Input disabled /> */}
                </Form.Item>

                <Form.Item
                  label='Họ và tên nhân viên:'
                  name='idName'
                  rules={[
                    {
                      required: true,
                      message: 'Không được để trống'
                    },
                    { max: 200, message: 'Không quá 50 ký tự' }
                  ]}
                >
                  <Input placeholder='Họ và tên theo CCCD' />
                </Form.Item>
                <Form.Item
                  label='Số CCCD:'
                  name='idNo'
                  rules={[
                    { required: true, message: 'Số CCCD không được để trống' },
                    {
                      pattern: /^(\d{12}|\d{9})$/,
                      message: 'Số CCCD/CMND gồm 9-12 số'
                    }
                  ]}
                >
                  <Input maxLength={12} placeholder='Nhập CCCD 12 chữ số' />
                </Form.Item>
                <Form.Item
                  className='min-min-'
                  label={
                    <div>
                      <span className='text-[red] text-[16px]'>{`* `}</span>
                      Ngày cấp / Nơi cấp
                    </div>
                  }
                >
                  <div className='flex gap-x-3'>
                    <Form.Item
                      name='idIssuedDate'
                      className='w-[205px]'
                      rules={[{ required: true, message: 'Không được để trống' }]}
                    >
                      <DatePicker clearIcon={false} placeholder='dd-mm-yyyy' format='DD-MM-YYYY' />
                    </Form.Item>
                    <Form.Item
                      className='w-[-webkit-fill-available]'
                      name='idIssuedLocation'
                      rules={[{ required: true, message: 'Không được để trống' }]}
                    >
                      <Input placeholder='Nhập nơi cấp' />
                    </Form.Item>
                  </div>
                </Form.Item>
                <Form.Item
                  className='min-'
                  label={
                    <div>
                      <span className='text-[red] text-[16px]'>{`* `}</span>
                      Ngày sinh / Giới tính
                    </div>
                  }
                >
                  <div className='flex gap-x-3'>
                    <Form.Item
                      name='birthday'
                      className='w-[205px]'
                      rules={[{ required: true, message: 'Không được để trống' }]}
                    >
                      <DatePicker clearIcon={false} placeholder='dd-mm-yyyy' format='DD-MM-YYYY' />
                    </Form.Item>
                    <Form.Item
                      className='w-[-webkit-fill-available]'
                      name='gender'
                      rules={[{ required: true, message: 'Không được để trống' }]}
                    >
                      <Select options={resource.gender} placeholder='Chọn Giới tính' />
                    </Form.Item>
                  </div>
                </Form.Item>
                <Form.Item
                  className='min-'
                  label={
                    <div>
                      <span className='text-[red] text-[16px]'>{`* `}</span>
                      Địa chỉ
                    </div>
                  }
                >
                  <div className='flex gap-x-3'>
                    <Form.Item
                      name='provinceId'
                      className='w-[100%]'
                      rules={[{ required: true, message: 'Không được để trống' }]}
                    >
                      <Select
                        options={resource.provinceId}
                        placeholder='Tỉnh / thành phố'
                        fieldNames={{ label: 'name', value: 'id' }}
                        showSearch
                        optionFilterProp='children'
                        filterOption={(input, option) =>
                          (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      className='w-[-webkit-fill-available]'
                      name='districtId'
                      rules={[{ required: true, message: 'Không được để trống' }]}
                    >
                      <Select
                        options={resource.districtId}
                        placeholder='Quận / huyện'
                        fieldNames={{ label: 'name', value: 'id' }}
                        showSearch
                        optionFilterProp='children'
                        filterOption={(input, option) =>
                          (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                      />
                    </Form.Item>
                  </div>
                </Form.Item>

                <Form.Item
                  className='min-'
                  label={
                    <div>
                      <span className='text-[red] text-[16px]'>{`* `}</span>
                      Địa chỉ cụ thể:
                    </div>
                  }
                >
                  <div className='flex gap-x-3'>
                    <Form.Item
                      name='wardId'
                      className='w-[50%]'
                      rules={[{ required: true, message: 'Không được để trống' }]}
                    >
                      <Select
                        options={resource.wardId}
                        placeholder='Phường / xã / thị trấn'
                        fieldNames={{ label: 'name', value: 'id' }}
                        showSearch
                        clearIcon
                        optionFilterProp='children'
                        filterOption={(input, option) =>
                          (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      className='w-[50%]'
                      name='address'
                      rules={[{ required: true, message: 'Không được để trống' }]}
                    >
                      <Input placeholder='Số nhà, tên đường, phường / xã / thị trấn ...' />
                    </Form.Item>
                  </div>
                </Form.Item>

                <Form.Item
                  label={
                    <div>
                      <span className='text-[red] text-[16px]'>{`* `}</span>
                      SĐT / Mail
                    </div>
                  }
                >
                  <div className='flex gap-x-3'>
                    <Form.Item
                      name='phone'
                      rules={[
                        { required: true, message: 'Không được để trống' },
                        {
                          pattern: /^(\+84|\+840|84|084|840|0)([9235678]{1}[0-9]{8})$/,
                          message: 'Số điện thoại nhập sai định dạng'
                        }
                      ]}
                    >
                      <Input maxLength={10} className='w-[205px]' placeholder='Số điện thoại' />
                    </Form.Item>
                    <Form.Item
                      name='email'
                      className='w-[-webkit-fill-available]'
                      rules={[
                        {
                          pattern: /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$/,
                          message: 'Sai định dạng mail'
                        }
                      ]}
                    >
                      <Input placeholder='Mail' />
                    </Form.Item>
                  </div>
                </Form.Item>
                <Form.Item label='Số BHXH/Thuế:'>
                  <div className='flex gap-x-3'>
                    <Form.Item name='insuranceNumber'>
                      <Input className='w-[100%]' placeholder='MS BHXH' />
                    </Form.Item>
                    <Form.Item name='taxCode'>
                      <Input className='w-[100%]' placeholder='MS thuế' />
                    </Form.Item>
                  </div>
                </Form.Item>
              </div>
            </div>
            <WrapForm
              styleLabel='pl-[38px]'
              title={
                <div className='flex items-center justify-end gap-x-3'>
                  <img width={20} height={20} src='/edit.png' alt='icon_edit' />
                  Thông tin ngân hàng
                </div>
              }
            >
              <div>
                <Form.Item label='Tên ngân hàng:' name='bankName'>
                  <Input />
                </Form.Item>
                <Form.Item label='Số tài khoản:' name='bankAccount'>
                  <Input />
                </Form.Item>
                <Form.Item label='Tên tài khoản:' name='bankAccountName'>
                  <Input />
                </Form.Item>
                <Form.Item label='Chi nhánh ngân hàng:' name='bankBranch'>
                  <Input />
                </Form.Item>
              </div>
            </WrapForm>
            <WrapForm
              styleLabel='ml-[35px]'
              title={
                <div className='flex items-center justify-end gap-x-3'>
                  <img width={20} height={20} src='/edit.png' alt='icon_edit' />
                  Thông tin hồ sơ
                </div>
              }
            >
              <div>
                <Form.Item label='Hồ sơ lý lịch:' name='profileTypes'>
                  <Select
                    allowClear
                    mode='multiple'
                    options={resource.profileTypes}
                    fieldNames={{ label: 'name', value: 'id' }}
                    placeholder='Chọn loại hồ sơ'
                  />
                </Form.Item>
                <Form.Item label='Mô tả công việc:' name='jobDesc'>
                  <Input />
                </Form.Item>
              </div>
            </WrapForm>
            <WrapForm
              styleLabel='ml-[35px]'
              title={
                <div className='flex items-center justify-end gap-x-3'>
                  <img width={20} height={20} src='/edit.png' alt='icon_edit' />
                  Ảnh
                </div>
              }
            >
              <div>
                <Form.Item label='Ảnh chân dung:' name='Avatar'>
                  {portrait?.length ? (
                    <div className='relative h-[160px] w-[160px]'>
                      <Button
                        className='bg-[#ffffff59] w-[40px] h-[40px] rounded-[50%] top-[35%] left-[65%] translate-x-[-50%] z-20 cursor-pointer absolute'
                        onClick={() => handleRemoveImage('PORTRAIT')}
                      >
                        <DeleteFilled className='relative text-xl top-[-5%] left-[50%] translate-x-[-50%]' />
                      </Button>
                      <div className='w-full cursor-pointer'>
                        <Image
                          src={portrait}
                          alt='Image 30shine'
                          className='image h-[160px] w-[160px] bg-cover bg-center bg-no-repeat rounded-md'
                          height={160}
                          width={160}
                          style={{ objectFit: 'cover' }}
                        />
                        <Button
                          onClick={() => avatarInputRef.current.click()}
                          className='bg-[#ffffff59] w-[40px] h-[40px] rounded-[50%] top-[36%] left-[33%] translate-x-[-50%] z-20 cursor-pointer absolute'
                        >
                          <EditFilled className='relative text-xl top-[-5%] left-[50%] translate-x-[-50%]' />
                        </Button>

                        <input
                          ref={avatarInputRef}
                          className='w-0 h-0 custom-file-input'
                          type='file'
                          accept='image/*'
                          onChange={(e) => handleFileInputChange(e, 'PORTRAIT')}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className='w-[160px] item-null'>
                      <label>
                        <img
                          className='cursor-pointer icon h-[160px] border-2 p-12 rounded-md'
                          src='/icon/upload_avatar.png'
                          alt='upload'
                        />

                        <input
                          className='w-0 h-0 custom-file-input'
                          type='file'
                          accept='image/*'
                          onChange={(e) => handleFileInputChange(e, 'PORTRAIT')}
                        />
                      </label>
                    </div>
                  )}
                </Form.Item>
                <Form.Item label='Ảnh CCCD:' name='IDCard'>
                  <div className='flex'>
                    {idFront?.length ? (
                      <div className='relative h-[160px] w-[240px]'>
                        <Button
                          className='bg-[#ffffff59] w-[40px] h-[40px] rounded-[50%] top-[35%] left-[65%] translate-x-[-50%] z-20 cursor-pointer absolute'
                          onClick={() => handleRemoveImage('ID_FRONT')}
                        >
                          <DeleteFilled className='relative text-xl top-[-5%] left-[50%] translate-x-[-50%]' />
                        </Button>
                        <div className='w-full cursor-pointer'>
                          <Image
                            src={idFront}
                            alt='Image 30shine'
                            className='image h-[160px] w-[240px] bg-cover bg-center bg-no-repeat rounded-md'
                            height={160}
                            width={240}
                            style={{ objectFit: 'cover' }}
                          />
                          <Button
                            onClick={() => idFrontInputRef.current.click()}
                            className='bg-[#ffffff59] w-[40px] h-[40px] rounded-[50%] top-[36%] left-[33%] translate-x-[-50%] z-20 cursor-pointer absolute'
                          >
                            <EditFilled className='relative text-xl top-[-5%] left-[50%] translate-x-[-50%]' />
                          </Button>

                          <input
                            ref={idFrontInputRef}
                            className='w-0 h-0 custom-file-input'
                            type='file'
                            accept='image/*'
                            onChange={(e) => handleFileInputChange(e, 'ID_FRONT')}
                          />
                        </div>
                      </div>
                    ) : (
                      <label className='h-[160px] w-[240px] item-null border-2 rounded-md relative cursor-pointer'>
                        <div className='absolute top-[30%] left-[50%] translate-x-[-50%] text-center text-[#9A9A9A]'>
                          <CameraOutlined className='text-4xl' />
                          <p>Mặt trước CCCD</p>
                        </div>
                        <input
                          className='w-0 h-0 custom-file-input'
                          type='file'
                          accept='image/*'
                          onChange={(e) => handleFileInputChange(e, 'ID_FRONT')}
                        />
                      </label>
                    )}
                    {idBack?.length ? (
                      <div className='relative h-[160px] w-[240px] ml-5'>
                        <Button
                          className='bg-[#ffffff59] w-[40px] h-[40px] rounded-[50%] top-[35%] left-[65%] translate-x-[-50%] z-20 cursor-pointer absolute'
                          onClick={() => handleRemoveImage('ID_BACK')}
                        >
                          <DeleteFilled className='relative text-xl top-[-5%] left-[50%] translate-x-[-50%]' />
                        </Button>
                        <div className='w-full cursor-pointer'>
                          <Image
                            src={idBack}
                            alt='Image 30shine'
                            className='image h-[160px] w-[240px] bg-cover bg-center bg-no-repeat rounded-md'
                            height={160}
                            width={240}
                            style={{ objectFit: 'cover' }}
                          />
                          <Button
                            onClick={() => idBackInputRef.current.click()}
                            className='bg-[#ffffff59] w-[40px] h-[40px] rounded-[50%] top-[36%] left-[33%] translate-x-[-50%] z-20 cursor-pointer absolute'
                          >
                            <EditFilled className='relative text-xl top-[-5%] left-[50%] translate-x-[-50%]' />
                          </Button>

                          <input
                            ref={idBackInputRef}
                            className='w-0 h-0 custom-file-input'
                            type='file'
                            accept='image/*'
                            onChange={(e) => handleFileInputChange(e, 'ID_BACK')}
                          />
                        </div>
                      </div>
                    ) : (
                      <label className='h-[160px] w-[240px] item-null border-2 rounded-md relative cursor-pointer ml-5'>
                        <div className='absolute top-[30%] left-[50%] translate-x-[-50%] text-center text-[#9A9A9A]'>
                          <CameraOutlined className='text-4xl' />
                          <p>Mặt sau CCCD</p>
                        </div>
                        <input
                          className='w-0 h-0 custom-file-input'
                          type='file'
                          accept='image/*'
                          onChange={(e) => handleFileInputChange(e, 'ID_BACK')}
                        />
                      </label>
                    )}
                  </div>
                </Form.Item>
              </div>
            </WrapForm>
            <WrapForm
              styleLabel='ml-[35px]'
              title={
                <div className='flex items-center justify-end gap-x-3'>
                  <img width={20} height={20} src='/edit.png' alt='icon_edit' />
                  Hợp đồng
                </div>
              }
            >
              <div>
                {resource.contractType.map((item: Contract_type, index: number) => (
                  <Form.Item
                    key={`${staffDetail.working?.staffId || ''}_${index}`}
                    label={item.name}
                    name={`contracts_${item.id}`}
                  >
                    <DatePicker placeholder='dd-mm-yyyy' format='DD-MM-YYYY' />
                  </Form.Item>
                ))}
              </div>
            </WrapForm>
            <Button
              className='float-right w-[90px] mt-16 mb-12 bg-[#4096ff] text-white'
              type='primary'
              htmlType='submit'
              disabled={loading}
            >
              {!loading ? 'Hoàn tất' : <Spin className='text-white' indicator={antIcon} />}
            </Button>
          </div>
        </Form>
      )}
    </>
  )
}
