import { Table } from 'antd'

const columns: any = [
    {
        title: 'ID Bill',
        dataIndex: 'billId',
        key: 'billId',
    },
    {
        title: 'Tên khách',
        dataIndex: 'cusName',
        key: 'cusName',
    },
    {
        title: 'Số điện thoại khách',
        dataIndex: 'cusPhone',
        key: 'cusPhone',
    },
    {
        title: 'Giờ đặt lịch',
        dataIndex: 'hourBook',
        key: 'hourBook',
    },
    {
        title: 'Thời gian in bill',
        dataIndex: 'createBillTime',
        key: 'createBillTime',
    },
    {
        title: 'Đội',
        dataIndex: 'group',
        key: 'group',
    },
    {
        title: 'SkinnerId',
        dataIndex: 'skinnerId',
        key: 'skinnerId',
    },
    {
        title: 'Thời gian xếp skinner',
        dataIndex: 'assignSkinnerTime',
        key: 'assignSkinnerTime',
    },
    {
        title: 'Thời gian dự kiến gội xong',
        dataIndex: 'estimateSkinnerTime',
        key: 'estimateSkinnerTime',
    },
    {
        title: 'Thời gian kết thúc gội thực tế',
        dataIndex: 'skinnerFinishTime',
        key: 'skinnerFinishTime',
    },
    {
        title: 'Trạng thái gội',
        dataIndex: 'skinnerStatus',
        key: 'skinnerStatus',
        render: (value, record) => (
            <div dangerouslySetInnerHTML={{ __html: value }}>
            </div>
        ),
    },
    {
        title: 'StylistId',
        dataIndex: 'stylistId',
        key: 'stylistId',
    },
    {
        title: 'Thời gian xếp stylist',
        dataIndex: 'assignStylistTime',
        key: 'assignStylistTime',
    },
    {
        title: 'Thời gian dự kiến cắt xong',
        dataIndex: 'estimateStylistTime',
        key: 'estimateStylistTime',
    },
    {
        title: 'Trạng thái cắt',
        dataIndex: 'stylistStatus',
        key: 'stylistStatus',
        render: (value, record) => (
            <div dangerouslySetInnerHTML={{ __html: value }}>
            </div>
        ),
    },
    {
        title: 'Bộ phận phục vụ',
        dataIndex: 'billDeliveryType',
        key: 'billDeliveryType',
    },
    {
        title: 'Log xếp khách',
        dataIndex: 'data',
        key: 'data',
    }
]

interface Props {
    billInfoList: any,
}

export default function BillInfo(props: Props) {
    const { billInfoList } = props

    return (
        <div>
            <div className='mt-5 text-xl'>THÔNG TIN BILL</div>
            <Table className='mt-2 ' scroll={{ x: 2500 }} columns={columns} dataSource={billInfoList} pagination={false} bordered />
        </div>
    )
}
