import { useState, useEffect, useContext } from 'react'
import { getListStaffChange } from 'pages/staff/api'
import FormComponent from 'pages/base/Form'
import { ColumnConfig, SearchConfig, TypeSearch } from 'pages/base/util'
import { AppContext } from 'layouts/DashboardLayout'
import { getCity } from '../csvc/api'
import { EditOutlined } from '@ant-design/icons'
import { getSalons } from 'api/catalog'

export const ListCSVC = () => {
  const [reload, setReload] = useState(false)
  const [salons, setSalons] = useState([])
  const [cities, setCities] = useState([])
  const { notification } = useContext(AppContext)

  const column: ColumnConfig[] = [
    {
      title: 'STT',
      dataIndex: 'index',
      key: 'index'
    },
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Ngày tạo YC',
      dataIndex: 'date',
      key: 'date'
    },
    {
      title: 'Salon',
      dataIndex: 'Salon',
      key: 'Salon'
    },
    {
      title: 'Người tạo',
      dataIndex: 'creater',
      key: 'creater'
    },
    {
      title: 'Hạng mục',
      dataIndex: 'menu',
      key: 'menu'
    },
    {
      title: 'Vấn đề chính',
      dataIndex: 'main_issue',
      key: 'main_issue'
    },

    {
      title: 'Vị trí',
      dataIndex: 'position',
      key: 'position'
    },
    {
      title: 'Mô tả sự cố',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: 'Ngày theo quy định',
      dataIndex: 'date_for_rule',
      key: 'date_for_rule'
    },
    {
      title: 'Point',
      dataIndex: 'point',
      key: 'point'
    },
    {
      title: 'Trạng thái xử lý',
      dataIndex: 'status',
      key: 'status'
    },
    {
      title: 'Người xử lý',
      dataIndex: 'member_handle',
      key: 'member_handle'
    },
    {
      title: 'Ngày hoàn thành',
      dataIndex: 'finish_date',
      key: 'finish_date'
    },
    {
      title: 'Trạng thái nghiệm thu',
      dataIndex: 'acceptance',
      key: 'acceptance'
    },
    {
      title: 'Nguyên nhân hỏng hóc',
      dataIndex: 'broken_reason',
      key: 'broken_reason'
    },
    {
      title: 'Hành dộng',
      dataIndex: 'action',
      key: 'action',
      render: () => (
        <div className='flex justify-center items-center cursor-pointer hover:text-[#4096ff]'>
          <EditOutlined />
        </div>
      )
    }
  ]
  const columnSearch: SearchConfig[] = [
    {
      label: 'Thời gian',
      param: 'date',
      type: TypeSearch.date,
      require: true
    },
    {
      label: 'Loại',
      param: 'type',
      type: TypeSearch.select,
      options: [
        { label: 'Ngày tạo yêu cầu', value: 1 },
        { label: 'Ngày phát sinh sự cố', value: 2 },
        { label: 'Ngày deadline mong muốn', value: 3 },
        { label: 'Ngày deadline chuẩn', value: 4 }
      ],
      defaultValue: 1,
      require: true,
      placeholder: 'trạng thái'
    },
    {
      label: 'Chọn khu vực',
      param: 'city',
      mode: 'multiple',
      type: TypeSearch.select,
      options: cities,
      placeholder: 'trạng thái',
      fieldNames: { label: 'name', value: 'id' }
    },
    {
      label: 'Chọn salon',
      param: 'salonId',
      type: TypeSearch.select,
      mode: 'multiple',
      options: salons,
      placeholder: 'Chọn salon',
      fieldNames: { label: 'name', value: 'id' }
    },
    {
      type: TypeSearch.break
    },
    {
      type: TypeSearch.break
    },
    {
      label: 'Chọn hạng mục',
      param: 'menu',
      type: TypeSearch.select,
      options: [],
      placeholder: 'Chọn hạng mục',
      fieldNames: { label: 'name', value: 'id' },
      col: 4
    },
    {
      label: 'Chọn vấn đề',
      param: 'issue',
      type: TypeSearch.select,
      options: [],
      placeholder: 'Chọn vấn đề',
      fieldNames: { label: 'name', value: 'id' }
    },
    {
      label: 'Chọn nguyên nhân',
      param: 'reason',
      type: TypeSearch.select,
      options: [],
      placeholder: 'Chọn nguyên nhân',
      fieldNames: { label: 'name', value: 'id' }
    },
    {
      label: 'Trạng thái xử lý',
      param: 'status',
      type: TypeSearch.select,
      options: [],
      placeholder: 'Trạng thái xử lý',
      fieldNames: { label: 'name', value: 'id' }
    },
    {
      label: 'Trạng thái nghiệm thu',
      param: 'acceptance',
      type: TypeSearch.select,
      options: [],
      placeholder: 'Trạng thái nghiệm thu',
      fieldNames: { label: 'name', value: 'id' }
    },
    {
      label: 'ID nhân viên sửa chữa',
      param: 'staff_id',
      type: TypeSearch.input,
      placeholder: 'ID nhân viên sửa chữa'
    }
  ]
  const handleChangeValue = (changedValues, onReset: (field: string[]) => void) => {
    if (changedValues?.salonId) {
      onReset(['statusName'])
    }
  }
  const handleFetchOptions = async () => {
    try {
      const [salons, cities]: any = await Promise.all([getSalons(), getCity()])
      if (salons.statusCode === 200 && cities.statusCode === 0) {
        setSalons(salons.data)
        setCities(cities.data)
      }
    } catch (error) {
      notification('error', 'Thất bại', 'Có lỗi! Vui lòng kiểm tra lại')
    }
  }
  useEffect(() => {
    handleFetchOptions()
  }, [])
  return (
    <div className='min-h-[100vh] m-auto bg-[#F3F3F3] pb-12'>
      <FormComponent
        currentUrl='/csvc/list'
        title='Thống kê sự cố'
        column={column}
        columnSearch={columnSearch}
        fetchData={getListStaffChange}
        handleChangeValue={handleChangeValue}
        exportCsv={true}
        reload={reload}
        setReload={setReload}
      />
    </div>
  )
}
