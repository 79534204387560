import getInstanceAxios from 'utils/requestManager/RequestGlobalConfig'

export const cataLogServiceApi = getInstanceAxios(process.env.REACT_APP_CATALOG_SERVICE)
export const catalog = getInstanceAxios(process.env.REACT_APP_API_CATALOG)
export const StaffRequestView = getInstanceAxios(process.env.REACT_APP_LINK_STAFF_SERVICE_VIEW)


// export const getAllSalonProductConfigList = () => {
//     return catalog.get(`/Salon`)
// }

export const importStaffLevelConfig = (body: any) => {
    return cataLogServiceApi.post(`/v1/staff-level/import`, body)
}

export const getListDepartments = () => {
    return StaffRequestView.get('/v1/departments')
}

export const getStaffType = (groupId: number) => {
    return StaffRequestView.get(`/v1/staff/types?groupId=${groupId}`)
}

export const getStaffSkillLevel = () => {
    return StaffRequestView.get(`/v1/staff/skill-levels`)
}