/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { UploadOutlined } from "@ant-design/icons"
import { Button, Form, Upload, UploadFile, notification } from "antd"
import { useEffect, useState } from "react"
import * as XLSX from 'xlsx'

interface Props {
  setFile: any,
  setFileName: any,
  defaultName: string
}

const ImportFile = (props: Props) => {
  const { setFile, setFileName, defaultName } = props
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const beforeUpload = (file: any) => {
    console.log(file,"filefilefile")
    try {
      const reader = new FileReader()

      reader.onload = (e) => {
        const binaryString = e.target.result
        const workbook = XLSX.read(binaryString, { type: 'binary' })
        // Lấy dữ liệu từ sheet đầu tiên của workbook
        if (!workbook.SheetNames[0]) throw new Error('Sheet names do not ewxits Sheet[0]')
        const firstSheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[firstSheetName]

        // Chuyển đổi dữ liệu từ worksheet thành mảng đối tượng
        const excelData = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
        if (excelData.length < 2) throw new Error('Excel cần chứa dữ liệu !!')
        // if (excelData.length > 10000) throw new Error('Excel không được chứa quá 10.000 bản ghi !!')

        // Lấy dữ liệu từ phần tử thứ 2 trở đi
        // console.log(excelData, excelData[0][0].toLowerCase(), excelData[0][0].toLowerCase().includes("userid"), "excelData")

        // if (!(excelData[0][0].toLowerCase().includes("userid") || excelData[0][1].toLowerCase().includes("slugkey"))) {
        //   notification.error({
        //     message: 'Thông báo!',
        //     description: <>Vui lòng tải file</>
        //   })
        // }

        // chuyển đổi file thành mảng
        // const newData = excelData.slice(1).map((row) => {
        //   return {
        //     userId: row[0],
        //     slugkey: row[1],
        //   }
        // })
        setFileName(file.name)
        // filterData(newData)
        setFile(file)
        setFileList([{
          uid: '1',
          name: file.name,
          status: 'done',
        }])
      }

      reader.readAsBinaryString(file)
      return false
    } catch (e) {
      console.log(e)
    }
  }

  const filterData = (data) => {
    const checkedData = data.filter(item =>
      Number.isInteger(item.userId) && typeof item.slugkey === 'string'
    );
    // Lưu giá trị vào state
    setFile(checkedData)

  }

  useEffect(() => {
    if (defaultName) {
      setFileList([{
        uid: '1',
        name: defaultName,
        status: 'done',
      }])
    }
  }, [defaultName])

  const onRemoveFile = (event) => {
    setFileList([])
    setFile(null)
    setFileName("")
  }
  
  return (
    <Form>
      <Form.Item
        label='File data'
        rules={[{ required: true, message: 'Vui lòng tải file' }]}
      >
        <Upload
          beforeUpload={beforeUpload}
          maxCount={1}
          fileList={fileList}
          accept=".csv"
          onRemove={e => onRemoveFile(e)}
        >
          <Button style={{ display: 'flex', alignItems: 'center' }} icon={<UploadOutlined />}>
            Import file
          </Button>
        </Upload>
        <a
          style={{ width: 200 }}
          href="https://storage.30shine.com/web-staff/notification/PushNotiDemo.csv"
          type="media_type"
          className="py-2 text-[#1890FF] underline-offset-4 underline "
        >
          Tải file import mẫu
        </a>
      </Form.Item>
    </Form>

  )
}
export default ImportFile
