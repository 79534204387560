import { MenuOutlined } from '@ant-design/icons'

export const MENU_BI = [
  {
    isParent: true,
    key: 'bi',
    path: 'bi',
    title: 'BI',
    icon: <img src='/icon/bi.png' alt='30shine' />,
    nested: [
      {
        isParent: true,
        key: '/bi/export',
        path: '/bi/export',
        title: 'Export data',
        icon: <MenuOutlined />
      },
      {
        isParent: true,
        key: '/bi/create',
        path: '/bi/create',
        title: 'Tạo mới Script',
        icon: <MenuOutlined />
      },
      {
        isParent: true,
        key: '/bi/update',
        path: '/bi/update',
        title: 'Chỉnh sửa Script',
        icon: <MenuOutlined />
      }
    ]
  }
]
