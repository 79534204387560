import { notification } from 'antd'
import axios from 'axios'
import icon from '../../../images/iconImg.svg'
import iconEdit from 'images/iconEdit.svg'
import Cookies from 'js-cookie'
import { resizeImage } from '../utils/resizeImage'
import { useState } from 'react'
type Props = {
  index: number
  image: any
  onFileChange: (url) => void
  text: any
}

export default function ItemBox({ text, index, onFileChange, image }: Props) {
  const [loading, setLoading] = useState(false)

  const handleFileInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files[0]
    console.log(file, 'file')
    if (file) {
      try {
        setLoading(true)
        const resizedImage = await resizeImage(file, 1024, 1024)
        await uploadImage(resizedImage)
      } catch (error) {
        console.error('Error resizing image:', error)
        setLoading(false)
      }
    } else {
      console.log('ko có ảnh')
      notification.error({
        description: 'Vui lòng chọn ảnh',
        message: 'Thông báo'
      })
    }
  }

  const uploadImage = async (resizedImage: Blob) => {
    const AccessToken = Cookies.get('AccessToken') || ''
    if (loading) return
    try {
      setLoading(true)
      const formData = new FormData()
      formData.append('photo', resizedImage, 'resized.jpg')
      const response = await axios.post(`${process.env.REACT_APP_UPLOAD_STAFF}/staff/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${AccessToken}`
        }
      })
      if (response.data.data.image_url) onFileChange(response.data.data.image_url)
    } catch (error) {
      notification.error({
        description: 'Có lỗi xảy ra, vui lòng thử lại sau',
        message: 'Thông báo'
      })
      throw error
    }
    setLoading(false)
  }

  return (
    <div className='item-box'>
      {image?.length ? (
        <div className='item-notnull'>
          <label className='edit-button'>
            <img src={iconEdit} className='edit-icon' alt='upload' />
            <input className='custom-file-input' type='file' onChange={handleFileInputChange} />
          </label>
          <img className='image' src={image} alt='upload' />
        </div>
      ) : (
        <label className='item-null'>
          <img className='icon' src={icon} alt='upload' />
          <span className='text'>{text}</span>
          <input className='custom-file-input' type='file' onChange={handleFileInputChange} />
        </label>
      )}
    </div>
  )
}
