import { LoadingOutlined } from '@ant-design/icons'
import { Row, Col, Select, Input } from 'antd'
import { setSearchParamsIntroduction } from '../stylistSlice'
import { useAppDispatch, useAppSelector } from 'redux/store'
export const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />
export default function FormSearchChange() {
  const dispatch = useAppDispatch()
  const { resource, searchParamsIntroduction }: any = useAppSelector((state) => state.stylist)
  const { role, userInfo }: any = useAppSelector((state) => state.user)

  return (
    <>
      <Row className='flex items-start gap-y-4 '>
        <Col span={6}>
          <Input
            value={searchParamsIntroduction.Keyword}
            onChange={(e) => {
              dispatch(setSearchParamsIntroduction({ ...searchParamsIntroduction, Keyword: e.target.value }))
            }}
            placeholder='Họ và tên/ID'
          />
        </Col>
        <Col span={4}>
          <Select
            disabled={userInfo.staffType.id === 9}
            defaultValue={userInfo.staffType.id === 9 ? userInfo.listSalon[0].id : undefined}
            allowClear
            mode='multiple'
            className='w-full'
            value={searchParamsIntroduction.SalonId}
            onChange={(e) => {
              dispatch(setSearchParamsIntroduction({ ...searchParamsIntroduction, SalonId: e }))
            }}
            placeholder='Chọn Salon'
            options={userInfo.listSalon}
            fieldNames={{ label: 'name', value: 'id' }}
            showSearch
            optionFilterProp='children'
            filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
          />
        </Col>
        <Col span={4}>
          <Select
            mode='multiple'
            allowClear
            value={searchParamsIntroduction.StatusId}
            onChange={(e) => {
              dispatch(
                setSearchParamsIntroduction({
                  ...searchParamsIntroduction,
                  StatusId: e
                })
              )
            }}
            className='w-full'
            options={[
              { value: 1, label: 'Chưa duyệt' },
              { value: 3, label: 'Đã duyệt' },
              { value: 2, label: 'Không duyệt' }
            ]}
            placeholder='Trạng thái'
          />
        </Col>
      </Row>
    </>
  )
}
