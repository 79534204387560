import { author } from './config'
import axios from 'axios'
import Cookies from 'js-cookie'

interface paramsProps {
  page: number
  pageSize: number
}
export const getRecordIntent = () => {
  return author.get(`/api/v1/ai-record-intent`)
}

export const getListRecord = (params: paramsProps) => {
  return author.get(`/api/v1/ai-record/list`, {
    params: params
  })
}

export const getAiRecordById = (id: number) => {
  return author.get(`/api/v1/ai-record/${id}`)
}

export const getListServices = (params: paramsProps) => {
  return author.get(`/api/v1/service-manage/services-manage-list`, {
    params: params
  })
}
export const updateServices = (servicesId: string, body: any) => {
  return author.put(`/api/v1/service-manage/${servicesId}/update`, body)
}

export const createServices = (body: any) => {
  return author.post(`/api/v1/service-manage/create`, body)
}

const getAccessTokenFromCookie = () => {
  return Cookies.get('accessToken') // Thay đổi key này nếu cần
}

export const downloadExcel = async (params: string) => {
  // Thêm kiểu trả về là Blob
  const accessToken = getAccessTokenFromCookie()
  const url = process.env.REACT_APP_API_WEBFORM_ERP + `/api/v1/service-manage/services-manage-list/export?${params}`

  axios({
    url: url,
    method: 'GET',
    responseType: 'blob', // Để nhận dữ liệu dưới dạng blob

    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${accessToken}`
    }
  })
    .then((response) => {
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      })
      const url = URL.createObjectURL(blob)

      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.download = `export_data.xlsx`

      document.body.appendChild(a)
      a.click()

      URL.revokeObjectURL(url)
      document.body.removeChild(a)
    })
    .catch((error) => {
      console.error('Error downloading Excel:', error)
    })
}

export const GetListServices = () => {
  return author.get(`/api/v1/list-select/services-list`)
}

export const getDetailServices = (salonId: string) => {
  return author.get(`/api/v1/service-manage/${salonId}/detail`)
}

export const getListTreeServices = () => {
  return author.get(`/api/v1/list-select/category-list`)
}

export const createAudio2Text = (id: string) => {
  return author.post(`/api/v1/ai-record/${id}/audio-to-text`)
}

export const createAudioScript = (id: string) => {
  return author.post(`/api/v1/ai-record/${id}/classify`)
}
