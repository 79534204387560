import { useEffect, useState } from 'react'
import './App.scss'
import config from 'configs/env'
import { SSO } from '@30shine-fe/sso-erp'
import axios from 'axios'
import DashboardLayout from 'layouts/DashboardLayout'
import { getListMenuAccess } from 'api/users'
import { setUrlAccess } from 'redux/slices/userSlice'
import { useDispatch } from 'react-redux'
import { useAppSelector } from 'redux/store'
import queryString from 'query-string'
import DefaultLayout from 'layouts/DefaultLayout'

const sso = new SSO(process.env.REACT_APP_ENV || 'test')
function App() {
  const dispatch = useDispatch()
  const { userInfo } = useAppSelector((state) => state.user)
  // console.log(userInfo, 'userInfo')

  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isWebview, setIsWebview] = useState(false)

  const fetchCredentials = async () => {
    const searchParams = queryString.parse(window.location.search)
    if (searchParams?.token) {
      setIsWebview(true)
      fetchAuthorization()
      localStorage.setItem('AccessToken', searchParams.token.toString())
      setIsAuthenticated(true)
      return
    }
    try {
      const credentials = await sso.ssoLogin()
      if (credentials) {
        localStorage.setItem('AccessToken', credentials.AccessToken)
        localStorage.setItem('RefreshToken', credentials.RefreshToken)
        localStorage.setItem('IdToken', credentials.IdToken)
        const request = axios.create({
          baseURL: config.base_authenticator_v2_url
        })
        request.defaults.headers.common['Authorization'] = 'Bearer ' + credentials.AccessToken
        request.interceptors.response.use(
          (response) => {
            return response
          },
          async (error) => {
            console.log(error)
            return Promise.reject(error)
          }
        )
        await fetchAuthorization()
        setIsAuthenticated(true)
      }
    } catch (e) {
      console.log(e)
      if (e?.message === 'Request failed with status code 403') {
        setIsAuthenticated(true)
      }
    }
  }
  const fetchAuthorization = async () => {
    try {
      const menu: any = await getListMenuAccess()
      if (menu.errorCode === 0) {
        dispatch(setUrlAccess(menu.data))
        setIsAuthenticated(true)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchCredentials()
  }, [])

  return (
    <div className='App'>{isAuthenticated ? <>{isWebview ? <DefaultLayout /> : <DashboardLayout />}</> : null}</div>
  )
}

export default App
