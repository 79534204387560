import { useState } from 'react'
import { ColumnConfig } from '../../base/util'
import { InputNumber, Form, Row, Table, Button, notification, Upload } from 'antd'
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons'
import { updateSalonServiceCatalog } from '../api'
import * as XLSX from 'xlsx'

export const ServiceImportPriceSalon = () => {
  const [data, setData] = useState([])

  const handleOnChange = (item: any, field: string, value?: any) => {
    const newData = [...data]
    const targetIndex = newData.findIndex((dataItem) => dataItem.id === item.id)

    if (targetIndex !== -1) {
      switch (field) {
        case 'IsCheckKCS':
          newData[targetIndex].IsCheckKCS = !newData[targetIndex].IsCheckKCS
          setData(newData)
          break
        case 'Price':
          newData[targetIndex].Price = value
          setData(newData)
          break
        case 'Delete':
          newData.splice(targetIndex, 1)
          setData(newData)
          break
        default:
          break
      }
    }
  }
  const column: ColumnConfig[] = [
    {
      title: 'Id Salon',
      dataIndex: 'SalonId',
      key: 'SalonId'
    },
    {
      title: 'Salon',
      dataIndex: 'Salon',
      key: 'Salon'
    },
    {
      title: 'ID dịch vụ',
      dataIndex: 'Id',
      key: 'Id'
    },
    {
      title: 'Tên dịch vụ',
      dataIndex: 'Name',
      key: 'Name'
    },
    {
      title: 'Giá chính',
      dataIndex: 'Price',
      key: 'Price'
    },
    {
      title: 'Giá Salon',
      key: 'PriceSalon',
      render: (record) => (
        <InputNumber value={record.PriceSalon} onChange={(value: number) => handleOnChange(record, 'Price', value)} />
      )
    },
    {
      title: '',
      key: 'delete',
      render: (record) => <DeleteOutlined onClick={(value) => handleOnChange(record, 'Delete', value)}></DeleteOutlined>
    }
  ]

  const beforeUpload = (file: any) => {
    try {
      const reader = new FileReader()

      reader.onload = (e) => {
        const binaryString = e.target.result
        const workbook = XLSX.read(binaryString, { type: 'binary' })

        // Lấy dữ liệu từ sheet đầu tiên của workbook
        if (!workbook.SheetNames[0]) throw new Error('Sheet names do not ewxits Sheet[0]')
        const firstSheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[firstSheetName]

        // Chuyển đổi dữ liệu từ worksheet thành mảng đối tượng
        const excelData = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
        if (excelData.length < 2) throw new Error('Excel cần chứa dữ liệu !!')
        // Lấy dữ liệu từ phần tử thứ 2 trở đi
        const newData = excelData.slice(1).map((row) => {
          return {
            SalonId: row[0],
            Id: row[1],
            PriceSalon: row[2]
          }
        })

        // Lưu giá trị vào state
        setData(newData)
      }

      reader.readAsBinaryString(file)
      return false
    } catch (e) {
      console.log(e)
    }
  }

  const handleUpdateConfig = async () => {
    if (!data || data.length === 0) {
      return notification.warning({
        description: 'Danh mục dịch vụ không thể bỏ trống !',
        message: 'Thiếu dữ liệu'
      })
    }
    console.log(data, 'data')
    try {
      const body = data.map((item) => {
        return {
          salonId: item.SalonId,
          serviceId: item.Id,
          price: item.PriceSalon
        }
      })
      // console.log(body, 'body')
      const response = await updateSalonServiceCatalog(body)
      if (response) {
        notification.success({
          description: 'Bạn đã import giá dịch vụ Salon thành công',
          message: 'Thành công'
        })
        setData([])
      } else {
        notification.warning({
          description: 'Không thể import giá dịch vụ Salon',
          message: 'Lỗi'
        })
      }
    } catch (error) {
      console.error('Error:', error)
      notification.error({
        description: error.message,
        message: 'Lỗi'
      })
    }
  }
  return (
    <div className='min-h-[100vh] m-auto bg-[#F3F3F3] pb-12'>
      <div className='w-full px-5 py-5 search-wrapper'>
        <div className='pb-10'>
          <h2 className='text-2xl font-bold mb-7'>Import giá dịch vụ Salon</h2>
          <Row className=''>
            <h3>Import file</h3>{' '}
            <a href='/excel/demo-import-salon/excel.xlsx' className='ml-3 italic underline'>
              Download file mẫu
            </a>
          </Row>
          <Row className='mt-2'>
            <Upload beforeUpload={beforeUpload} showUploadList={false}>
              <Button icon={<UploadOutlined style={{ position: 'relative', top: '-4px' }} />}>Upload File</Button>
            </Upload>
          </Row>
          <div
            className='mt-5 bg-white'
            style={{ boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px', borderRadius: 10 }}
          >
            <Row style={{ display: 'contents' }} gutter={18}>
              <Table
                scroll={{ x: 1500, y: 550 }}
                className='min-w-[1000px] overflow-auto'
                dataSource={data}
                columns={column}
                pagination={false}
                bordered
              />
            </Row>
          </div>
        </div>
        <Button type='primary' onClick={handleUpdateConfig}>
          Cập nhật cấu hình
        </Button>
      </div>
    </div>
  )
}
