import '../index.scss'
import { LoadingOutlined } from '@ant-design/icons'
import { Row, Col, Select, DatePicker, Input } from 'antd'
import dayjs from 'dayjs'
import { removeVietnamese } from 'utils/selector/removeVietnamese'
import { setSearchParamsStaffChange } from '../staffSlice'
import { useAppDispatch, useAppSelector } from 'redux/store'
export const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />
const { RangePicker } = DatePicker
export default function FormSearchChange() {
  const dispatch = useAppDispatch()
  const { resource, searchParamsStaffChange }: any = useAppSelector((state) => state.staff)
  const { role, userInfo }: any = useAppSelector((state) => state.user)

  return (
    <>
      <Row className='flex items-start gap-y-4 '>
        <Col span={3}>
          <Input
            value={searchParamsStaffChange.Keyword}
            onChange={(e) => {
              dispatch(setSearchParamsStaffChange({ ...searchParamsStaffChange, Keyword: e.target.value }))
            }}
            placeholder='Họ và tên/ID'
          />
        </Col>
        <Col span={5}>
          <RangePicker
            className='w-full'
            placeholder={['Từ ngày', 'Đến ngày']}
            format={'DD-MM-YYYY'}
            value={
              searchParamsStaffChange?.birthday
                ? [dayjs(searchParamsStaffChange.birthday[0]), dayjs(searchParamsStaffChange.birthday[1])]
                : undefined
            }
            onChange={(value) => {
              dispatch(
                setSearchParamsStaffChange({
                  ...searchParamsStaffChange,
                  birthday: value
                    ? [
                        value[0] ? dayjs(value[0]).format('YYYY-MM-DD') : '',
                        value[1] ? dayjs(value[1]).format('YYYY-MM-DD') : ''
                      ]
                    : undefined
                })
              )
            }}
          />
        </Col>
        <Col span={3}>
          <Select
            showSearch
            allowClear
            className='w-full'
            value={searchParamsStaffChange.GroupId}
            onChange={(e) => {
              dispatch(setSearchParamsStaffChange({ ...searchParamsStaffChange, GroupId: e }))
            }}
            placeholder='Khối'
            options={resource.groupId}
            fieldNames={{ label: 'name', value: 'id' }}
            filterOption={(input: any, option: any) =>
              removeVietnamese(option?.name ?? '').includes(removeVietnamese(input))
            }
          />
        </Col>
        <Col span={3}>
          <Select
            allowClear
            mode='multiple'
            className='w-full'
            value={searchParamsStaffChange.SalonId}
            onChange={(e) => {
              dispatch(setSearchParamsStaffChange({ ...searchParamsStaffChange, SalonId: e }))
            }}
            placeholder='Chọn Salon'
            options={role === 'ADMIN' ? resource.salonId : userInfo.listSalon}
            fieldNames={{ label: 'name', value: 'id' }}
            showSearch
            optionFilterProp='children'
            filterOption={(input: any, option: any) =>
              removeVietnamese(option?.name ?? '').includes(removeVietnamese(input))
            }
          />
        </Col>
        <Col span={3}>
          <Select
            mode='multiple'
            showSearch
            filterOption={(input: any, option: any) =>
              removeVietnamese(option?.label ?? '').includes(removeVietnamese(input))
            }
            allowClear
            value={searchParamsStaffChange.ActiveStatus}
            onChange={(e) => {
              dispatch(
                setSearchParamsStaffChange({
                  ...searchParamsStaffChange,
                  ActiveStatus: e
                })
              )
            }}
            className='w-full'
            options={resource.active}
            placeholder='Trạng thái làm việc'
          />
        </Col>
        <Col span={3}>
          <Select
            mode='multiple'
            allowClear
            className='w-full'
            value={searchParamsStaffChange.StaffType}
            onChange={(e) => {
              dispatch(
                setSearchParamsStaffChange({
                  ...searchParamsStaffChange,
                  StaffType: e
                })
              )
            }}
            options={[...resource.staffTypeId, ...resource.staffTypeId2]}
            fieldNames={{ label: 'name', value: 'id' }}
            placeholder='Chọn bộ phận'
            showSearch
            filterOption={(input: any, option: any) =>
              removeVietnamese(option?.name ?? '').includes(removeVietnamese(input))
            }
          />
        </Col>
      </Row>
    </>
  )
}
