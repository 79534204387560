/* eslint-disable jsx-a11y/anchor-has-content */
import '../index.scss'
import '../../../index.css'
import { FileExcelOutlined, LoadingOutlined, SearchOutlined } from '@ant-design/icons'
import { Form, Button, Row, Table, Pagination, Spin, Select } from 'antd'
import { useEffect, useMemo, useRef, useState, useContext } from 'react'
import {
  convertParamsAxios,
  convertQueryStringToObject,
  exportListStaffChange,
  getListStaffChange,
  getStaffType
} from '../api'
import { setSearchParamsStaffChange } from '../staffSlice'
import { ParamsSearchType } from '../interface'
import FormSearchChange from './FormSearch'
import { SpinIndicator } from 'antd/es/spin'
import { AppContext } from 'layouts/DashboardLayout'
import { Link, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'redux/store'
import dayjs from 'dayjs'
import { optionsListColumnStaffChange, valueDefaultListColumnStaffChange } from '../constant'
import { fetchDataByApiKey } from '../hook/fetchViewStaff'
import * as XLSX from 'xlsx'
import { getSalons } from 'api/catalog'

export const antIcon: SpinIndicator = <LoadingOutlined style={{ fontSize: 24 }} spin />
const { Option } = Select
export default function StaffChangeComponent() {
  const { notification } = useContext(AppContext)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { role, userInfo }: any = useAppSelector((state) => state.user)
  const { resource, searchParamsStaffChange }: any = useAppSelector((state) => state.staff)
  const [form] = Form.useForm()

  const [data, setData] = useState([])
  const [total, setTotal] = useState(0)
  const [preParams, setPreParams] = useState<ParamsSearchType>({ ...searchParamsStaffChange })
  const [columnShow, setColumnShow] = useState<string[]>([])

  const columns = useMemo(() => {
    return [
      {
        title: 'STT',
        dataIndex: 'index',
        key: 'index',
        align: 'center'
      },
      {
        title: 'ID nhân viên',
        dataIndex: 'staffId',
        key: 'staffId',
        align: 'center'
      },
      {
        title: <div className='m-auto w-fit'>{'Tên nhân viên'}</div>,
        key: 'staffName',
        render: (item) => (
          <Link className='text-[#1677ff] hover:text-black' to={`/staff/detail/${item.staffIdMongo}`}>
            {item.staffName}
          </Link>
        )
      },
      {
        title: <div className='m-auto w-fit'>{'Bộ phận'}</div>,
        dataIndex: 'staffTypeName',
        key: 'staffTypeName'
      },
      {
        title: <div className='m-auto w-fit'>{'Bậc'}</div>,
        dataIndex: 'levelName',
        key: 'levelName',
        render: (item: string) => <div className='min-w-[50px]'>{item}</div>
      },
      {
        title: <div className='m-auto w-fit'>{'Salon hiện tại'}</div>,
        dataIndex: 'salonCurrentName',
        key: 'salonCurrentName'
      },
      {
        title: <div className='m-auto w-fit'>{'Salon thay đổi'}</div>,
        dataIndex: 'salonChangeName',
        key: 'salonChangeName'
      },

      {
        title: <div className='m-auto w-fit'>{'Trạng thái'}</div>,
        dataIndex: 'statusName',
        key: 'statusName'
      },
      {
        title: 'Ngày biến động',
        dataIndex: 'createdDate',
        key: 'createdDate',
        render: (item: Date) => <div>{item ? dayjs(item).format('DD/MM/YYYY') : ''}</div>,
        align: 'center'
      },
      {
        title: <div className='max-w-[100px]'>{'Ngày phát sinh công cuối cùng'}</div>,
        dataIndex: 'lastTimekeepingDate',
        key: 'lastTimekeepingDate',
        render: (item: Date) => <div>{item ? dayjs(item).format('DD/MM/YYYY') : ''}</div>,
        align: 'center'
      },
      {
        title: <div className='max-w-[102px]'>{'Tổng thâm niên xét lên bậc'}</div>,
        dataIndex: 'totalSeniorityLevelUp',
        key: 'totalSeniorityLevelUp',
        render: (item: string) => <div className='max-w-[135px]'>{item}</div>,
        align: 'center'
      },
      {
        title: <div className='max-w-[105px]'>{'Tổng thâm niên nghề tại 30shine'}</div>,
        dataIndex: 'totalSeniorityAt30Shine',
        key: 'totalSeniorityAt30Shine',
        align: 'center'
      },
      {
        title: <div className='m-auto w-fit'>{'Ghi chú'}</div>,
        dataIndex: 'description',
        key: 'description'
      },
      {
        title: <div className='m-auto w-fit'>{'Nguồn lao động'}</div>,
        dataIndex: 'recruitmentSourceName',
        key: 'recruitmentSourceName'
      }
    ]
  }, [])

  const handleFetchListStaff = async (paramsSearch: ParamsSearchType) => {
    if (isNaN(Number(paramsSearch.PageIndex))) {
      paramsSearch.PageIndex = 1
    }
    if (isNaN(Number(paramsSearch.PageSize))) {
      paramsSearch.PageSize = 10
    }
    const payload: ParamsSearchType = { ...paramsSearch }
    const result = []

    if (payload?.asms && payload.asms.length > 0 && resource.asms.length > 0) {
      resource.asms
        .filter((i) => payload.asms.includes(i.id))
        .forEach((element) => {
          element.salonIds.forEach((e) => {
            result.push(e)
          })
        })
    }

    if (paramsSearch?.birthday) {
      payload.FromDateString = paramsSearch?.birthday[0]
      payload.ToDateString = paramsSearch?.birthday[1]
    }
    result.length > 0 ? (payload.SalonId = result) : ''
    delete payload.birthday
    delete payload.asms

    try {
      setLoading(true)
      const res: any = await getListStaffChange(payload)
      setLoading(false)
      if (res.statusCode === 0) {
        setTotal(res.data.totalRecords)
        setData(res.data.listData)
        setPreParams(payload)
      }
    } catch (error) {
      notification('error', 'Thất bại', 'Có lỗi! Vui lòng kiểm tra lại')
    }
    setLoading(false)
  }
  const prefetchDataStaffList = () => {
    const paramsSearchResult: any = convertQueryStringToObject(window.location.search.replace('?', ''))
    if (Object.keys(paramsSearchResult).length > 0) {
      dispatch(
        setSearchParamsStaffChange({
          ...paramsSearchResult,
          PageIndex: Number(paramsSearchResult.PageIndex),
          PageSize: Number(paramsSearchResult.PageSize)
        })
      )
      handleFetchListStaff(paramsSearchResult)
    } else {
      setData([])
    }
  }
  const exportToExcel = async () => {
    try {
      let payload: any = {}
      if (userInfo.staffType.id === 17 && !preParams.SalonId) {
        const listSalon = userInfo.listSalon
        const paramSalonArray = listSalon.map((salon) => {
          return salon.id
        })
        const newSalonId = {
          SalonId: paramSalonArray
        }
        payload = { ...preParams, ...newSalonId }
      } else {
        payload = { ...preParams }
      }
      delete payload.PageIndex
      delete payload.PageSize
      const dataExcel = await exportListStaffChange(payload)

      const ws = XLSX.utils.json_to_sheet(dataExcel.data)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
      const buffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })

      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = 'danh_sach_bien_dong_nhan_su.xlsx'
      document.body.appendChild(a)
      a.click()
    } catch (error) {
      console.log(error)
      notification('error', 'Thất bại', 'Có lỗi xảy ra!')
    }
  }

  const onFinish = () => {
    const newUrlParams = convertParamsAxios({ ...searchParamsStaffChange, PageIndex: 1 })
    const currentUrlParams = window.location.search.substring(1)
    if (newUrlParams === currentUrlParams) {
      prefetchDataStaffList()
    } else {
      navigate(`/change?${newUrlParams}`)
    }
  }
  useEffect(() => {
    prefetchDataStaffList()
  }, [window.location.search])
  const handleSetValueLocalStorage = async () => {
    const columStorage = await localStorage.getItem('SEARCH_COLUMN_STAFF_CHANGE')
    if (!columStorage) {
      localStorage.setItem('SEARCH_COLUMN_STAFF_CHANGE', JSON.stringify(valueDefaultListColumnStaffChange))
    } else {
      setColumnShow(JSON.parse(columStorage))
    }
  }

  useEffect(() => {
    handleSetValueLocalStorage()
  }, [])
  const handleFetchData = async () => {
    try {
      await Promise.all([
        fetchDataByApiKey('staffTypeId', getStaffType, dispatch, notification, 1),
        fetchDataByApiKey('staffTypeId2', getStaffType, dispatch, notification, 2),
        fetchDataByApiKey('salonId', getSalons, dispatch, notification)
      ])
    } catch (error) {
      notification('error', 'Thất bại', 'Có lỗi xảy ra!')
    }
  }
  useEffect(() => {
    handleFetchData()
  }, [])
  return (
    <div className='w-full px-5 py-5 search-wrapper'>
      <Form className='pb-10' form={form} onFinish={onFinish}>
        <div className='text-sm flex gap-x-3 text-[#b7b3b3]'>
          <Link className='hover:text-black' to='#'>
            Quản lý nhân viên
          </Link>
          <span>{`>`}</span>
          <span className='text-black'>Danh sách biến động nhân sự</span>
        </div>
        <h2 className='text-2xl mb-7'>Danh sách biến động nhân sự</h2>
        <FormSearchChange />
        <div className='mt-8 bg-white ' style={{ padding: '20px 20px 100px 20px' }}>
          <Row className='flex gap-x-3'>
            <Button
              type='default'
              htmlType='submit'
              className='w-[200px] bg-[#4096ff] text-white hover:bg-white'
              disabled={loading}
            >
              {!loading ? (
                <div className='flex items-center justify-center hover_icon'>
                  <SearchOutlined className='mr-1 text-white' />
                  Xem dữ liệu
                </div>
              ) : (
                <Spin indicator={antIcon} />
              )}
            </Button>
            <Button
              onClick={exportToExcel}
              type='default'
              className='w-[200px] bg-[#28A745] text-white hover:bg-white hover_icon'
            >
              <div className='flex items-center justify-center'>
                <FileExcelOutlined className='mr-1 text-white' />
                Xuất excel
              </div>
            </Button>
          </Row>

          <Row style={{ display: 'contents' }} gutter={18}>
            <div className='flex items-center justify-between'>
              <h3>
                {'Tổng số nhân viên biến động : '}
                <span className='text-[red] font-semibold'>{total}</span>
              </h3>
              <Pagination
                className='float-right pb-8 mt-8'
                current={searchParamsStaffChange.PageIndex}
                pageSize={searchParamsStaffChange.PageSize}
                total={total}
                onChange={(page: number, pageSize: number) => {
                  dispatch(
                    setSearchParamsStaffChange({
                      ...searchParamsStaffChange,
                      PageSize: pageSize,
                      PageIndex: searchParamsStaffChange.PageSize !== pageSize ? 1 : page
                    })
                  )
                  navigate(
                    `/change?${convertParamsAxios({
                      ...searchParamsStaffChange,
                      PageSize: pageSize,
                      PageIndex: searchParamsStaffChange.PageSize !== pageSize ? 1 : page
                    })}`
                  )
                }}
                showSizeChanger
              />
            </div>
            <div className='select_mode min-w-[250px] float-right mb-4 relative'>
              <h3 className='absolute top-[5px] left-[30%] font-semibold'> Chọn cột hiển thị</h3>
              <Select
                mode='multiple'
                className='min-w-[250px] float-right'
                onChange={(e) => {
                  if (e.includes('all')) {
                    localStorage.setItem(
                      'SEARCH_COLUMN_STAFF_CHANGE',
                      JSON.stringify(valueDefaultListColumnStaffChange)
                    )
                    setColumnShow(valueDefaultListColumnStaffChange)
                  } else if (e.includes('clear')) {
                    localStorage.setItem('SEARCH_COLUMN_STAFF_CHANGE', JSON.stringify([]))
                    setColumnShow([])
                  } else {
                    localStorage.setItem('SEARCH_COLUMN_STAFF_CHANGE', JSON.stringify(e))
                    setColumnShow(e)
                  }
                }}
                value={columnShow}
              >
                {columnShow.length === 11 ? (
                  <Option style={{ textAlign: 'center' }} value={'clear'}>
                    Bỏ tất cả
                  </Option>
                ) : (
                  <Option style={{ textAlign: 'center' }} value={'all'}>
                    Chọn tất cả
                  </Option>
                )}

                {optionsListColumnStaffChange.map((item) => (
                  <Option key={item.value} value={item.value} label={item.label}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </div>
            <Table
              className='mt-2 overflow-auto'
              dataSource={data}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              columns={columns.filter((i) =>
                [...columnShow, 'index', 'staffId', 'staffName', 'action'].includes(i.key)
              )}
              pagination={false}
              bordered
            />
            <Pagination
              className='float-right pb-8 mt-8'
              current={searchParamsStaffChange.PageIndex}
              pageSize={searchParamsStaffChange.PageSize}
              total={total}
              onChange={(page: number, pageSize: number) => {
                dispatch(
                  setSearchParamsStaffChange({
                    ...searchParamsStaffChange,
                    PageSize: pageSize,
                    PageIndex: searchParamsStaffChange.PageSize !== pageSize ? 1 : page
                  })
                )
                navigate(
                  `/change?${convertParamsAxios({
                    ...searchParamsStaffChange,
                    PageSize: pageSize,
                    PageIndex: searchParamsStaffChange.PageSize !== pageSize ? 1 : page
                  })}`
                )
                setTimeout(
                  () =>
                    window.document.getElementById('body__page').scrollTo({
                      top: 0,
                      behavior: 'smooth'
                    }),
                  500
                )
              }}
              showSizeChanger
            />
          </Row>
        </div>
      </Form>
    </div>
  )
}
