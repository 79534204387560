import { getResultsByAiCameraId } from '../api'
import React, { useState, useEffect } from 'react'
import { Spin } from 'antd'

const AiCameraDetail = ({ aiCameraId }: { aiCameraId: number }) => {
  const [results, setResults] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const fetchData = async () => {
    try {
      const res: any = await getResultsByAiCameraId(aiCameraId)
      if (res) {
        const resultItems = []
        for (let index = 0; index < res.length; index++) {
          const element = res[index]
          resultItems.push(...element.images)
        }
        setResults(resultItems)
      }
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, []) // Empty dependency array ensures the effect runs only once on component mount

  if (loading) {
    return <p>Loading...</p>
  }

  if (error) {
    return <p>Error: {error.message}</p>
  }

  return (
    <div className='grid grid-cols-3 gap-4'>
      {results &&
        results.map((item) => {
          return (
            <div key={item.startTime}>
              <img src={item.url} alt='' />
            </div>
          )
        })}
    </div>
  )
}

export default AiCameraDetail
