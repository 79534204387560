import { author } from './config'
import axios from 'axios'
import Cookies from 'js-cookie'
interface paramsProps {
  page: number
  pageSize: number
}
interface bodySalonProps {
  Publish?: boolean
  IsSalonHoiQuan?: boolean
  SalonOTP?: boolean
}

// list tỉnh
// list config
export const getListCity = () => {
  return author.get(`/api/v1/list-select/city-list`)
}

export const getListDistricts = (cityId: string) => {
  return author.get(`/api/v1/list-select/districs-list?cityId=${cityId}`)
}

// list config
export const getListTblConfig = (key: string) => {
  return author.get(`/api/v1/list-select/processing-list?key=${key}`)
}

export const getListSalon = (params: paramsProps) => {
  return author.get(`/api/v1/salon/list-salon`, {
    params: params
  })
}
export const getListServiceSalon = (params: paramsProps) => {
  return author.get(`/api/salon-service/list`, {
    params: params
  })
}

export const updateSalonStatus = (salonId: string, body: bodySalonProps) => {
  return author.put(`/api/v1/salon/detail-salon/${salonId}/update-status`, body)
}
const getAccessTokenFromCookie = () => {
  return Cookies.get('accessToken') // Thay đổi key này nếu cần
}

export const downloadExcel = async (params: string) => {
  // Thêm kiểu trả về là Blob
  const accessToken = getAccessTokenFromCookie()
  const url = process.env.REACT_APP_API_WEBFORM_ERP + `/api/v1/salon/list-salon/export?${params}`

  axios({
    url: url,
    method: 'GET',
    responseType: 'blob', // Để nhận dữ liệu dưới dạng blob

    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${accessToken}`
    }
  })
    .then((response) => {
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      })
      const url = URL.createObjectURL(blob)

      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.download = `export_data.xlsx`

      document.body.appendChild(a)
      a.click()

      URL.revokeObjectURL(url)
      document.body.removeChild(a)
    })
    .catch((error) => {
      console.error('Error downloading Excel:', error)
    })
}

export const getDetailSalon = (salonId: string) => {
  return author.get(`/api/v1/salon/detail-salon/${salonId}`)
}

export const updateDataSalon = (salonId: string, body: any) => {
  return author.put(`/api/v1/salon/detail-salon/${salonId}/update`, body)
}

export const createNewSalon = (body: any) => {
  return author.post(`/api/v1/salon/create`, body)
}

export const GetListServices = () => {
  return author.get(`/api/v1/list-select/services-list`)
}

export const ListServicesSalonConfig = (PageIndex, PageSize, SalonId, ServiceId) => {
  return author.get(
    `/api/v1/service-manage/services-list/salon?PageIndex=${PageIndex}&PageSize=${PageSize}&SalonId=${SalonId}&ServiceId=${ServiceId}`
  )
}

export const updateServicesSalonConfig = (body: any) => {
  return author.put(`/api/v1/service-manage/services-list/update`, body)
}

export const updateSalon = (SalonId: string, body: any) => {
  return author.put(`/api/v1/salon/detail-salon/${SalonId}/update`, body)
}

export const createSalon = (body: any) => {
  return author.post(`/api/v1/salon/create`, body)
}
