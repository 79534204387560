/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button, Form, Select, TreeSelect } from "antd";
import { useEffect, useState } from "react";
import { DeleteFilled, PlusCircleOutlined } from "@ant-design/icons";
import './index.scss'
import convert from "utils/helpers/convert";
import { useMediaQuery } from "react-responsive";

interface Props {
    listDepartments: any,
    group: number,
    listGroupSalon: any,
    listGroupOffice: any,
    jobPosition: any,
    setJobPosition: any,
    setLevelList: any,
    isAddedType: number,
    setLoading: any
    defaultData: any
    defaultgGroup: number;
    isChangeType: boolean;
}

export default function SlectDepartments(props: Props) {
    const { listDepartments, defaultgGroup, group, listGroupSalon, listGroupOffice, setJobPosition, jobPosition, setLevelList, isAddedType, setLoading, defaultData, isChangeType } = props
    const [newData, setNewData] = useState([]);
    const [newListType, setNewType] = useState([]);
    const [waring, setWaring] = useState([])
    const isMobile = useMediaQuery({
        query: '(max-width: 500px)'
    })

    // khởi tạo giá trị
    useEffect(() => {
        if (group) {
            if (group === defaultgGroup && defaultData?.jobPosition) {
                setJobPosition(defaultData?.jobPosition)
                setLevelList(listGroupSalon.find((item) => item.id === defaultData?.jobPosition[0]?.staffTypeId)?.skillLevelIds)
            } else {
                setJobPosition([
                    {
                        departmentId: 0,
                        staffTypeId: 0,
                    }
                ])
            }
        }
    }, [group, defaultData, isAddedType, listGroupSalon])

    // check trùng
    useEffect(() => {
        if (jobPosition) {
            const duplicateIndexes = [];

            for (let i = 0; i < jobPosition.length; i++) {
                for (let j = i + 1; j < jobPosition.length; j++) {
                    if (jobPosition[i].departmentId === jobPosition[j].departmentId) {
                        duplicateIndexes.push(j);
                    }
                }
            }
            if (duplicateIndexes.length != 0) {
                setLoading(true)
            } else {
                setLoading(false)
            }
            setWaring(duplicateIndexes)
        }
    }, [jobPosition])

    useEffect(() => {
        if (jobPosition[0]?.staffTypeId) {
            setLevelList(newListType.find((item) => item.id === jobPosition[0]?.staffTypeId)?.skillLevelIds)
        }
    }, [jobPosition])

    // // Chuyển đổi giữa mã chính và mã kiêm nghiệm
    useEffect(() => {
        if (isAddedType == 2) {
            if (jobPosition[0]?.departmentId != 0 || jobPosition[0]?.staffTypeId != 0) {
                const newData = jobPosition[0]
                setJobPosition([newData])
            } else {
                setJobPosition([
                    {
                        departmentId: 0,
                        staffTypeId: 0,
                    }
                ])
            }
        }
    }, [isChangeType])

    // thay đổi chức danh
    const handleChangeType = (value: number, index: number, item: any) => {
        const newJobPosition = [...jobPosition]
        try {
            newJobPosition[index] = {
                departmentId: newJobPosition[index].departmentId,
                staffTypeId: item.id
            }
            if (index === 0) {
                setLevelList(item.skillLevelIds)
            }
            setJobPosition(newJobPosition)
        } catch (error) {
            console.log(error)
        }
    };

    // thay đổi phòng ban
    const handleChangeDepartment = (id: any, index: number) => {
        const newJobDepartment = [...jobPosition]
        try {
            newJobDepartment[index] = {
                departmentId: id,
                staffTypeId: newJobDepartment[index].staffTypeId
            }
            setJobPosition(newJobDepartment)
        } catch (error) {
            console.log(error)
        }
    };

    // thêm vị trí phụ
    const handleClicklAdd = () => {
        setJobPosition([
            ...jobPosition,
            {
                departmentId: 0,
                staffTypeId: 0
            }
        ])
    }

    // xóa vị trí cuối
    const handleDelete = (value) => {
        const newListJobPosition = jobPosition?.filter((item, index) => index !== value)
        setLoading(false)
        setJobPosition(newListJobPosition)
    }

    // gán dữ liệu 
    function createDepartmentTree(departments, group, parentId) {
        return departments
            .filter((item) => item.group === group && item.parentId === parentId)
            .map((item) => ({
                ...item,
                value: item.name,
                children: createDepartmentTree(departments, group, item.id)
            }));
    }
    useEffect(() => {
        if (!listDepartments) return
        if (group === 1) {
            const SalonDepartmentList = createDepartmentTree(listDepartments, 1, 1)

            const NewListGroupSalon = listGroupSalon.map((item: any, index: any) => ({
                ...item,
                value: item.name,
            }))
            setNewData(SalonDepartmentList)
            setNewType(NewListGroupSalon)
        } else if (group === 2) {
            const OfficeDepartmentList = createDepartmentTree(listDepartments, 2, 0)
            const NewListGroupOffice = listGroupOffice.map((item: any, index: any) => ({
                ...item,
                value: item.name,
            }))

            setNewData(OfficeDepartmentList)
            setNewType(NewListGroupOffice)
        }
    }, [group])

    return (
        <Form.Item
            label={
                <div style={{ display: "flex" }}>
                    <div style={{ color: "red", marginRight: 3, display: jobPosition?.length === 0 ? "none" : "" }}>*</div>
                    <div>Vị trí</div>
                </div>}
            name='jobPosition'
            rules={[{ required: jobPosition?.length === 0, message: 'Không được để trống' }]}
        >
            {jobPosition?.map((item, index) => (
                <>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: isMobile ? 'column' : 'row',
                            alignItems: 'end',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Form.Item
                            label=''
                            name=''
                            rules={[{ required: item?.departmentId !== 0 ? false : true, message: 'Không được để trống' }]}
                        >
                            <div style={{ display: "flex", alignItems: "flex-start", justifyItems: "center", flexDirection: "column" }}>
                                <div>Phòng ban: <span style={{ color: "red", display: isAddedType === 2 ? "none" : "" }}>{index === 0 ? "*vị trí chính*" : ""}</span></div>
                                {newData ?
                                    <TreeSelect
                                        key={JSON.stringify(newData)}
                                        treeDefaultExpandedKeys={[newData[0]?.id, item?.departmentId, 3, 4]}
                                        showSearch
                                        value={item?.departmentId !== 0 ? item?.departmentId : null}
                                        style={{ width: 250 }}
                                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                        placeholder="Chọn phòng ban"
                                        onChange={(id) => handleChangeDepartment(id, index)}
                                        treeData={newData}
                                        fieldNames={{ label: 'name', value: 'id' }}
                                        filterTreeNode={(input, option) =>
                                            convert.removeVietnameseAccent(option?.value ?? '')
                                                .indexOf(convert.removeVietnameseAccent(input)) >= 0
                                        }
                                        status={waring?.find((item) => item === index) ? "error" : ""}
                                    />
                                    :
                                    null
                                }
                            </div>
                        </Form.Item>
                        <Form.Item
                            label=''
                            name=''
                            rules={[{ required: item?.staffTypeId !== 0 ? false : true, message: 'Không được để trống' }]}
                        >
                            <div style={{ display: "flex", alignItems: "flex-start", justifyItems: "center", flexDirection: "column" }}>
                                <div>Chức danh: <span style={{ color: "red", display: isAddedType === 2 ? "none" : "" }}>{index === 0 ? "*vị trí chính*" : ""}</span></div>
                                <Select
                                    showSearch
                                    value={item?.staffTypeId !== 0 ?
                                        newListType.find((type) => type.id === item?.staffTypeId)?.value
                                        :
                                        null}
                                    style={{ width: 250, cursor: "pointer", backgroundColor: "white" }}
                                    placeholder='Chọn bộ phận'
                                    options={newListType}
                                    onChange={(value, item) => handleChangeType(value, index, item)}
                                    fieldNames={{ label: 'name', value: 'name' }}
                                    filterOption={(input, option) =>
                                        convert.removeVietnameseAccent(option?.value ?? '')
                                            .indexOf(convert.removeVietnameseAccent(input)) >= 0
                                    }
                                />
                            </div>
                        </Form.Item>
                        <div style={{ width: "46px", height: "32px", marginBottom: "10px" }}>
                            <Button
                                style={{
                                    display: index != 0 ? 'flex' : 'none',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: 'white'
                                }}
                                onClick={() => handleDelete(index)}
                            >
                                <DeleteFilled />
                            </Button>
                        </div>
                    </div>
                    {
                        waring?.find((item) => item === index) ?
                            <div style={{ color: "red" }}>Phòng ban bị trùng</div>
                            :
                            null
                    }
                </>
            ))}
            <div style={{ display: isAddedType === 2 ? "none" : "flex", justifyContent: "end" }}>
                <Button
                    style={{
                        marginTop: '10px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'white'
                    }}
                    icon={<PlusCircleOutlined />}
                    disabled={jobPosition[jobPosition.length - 1]?.departmentId === 0 || jobPosition[jobPosition.length - 1]?.staffTypeId === 0 || isAddedType === 2 || waring?.length != 0}
                    onClick={() => handleClicklAdd()}
                >
                    Thêm
                </Button>
            </div>
        </Form.Item>
    );
};