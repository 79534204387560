import '../index.scss'
import { Working } from './Working'
import { Employee } from './Employee'
import { Permission } from './Permission'
import { MFA } from './MFA'
import { History } from './History'
import { Tabs } from 'antd'
import type { TabsProps } from 'antd'
import { useState } from 'react'
import { useMediaQuery } from 'react-responsive'

type Props = {
  tabDefault: string
}
export const MenuStaff = ({ tabDefault }: Props) => {
  const [tab, setTab] = useState(tabDefault ? tabDefault : '1')
  const isMobile = useMediaQuery({
    query: '(max-width: 500px)'
  })
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Thông tin làm việc',
      children: <Working tab={tab} />
    },
    {
      key: '2',
      label: 'Thông tin nhân viên',
      children: <Employee tab={tab} />
    },
    {
      key: '3',
      label: 'Phân quyền',
      children: <Permission tab={tab} />
    },
    {
      key: '5',
      label: 'Bảo mật',
      children: <MFA tab={tab} />
    },
    {
      key: '4',
      label: 'Lịch sử',
      children: <History tab={tab} />
    }
  ]
  return (
    <>
      {isMobile ? (
        <div className='bg-[#F3F3F3] pb-5 tab-mobile'>
          <Tabs onChange={(e: string) => setTab(e)} defaultActiveKey={tabDefault ? tabDefault : '1'} items={items} />
        </div>
      ) : (
        <div className=' min-h-[100vh] m-auto bg-[#F3F3F3] pb-12'>
          <Tabs onChange={(e: string) => setTab(e)} defaultActiveKey={tabDefault ? tabDefault : '1'} items={items} />
        </div>
      )}
    </>
  )
}
