import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { ColumnConfig, DateFormat } from '../../base/util'
import { SpinIndicator } from 'antd/es/spin'
import { Checkbox, Button, notification, Pagination, InputNumber, Form, Row, Table, Spin, Select } from 'antd'
import { EditOutlined, SearchOutlined, LoadingOutlined } from '@ant-design/icons'
import {
  getListServices,
  GetListProducts,
  getListSalonProduct,
  getListProductSalon,
  updateSalonProduct,
  getListServicesFlowSalon,
  getListSalonFlowService
} from '../api'
import { convertToStringArray } from '../../../utils/selector/Array'
import { removeVietnamese } from '../../../utils/selector/removeVietnamese'
import { getSalons } from 'api/catalog'

export const ProductConfigSalon = () => {
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState(1)
  const [params, setParams] = useState<any>({
    inventoryType: '1'
  })
  const [isChooseService, setIsChooseService] = useState<boolean>(false)
  const [total, setTotal] = useState(0)
  const [loadingSelectSalon, setLoadingSelectSalon] = useState<boolean>(false)
  const [reload, setReload] = useState(false)
  const [loading, setLoading] = useState(false)
  const [serviceId, setServiceId] = useState<any>(null)
  const [loadingSelectService, setLoadingSelectService] = useState<boolean>(false)
  const antIcon: SpinIndicator = <LoadingOutlined style={{ fontSize: 24 }} spin />
  const [form] = Form.useForm()
  const [isCheckAll, setIsCheckAll] = useState(false)
  const [listSalon, setListSalon] = useState([])
  const [isChooseSalon, setIsChooseSalon] = useState<boolean>(false)
  const [listProducts, setListProducts] = useState([])
  const [listServices, setListServices] = useState([])
  console.log(listServices, 'listServices')
  const [mount, setMount] = useState(false)
  const [data, setData] = useState([])
  const [tableLoading, setTableLoading] = useState(false)
  const [dataChange, setDataChange] = useState([])
  const [listType, setListType] = useState([
    {
      value: '1',
      label: 'Hàng hóa dùng để bán'
    },
    {
      value: '2',
      label: 'Hàng hóa dùng trong dịch vụ'
    },
    {
      value: '3',
      label: 'Hàng hóa cho nhân viên'
    },
    {
      value: '4',
      label: 'Hàng xuất dùng chung'
    },
    {
      value: '5',
      label: 'Hàng từ nhà cung cấp'
    },
    {
      value: '6',
      label: 'Thiết bị'
    }
  ])
  const handleListSalon = async () => {
    try {
      const response: any = await getSalons()
      const { statusCode, data } = response
      if (statusCode === 200) {
        setListSalon(data)
      }
    } catch (error) {
      console.error('Error:', error.message)
    }
  }
  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page)
    setParams({ ...params, PageIndex: page, PageSize: pageSize })
  }
  const handleListServices = async () => {
    try {
      const response: any = await GetListProducts()
      console.log(response)
      if (response?.statusCode === 200) {
        setListProducts(response?.data?.data.filter((c) => c.publish === true))
        const filterProducts = response?.data?.data.filter(
          (product) => product.inventoryType === '1' && product.publish === true
        )
        setListServices(filterProducts)
        // setTotal(response?.total)
      }
    } catch (error) {
      console.error('Error:', error.message)
    }
  }
  useEffect(() => {
    handleListServices()
  }, [])
  useEffect(() => {
    handleListSalon()
  }, [])
  function updateOrAddProductById(products, updatedProduct) {
    // Sử dụng map để tạo một mảng mới với phần tử được cập nhật
    const updatedProducts = products.map((product) => {
      // Kiểm tra nếu productId và salonId của phần tử trùng với productId và salonId của sản phẩm cần cập nhật
      if (product.productId === updatedProduct.productId && product.salonId === updatedProduct.salonId) {
        // Thực hiện cập nhật
        return { ...product, ...updatedProduct }
      }
      // Nếu không phải sản phẩm cần cập nhật, giữ nguyên phần tử
      return product
    })

    // Kiểm tra xem sản phẩm có tồn tại trong mảng ban đầu không
    const isProductExist = updatedProducts.some(
      (product) => product.productId === updatedProduct.productId && product.salonId === updatedProduct.salonId
    )

    // Nếu sản phẩm không tồn tại, thêm nó vào cuối mảng
    if (!isProductExist) {
      updatedProducts.push(updatedProduct)
    }

    return updatedProducts
  }
  const handleOnChange = (item: any, field: string, value: any) => {
    const newData = [...data]
    if (field !== 'isCheckAll') {
      const targetItem = newData.find(
        (dataItem) => dataItem.productId === item.productId && dataItem.salonId === item.salonId
      )
      console.log(targetItem, 'targetItem')
      if (targetItem) {
        switch (field) {
          case 'isCheck':
            targetItem.isCheck = !targetItem.isCheck
            setData(newData)
            break
          case 'isInventoryCheck':
            targetItem.isInventoryCheck = !targetItem.isInventoryCheck
            setData(newData)
            break
          case 'Price':
            targetItem.price = value
            setData(newData)
            break
        }
        const newDataArrayUpdate = updateOrAddProductById(dataChange, targetItem)
        setDataChange(newDataArrayUpdate)
      }
    } else {
      newData.map((dataItem) => (dataItem.isCheck = !isCheckAll))
      setDataChange(newData)
      setData(newData)
      setIsCheckAll(!isCheckAll)
    }
  }
  console.log(dataChange, 'newdataChange')
  const column = [
    {
      title: () => {
        return <Checkbox onChange={() => handleOnChange(null, 'isCheckAll', null)}></Checkbox>
      },
      key: 'isCheck',
      render: (item, index, i) => {
        return <Checkbox checked={item.isCheck} onChange={() => handleOnChange(item, 'isCheck', null)}></Checkbox>
      }
    },
    {
      title: 'Salon',
      dataIndex: 'salonName',
      key: 'salonName'
    },
    {
      title: 'ID Sản phẩm',
      dataIndex: 'productId',
      key: 'productId'
    },
    {
      title: 'Sản phẩm',
      dataIndex: 'productName',
      key: 'productName'
    },
    {
      title: 'Mã sản phẩm',
      dataIndex: 'code',
      key: 'code'
    },
    {
      title: 'Cho phép kiểm',
      key: 'isInventoryCheck',
      render: (item, index, i) => {
        return (
          <Checkbox
            checked={item.isInventoryCheck}
            onChange={() => handleOnChange(item, 'isInventoryCheck', null)}
          ></Checkbox>
        )
      }
    },
    {
      title: 'Giá chính',
      dataIndex: 'originalPrice',
      key: 'originalPrice'
    },
    {
      title: 'Giá Salon',
      key: 'price',
      render: (record) => (
        <InputNumber
          disabled={record.inventoryType === '2'}
          value={record.price}
          onChange={(value: number) => handleOnChange(record, 'Price', value)}
        />
      )
    }
  ]

  const handleChangeValue = (changedValues, onReset: (field: string[]) => void) => {
    if (changedValues?.salonId) {
      onReset(['statusName'])
    }
  }

  const onValuesChange = (changedValues) => {
    const keyName = Object.keys(changedValues)[0]
    const value = changedValues[keyName]

    if (keyName.toLowerCase().includes('date')) {
      setParams({
        ...params
      })
    } else {
      setParams({ ...params, ...changedValues })
    }
    handleChangeValue(changedValues, onReset)
  }
  const onReset = (field: string[]) => {
    form.resetFields([...field])
  }
  const handleFetchListStaff = async (paramsSearch: any) => {
    try {
      setLoading(true)
      const res: any = await getListServices(paramsSearch)
      setLoading(false)
      if (res) {
        setTotal(res.totalRecords)
        setData(res.data.listData)
      }
    } catch (error) {
      notification.error({
        description: 'Không thể thao tác, vui lòng thử lại sau',
        message: 'Lỗi'
      })
    }
    setLoading(false)
    setReload(false)
  }

  const onFinish = async () => {
    const value = form.getFieldsValue()
    console.log(value, 'value')
    if (!value.salonId && !value.productId) {
      return
    }
    console.log(isChooseSalon, 'isChooseSalon')
    if (isChooseSalon) {
      setTableLoading(true)
      let response
      if (value.salonId) {
        const paramsend = {
          inventoryType: params.inventoryType,
          productIds: convertToStringArray(value.productId)
        }
        response = await getListSalonProduct(value.salonId, paramsend)
      } else {
        const paramsend = {
          inventoryType: params.inventoryType,
          salonIds: convertToStringArray(value.salonId)
        }
        response = await getListProductSalon(value.productId, paramsend)
      }
      if (response?.statusCode === 200) {
        setData(response?.data)
      } else {
        notification.error({
          description: response?.message,
          message: 'Lỗi'
        })
      }
    } else {
      setTableLoading(true)
      let response
      if (value.productId) {
        const paramsend = {
          inventoryType: params.inventoryType,
          salonIds: convertToStringArray(value.salonId)
        }
        response = await getListProductSalon(value.productId, paramsend)
      } else {
        const paramsend = {
          inventoryType: value.inventoryType,
          productIds: convertToStringArray(value.productId)
        }
        response = await getListSalonProduct(value.salonId, paramsend)
      }
      if (response?.statusCode === 200) {
        setData(response?.data)
      } else {
        notification.error({
          description: response?.message,
          message: 'Lỗi'
        })
      }
    }
    setTableLoading(false)
  }

  const prefetchDataStaffList = () => {
    const paramsSearchResult = window.location.search.replace('?', '')
    console.log(paramsSearchResult, 'paramsSearchResult')
    if (Object.keys(paramsSearchResult).length > 0) {
      const params = new URLSearchParams(paramsSearchResult)
      const paramsObject = {}
      params.forEach(function (value, key) {
        paramsObject[key] = value
      })
      handleFetchListStaff(paramsObject)
    } else {
      setData([])
    }
    if (!mount) setMount(true)
  }

  useEffect(() => {
    prefetchDataStaffList()
  }, [window.location.search])

  const handleUpdateConfig = async () => {
    try {
      const body = dataChange
        .map((c) => {
          return {
            salonId: c.salonId,
            productId: c.productId,
            price: c.price ? c.price : c.originalPrice,
            isCheck: c.isCheck ? true : false,
            isInventoryCheck: c.isInventoryCheck ? true : false
          }

          return undefined
        })
        .filter((item) => item !== undefined && item !== null)
      const response: any = await updateSalonProduct(body)
      if (response.statusCode === 200) {
        notification.success({
          message: response.message
        })
      } else {
        notification.error({
          description: response.message,
          message: 'Lỗi'
        })
      }
    } catch (error) {
      notification.error({
        description: error.message,
        message: 'Lỗi'
      })
    }
  }
  const handleOnchangeSalon = async (e: any) => {
    if (!isChooseService) {
      console.log(params, 'params')
      try {
        setIsChooseSalon(true)
        setLoadingSelectService(true)
        handleOnchangeServices(undefined)
        if (e) {
          const response = await getListServicesFlowSalon({}, e)
          // console.log(response, 'response')
          if (response.data) {
            const listServices = response.data?.filter((product) => product.inventoryType === params.inventoryType)
            const dataMap = listServices.map((item) => {
              return {
                productId: item.productId,
                name: '(' + item.productId + ') ' + item.productName
              }
            })
            setListServices(dataMap)
          }
        } else {
          const listServices = listProducts.filter(
            (product) => product.inventoryType === params.inventoryType && product.publish === true
          )
          setListServices(listServices)
        }

        setLoadingSelectService(false)
      } catch (error) {
        setLoadingSelectService(false)
        console.error('Error:', error)
        notification.error({
          description: error.message,
          message: 'Lỗi'
        })
      }
    }
    form.setFieldValue('salonId', e)
    setParams({ ...params, ['salonId']: e })
  }
  console.log(params, 'params')
  const handleOnchangeType = async (e: any) => {
    try {
      handleOnchangeServices(undefined)
      form.setFieldValue('inventoryType', e)
      setParams({ ...params, ['inventoryType']: e })
      setLoadingSelectService(true)
      if (e) {
        const dataProducts = [...listProducts]
        const filterProducts = dataProducts.filter((product) => product.inventoryType === e.toString())
        setListServices(filterProducts)
      } else {
        handleListServices()
      }
      setLoadingSelectService(false)
    } catch (error) {
      setLoadingSelectService(false)
      console.error('Error:', error)
      notification.error({
        description: error.message,
        message: 'Lỗi'
      })
    }
  }
  // console.log(listServices, 'listServices')
  const handleOnchangeServices = async (e: any) => {
    if (!isChooseSalon && e) {
      try {
        // console.log(body, 'body')
        setIsChooseService(true)
      } catch (error) {
        setLoadingSelectSalon(false)
        console.error('Error:', error)
        notification.error({
          description: error.message,
          message: 'Lỗi'
        })
      }
    }
    form.setFieldValue('productId', e)
    setParams({ ...params, ['productId']: e })
  }

  const handleResetFilter = () => {
    form.resetFields()
    setIsChooseSalon(false)
    setIsChooseService(false)
    handleListServices()
  }
  return (
    <div className='min-h-[100vh] m-auto bg-[#F3F3F3] pb-12'>
      <div className='w-full px-5 py-5 search-wrapper'>
        <Form className='pb-10' form={form} onFinish={onFinish} onValuesChange={onValuesChange}>
          <h2 className='text-2xl font-bold mb-7'>Cấu hình sản phẩm Salon</h2>
          <Row className=''>
            <Form.Item
              name='salonId'
              label='Chọn Salon'
              // rules={[{ required: isChooseService, message: 'Bạn phải chọn Salon' }]}
              className='w-[300px]'
              // key={salonId}
            >
              {isChooseService ? (
                <Select
                  allowClear
                  className='w-full'
                  placeholder={'Chọn Salon'}
                  fieldNames={{ label: 'name', value: 'id' }}
                  options={listSalon}
                  showSearch
                  optionFilterProp='children'
                  onChange={(e) => handleOnchangeSalon(e)}
                  filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
                  loading={loadingSelectSalon}
                  disabled={loadingSelectSalon}
                  mode='multiple'
                />
              ) : (
                <Select
                  allowClear
                  className='w-full'
                  placeholder={'Chọn Salon'}
                  fieldNames={{ label: 'name', value: 'id' }}
                  options={listSalon}
                  showSearch
                  optionFilterProp='children'
                  onChange={(e) => handleOnchangeSalon(e)}
                  filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
                  loading={loadingSelectSalon}
                  disabled={loadingSelectSalon}
                />
              )}
            </Form.Item>
            <Form.Item name='inventoryType' label='Loại' className='w-[300px] ml-10'>
              <Select
                allowClear
                className='w-full'
                defaultValue={'1'}
                options={listType}
                showSearch
                optionFilterProp='children'
                onChange={(e) => handleOnchangeType(e)}
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              />
            </Form.Item>
            <Form.Item
              name='productId'
              label='Chọn sản phẩm'
              // rules={[{ required: isChooseService, message: 'Bạn phải chọn sản phẩm' }]}
              className='w-[400px] ml-10'
              key={JSON.stringify(serviceId)}
            >
              {isChooseSalon ? (
                <Select
                  allowClear
                  className='w-full'
                  defaultValue={serviceId ? serviceId : undefined}
                  fieldNames={{ label: 'name', value: 'productId' }}
                  placeholder={'Chọn sản phẩm'}
                  options={listServices}
                  showSearch
                  optionFilterProp='children'
                  onChange={(e) => handleOnchangeServices(e)}
                  filterOption={(input, option) =>
                    removeVietnamese(option.name).includes(removeVietnamese(input)) ||
                    option?.productId?.toString().includes(input)
                  }
                  loading={loadingSelectService}
                  // disabled={loadingSelectService}
                  mode='multiple'
                />
              ) : (
                <Select
                  allowClear
                  className='w-full'
                  fieldNames={{ label: 'name', value: 'productId' }}
                  defaultValue={serviceId ? serviceId : undefined}
                  placeholder={'Chọn sản phẩm'}
                  options={listServices}
                  showSearch
                  optionFilterProp='children'
                  onChange={(e) => handleOnchangeServices(e)}
                  filterOption={(input, option) =>
                    removeVietnamese(option.name).includes(removeVietnamese(input)) ||
                    option?.productId?.toString().includes(input)
                  }
                  loading={loadingSelectService}
                  // disabled={loadingSelectService}
                />
              )}
            </Form.Item>
          </Row>
          <Row className='mt-3'>
            <Button
              type='default'
              htmlType='submit'
              className='w-[160px] bg-[#4096ff] text-white hover:bg-white'
              disabled={loading}
            >
              {!loading ? (
                <div className='flex items-center justify-center hover_icon'>
                  <SearchOutlined className='mr-1 text-white' />
                  Xem dữ liệu
                </div>
              ) : (
                <Spin indicator={antIcon} />
              )}
            </Button>
            <Button
              onClick={() => handleResetFilter()}
              type='default'
              className='w-[160px] bg-[#4096ff] text-white hover:bg-white'
            >
              Reset Filter
            </Button>
          </Row>
          <div
            className='mt-5 bg-white'
            style={{ boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px', borderRadius: 10 }}
          >
            <Row style={{ display: 'contents' }} gutter={18}>
              <Table
                scroll={{ x: 1500, y: 550 }}
                className='min-w-[1000px] overflow-auto'
                dataSource={data}
                columns={column}
                pagination={false}
                bordered
                loading={tableLoading}
              />
            </Row>
          </div>
        </Form>
        <Button type='primary' onClick={handleUpdateConfig}>
          Cập nhật cấu hình
        </Button>
      </div>
    </div>
  )
}
